import React from "react";


function SVGremoveConnection() {
  return (
    <svg className='marginSVG' viewBox="0 0 24 24" width="20" height="20" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.008 21.092l-3.392 1.959c-3.384 1.954-7.713.794-9.668-2.592-1.953-3.385-.793-7.713 2.591-9.667l3.393-1.959 
        1.495 2.589-3.393 1.96c-1.95 1.125-2.622 3.631-1.495 5.581 1.126 1.953 3.631 2.625 5.582 1.495l3.392-1.956 1.495 
        2.59zm-3.214-17.553l-1.959 3.392 2.589 1.497 1.959-3.393c1.126-1.95 3.631-2.622 5.581-1.495 1.952 1.127 2.624 3.629 1.499 
        5.582l-1.96 3.392 2.589 1.494 1.96-3.391c1.952-3.387.792-7.714-2.59-9.667-3.387-1.955-7.716-.795-9.668 2.589zm7.398 
        20.217l-1.724.244-.523-3.688 1.722-.243.525 3.687zm5.808-7.284l-.249 1.724-3.688-.527.247-1.722 3.69.525zm-5.733 
        3.013l1.229-1.23 2.638 2.636-1.231 1.23-2.636-2.636z" fill="#002c2c"/>
    </svg>
  );
}

export default SVGremoveConnection;