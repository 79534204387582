import React, { useState, useRef, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import FigureClaimSupport from '../figureClaimStatelessSupportUpdate';
import FigureClaimContra from '../figureClaimStatelessContradictUpdate';
import { useNavigate } from "react-router-dom"
import { history } from '../../routers/AppRouter'
import { startAddClaimFollow, startRemoveClaimFollow, returnClaimInfoHomeFeed, updateLastViewedClaim} from '../../actions/claimModels';
import Tippy from '@tippyjs/react'
import moment from 'moment';
import SVGConnection from '../SVGs/SVGConnection';
import SVGaddFigure from '../SVGs/SVGaddFigure';
import SVGDelete from '../SVGs/SVGDelete';
import SVGOptionsDots from '../SVGs/SVGoptionsDots';
import DeleteModal from '../deleteModalClaim';
import { v4 as uuidv4 } from 'uuid';


const Claim = (props) => {
    const [optionsOpen, setOptionsOpen] = useState(false)
    const navigate = useNavigate()
    const connectClaim = () => {
        navigate('/ConnectClaim/' + props.id)
    }

    function getUniqueEngagement (objects) {
        const seenIds = new Set();
        return objects.filter(obj => {
            if (seenIds.has(obj.id)) {
            return false;
            } else {
            seenIds.add(obj.id);
            return true;
            }
        });
    }

    function removeEngagement (array1, array2) {
        return array1.filter(obj1 => !array2.some(obj2 => obj1.occurrence === obj2.occurrence));
    }

    //Here are all of the state values that need to be kept track of for the claim update
    const [followed, setFollowed] = useState(false)
    const [uniqueEngagement, setUniqueEngagement] = useState([])
    const [totalEngagement, setTotalEngagement] = useState([])
    const [deleteModalState, setDeleteModalState] = useState(false)
    const [timeStampPageVisit, setTimeStampPageVisit] = useState()
    const [title, setTitle] = useState('')
    const [owner, setOwner] = useState('')
    const [ownerTags, setOwnerTags] = useState([])
    const [localDate, setLocalDate] = useState()
    const [paperDoi, setPaperDoi] = useState('')
    const [paperID, setPaperID] = useState('')
    const [updatedLastViewedAlready, setUpdatedLastViewedAlready] = useState(false)
    const [doesNotSupportTally, setDoesNotSupportTally] = useState(0)
    const [somewhatSupportsTally, setSomewhatSupportsTally] = useState(0)
    const [reallySupportsTally, setReallySupportsTally] = useState(0)
    const [doesNotContradictTally, setDoesNotContradictTally] = useState(0)
    const [somewhatContradictsTally, setSomewhatContradictsTally] = useState(0)
    const [reallyContradictsTally, setReallyContradictsTally] = useState(0)
    const [extractedClaim, setExtractedClaim] = useState(false)
    const [associatedTheories, setAssociatedTheories] = useState([])
    const [claimConnections, setClaimConnections] = useState(false)
    const [comments, setComments] = useState(false)
    const [notDeleted, setNotDeleted] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [supportFigsToShow, setSupportFigsToShow] = useState([])
    const [contraFigsToShow, setContraFigsToShow] = useState([])

    let optionsRefDiv = useRef();

    //The following three functions get the tally for each type of vote in order to calculate the initial confidence value.
    const gatherDoesNotSupportTally = (supportFigs) => {
        let currentTally = 0;
        supportFigs.forEach((fig) => {
            currentTally += fig.doesNotSupport.length;
        })
        return currentTally;
    }

    const gatherSomewhatSupportsTally = (supportFigs) => {
        let currentTally = 0;
        supportFigs.forEach((fig) => {
            currentTally += fig.somewhatSupports.length;
        })
        return currentTally;
    }

    const gatherReallySupportsTally = (supportFigs) => {
        let currentTally = 0;
        supportFigs.forEach((fig) => {
            currentTally += fig.reallySupports.length;
        })
        return currentTally;
    }

    const gatherDoesNotContradictTally = (contraFigs) => {
        let currentTally = 0;
        contraFigs.forEach((fig) => {
            currentTally += fig.doesNotSupport.length;
        })
        return currentTally;
    }

    const gatherSomewhatContradictsTally = (contraFigs) => {
        let currentTally = 0;
        contraFigs.forEach((fig) => {
            currentTally += fig.somewhatSupports.length;
        })
        return currentTally;
    }

    const gatherReallyContradictsTally = (contraFigs) => {
        let currentTally = 0;
        contraFigs.forEach((fig) => {
            currentTally += fig.reallySupports.length;
        })
        return currentTally;
    }

    //This method determines if there were figure connections, comments on figures, or if the claim 
    //was used in a theory ever since the user last saw the figure. It takes the whole claim document
    //as its first argument and the time the user last viewed the claim as its second arguments and
    //then returns an object with all the appropriate information.
    const getLatestUpdatesClaim = (theClaim, lastViewed) => {
        let supportFigs = []
        let contraFigs = []
        let claimConnections = false;
        let comments = false;
        let associatedTheories = false;
        let theTheories = [];
        let anyUpdate = false;
        for (const supportFig of theClaim.supportFigs) {
            const date1 = new Date(supportFig.timeStamp)
            const date2 = new Date(lastViewed)
            let supportFigToAdd = {
                figure: supportFig,
                messages: []
            }
            if (date1 > date2) {
                supportFigToAdd.messages.push('- This figure was recently connected to the claim.')
                claimConnections = true;
                anyUpdate = true;
            }
            for (const criticism of supportFig.criticisms) {
                const date1 = new Date(criticism.timeStamp)
                const date2 = new Date(lastViewed)
                if (date1 > date2) {
                    supportFigToAdd.messages.push('- Fellows are commenting on this figure.')
                    anyUpdate = true;
                    comments = true;
                }
            }
            supportFigs.push(supportFigToAdd)
        }
        for (const contraFig of theClaim.contraFigs) {
            const date1 = new Date(contraFig.timeStamp)
            const date2 = new Date(lastViewed)
            let contraFigToAdd = {
                figure: contraFig,
                messages: []
            }
            if (date1 > date2) {
                contraFigToAdd.messages.push('- This figure was recently connected to the claim.')
                claimConnections = true;
                anyUpdate = true;
            }
            for (const criticism of contraFig.criticisms) {
                const date1 = new Date(criticism.timeStamp)
                const date2 = new Date(lastViewed)
                if (date1 > date2) {
                    contraFigToAdd.messages.push('- Fellows are commenting on this figure.')
                    anyUpdate = true;
                    comments = true;
                }
            }
            contraFigs.push(contraFigToAdd)
        }
        for (const update of theClaim.associatedTheories) {
            const date1 = new Date(update.timeStamp)
            const date2 = new Date(lastViewed)
            if (date1 > date2) {
                associatedTheories = true;
                if (!theTheories.includes(update.id)) {
                    theTheories.push(update.id)
                }
                anyUpdate = true;
            }
        }
        return { 
                theTheories: theTheories,
                supportFigs: supportFigs,
                contraFigs: contraFigs,
                anyUpdate: anyUpdate,
                comments: comments,
                claimConnections: claimConnections
             }
    }

    function removeElementAtIndex(index, array) {
        // Check if the index is within the bounds of the array
        if (index > -1 && index < array.length) {
            array.splice(index, 1);
        } else {
            // console.log("Index out of bounds");
        }
        return array;
    }
    

    useEffect( async () => {
        //Make call to Firebase with returnFigureInfoUpdates function to fetch info off of database.
        let claimInfo = await props.returnClaimInfoHomeFeed(props.id)
        //If info is able to be fetched, update all relevant state values.
        if (claimInfo) {
            const theDate = new Date(claimInfo.timeStamp)
            const newMoment = moment(theDate)
            newMoment.local()
            const theLocalDate = newMoment.format('LLL')
            const updates = getLatestUpdatesClaim(claimInfo, props.lastViewed)

            //Here we record the time that the info of this figure was pulled off of Firebase. This will
            //be used as the time the user last viewed this figure. We call the appropriate function:
            let timeStamp = moment().utc().toString()
            setTimeStampPageVisit(timeStamp)
            //Here we check the "anyUpdate" value from calling the getLastestUpdatesFigure to see if there are any
            //updates. If there are, we set the state for all the variables we need.

            if (updates.anyUpdate) {
                for(var i = 0; i < claimInfo.follows.length; i++) {
                    if (claimInfo.follows[i].id === props.user) {
                        setFollowed(true)
                        break;
                    }
                }
                setTotalEngagement(claimInfo.engagement)
                setTitle(claimInfo.title)
                setAssociatedTheories(updates.theTheories)
                setDoesNotSupportTally(gatherDoesNotSupportTally(claimInfo.supportFigs))
                setSomewhatSupportsTally(gatherSomewhatSupportsTally(claimInfo.supportFigs))
                setReallySupportsTally(gatherReallySupportsTally(claimInfo.supportFigs))
                setDoesNotContradictTally(gatherDoesNotContradictTally(claimInfo.contraFigs))
                setSomewhatContradictsTally(gatherSomewhatContradictsTally(claimInfo.contraFigs))
                setReallyContradictsTally(gatherReallyContradictsTally(claimInfo.contraFigs))
                setSupportFigsToShow(updates.supportFigs)
                setContraFigsToShow(updates.contraFigs)
                setLocalDate(theLocalDate)
                setExtractedClaim(claimInfo.extractedClaim)
                setPaperID(claimInfo.paperID)
                setPaperDoi(claimInfo.paperDoi)
                setOwnerTags(claimInfo.ownerTags)
                setOwner(claimInfo.owner)
                setClaimConnections(updates.claimConnections)
                setComments(updates.comments)
                setNotDeleted(true)
                setLoaded(true)
            } 

        } else {
            //If the figure is not available on Firebase, it is probably because it was deleted.
            //If it was deleted, nothing should be shown.

            //Also set to false if there are no updates to be shown for this claim so that it does
            //not show up.
            setNotDeleted(false)
        }
    }, [])


    useEffect(() => {
        let theUniqueEngagement = getUniqueEngagement(totalEngagement)
        setUniqueEngagement(theUniqueEngagement)
    }, [totalEngagement])

    useEffect(() => {
        let handler = (event) => {
            if(!optionsRefDiv.current.contains(event.target)) {
                setOptionsOpen(false)
            }
        }
        document.addEventListener("mousedown", handler)
        return () => {
            document.removeEventListener("mousedown", handler)
        }
    })

    //This function takes into account all of the vote tallies and calculates the confidence of the claim.
    const checkingIt2 = () => {
        let supportCalculation = 100 * (reallySupportsTally*3 + somewhatSupportsTally)
        let contradictCalculation = (reallyContradictsTally*3 + somewhatContradictsTally - doesNotContradictTally + doesNotSupportTally 
            + reallySupportsTally*3 + somewhatSupportsTally)
        
        if (supportCalculation < 0 || isNaN(supportCalculation) ) {
            supportCalculation = 0;
        }

        if (contradictCalculation < 0 || isNaN(contradictCalculation) ) {
            contradictCalculation = 1;
        }

        let calculation = supportCalculation/contradictCalculation;

        if (calculation < 0) {
            calculation = 0;
        }

        if (isNaN(calculation)) {
            
            return "?";
        }

        if (Math.trunc(calculation) > 100) {
            return '100%'
        }

        if (Math.trunc(calculation) < 0) {
            return '0%'
        }

        return Math.trunc(calculation) + "%";
    }

    //This is just a function that allows the user to follow the claim or unfollow the claim.
    const followClaim = () => {
        setFollowed(!followed)
        if (!followed) {
            let timeStamp = moment().utc().toString()
            let occurrence = uuidv4();
            let timeStamps = {
                followed: timeStamp,
                visited: timeStampPageVisit
            }
            props.startAddClaimFollow(props.user, props.id, occurrence, timeStamps).then(() => {
                
            }).catch(() => {
                // console.log('claim was not followed')
            })
        } else if (followed) {
            props.startRemoveClaimFollow(props.user, props.id).then(() => {
                
            }).catch(() => {
                // console.log('claim was not followed')
            })
        } else {
            // console.log('something wrong with claim-follow logic')
        }
        
    }





    let noReload;
    if (history.location.pathname != window.location.pathname) {
        noReload = false;
        window.location.reload(false);
    } else {
        noReload = true;
    }


    

    const reportFigure = () => {
        navigate('/Report/Claim/' + props.id)
    }

    const addClaim = () => {
        navigate('/AddClaim')
    }


    //This determines if the user has scrolled over the claim and thus has viewed it.
    //If so, the lastViewed time stamp will be updated on their profile, so call the 
    //function below.
    // const observer = useRef()
    // const lastUpdateElementRef = useCallback((node) => {
    //     if (observer.current) {
    //         observer.current.disconnect()
    //     }
    //     let timeStamp2 = moment().utc().toString()
    //     observer.current = new IntersectionObserver((entry) => {
    //         if (entry[0].isIntersecting && !updatedLastViewedAlready) {
    //             props.updateLastViewedClaim(props.user, props.id, timeStamp2)
    //             setUpdatedLastViewedAlready(true)
    //             // console.log('viewing')
    //         }
    //     })
    //     if (node) {
    //         observer.current.observe(node)
    //     }
        
    // }, [updatedLastViewedAlready])



    //some junk we probably wont use:

    // const colorList = {
    //     highest: 'rgb(98, 189, 184)',
    //     higher: 'rgb(98, 189, 169)',
    //     high: 'rgb(98, 189, 136)',
    //     somewhatHigh: 'rgb(134, 189, 98)',
    //     medium: 'rgb(157, 189, 98)',
    //     somewhatLow: 'rgb(169, 189, 98)',
    //     low: 'rgb(189, 180, 98)',
    //     lower: 'rgb(189, 112, 98)',
    //     lowest: 'rgb(228, 92, 92)'
    // }
    const colorList = [
        'rgb(228, 92, 92)',
        'rgb(189, 112, 98)',
        'rgb(189, 180, 98)',
        'rgb(169, 189, 98)',
        'rgb(157, 189, 98)',
        'rgb(134, 189, 98)',
        'rgb(98, 189, 136)',
        'rgb(98, 189, 169)',
        'rgb(98, 189, 184)'
    ]
    const setColor = (support, total) => {
        let ratio = (support/total * 10)-1;
        let ratioIndex = parseInt(ratio)
        const style = {
            fontSize: '120%',
            color: colorList[ratioIndex]
        }
        return style;
    }

    function removeSubstring(originalString) {
        let split = originalString.split('/')
        let theURL = '/' + split[3] + '/' + split[4]
        return theURL;
    }

    function handleClick(event) {
        event.preventDefault();
        let URL = removeSubstring(event.target.href, event.target.baseURI)
        navigate(URL)
    }


    return (
        <div className={loaded && notDeleted ? "" : "hidden"}>
            {deleteModalState && <DeleteModal id={props.id} closeModal={setDeleteModalState} element='claim' />}
            <div>
                <div className="majorContainerFigureUpdate">
                    <div className="smallFigureContainerHome">
                        <div className='figureUpdatePaddingHome'>
                            
                            <div className={associatedTheories.length > 0 ? 'updateLine' : 'hidden'}>
                            {
                                associatedTheories.map((theory, index) => {
                                    return <p key={index + theory}>- This claim was cited in a <a className='figureUpdateClaimAnchorHome' 
                                    onClick={handleClick} href={'/Theory/' + theory}>theory</a>.</p>
                                })
                            }
                            <p className={claimConnections ? '' : 'hidden'}>- Figures were recently connected to this claim.</p>
                            <p className={comments ? '' : 'hidden'}>- Fellows are commenting on figures connected to this claim.</p>
                            <div className={associatedTheories.length > 0 ? 'line3Claim' : 'hidden'}></div>
                            </div>
                            <div className='figureUpdateTitleHomeDiv'>
                                <a className='figureUpdateTitleHome' href={'/Claim/' + props.id} onClick={handleClick}>{title}</a>    
                            </div>
                            <div className={!extractedClaim ? 'hidden' : 'fromPaper'}><b>Source: <a className="paperAnchor" onClick={handleClick}
                            href={'/Paper/' + paperID}>
                                Paper</a></b>
                            </div>
                            <div className='flexClaimUpdate2'>
                                <div className='confidenceAndEngagementClaim'>
                                    <div className='centerVertical'>
                                        <div className='verticalStrecthFigure'>
                                            <div className='HomeNoWrap'><b>Confidence: {checkingIt2()}</b></div>
                                            <div className='HomeNoWrap'><b>Engagement: {uniqueEngagement.length}</b></div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div hidden={!props.loggedIn} ref={optionsRefDiv} className='topBarFigureUpdateHome'>
                                    
                                    <Tippy content={followed ? "Unfollow Claim" : "Follow Claim"} >
                                        <svg className={followed ? "followSVGSVGClaimUpdateClicked" : "followSVGSVGClaimUpdate"} onClick={followClaim} version="1.1" 
                                        viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                                            <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path className="color000 svgShape" d="m24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" 
                                                fill={followed ? "#fff" : "#002c2c"}/>
                                            </svg>
                                        </svg>
                                    </Tippy>
                                    <Tippy content='Options' >
                                        <svg hidden={!props.loggedIn} className="input5ClaimUpdate" onClick={() => setOptionsOpen(!optionsOpen)} version="1.1" 
                                        viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                                            <svg width="288" height="288" enableBackground="new 0 0 32.055 32.055" viewBox="0 0 32.055 32.055" 
                                            xmlns="http://www.w3.org/2000/svg">
                                                <path className="color000 svgShape" d="m3.968 12.061c-2.193 0-3.968 1.774-3.968 3.966s1.773 3.967 3.968 3.967c2.189 0 3.966-1.772 3.966-3.967 0-2.192-1.777-3.966-3.966-3.966zm12.265 0c-2.188 0-3.968 1.773-3.968 3.965s1.778 3.967 3.968 3.967 3.97-1.772 3.97-3.967c-2e-3 -2.191-1.78-3.965-3.97-3.965zm11.857 0c-2.192 0-3.969 1.774-3.969 3.967 0 2.19 1.774 3.965 3.969 3.965 2.188 0 3.965-1.772 3.965-3.965s-1.777-3.967-3.965-3.967z" fill="#002c2c"/>
                                            </svg>
                                        </svg>
                                    </Tippy>
                                    <div hidden={!optionsOpen} className='dropDownClaimUpdateHome'>
                                        <div className='optionsAnchorDiv' hidden={props.user != owner} onClick={() => {connectClaim()}}>
                                            <SVGConnection/>
                                            <a><b>Connect figures</b></a>
                                        </div>
                                        <div className='optionsAnchorDiv' onClick={() => {addClaim()}}>
                                            <SVGaddFigure/>
                                            <a><b>Create a new Claim</b></a>
                                        </div>
                                        <div className={props.user == owner ? 'optionsAnchorDiv' : 'hidden'} onClick={() => setDeleteModalState(true)} hidden={props.user != owner} 
                                        href={props.image}>
                                            <SVGDelete/>
                                            <a><b>Delete this Claim</b></a>
                                        </div>
                                        <div className='optionsAnchorDiv' onClick={reportFigure}>
                                            <svg className='marginSVG' width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 
                                                0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z" fill="#002c2c"/>
                                            </svg>
                                            <a><b>Report this Claim</b></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='verticalCenterClaimUpdate'>
                                <div>
                                    <div className='divAbove2Figure'>
                                        <div className='addedByFigure'><b>Uploaded by: </b></div>
                                            {
                                                ownerTags.map((tag, index) => {
                                                    return (<a key={tag.displayName + index} className="anchorNameClaim" onClick={handleClick}
                                                    href={'/Profile/' + tag.id }>{tag.displayName}
                                                    </a>)
                                                })
                                            }
                                        </div>
                                    <div className='localDateDivClaimUpdate2'><b>- {localDate}</b></div>
                                </div>
                            </div>
                        </div>
                        {
                            supportFigsToShow.map((fig, supportFigIndex) => {
                                return (
                                    <FigureClaimSupport key={fig.figure.id + supportFigIndex} length={supportFigsToShow.length > supportFigIndex + 1} loggedIn={props.loggedIn} 
                                    imgsrc={fig.figure.imgsrc} figid={fig.figure.id} user={props.user} claimID = {props.id} 
                                 reallySupports={fig.figure.reallySupports} somewhatSupports={fig.figure.somewhatSupports} 
                                 doesNotSupport={fig.figure.doesNotSupport} title={fig.figure.title} explanation={fig.figure.explanation}
                                 description={fig.figure.description} ownerTags={fig.figure.ownerTags} connector={fig.figure.owner} 
                                 criticisms={fig.figure.criticisms}  figType="support" tags={props.tags}
                                 userName={props.userName} index={supportFigIndex} messages={fig.messages}
                                 setDoesNotSupportTally={setDoesNotSupportTally} setSomewhatSupportsTally={setSomewhatSupportsTally} 
                                 setReallySupportsTally={setReallySupportsTally} 
                                 doesNotSupportTally={doesNotSupportTally} somewhatSupportsTally={somewhatSupportsTally} 
                                 reallySupportsTally={reallySupportsTally} totalEngagement={totalEngagement} 
                                 setTotalEngagement={setTotalEngagement} removeEngagement={removeEngagement}
                                 array={supportFigsToShow} setIndex={setSupportFigsToShow} figIndex={supportFigIndex}/>
                                )   
                            })
                        }
                        {
                            contraFigsToShow.map((fig, contraFigIndex) => {
                                return (
                                    <FigureClaimContra key={fig.figure.id + contraFigIndex} length={contraFigsToShow.length > contraFigIndex + 1} 
                                    imgsrc={fig.figure.imgsrc} figid={fig.figure.id} user={props.user} claimID = {props.id} loggedIn={props.loggedIn} 
                                 reallySupports={fig.figure.reallySupports} somewhatSupports={fig.figure.somewhatSupports} 
                                 doesNotSupport={fig.figure.doesNotSupport} title={fig.figure.title} explanation={fig.figure.explanation}
                                 description={fig.figure.description} ownerTags={fig.figure.ownerTags} connector={fig.figure.owner} 
                                 criticisms={fig.figure.criticisms}  figfigureType="contradict" tags={props.tags}
                                 userName={props.userName} index={contraFigIndex} messages={fig.messages}
                                 setDoesNotContradictTally={setDoesNotContradictTally} setSomewhatContradictsTally={setSomewhatContradictsTally} 
                                 setReallyContradictsTally={setReallyContradictsTally} 
                                 doesNotContradictTally={doesNotContradictTally} somewhatContradictsTally={somewhatContradictsTally} 
                                 reallyContradictsTally={reallyContradictsTally} totalEngagement={totalEngagement} 
                                 setTotalEngagement={setTotalEngagement} removeEngagement={removeEngagement}
                                 array={contraFigsToShow} setIndex={setContraFigsToShow} figIndex={contraFigIndex}
                                 />
                                )   
                            })
                        }
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state, props) => {
    if (state.profModels.length == 1) {
        return {
            user: state.profModels[0].id,
            userName: state.profModels[0].name,
            tags: state.profModels[0].tags,
            loggedIn: true,
        };
    } else {
        return {
            user: '',
            userName: '',
            tags: [],
            loggedIn: false
        };
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    startAddCriticism: (id, userName, criticism) => dispatch(startAddCriticism(id, userName, criticism)),
    startDeleteCriticism: (critText, id, imgsrc) => dispatch(startDeleteCriticism(critText, id, imgsrc)),
    startAddClaimFollow: (user, id, occurrence, timeStamp) => dispatch(startAddClaimFollow(user, id, occurrence, timeStamp)),
    startRemoveClaimFollow: (user, id) => dispatch(startRemoveClaimFollow(user, id)),
    updateLastViewedClaim: (user, id, timeStamp) => dispatch(updateLastViewedClaim(user, id, timeStamp)),
    returnClaimInfoHomeFeed: (claim) => dispatch(returnClaimInfoHomeFeed(claim))

})

const ConnectedClaim = connect(mapStateToProps, mapDispatchToProps)(Claim);

export default ConnectedClaim;