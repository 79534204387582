import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from "react-router-dom"
import { startSetProfile } from '../actions/profModels'
import Modal from './FigureShowModal'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import SVGoptionsDotsMyClaims from './SVGs/SVGoptionsDotsMyClaims';
import { history } from '../routers/AppRouter'
import useWindowDimensions from '../custom hooks/windowDimensions'
import { returnClaimInfoMyClaims2 } from '../actions/claimModels'
import Spinner from "react-svg-spinner";
import moment from 'moment'




export const myClaims = (props) => {
    const [whichTabIsIt, setWhichTabIsIt] = useState('Created Claims')
    const [percentageConfidences, setPercentageConfidences] = useState([])
    const [claimTitles, setClaimTitles] = useState([])
    const [claimEngagements, setClaimEngagements] = useState([])
    const [claimMoments, setClaimMoments] = useState([])
    const [claimTimeStamps, setClaimTimeStamps] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [claimsListCreated, setClaimsListCreated] = useState(props.myClaims.createdClaims)
    const [claimsListFollowed, setClaimsListFollowed] = useState(props.myClaims.followedClaims)
    const [claimsListPaper, setClaimsListPaper] = useState(props.myClaims.paperClaims)
    const [claimsListFigure, setClaimsListFigure] = useState(props.myClaims.figureClaims)
    const [claimsListCurrent, setClaimsListCurrent] = useState(props.myClaims.createdClaims)
    const [sortBySelector, setSortBySelector] = useState('None')
    const [selectorOpen, setSelectorOpen] = useState(false)
    const [redo, setRedo] = useState(false)
    let selectorRef = useRef();
    const navigate = useNavigate()

    let loaded2;
    if (props.myClaims != []) {
        loaded2 = false
    } else {
        loaded2 = true
    }

    const whichTab = (tab) => {
        if (loaded != true) {
            return;
        }
        setLoaded(false)
        if (tab === 'Created Claims') {
            setClaimsListCurrent(claimsListCreated)
            setSortBySelector('None')
        } else if (tab === 'Followed Claims') {
            setClaimsListCurrent(claimsListFollowed)
            setSortBySelector('None')
        } else if (tab === 'Claims from my papers') {
            setClaimsListCurrent(claimsListPaper)
            setSortBySelector('None')
        } else if (tab === 'Claims from my figures') {
            setClaimsListCurrent(claimsListFigure)
            setSortBySelector('None')
        }
        setWhichTabIsIt(tab)
    }


    const based = (index, id) => {
        let newArray1 = [...claimsListCurrent]
        let newArray2 = [...percentageConfidences]
        let newArray3 = [...claimTitles]
        let newArray4 = [...claimEngagements]
        let newArray5 = [...claimMoments]
        let newArray6 = [...claimTimeStamps]

        newArray1.splice(index, 1)
        newArray2.splice(index, 1)
        newArray3.splice(index, 1)
        newArray4.splice(index, 1)
        newArray5.splice(index, 1)
        newArray6.splice(index, 1)


        //If we are in the Created Claims tab, this method will delete the claim when called.
        if (whichTabIsIt === 'Created Claims') {
            let newArray7 = [...claimsListCreated]
            let nextList = newArray7.filter((claim) => {
                return claim.id !== id
            })
            setClaimsListCreated(nextList)
            //Also check to see if the claim is in the claimsListFollowed and remove it if it is.
            if (claimsListFollowed.includes(id)) {
                let newArray = claimsListFollowed.filter((claim) => {
                    return claim.id !== id;
                })
                setClaimsListFollowed(newArray)
            }
        }

        //If we are in the Followed Claims tab, this method will unfollow the claim when called.
        if (whichTabIsIt === 'Followed Claims') {
            let newArray7 = [...claimsListFollowed]
            let nextList = newArray7.filter((claim) => {
                return claim.id !== id
            })
            setClaimsListFollowed(nextList)
        }
        
        setClaimsListCurrent(newArray1)
        setPercentageConfidences(newArray2)
        setClaimTitles(newArray3)
        setClaimEngagements(newArray4)
        setClaimMoments(newArray5)
        setClaimTimeStamps(newArray6)

    }


   
    useEffect(() => {
        let handler = (event) => {
            if(!selectorRef.current.contains(event.target)) {
                setSelectorOpen(false)
            }
        }
        document.addEventListener("mousedown", handler)
        return () => {
            document.removeEventListener("mousedown", handler)
        }
    })

    useEffect(async () => {
        let thePercentageConfidences = []
        let theTitles = []
        let theEngagements = []
        let theMoments = []
        let claimTimeStamps = []

        for (const claim of claimsListCurrent) {
            let claimInfo = await props.returnClaimInfoMyClaims2(claim.id)
            theTitles.push(claimInfo.title)
            theEngagements.push(claimInfo.engagementNumber)
            theMoments.push(claimInfo.timeStamp)
            claimTimeStamps.push(claimInfo.timeStamp.format('LLL'))
            if (isNaN(claimInfo.confidence)) {
                thePercentageConfidences.push('?')
            } else {
                thePercentageConfidences.push(claimInfo.confidence)
            }
        }
        setPercentageConfidences(thePercentageConfidences)
        setClaimTitles(theTitles)
        setClaimEngagements(theEngagements)
        setClaimMoments(theMoments)
        setClaimTimeStamps(claimTimeStamps)
        setLoaded(true)
        
    }, [whichTabIsIt, redo])
    
    const openSelector = () => {
        setSelectorOpen(!selectorOpen)
    }

    // useEffect(async () => {
    //     fetch('https://www.doi2bib.org/doi2bib?id=10.1016%2Fj.actpsy.2016.09.007').then((res) => {
    //         console.log(res)
    //     }).catch((err) => {
    //         console.log('there was an error')
    //     })
    // }, [])

    const sortClaimsByConfidence = () => {
        //if Confidence is already selected, don't do anything.
        if (sortBySelector === 'Confidence') {
            setSelectorOpen(false)
            return;
        }
        
        //combine all data into array of objects for easy sorting.
        let allArrayData = []
        for (let i = 0; i < claimTitles.length; i++) {
            let item = {
                title: claimTitles[i],
                engagements: claimEngagements[i],
                confidence: percentageConfidences[i],
                id: claimsListCurrent[i],
                moment: claimMoments[i],
                timeStamp: claimTimeStamps[i]
            }
            allArrayData.push(item)
        }
        //sort allArrayData on the basis of Engagement
        allArrayData.sort((a,b) => {
            if (a.confidence !== '?' && b.confidence !== '?') {
                return b.confidence - a.confidence; 
            } else if (a.confidence == '?') {
                return 1;
            } else if (b.confidence == '?') {
                return -1;
            }
        })
        
        //create a new array for dates, engagements, confidences, and timeStamps and recreate the state arrays
        let newEngagements = []
        let newConfidences = []
        let newTitles = []
        let newClaimsList = []
        let newMoments = []
        let newTimeStamps = []
        for (let i = 0; i < allArrayData.length; i++) {
            newEngagements.push(allArrayData[i].engagements)
            newConfidences.push(allArrayData[i].confidence)
            newTitles.push(allArrayData[i].title)
            newClaimsList.push(allArrayData[i].id)
            newMoments.push(allArrayData[i].moment)
            newTimeStamps.push(allArrayData[i].timeStamp)
        }
        
        //all we have to do is set everything to the original list, which is stored when the page is loaded and never changes
        setPercentageConfidences(newConfidences)
        setClaimEngagements(newEngagements)
        setClaimsListCurrent(newClaimsList)
        setClaimTitles(newTitles)
        setClaimMoments(newMoments)
        setClaimTimeStamps(newTimeStamps)
        setSelectorOpen(false)
        setSortBySelector('Confidence')
    }

    const sortClaimsByEngagement = () => {
        //if Engagement is already selected, don't do anything.
        if (sortBySelector === 'Engagement') {
            setSelectorOpen(false)
            return;
        }

        //combine all data into array of objects for easy sorting.
        let allArrayData = []
        for (let i = 0; i < claimTitles.length; i++) {
            let item = {
                title: claimTitles[i],
                engagements: claimEngagements[i],
                confidence: percentageConfidences[i],
                id: claimsListCurrent[i],
                moment: claimMoments[i],
                timeStamp: claimTimeStamps[i]
            }
            allArrayData.push(item)
        }

        //sort allArrayData on the basis of Engagement
        allArrayData.sort((a,b) => b.engagements - a.engagements)

        //create a new array for dates, engagements, confidences, and timeStamps and recreate the state arrays
        let newEngagements = []
        let newConfidences = []
        let newTitles = []
        let newClaimsList = []
        let newMoments = []
        let newTimeStamps = []
        for (let i = 0; i < allArrayData.length; i++) {
            newEngagements.push(allArrayData[i].engagements)
            newConfidences.push(allArrayData[i].confidence)
            newTitles.push(allArrayData[i].title)
            newClaimsList.push(allArrayData[i].id)
            newMoments.push(allArrayData[i].moment)
            newTimeStamps.push(allArrayData[i].timeStamp)
        }
        
        //all we have to do is set everything to the original list, which is stored when the page is loaded and never changes
        setPercentageConfidences(newConfidences)
        setClaimEngagements(newEngagements)
        setClaimsListCurrent(newClaimsList)
        setClaimTitles(newTitles)
        setClaimMoments(newMoments)
        setClaimTimeStamps(newTimeStamps)
        setSelectorOpen(false)
        setSortBySelector('Engagement')
    }

    const sortClaimsByNewest = (tab) => {
        //if Oldest is already selected, don't do anything.
        if (sortBySelector === 'Newest') {
            setSelectorOpen(false)
            return;
        }

        //combine all data into array of objects for easy sorting.
        let allArrayData = []
        for (let i = 0; i < claimTitles.length; i++) {
            let item = {
                title: claimTitles[i],
                engagements: claimEngagements[i],
                confidence: percentageConfidences[i],
                id: claimsListCurrent[i],
                moment: claimMoments[i],
                timeStamp: claimTimeStamps[i]
            }
            allArrayData.push(item)
        }

        //sort allArrayData on the basis of Engagement
        allArrayData.sort((a,b) => {
            if (a.moment.isBefore(b.moment)) {
                return 1; 
            } else {
                return -1;
            }
        })


        //create a new array for dates, engagements, confidences, and timeStamps and recreate the state arrays
        let newEngagements = []
        let newConfidences = []
        let newTitles = []
        let newClaimsList = []
        let newMoments = []
        let newTimeStamps = []
        for (let i = 0; i < allArrayData.length; i++) {
            newEngagements.push(allArrayData[i].engagements)
            newConfidences.push(allArrayData[i].confidence)
            newTitles.push(allArrayData[i].title)
            newClaimsList.push(allArrayData[i].id)
            newMoments.push(allArrayData[i].moment)
            newTimeStamps.push(allArrayData[i].timeStamp)
        }
        
        //all we have to do is set everything to the original list, which is stored when the page is loaded and never changes
        setPercentageConfidences(newConfidences)
        setClaimEngagements(newEngagements)
        setClaimsListCurrent(newClaimsList)
        setClaimTitles(newTitles)
        setClaimMoments(newMoments)
        setClaimTimeStamps(newTimeStamps)
        setSelectorOpen(false)
        setSortBySelector('Newest')
    }

    const sortClaimsByOldest = (tab) => {
        //if Oldest is already selected, don't do anything.
        if (sortBySelector === 'Oldest') {
            setSelectorOpen(false)
            return;
        }

        //combine all data into array of objects for easy sorting.
        let allArrayData = []
        for (let i = 0; i < claimTitles.length; i++) {
            let item = {
                title: claimTitles[i],
                engagements: claimEngagements[i],
                confidence: percentageConfidences[i],
                id: claimsListCurrent[i],
                moment: claimMoments[i],
                timeStamp: claimTimeStamps[i]
            }
            allArrayData.push(item)
        }

        //sort allArrayData on the basis of Engagement
        allArrayData.sort((a,b) => {
            if (a.moment.isBefore(b.moment)) {
                return -1; 
            } else {
                return 1;
            }
        })

        //create a new array for dates, engagements, confidences, and timeStamps and recreate the state arrays
        let newEngagements = []
        let newConfidences = []
        let newTitles = []
        let newClaimsList = []
        let newMoments = []
        let newTimeStamps = []
        for (let i = 0; i < allArrayData.length; i++) {
            newEngagements.push(allArrayData[i].engagements)
            newConfidences.push(allArrayData[i].confidence)
            newTitles.push(allArrayData[i].title)
            newClaimsList.push(allArrayData[i].id)
            newMoments.push(allArrayData[i].moment)
            newTimeStamps.push(allArrayData[i].timeStamp)
        }
        
        //all we have to do is set everything to the original list, which is stored when the page is loaded and never changes
        setPercentageConfidences(newConfidences)
        setClaimEngagements(newEngagements)
        setClaimsListCurrent(newClaimsList)
        setClaimTitles(newTitles)
        setClaimMoments(newMoments)
        setClaimTimeStamps(newTimeStamps)
        setSelectorOpen(false)
        setSortBySelector('Oldest')
    }


    function removeSubstring(originalString) {
        let split = originalString.split('/')
        let theURL = '/' + split[3] + '/' + split[4]
        return theURL;
    }

    function handleClick(event) {
        event.preventDefault();
        let URL = removeSubstring(event.target.href, event.target.baseURI)
        navigate(URL)
    }

    return ( 
        <div>
            <div hidden={props.loggedIn} className="loginNoticeMyPage" ><a className="anchor" onClick={() => navigate("/Login")}>
                    Login or create an account</a> and to start tracking your claims.
            </div>
            <div className='center' hidden={!loaded2 || !props.loggedIn}>
                <div className="myClaimsMajorContainer">
                    <div hidden={!props.loggedIn} className='myClaimsTabContainer'>
                        <div onClick={() => {whichTab('Created Claims')}} 
                        className={whichTabIsIt == 'Created Claims' ? "myClaimsTabSelected" : "myClaimsTab"}>
                            Created Claims
                        </div>
                        <div onClick={() => {whichTab('Followed Claims')}} 
                        className={whichTabIsIt == 'Followed Claims' ? "myClaimsTabSelected" : "myClaimsTab"}>
                            Followed Claims
                        </div>
                        <div onClick={() => {whichTab('Claims from my papers')}} 
                        className={whichTabIsIt == 'Claims from my papers' ? "myClaimsTabSelected" : "myClaimsTab"}>
                            Paper Claims
                        </div>
                        <div onClick={() => {whichTab('Claims from my figures')}} 
                        className={whichTabIsIt == 'Claims from my figures' ? "myClaimsTabSelected" : "myClaimsTab"}>
                            Figure Claims
                        </div>
                        
                    </div>
                    <div className='myClaimsWrapperContainer'>
                        <div hidden={!props.loggedIn} className="myClaimsClaimContainer">
                            <div className='addClaimButtonMyClaims'>
                                <h1 className='myHeadersMyClaims'>{whichTabIsIt}</h1>
                                <Tippy content='Create a claim'>
                                    <div className='addDivMyClaims' onClick={() => {navigate('/AddClaim')}}>
                                        <svg version="1.1" viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                                            <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path className="color000 svgShape" d="m24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" fill="#fff"/>
                                            </svg>
                                        </svg>
                                    </div>
                                </Tippy>
                            </div>  
                            <div className={claimsListCurrent.length != 0 ? 'sortByDivMyClaimWrapper' : 'hidden'}>
                                <div className='slightSpacerMyClaim'>Sort by:</div>
                                <div className='sortByDivMyClaim'>
                                    <span className='minimumWidthMyClaims'>{sortBySelector}</span>
                                    <span className='tagsSpanMyClaims'>
                                            <svg onClick={() => {openSelector()}} id="tagSVG" xmlns="http://www.w3.org/2000/svg" width="18" 
                                            height="18" viewBox="0 0 24 24">
                                                <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/>
                                            </svg>
                                    </span>
                                    <div className='seletorsDivMyClaims' hidden={!selectorOpen} ref={selectorRef}>
                                        <div className='selectorOptionsMyClaims' onClick={() => {sortClaimsByConfidence()}}>Confidence</div>
                                        <div className='selectorOptionsMyClaims' onClick={() => {sortClaimsByEngagement()}}>Engagement</div>
                                        <div className='selectorOptionsMyClaims' onClick={() => {sortClaimsByOldest()}}>Oldest</div>
                                        <div className='selectorOptionsMyClaims' onClick={() => {sortClaimsByNewest()}}>Newest</div>
                                    </div>
                                    
                                </div>
                            </div>
                            {
                                claimsListCurrent.map((claim, index) => {
                                    return (
                                        <div key={index} className={loaded ? 'userClaimWrapperMyClaims' : 'hidden'}>
                                            <div className='userClaimMyClaims'>
                                                <div className='myClaimsAnchorSpan'>
                                                    <a className='ProfileFontBold' href={'/Claim/' + claimsListCurrent[index].id}
                                                    onClick={handleClick}>
                                                        {claimTitles[index]}
                                                    </a>
                                                    <div className='timeStampDivMyClaims'>- {claimTimeStamps[index]}</div>
                                                </div>
                                                <div className='claimStatsWidgetMyClaims'>
                                                    <div className='confidenceLoader'></div>
                                                    <div hidden={!loaded}>{percentageConfidences[index]}%  | {claimEngagements[index]}</div>
                                                </div>
                                                <div className='optionsClaimWidget'>
                                                    <SVGoptionsDotsMyClaims whichTabIsIt={whichTabIsIt}
                                                    user={props.user} id={claim.id} index={index} based={based} claimsListFollowed={claimsListFollowed}/>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div className={loaded ? 'hidden' : 'ProfileComponentsSpinnerDiv'}>
                                <Spinner />
                            </div>
                            <div className={whichTabIsIt === 'Created Claims' && claimsListCurrent.length == 0 ? 'noClaimsToShow' : 'hidden'}>
                                You have not created any claims yet <div className='noClaimSpacer'></div>
                            </div>
                            <div className={whichTabIsIt === 'Followed Claims' && claimsListCurrent.length == 0 ? 'noClaimsToShow' : 'hidden'}>
                                You have not followed any claims yet <div className='noClaimSpacer'></div>
                            </div>     

                            <div className={whichTabIsIt === 'Claims from my papers' && claimsListCurrent.length == 0 ? 'noClaimsToShow' : 'hidden'}>
                                No claims have been extracted from your papers yet
                            </div>
                            <div className={whichTabIsIt === 'Claims from my figures' && claimsListCurrent.length == 0 ? 'noClaimsToShow' : 'hidden'}>
                                No claims have been connected to figures you uploaded yet
                            </div>
                                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) 
}

const mapStateToProps = (state, props) => {
    if (state.profModels.length == 1) {
        return { 
            user: state.profModels[0].id,
            userName: state.profModels[0].name,
            tags: state.profModels[0].tags,
            myClaims: state.profModels[0].myClaims,
            loggedIn: true
        }
    } else {
        return {
            user: '',
            userName: '',
            tags: [],
            myClaims: {createdClaims: [], followedClaims: [], paperClaims: [], figureClaims: []},
            loggedIn: false,
        };
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    returnClaimInfoMyClaims2: (claim) => dispatch(returnClaimInfoMyClaims2(claim))
})

const ConnectedMyClaims = connect(mapStateToProps, mapDispatchToProps)(myClaims);

export default ConnectedMyClaims;