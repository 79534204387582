import React, {useState} from "react";
import { connect } from 'react-redux'
import Spinner from "react-svg-spinner";
import moment from 'moment'
import { reportCriticism } from "../actions/profModels";

function reportModal (props) {
    const [loaded, setLoaded] = useState(true)
    const [reportText, setReportText] = useState('')
    const [submitted, setSubmitted] = useState(false)   

    const updateReportText = (e) => {
        setReportText(e.target.value)
    }

    const reportCriticism = async () => {
        setLoaded(false)
        let timeStamp = moment().utc().toString()

        let reportRequest = {
            requester: props.user,
            timeStamp: timeStamp,
            request: reportText.trim(),
            type: 'criticism',
            elementID: props.id,
            elementType: props.elementType,
            occurrence: props.occurrence,
            critText: props.critText,
            owner: props.criticismOwner
        }
        try {
            await props.reportCriticism(reportRequest)
            setLoaded(true)
            setSubmitted(true)
        } catch (error) {
            console.error(error)
        }
    }
    
    return (
            <div>
                <div onClick={() => {props.closeModal(false)}} className="blackBackground"></div>
                <div className='FigureReportCritModal'>
                    <div className="deleteFlex">
                        <h1>Report Criticism</h1>
                        <svg onClick={() => {props.closeModal(false)}} className="deleteX" version="1.1" viewBox="0 0 288 288" 
                        xmlns="http://www.w3.org/2000/svg">
                            <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path className="color000 svgShape" d="m24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" 
                                fill="#002c2c"/>
                            </svg>
                        </svg>
                    </div>
                    <div className={!submitted ? '' : 'hidden'}>
                        <div className='withinDelete'>
                            <div>What would you like to report?</div>
                        </div>
                        <textarea onChange={updateReportText} required={true} className="FigureReportCriticismText" placeholder="Describe the issue with this criticism..." name="critText">
                        </textarea>
                        <div className='FigureReportCriticismButtonWrapper'>
                            <button className={loaded ? "deleteButtonMyClaim": 'deleteButtonMyClaimClicked'} onClick={reportCriticism}>
                                <div hidden={!loaded}>Report</div>
                                <Spinner hidden={loaded} />
                            </button>
                        </div>    
                    </div>
                    <div className={submitted ? 'FigureReportSubmitted' : 'hidden'}>
                        We will review your report. Thank you.
                    </div>
                </div>
            </div>
    );
}

const mapDispatchToProps = (dispatch, props) => ({
    reportCriticism: (reportRequest) => dispatch(reportCriticism(reportRequest))
})

const reportModalConnected = connect(undefined, mapDispatchToProps)(reportModal);


export default reportModalConnected