import { db, claimCollection, figureCollection, paperCollection } from '../firebase/firebase'
import { doc, setDoc, getDoc, addDoc, deleteDoc, getDocs, collection } from 'firebase/firestore'
import moment from 'moment'
import { setProfile } from './profModels';


//Method for searching for a paper in Firebase will accomplish the following:
//1) Search for the paperDoi in the allPapers array argument. - check
//2) Fetcth that Paper document from the "Papers" collection in Firebase. - check
export const searchForPaper = (paperDoi, allPapers) => {
    return async (dispatch) => {
      try {
        //If nothing is being searched, then return nothing.
        if (paperDoi === '') {
            return false;
        }

        //Get the correct paperID by looking through the allPapers array that gets passed in.
        let paperID;
        allPapers.forEach((paper) => {
            if (paper.doi === paperDoi) {
                paperID = paper.id
            }
        })

        //If the paperDoi is not found in the paper collection, return false.
        if (paperID == undefined) {
            return false;
        }

        //Get the paper data from the Papers collection.
        const docRef = doc(db, "Papers", paperID);
        const document = await getDoc(docRef)

        //If the paper document is able to be fetched, return its info
        if (!document.data()) {
            return false;
        }

        let paperInfo = document.data()
        paperInfo.id = paperID
  
        return paperInfo;
      } catch (err) {
        console.log('problem searching for paper in firebase')
      }
    }
}

//This function fetches all of the dois of the papers in the "Papers" collection in Firebase.
//This needs to be done in order to search for dois and find the associated paperID.
export const getPaperDois = () => {
    return async (dispatch) => {
        try {
            let papers = [];
            const thePaperCollection = await getDocs(collection(db, "Papers"))
            thePaperCollection.forEach((paper) => {
                papers.push({
                    id: paper.id,
                    doi: paper.data().doi
                })
            })
            return papers;
        } catch (err) {
            console.log('Could not fetch papers collection')
        }
    }
}

//Method to identify a new paper in Firebase will accomplish the following:
//1) Add a new paper document in the "Papers" collection in Firebase.
//2) Add the Paper to the "identifiedPapers" array under the "myPapers" object of the fellow.
//3) Add the Paper to the "identifiedPapers" array under the "updates" object of the fellow.
//Validated AddPaper
//Validated AddClaim
//Validated AddFigure
export const startIdentifyPaper = (paperInfo = {}, theOwner) => {
  return async (dispatch) => {
    try {
      const thePaper = {
        ...paperInfo
      }
      //Create a new paper in firebase with the paperInfo.
      const paperDoc = await addDoc(paperCollection, thePaper)

      //Fetch the profile document of the owner and add the paperID to the identifiedPapers array.
      //Also add entry to the identifiedPapers array under updates object.
      const docRef2 = doc(db, "Profiles", theOwner);
      const document = await getDoc(docRef2)
      let profileData = document.data()

      if (profileData) {
        const theOccurrence = paperInfo.updates.identified[paperInfo.updates.identified.length - 1].occurrence
        profileData.myPapers.identifiedPapers.push(
          {
              id: paperDoc.id,
              lastViewed: paperInfo.timeStamp
          }
        )
        profileData.updates.identifiedPapers.push(
          {
            occurrence: theOccurrence,
            timeStamp: paperInfo.timeStamp,
            id: paperDoc.id
          }
        )
        await setDoc(docRef2, profileData)
        dispatch(setProfile({
          ...profileData, 
          id: theOwner
        }))
      }
      //return the paperID.
      return paperDoc.id;
      
    } catch (err) {
      console.log('problem creating paper in firebase')
    }
  }
}

export const setPaper = (
    {
      title = '',
      doi = '',
      owner = '',
      ownerTags = [],
      figures = [],
      follows = [],
      extractedClaims = [],
      journal = '',
      id,
      timeStamp = 0,
      updates = []
    } = {paper}
  ) => ({
    type: 'SET_PAPER',
    paper: {
      title: title,
      doi: doi,
      owner: owner,
      ownerTags: ownerTags,
      figures: figures,
      follows: follows,
      extractedClaims: extractedClaims,
      journal: journal,
      id: id,
      timeStamp: timeStamp,
      updates: updates
    }
  });
  
export const startSetPaper = (id) => {
  return async (dispatch) => {
      try {
        const docRef = doc(db, "Papers", id);
        const paperDocument = await getDoc(docRef)
        const paperData = paperDocument.data()
        dispatch(setPaper({
          ...paperData,
          id: paperDocument.id 
        }))
      } catch (err) {
        console.log(err)
      }
  }
}

//Method to follow a Paper should accomplish the following:
//1) Add the fellowID + occurrence to the "follows" array of the paper.
//2) Add the fellowID + occurrence to the "follows" array under the "updates" object of the paper.
//3) Add the paperID to the "followedPapers" array under the "myPapers" object of the fellow.
//Validated Paper/:id
export const startAddPaperFollow = (user, paperID, occurrence, timeStamp) => {
  return async (dispatch) => {
    try {
      //First fetch the data from the specific paper from the database.
      const docRef = doc(db, "Papers", paperID);
      const PaperDocument = await getDoc(docRef)
      const PaperData = PaperDocument.data()

      if (PaperData) {
        //Add the user's ID to the follows array, which tells us who is following this particular paper.
        PaperData.follows.push({
          id: user,
          occurrence: occurrence,
          timeStamp: timeStamp
        })
        PaperData.updates.follows.push(
          {
            id: user,
            occurrence: occurrence,
            timeStamp: timeStamp
          }
        )

        //Re-save the paper data in firebase.
        await setDoc(docRef, PaperData)

        //Additionally, remove paper ID from "myPapers" array in the user Profile.
        //First fetch the profile data from the specific profile from the database.
        const profileRef = doc(db, "Profiles", user);
        const profileDocument = await getDoc(profileRef);
        const profileData = profileDocument.data()

        if (profileData) {
          //Add the paper's ID to the myPapers array.
          profileData.myPapers.followedPapers.push(
              {
                  id: paperID,
                  lastViewed: timeStamp
              }
          )
          await setDoc(profileRef, profileData)
          dispatch(setProfile({
            ...profileData, 
            id: user
          }))
        }
      }
    } catch (err) {
      console.log('unable to startAddPaperFollow')
    }
  }
}

//Method for removing a follow of a paper should accomplish the following:
//1) Remove the fellowID + occurrence from the "follows" array of the paper.
//2) Remove the fellowID + occurrence from the "follows" array under the "updates" object of the paper.
//3) Remove the paperID from the "followedPapers" array under the "myPapers" object of the fellow.
//Validated Paper/:id
export const startRemovePaperFollow = (user, paperID) => {
  return async (dispatch) => {
    try {
      //First fetch the paper data from the specific paper from the database.
      const paperRef = doc(db, "Papers", paperID);
      const paperDocument = await getDoc(paperRef)
      const paperData = paperDocument.data()

      if (paperData) {
          //Create a new "follows" array and remove the user's ID from it.
          const removeUser = paperData.follows.filter((theUser) => {
          return theUser.id != user
          })
          
          //Set the follows array to the new array.
          paperData.follows = removeUser

          //Now remove the follow update.
          const removeFollowUpdate = paperData.updates.follows.filter(fellow => fellow.id != user)
          paperData.updates.follows = removeFollowUpdate
          await setDoc(paperRef, paperData)
      }
      //Additionally, remove paper ID from the followedPapers array in the "myPapers" object of the user's Profile.
      //First fetch the profile data from the specific profile from the database.
      const profileRef = doc(db, "Profiles", user);
      const profileDocument = await getDoc(profileRef);
      const profileData = profileDocument.data()

      //Create a new "myPapers" array and remove the paper's ID from it.
      const removePaper = profileData.myPapers.followedPapers.filter((thePaper) => {
        return thePaper.id != paperID
      })

      //Set the myPapers array to the new array.
      profileData.myPapers.followedPapers = removePaper
      await setDoc(profileRef, profileData)
      dispatch(setProfile({
        ...profileData, 
        id: user
      }))

      // dispatch(setPaper({
      //   ...paperData,
      //   id: paperID
      // }))
    } catch (err) {
      console.log('unable to startRemovePaperFollow')
    }
  }
}

//Method for deleting a Paper should accomplish the following:
//1) Delete the Paper from the "Papers" collection.
//2) Remove the Paper from the "identifiedPapers" array under the "myPapers" object on the fellow's profile.
//3) Remove the Paper from the "identifiedPapers" array under the "updates" object on the fellow's profile.
//Validated myPapers.
//Validated Paper/:id
export const startDeletePaper = (id) => {
  return async (dispatch) => {
    try {
      const docRef = doc(db, "Papers", id)
      const paperDocument = await getDoc(docRef)
      const paperData = paperDocument.data()

      if (paperData) {
        await deleteDoc(docRef)
      }

      //Next, delete the paper from the "identifiedPapers" array in the "myPapers" map of the owner's profile
      //1) get the profile data from the owner
      const ownerProfileRef = doc(db, "Profiles", paperData.owner)
      const profileDocument = await getDoc(ownerProfileRef)
      const profileData = profileDocument.data()

      if (profileData) {
        //2) delete the paperID from the identifiedPapers array
        let newIdentifiedPapers = profileData.myPapers.identifiedPapers.filter((paper) => {
          return paper.id != id
        })
        profileData.myPapers.identifiedPapers = newIdentifiedPapers
        let newUpdates = profileData.updates.identifiedPapers.filter((update) => {
          return update.id != id
        })
        profileData.updates.identifiedPapers = newUpdates
        //3) re-save the profile.
        await setDoc(ownerProfileRef, profileData)
        dispatch(setProfile({
          ...profileData, 
          id: paperData.owner
        }))
      }
    } catch (err) {
      console.log('error in deleting the paper.')
    }
  }
}

const checkIfAnonymous = (owner, ownerTags) => {
  let anonymous = true;
  ownerTags.forEach((tag) => {
    if (tag.id == owner) {
      anonymous = false
    }
  })
  return anonymous;
}

export const returnPaperInfo = (paper) => {
  return async (dispatch) => {
    try {
      //fetch the correct paper from the papers database in firestore
      const docRef = doc(db, "Papers", paper);
      const paperDocument = await getDoc(docRef);
      const paperData = paperDocument.data()

      if (paperData == undefined) {
        const date = new Date('Thu Jan 01 1970 00:00:00 GMT+0000')
        const paperInfo = {
          numberOfFigures: '?',
          numberOfExtractedClaims: '?',
          title: 'paper Unavailable',
          timeStamp: moment(date)
        }
        return paperInfo;
      }
      
      const numberOfExtractedClaims = paperData.extractedClaims.length
      const numberOfFigures = paperData.figures.length
      const title = paperData.title
      const theTimeStamp = new Date(paperData.timeStamp)
      const paperInfo = {
        numberOfFigures: numberOfFigures,
        numberOfExtractedClaims: numberOfExtractedClaims,
        title: title,
        timeStamp: moment(theTimeStamp),
        id: paper,
        moment: moment(theTimeStamp)
      }
      paperInfo.timeStamp = paperInfo.timeStamp.format('LLL')
      paperInfo.anonymous = checkIfAnonymous(paperData.owner, paperData.ownerTags)
      return paperInfo;
    } catch (err) {
      console.log('returning paper info did not work.')
    }
  }
}

export const returnPaperInfoProfile = (paper) => {
  return async (dispatch) => {
    try {
      //fetch the correct paper from the papers database in firestore
      const docRef = doc(db, "Papers", paper);
      const paperDocument = await getDoc(docRef);
      const paperData = paperDocument.data()

      if (paperData == undefined) {
        const date = new Date('Thu Jan 01 1970 00:00:00 GMT+0000')
        const paperInfo = {
          numberOfFigures: '?',
          numberOfExtractedClaims: '?',
          title: 'paper Unavailable',
          timeStamp: moment(date),
          unavailable: true
        }
        return paperInfo;
      }
      
      const numberOfExtractedClaims = paperData.extractedClaims.length
      const numberOfFigures = paperData.figures.length
      const title = paperData.title
      const theTimeStamp = new Date(paperData.timeStamp)
      const paperInfo = {
        numberOfFigures: numberOfFigures,
        numberOfExtractedClaims: numberOfExtractedClaims,
        title: title,
        timeStamp: moment(theTimeStamp),
        id: paper,
        moment: moment(theTimeStamp)
      }
      paperInfo.timeStamp = paperInfo.timeStamp.format('LLL')
      paperInfo.anonymous = checkIfAnonymous(paperData.owner, paperData.ownerTags)
      return paperInfo;
    } catch (err) {
      console.log('returning paper info did not work.')
    }
  }
}


//This method simply pulls the paper document off of Firebase and returns it.
export const returnPaperInfoHomeFeed = (paper) => {
  return async (dispatch) => {
    try {
      //fetch the correct paper from the papers database in firestore
      const docRef = doc(db, "Papers", paper);
      const paperDocument = await getDoc(docRef);
      const paperData = paperDocument.data()
      return paperData;
    } catch (err) {
      console.log('returning paper info did not work.')
    }
  }
}



//Method for disconnecting a figure from a paper will accomplish the following:
//1) Remove the figure from the "figures" array of the paper.
//2) Remove the figure from the "figureUploads" array under the "updates" object of the paper.
//Validated Paper/:id
export const startDisconnectFigure = (figureID, paperID, user) => {
  return async (dispatch) => {
    try {
      //First, fetch the paper document from firebase
      const docRef = doc(db, "Papers", paperID)
      const paperDocument = await getDoc(docRef)
      const paperData = paperDocument.data()

      //Next, remove the figureID from the figures array
      let figureIndex = paperData.figures.findIndex((figure) => {
          return figure.id == figureID
      })  
      //Grab the occurence for when this figure was connected to the paper.
      let occurrence = paperData.figures[figureIndex].occurrence

      //Delete the figureIndex on the figures array.
      paperData.figures.splice(figureIndex, 1)

      //If the occurrence can be found in the updates array on the paper, delete that as well.
      let updateIndex = paperData.updates.figureUploads.findIndex((update) => {
          return update.occurrence == occurrence;
      })
      if (updateIndex > -1) {
          paperData.updates.figureUploads.splice(updateIndex, 1)
      }

      //Lastly, re-save the paper.
      await setDoc(docRef, paperData)

      return;
    } catch (err) {
      console.log('error in disconnecting figure from paper.')
    }
  }
}


//Method for disconnecting a claim from a paper will accomplish the following:
//1) Remove the claim from the "claims" array of the paper.
//2) Remove the claim from the "claimExtractions" array under the "updates" object of the paper.
//Validated Paper/:id
export const startDisconnectClaim = (claimID, paperID, user) => {
  return async (dispatch) => {
    try {
      //First, fetch the paper document from firebase
      const docRef = doc(db, "Papers", paperID)
      const paperDocument = await getDoc(docRef)
      const paperData = paperDocument.data()

      //Next, remove the claimID from the figures array
      let claimIndex = paperData.extractedClaims.findIndex((claim) => {
          return claim.id == claimID
      })  
      //Grab the occurence for when this claim was connected to the paper.
      let occurrence = paperData.extractedClaims[claimIndex].occurrence

      //Delete the claimIndex on the claims array.
      paperData.extractedClaims.splice(claimIndex, 1)

      //If the occurrence can be found in the updates array on the paper, delete that as well.
      let updateIndex = paperData.updates.claimExtractions.findIndex((update) => {
          return update.occurrence == occurrence;
      })
      if (updateIndex > -1) {
          paperData.updates.claimExtractions.splice(updateIndex, 1)
      }

      //Lastly, re-save the paper.
      await setDoc(docRef, paperData)

      return;
    } catch (err) {
      console.log('error in disconnecting claim from paper.')
    }
  }
}

//Method to update the last time a Paper was viewed will do the following:
//1) Update the "lastViewed" string to reflect the current time when the figure is viewed if it is somewhere in the fellow's colleciton.
//Validated paper/:id
export const updateLastViewedPaper = (user, paperID, timeStamp) => {
  return async (dispatch) => {
    try {
      //Fetch the profile document of the owner.
      const profRef = doc(db, "Profiles", user);
      const document = await getDoc(profRef)
      let profileData = document.data()
      //If the profile can be fetched from Firebase, run the following: 
      if (profileData) {
        const isInIdentified = profileData.myPapers.identifiedPapers.findIndex((paper) => {
          return paper.id == paperID
        })
        const isInFollowed = profileData.myPapers.followedPapers.findIndex((paper) => {
          return paper.id == paperID
        })
  
        //Search through all papers within paperClaims, identifiedPapers, and followedPapers for the ID
        if (isInIdentified > -1) {
          profileData.myPapers.identifiedPapers[isInIdentified].lastViewed = timeStamp
        }
        if (isInFollowed > -1) {
          profileData.myPapers.followedPapers[isInFollowed].lastViewed = timeStamp
        }
      
        await setDoc(profRef, profileData)
        return;
      }
    } catch (err) {
      console.log('Not able to update lastViewed in myClaims.')
    }
  }
}