import React, { useState, useRef, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from "react-router-dom"
import { history } from '../routers/AppRouter'
import {createNewTheory} from '../actions/theoryModels';
import { returnFigureInfoUpdates } from '../actions/figModels';
import { returnFellowInfoHome } from '../actions/profModels';
import moment from 'moment';
import DeleteModal from './deleteModalPaper';
import FigureUpdate from './HomePageComponents/figureUpdatev2';
import ClaimUpdate from './HomePageComponents/ClaimUpdate2';
import PaperUpdate from './HomePageComponents/paperUpdate';
import TheoryUpdate from './HomePageComponents/theoryUpdate';
import FellowFigureUpdate from './HomePageComponents/fellowFigureUpdate';
import FellowTheoryUpdate from './HomePageComponents/fellowTheoryUpdate';
import FellowPaperUpdate from './HomePageComponents/fellowPaperUpdate';
import FellowClaimUpdate from './HomePageComponents/fellowClaimUpdate';
import { v4 as uuidv4 } from 'uuid';

const Home = (props) => {

    const navigate = useNavigate()
    const theDate = new Date(props.timeStamp)
    const newMoment = moment(theDate)
    newMoment.local()
    const localDate = newMoment.format('LLL')
    
    const [deleteModalState, setDeleteModalState] = useState(false)
    const [allUpdates, setAllUpdates] = useState([])
    const [shownUpdates, setShownUpdates] = useState([])
    const [noMoreUpdates, setNoMoreUpdates] = useState(false)

    const observer = useRef()
    const lastUpdateElementRef = useCallback((node) => {
        if (observer.current) {
            observer.current.disconnect()
        }

        //We use the code here to constantly update the list of updates that are already shown.
        //As user scrolls down, more and more updates are added to shownUpdates array, one at a time, and
        //it is added to the home page.
        observer.current = new IntersectionObserver((entry) => {
            if (entry[0].isIntersecting) {
                let newShownUpdates = [...allUpdates]
                if (shownUpdates.length < allUpdates.length) {
                    let newerShownUpdates = newShownUpdates.slice(0, shownUpdates.length + 1)
                    setShownUpdates(newerShownUpdates)
                }
            }
        })
        if (node) {
            observer.current.observe(node)
        }
        if (shownUpdates.length >= allUpdates.length) {
            setNoMoreUpdates(true)
        }
    }, [shownUpdates])


    //This function will fetch each fellow in the array and determine if they created
    //a claim, uploaded a figure, authored a theory, or identified a paper ever since the user
    //last visited their profile
    const getLatestUpdatesFellow = async (fellowIDAndLastViewed) => {
        let createdClaims = [];
        let uploadedFigures = [];
        let authoredTheories = [];
        let identifiedPapers = [];
        let fellowDocument = await props.returnFellowInfoHome(fellowIDAndLastViewed.id)
        for (const update of fellowDocument.updates.authoredTheories) {
            if (fellowIDAndLastViewed.lastViewed < update.timeStamp) {
                authoredTheories.push({ id: update.id})
            }
        }
        for (const update of fellowDocument.updates.createdClaims) {
            if (fellowIDAndLastViewed.lastViewed < update.timeStamp) {
                createdClaims.push({ id: update.id})
            }
        }
        for (const update of fellowDocument.updates.identifiedPapers) {
            if (fellowIDAndLastViewed.lastViewed < update.timeStamp) {
                identifiedPapers.push({ id: update.id})
            }
        }
        for (const update of fellowDocument.updates.uploadedFigures) {
            if (fellowIDAndLastViewed.lastViewed < update.timeStamp) {
                uploadedFigures.push({ id: update.id})
            }
        }

        return { 
            claims: createdClaims,
            figures: uploadedFigures,
            theories: authoredTheories,
            papers: identifiedPapers
        }
    }

    //When the home page loads, this useEffect loads the entire updates array 
    useEffect( async () => {

        //If the user is not logged in, home page will just redirect to login page.
        if (!props.loggedIn) {
            navigate('/Login')
        } else {

            //Create a list for each of the different types of elements on Outwit that a user could receive updates on.
            //This includes figures, claims, papers, theories, and other users.
            let allMyElementsList = [];
            let figuresList = [];
            let claimsList = []
            let papersList = []
            let theoriesList = []
            let fellowsList = []
            let fellowsIDsAndLastViewed = []
            let shufflerArray = []
            let i = 0;


            
            //Add all figures to the array
            // allMyElementsList.concat(props.myFigures.uploadedFigures)
            // allMyElementsList.concat(props.myFigures.acceptedFigures)
            // allMyElementsList.concat(props.myFigures.rejectedFigures)
            // allMyElementsList.concat(props.myClaims.createdClaims)
            // allMyElementsList.concat(props.myClaims.figureClaims)
            // allMyElementsList.concat(props.myClaims.followedClaims)
            // allMyElementsList.concat(props.myClaims.paperClaims)
            // allMyElementsList.concat(props.myPapers.followedPapers)
            // allMyElementsList.concat(props.myPapers.identifiedPapers)
            // allMyElementsList.concat(props.my)
            // allMyElementsList.concat(props.my)
            // allMyElementsList.concat(props.my)
            // allMyElementsList.concat(props.my)
            // allMyElementsList.concat(props.my)
            // allMyElementsList.concat(props.my)

            //Add all figures to the array.
            //Start with adding all figures that the user themself uploaded.
            for (const figure of props.myFigures.uploadedFigures) {
                figure.type = 'figure'
                if (!figuresList.includes(figure.id)) {
                    figuresList.push(figure.id)
                    allMyElementsList.push(figure)
                    shufflerArray.push(i)
                    i += 1
                }
            }

            //Next add all the figures that the user accepted.
            for (const figure of props.myFigures.acceptedFigures) {
                figure.type = 'figure'
                if (!figuresList.includes(figure.id)) {
                    figuresList.push(figure.id)
                    allMyElementsList.push(figure)
                    shufflerArray.push(i)
                    i += 1
                }
            }

            //Add all of the figures that the user rejected.
            for (const figure of props.myFigures.rejectedFigures) {
                figure.type = 'figure'
                if (!figuresList.includes(figure.id)) {
                    figuresList.push(figure.id)
                    allMyElementsList.push(figure)
                    shufflerArray.push(i)
                    i += 1
                }
            }

            //Add all claims to the array.
            //Start with all of the claims that the user themself has created.
            for (const claim of props.myClaims.createdClaims) {
                claim.type = 'claim'
                if (!claimsList.includes(claim.id)) {
                    claimsList.push(claim.id)
                    allMyElementsList.push(claim)
                    shufflerArray.push(i)
                    i += 1
                }
            }

            //Then add all of the claims that are connected to figures that the user uploaded.
            for (const claim of props.myClaims.figureClaims) {
                claim.type = 'claim'
                if (!claimsList.includes(claim.id)) {
                    claimsList.push(claim.id)
                    allMyElementsList.push(claim)
                    shufflerArray.push(i)
                    i += 1
                }
            }

            //Obviously add all of the claims that the user themself has decided to follow.
            for (const claim of props.myClaims.followedClaims) {
                claim.type = 'claim'
                if (!claimsList.includes(claim.id)) {
                    claimsList.push(claim.id)
                    allMyElementsList.push(claim)
                    shufflerArray.push(i)
                    i += 1
                }
            }

            //Add all of the claims that have been extracted from papers that the user uploaded.
            for (const claim of props.myClaims.paperClaims) {
                claim.type = 'claim'
                if (!claimsList.includes(claim.id)) {
                    claimsList.push(claim.id)
                    allMyElementsList.push(claim)
                    shufflerArray.push(i)
                    i += 1
                }
            }

            //Add all papers to the array.
            //Start with papers that the user has followed.
            for (const paper of props.myPapers.followedPapers) {
                paper.type = 'paper'
                if (!papersList.includes(paper.id)) {
                    papersList.push(paper.id)
                    allMyElementsList.push(paper)
                    shufflerArray.push(i)
                    i += 1
                }
            }

            //Obviously add papers that the user has first identified.
            for (const paper of props.myPapers.identifiedPapers) {
                paper.type = 'paper'
                if (!papersList.includes(paper.id)) {
                    papersList.push(paper.id)
                    allMyElementsList.push(paper)
                    shufflerArray.push(i)
                    i += 1
                }
            }

            //Add all theories to the array.
            //Start with adding all theories that the user themself has followed.
            for (const theory of props.myTheories.followedTheories) {
                theory.type = 'theory'
                if (!theoriesList.includes(theory.id)) {
                    theoriesList.push(theory.id)
                    allMyElementsList.push(theory)
                    shufflerArray.push(i)
                    i += 1
                }
            }

            //Then add obviously any theories that the user has authored themself.
            for (const theory of props.myTheories.authoredTheories) {
                theory.type = 'theory'
                if (!theoriesList.includes(theory.id)) {
                    theoriesList.push(theory.id)
                    allMyElementsList.push(theory)
                    shufflerArray.push(i)
                    i += 1
                }
            }

            //Updates from fellows are going to be a little bit different because we dont have to have the 
            //"lastViewed" value. All of the updates from other fellows are going to be things that they
            //created recently. This means the user has never seen them before anyway and we don't need
            //the "lastViewed" values.
            for (const fellow of props.myFellows.followedFellows) {
                if (!fellowsList.includes(fellow.id)) {
                    fellowsList.push(fellow.id)
                    let updates = await getLatestUpdatesFellow(fellow)
                    for (const figure of updates.figures) {
                        figure.type = 'fellowFigure'
                        figure.fellowID = fellow.id
                        allMyElementsList.push(figure)
                        shufflerArray.push(i)
                        i += 1
                    }
                    for (const claim of updates.claims) {
                        claim.type = 'fellowClaim'
                        claim.fellowID = fellow.id
                        allMyElementsList.push(claim)
                        shufflerArray.push(i)
                        i += 1
                    }
                    for (const theory of updates.theories) {
                        theory.type = 'fellowTheory'
                        theory.fellowID = fellow.id
                        allMyElementsList.push(theory)
                        shufflerArray.push(i)
                        i += 1
                    }
                    for (const paper of updates.papers) {
                        paper.type = 'fellowPaper'
                        paper.fellowID = fellow.id
                        allMyElementsList.push(paper)
                        shufflerArray.push(i)
                        i += 1
                    }
                }
            }

            //This function generates a list of shuffled indexes for all the updates which will later be used to decide in what
            //order the updates will be show.
            function shuffle(array) {
                for (let i = array.length - 1; i > 0; i--) {
                    let j = Math.floor(Math.random() * (i + 1));
                    const temp = array[i]
                    array[i] = array[j]
                    array[j] = temp
                }
                return array;
            }

            //Here we take the shuffled indicies and use them to generate an array of shuffled updates.
            let shuffledArray = shuffle(shufflerArray)
            let shuffledAllMyElementsList = []
            for (const number of shuffledArray) {
                shuffledAllMyElementsList.push(allMyElementsList[number])
            }

            //Update the setAllUpdates state
            setAllUpdates(shuffledAllMyElementsList)
            let allUpdatesToShow = [...shuffledAllMyElementsList]

            let k = shuffledAllMyElementsList.length - 1;
            if (k > 3) {
                k = 3;
            }
            let firstShownUpdates = allUpdatesToShow.slice(0, shownUpdates.length + k)
            setShownUpdates(firstShownUpdates)

        }


    }, [window.location.pathname])

    const createTheory = async () => {
        if (!props.loggedIn) {
            return;
        }
        let timeStamp = moment().utc().toString()
        let occurrence = uuidv4()
        props.createNewTheory(props.user, timeStamp, occurrence).then((id) => {
            navigate('/AddTheory/' + id)
        })
    }
    
    return (
        <div className="claimShow">
            <div hidden={props.loggedIn} className="loginNotice" ><a className="anchor" href="/Login">
                Login or register an account</a> to identify papers to be added to Outwit.
            </div>
            {deleteModalState && <DeleteModal id={props.id} closeModal={setDeleteModalState} element='paper' />}
            <div className='majorContainerHome' hidden={false}>
                <div className='makeEntryHomeSmall'>
                    <h2>Make a Outwit entry:</h2>
                </div>
                <div className='JCEntryDivHome'>
                    <h2 className='makeEntryHome'>Make a Outwit entry:</h2>
                    <div className='JCEntryHome' onClick={() => createTheory()}>
                        <div className='linkerDivPaper'>
                            <a className='NavLinkPaper'>
                                <svg className="SVGFigureHome" version="1.1" viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                                    <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path className="color000 svgShape" d="M14 19h-4c-.276 0-.5.224-.5.5s.224.5.5.5h4c.276 0 .5-.224.5-.5s-.224-.5-.5-.5zm0 2h-4c-.276 0-.5.224-.5.5s.224.5.5.5h4c.276 0 .5-.224.5-.5s-.224-.5-.5-.5zm.25 2h-4.5l1.188.782c.154.138.38.218.615.218h.895c.234 0 .461-.08.615-.218l1.187-.782zm3.75-13.799c0 3.569-3.214 5.983-3.214 8.799h-1.989c-.003-1.858.87-3.389 1.721-4.867.761-1.325 1.482-2.577 1.482-3.932 0-2.592-2.075-3.772-4.003-3.772-1.925 0-3.997 1.18-3.997 3.772 0 1.355.721 2.607 1.482 3.932.851 1.478 1.725 3.009 1.72 4.867h-1.988c0-2.816-3.214-5.23-3.214-8.799 0-3.723 2.998-5.772 5.997-5.772 3.001 0 6.003 2.051 6.003 5.772zm4-.691v1.372h-2.538c.02-.223.038-.448.038-.681 0-.237-.017-.464-.035-.69h2.535zm-10.648-6.553v-1.957h1.371v1.964c-.242-.022-.484-.035-.726-.035-.215 0-.43.01-.645.028zm-3.743 1.294l-1.04-1.94 1.208-.648 1.037 1.933c-.418.181-.822.401-1.205.655zm10.586 1.735l1.942-1.394.799 1.115-2.054 1.473c-.191-.43-.423-.827-.687-1.194zm-3.01-2.389l1.038-1.934 1.208.648-1.041 1.941c-.382-.254-.786-.473-1.205-.655zm-10.068 3.583l-2.054-1.472.799-1.115 1.942 1.393c-.264.366-.495.763-.687 1.194zm13.707 6.223l2.354.954-.514 1.271-2.425-.982c.21-.397.408-.812.585-1.243zm-13.108 1.155l-2.356 1.06-.562-1.251 2.34-1.052c.173.433.371.845.578 1.243zm-1.178-3.676h-2.538v-1.372h2.535c-.018.226-.035.454-.035.691 0 .233.018.458.038.681z" fill="#002c2c"/>
                                    </svg>
                                </svg>
                                Theory
                            </a>
                        </div>
                    </div>
                    <div className='JCEntryHome' onClick={() => {navigate('/AddFigure')}}>
                        <div className='linkerDivPaper'>
                            <a className='NavLinkPaper'>
                                <svg className="SVGFigureHome" version="1.1" viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                                    <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path className="color000 svgShape" fill="#002c2c" d="m24 3.875l-6 1.221 1.716 1.708-5.351 5.358-3.001-3.002-7.336 7.242 1.41 1.418 5.922-5.834 2.991 2.993 6.781-6.762 1.667 1.66 1.201-6.002zm0 17.125v2h-24v-22h2v20h22z"/>
                                    </svg>
                                </svg>
                                Figure
                            </a>
                        </div>
                    </div>
                    <div className='JCEntryHome' onClick={() => {navigate('/AddClaim')}}>
                        <div className='linkerDivPaper'>
                            <a className='NavLinkPaper'>
                                <svg className="SVGFigureHome" version="1.1" viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                                    <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path className="color000 svgShape" d="M15.996 24h-12.605s.734-3.931.633-5.686c-.041-.724-.161-1.474-.54-2.104-.645-1-2.636-3.72-2.475-7.43.224-5.209 4.693-8.779 10.126-8.779 5.098 0 8.507 3.001 9.858 7.483.328 1.079.311 1.541-.151 2.607l-.006.013 1.751 2.142c.26.381.413.791.413 1.239 0 .547-.233 1.045-.61 1.399-.368.345-.767.452-1.248.642 0 0-.576 2.592-.873 3.291-.7 1.643-1.97 1.659-2.97 1.849-.394.083-.49.133-.681.681-.208.591-.363 1.435-.622 2.653zm-4.842-22c-4.285.048-7.74 2.548-8.121 6.488-.192 1.991.463 3.986 1.516 5.705.611 1 1.305 1.592 1.464 3.875.091 1.313-.05 2.636-.241 3.932h8.604c.141-.645.35-1.485.687-2.057.449-.766 1.097-1.099 1.926-1.254.838-.148 1.238-.059 1.489-.785.212-.579.612-2.221.831-3.902 1.203-.335.612-.161 1.671-.559-.206-.234-1.918-2.314-2.045-2.6-.336-.759-.046-1.19.225-1.913.086-.251.06-.357-.009-.613-1.049-3.949-3.891-6.317-7.997-6.317zm.063 14h-.447c-.117 0-.231-.039-.308-.109l-.594-.391h2.25l-.594.391c-.076.069-.189.109-.307.109zm.922-1h-2.279c-.138 0-.25-.111-.25-.25 0-.138.112-.25.25-.25h2.279c.138 0 .25.112.25.25s-.111.25-.25.25zm-1.322-.986h-1.414c-.013-2.57-1.403-2.878-1.403-4.647 0-1.695 1.327-2.852 3-2.852h.02c1.663.009 2.98 1.163 2.98 2.852 0 1.769-1.391 2.077-1.404 4.647h-1.414c0-2.735 1.318-3.614 1.318-4.651 0-.856-.694-1.333-1.5-1.348h-.019c-.798.022-1.481.499-1.481 1.348 0 1.037 1.317 1.916 1.317 4.651zm4.053-3.628l1.349.612-.414.911-1.298-.589c.151-.3.276-.607.363-.934zm-7.739 0c.086.332.208.63.359.935l-1.296.588-.413-.911 1.35-.612zm9.369-.886h-1.501c.01-.335-.021-.672-.093-1h1.594v1zm-9.499 0h-1.501v-1h1.593c-.071.327-.101.663-.092.998v.002zm7.02-2.714l1.243-.881.579.815-1.252.889c-.147-.291-.336-.566-.57-.823zm-6.043 0c-.23.251-.418.525-.569.822l-1.251-.888.578-.815 1.242.881zm4.435-1.046l.663-1.345.896.442-.663 1.345c-.278-.183-.581-.332-.896-.442zm-2.826-.001c-.316.11-.618.258-.897.442l-.663-1.344.897-.442.663 1.344zm1.913-.208c-.334-.039-.654-.041-1-.002v-1.529h1v1.531z"/>
                                    </svg>
                                </svg>
                                Claim
                            </a>
                        </div>
                    </div>
                    <div className='JCEntryHome' onClick={() => {navigate('/AddPaper')}}> 
                        <div className='linkerDivPaper'>
                            <a className='NavLinkPaper'>
                                <svg className="SVGFigureHome" version="1.1" viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                                    <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path className="color000 svgShape" d="M11.362 2c4.156 0 2.638 6 2.638 6s6-1.65 6 2.457v11.543h-16v-20h7.362zm.827-2h-10.189v24h20v-14.386c0-2.391-6.648-9.614-9.811-9.614zm4.811 13h-10v-1h10v1zm0 2h-10v1h10v-1zm-3 3h-7v1h7v-1z"/>
                                    </svg>
                                </svg>
                                Paper
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='majorContainerFlex'>
                <div className='middleColumnHome'>
                    {
                        shownUpdates.map((update, index) => {
                            if (update.type === 'figure') {
                                return <FigureUpdate key={update.id + index} id={update.id} lastViewed={update.lastViewed}
                                setShownUpdates={setShownUpdates} figIndex={index}></FigureUpdate>
                            } else if (update.type === 'claim') {
                                return <ClaimUpdate key={update.id + index} id={update.id} lastViewed={update.lastViewed}
                                setShownUpdates={setShownUpdates} figIndex={index}></ClaimUpdate>
                            } else if (update.type === 'theory') {
                                return <TheoryUpdate key={update.id + index} id={update.id} lastViewed={update.lastViewed}
                                setShownUpdates={setShownUpdates} figIndex={index}></TheoryUpdate>
                            } else if (update.type === 'paper') {
                                return <PaperUpdate key={index} id={update.id} lastViewed={update.lastViewed}></PaperUpdate>
                            } else if (update.type === 'fellowFigure') {
                                return <FellowFigureUpdate key={index + update.fellowID} fellowID={update.fellowID} id={update.id}
                                ></FellowFigureUpdate>
                            } else if (update.type === 'fellowTheory') {
                                return <FellowTheoryUpdate key={index + update.fellowID} fellowID={update.fellowID} id={update.id}
                                ></FellowTheoryUpdate>
                            } else if (update.type === 'fellowPaper') {
                                return <FellowPaperUpdate key={index + update.fellowID} fellowID={update.fellowID} id={update.id}
                                ></FellowPaperUpdate>
                            } else if (update.type === 'fellowClaim') {
                                return <FellowClaimUpdate key={index + update.fellowID} fellowID={update.fellowID} id={update.id}
                                ></FellowClaimUpdate>
                            }
                        })
                    }
                    <div ref={lastUpdateElementRef}></div>
                    <div className='smallFigureContainerHomeNoMore'><h2>No more updates!</h2></div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state, props) => {
    if (state.profModels.length == 1) {
        return { 
            user: state.profModels[0].id,
            userName: state.profModels[0].name,
            tags: state.profModels[0].tags,
            loggedIn: true,
            myFigures: state.profModels[0].myFigures,
            myClaims: state.profModels[0].myClaims,
            myTheories: state.profModels[0].myTheories,
            myPapers: state.profModels[0].myPapers,
            myFellows: state.profModels[0].myFellows,
            imgsrc: state.profModels[0].imgsrc
        }
    } else {
        return { 
            user: '',
            userName: '',
            tags: [],
            loggedIn: false,
            myFigures: [],
            myClaims: [],
            myTheories: [],
            myPapers: [],
            myFellows: [],
            imgsrc: ''
        }
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    createNewTheory: (owner, occurrence, timeStamp) => dispatch(createNewTheory(owner, occurrence, timeStamp)),
    returnFigureInfoUpdates: (figure) => dispatch(returnFigureInfoUpdates(figure)),
    returnFellowInfoHome: (fellow) => dispatch(returnFellowInfoHome(fellow))
})

const ConnectedHome = connect(mapStateToProps, mapDispatchToProps)(Home);

export default ConnectedHome;