import React, { Component, useState, useRef, useMemo, useEffect } from 'react'
import MyComponent from '../ContentEditableDiv'
import { connect } from 'react-redux'
import { storage } from '../../firebase/firebase'
import { ref, uploadBytesResumable } from 'firebase/storage'
import { useNavigate } from "react-router-dom"
import { startAddTheory, startSetTheory, startAddTheoryFollow, startRemoveTheoryFollow, 
    startVoteComprehensiveness, returnTheoryInfoHomeUpdate } from '../../actions/theoryModels'
import ClaimReference from '../ClaimReferenceUpdate'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import { ClaimReferenceDiv } from '../ClaimRefDiv'
import { calculateTheoryClaimConfidence } from '../../actions/theoryModels'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Spinner from "react-svg-spinner";
import SVGOptionsDots from '../SVGs/SVGoptionsDotsTheories';
import { createNewTheory } from '../../actions/theoryModels'
import moment from 'moment'
import { history } from '../../routers/AppRouter'
import SVGaddFigure from '../SVGs/SVGaddFigure';

import SVGDelete from '../SVGs/SVGDelete';
import LoadingScreen from '../loadingScreen';
import DeleteModal from '../deleteModalTheory';
import { updateLastViewedTheory } from '../../actions/theoryModels'
import { v4 as uuidv4 } from 'uuid';

export const FellowTheoryUpdate = (props) => {

    function getUniqueEngagement (objects) {
        const seenIds = new Set();
        return objects.filter(obj => {
            if (seenIds.has(obj.id)) {
            return false;
            } else {
            seenIds.add(obj.id);
            return true;
            }
        });
    }

    const [localDate, setLocalDate] = useState()
    const [theText, setTheText] = useState([])
    const [percentConfidence, setThePercentConfidence] = useState(0)
    const [followed, setFollowed] = useState(false)
    const [totalEngagement, setTotalEngagement] = useState([])
    const [uniqueEngagement, setUniqueEngagement] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [firstLoad, setFirstLoad] = useState(false)
    const [compreSubmit, setCompreSubmit] = useState(false)
    const [optionsOpen, setOptionsOpen] = useState(false)
    const [deleteModalState, setDeleteModalState] = useState(false)
    const [title, setTitle] = useState('')
    const [owner, setOwner] = useState('')
    const [ownerTags, setOwnerTags] = useState([])
    const [notDeleted, setNotDeleted] = useState(false)
    const [claimsList, setClaimsList] = useState([])
    const [published, setPublished] = useState(false)
    const [comprehensiveness, setComprehensiveness] = useState({five: [], four: [], three: [], two: [], one: []})
    const [timeStampPageVisit, setTimeStampPageVisit] = useState()
    const [openDesc, setOpenDesc] = useState(true)
    const [percentLoaded, setPercentLoaded] = useState(false)

    const textAreaRef = useRef()
    const checkBox1 = useRef()
    const checkBox2 = useRef()
    const checkBox3 = useRef()
    const checkBox4 = useRef()
    const checkBox5 = useRef()
    let optionsRefDiv = useRef();
    let navigate = useNavigate()

    function removeEngagement (array1, array2) {
        return array1.filter(obj1 => !array2.some(obj2 => obj1.occurrence === obj2.occurrence));
    }

    //This is a simple function that loops through the ownerTags of the theory to look for one that
    //has an id that matches fellowID. If it does, it means that the theory was not posted 
    //anonymously and the fellowFigureUpdate should be shown.
    function checkNotAnonymous(id, theoryOwnerTags) {
        // Loop through the array of objects
        for (let i = 0; i < theoryOwnerTags.length; i++) {
          // Check if the current object's id matches the provided id
          if (theoryOwnerTags[i].id === id) {
            // Return true if a match is found
            return true;
          }
        }
        // Return false if no match is found
        return false;
    }

    useEffect( async () => {
        //Make call to Firebase with returnFigureInfoUpdates function to fetch info off of database.
        let theoryInfo = await props.returnTheoryInfoHomeUpdate(props.id)
        //If info is able to be fetched, update all relevant state values.
        if (theoryInfo) {
            const theDate = new Date(theoryInfo.timeStamp)
            const newMoment = moment(theDate)
            newMoment.local()
            const theLocalDate = newMoment.format('LLL')
            //Here we record the time that the info of this theory was pulled off of Firebase. This will
            //be used as the time the user last viewed their fellow's content. But first, we have to make
            //sure the fellow didnt upload the theory anonymously. If they uploaded the theory anonymously,
            //we don't want to show this theory update because it could expose them. We use the simple function
            //below to check first before assigning all the values to the state. 
            let notAnonymous = checkNotAnonymous(props.fellowID, theoryInfo.ownerTags)
            if (notAnonymous) {
                //Here we record the time that the info of this theory was pulled off of Firebase. This will
                //be used as the time the user last viewed this theory. We call the appropriate function:
                let timeStamp = moment().utc().toString()
                setTimeStampPageVisit(timeStamp)
                for(var i = 0; i < theoryInfo.follows.length; i++) {
                    if (theoryInfo.follows[i].id === props.user) {
                        setFollowed(true)
                        break;
                    }
                }
                setPublished(true)
                setComprehensiveness(theoryInfo.comprehensiveness)
                setClaimsList(theoryInfo.claimsList)
                setTotalEngagement(theoryInfo.engagement)
                setTheText(theoryInfo.claimsList)
                setTitle(theoryInfo.title)
                setLocalDate(theLocalDate)
                setOwnerTags(theoryInfo.ownerTags)
                setOwner(theoryInfo.owner)
                setNotDeleted(true)
                setFirstLoad(true)
                setPercentLoaded(true)
            } else {
                //We pretend the theory has simply been deleted if it was posted anonymously so it does not 
                //end up getting shown.
                setNotDeleted(false)
            }

        } else {
            //If the theory is not available on Firebase, it is probably because it was deleted.
            //If it was deleted, nothing should be shown.

            //Also set to false if there are no updates to be shown for this claim so that it does
            //not show up.
            setNotDeleted(false)
        }
    }, [])
    
    useEffect(() => {
        let theUniqueEngagement = getUniqueEngagement(totalEngagement)
        setUniqueEngagement(theUniqueEngagement)
    }, [totalEngagement])

    useEffect(() => {
        let handler = (event) => {
            if(!(optionsRefDiv.current.contains(event.target))) {
                setOptionsOpen(false)
            }
        }
        document.addEventListener("mousedown", handler)
        return () => {
            document.removeEventListener("mousedown", handler)
        }
    })

    //This determines if the user has scrolled over the claim and thus has viewed it.
    //If so, the lastViewed time stamp will be updated on their profile, so call the 
    //function below.
    // const observer = useRef()
    // const lastUpdateElementRef = useCallback((node) => {
    //     if (observer.current) {
    //         observer.current.disconnect()
    //     }
    //     let timeStamp2 = moment().utc().toString()
    //     observer.current = new IntersectionObserver((entry) => {
    //         if (entry[0].isIntersecting && !updatedLastViewedAlready) {
    //             props.updateLastViewedClaim(props.user, props.id, timeStamp2)
    //             setUpdatedLastViewedAlready(true)
    //             // console.log('viewing')
    //         }
    //     })
    //     if (node) {
    //         observer.current.observe(node)
    //     }
        
    // }, [updatedLastViewedAlready])




    function calculateAverageComprehensiveness() {
        let allCheckBoxes = []
        allCheckBoxes.push(comprehensiveness.one)
        allCheckBoxes.push(comprehensiveness.two)
        allCheckBoxes.push(comprehensiveness.three)
        allCheckBoxes.push(comprehensiveness.four)
        allCheckBoxes.push(comprehensiveness.five)
        let sum = 0;
        let voteCount = 0;
        allCheckBoxes.forEach((array, index) => {
            sum += array.length * (index + 1)
            voteCount += array.length
        })
        return (sum/voteCount * 0.2);
    }


    //This useEffect calculates the claim confidence of the theory so that it can be displayed.
    useEffect(async () => {
        if (percentLoaded) {
            return;
        }

        if (props.owner === props.user && !published) {
            navigate('/AddTheory/' + props.id)
        }

        if (props.title === '') {
            return;
        }
        let newPercentConfidence = await props.calculateTheoryClaimConfidence(claimsList)
        let averageComprehensiveness = calculateAverageComprehensiveness()
        let newValue = Math.round(newPercentConfidence * averageComprehensiveness)
        if (isNaN(newValue)) {
            setThePercentConfidence('?')
            setTimeout(() => {
                setPercentLoaded(true)
            }, 300)

        } else {
            setThePercentConfidence(newValue.toString() + '%')
            setTimeout(() => {
                setPercentLoaded(true)
            }, 300)
        }
    }, [claimsList, percentLoaded])


    //This useEffect is for indicating which comprehensiveness checkbox has been checked after the user
    //decides how comprehensive it is.
    useEffect(() => {
        // console.log(props.comprehensiveness)
        setCheckBox()
        setCompreSubmit(true)
    }, [compreSubmit, comprehensiveness])




    //We call this function to indicate which checkbox has been selected after the user has already voted
    //on how comprehensive the theory is.
    function setCheckBox() {
        let checkBoxOne = checkBox1.current;
        let checkBoxTwo = checkBox2.current;
        let checkBoxThree = checkBox3.current;
        let checkBoxFour = checkBox4.current;
        let checkBoxFive = checkBox5.current;

        let i = 1;
        let checked1 = comprehensiveness.one.findIndex(vote => vote.id === props.user)
        if (checked1 > -1) {
            checkBoxOne.checked = true
        }
        let checked2 = comprehensiveness.two.findIndex(vote => vote.id === props.user)
        if (checked2 > -1) {
            checkBoxTwo.checked = true
        }
        let checked3 = comprehensiveness.three.findIndex(vote => vote.id === props.user)
        if (checked3 > -1) {
            checkBoxThree.checked = true
        }
        let checked4 = comprehensiveness.four.findIndex(vote => vote.id === props.user)
        if (checked4 > -1) {
            checkBoxFour.checked = true
        }
        let checked5 = comprehensiveness.five.findIndex(vote => vote.id === props.user)
        if (checked5 > -1) {
            checkBoxFive.checked = true
        }
    }

    //Function that is called when a user wants to report the Theory.
    const reportTheory = () => {
        navigate('/ReportTheory/' + props.id)
    }



    const newEngagementArray = () => {
        let engagementIndex = totalEngagement.findIndex(engagement => engagement.id === props.user)
        if (engagementIndex == -1) {
            let timeStamp = moment().utc().toString();
            let occurrence = uuidv4();
            totalEngagement.push(
                {
                    id: props.user,
                    timeStamp: timeStamp,
                    occurrence: occurrence
                }
            )
            return totalEngagement;
        }
        return totalEngagement;
    }


    //Function that gets called when Follow button is pressed by the user. It will update Firebase
    //to reflect the change. It handles following and unfollowing.
    const followTheory = () => {
        setFollowed(!followed)
        let timeStamp = moment().utc().toString();
        let occurrence = uuidv4();
        if (!followed) {
            props.startAddTheoryFollow(props.user, props.id, occurrence, timeStamp).then(() => {
                
            }).catch(() => {
                // console.log('theory was not followed')
            })
        } else if (followed) {
            props.startRemoveTheoryFollow(props.user, props.id).then(() => {
                
            }).catch(() => {
                // console.log('theory was not followed')
            })
        } else {
            // console.log('something wrong with theory-follow logic')
        }
        
    }
    function getCaretIndex(element) {
        let position = 0;
        const isSupported = typeof window.getSelection !== "undefined";
        if (isSupported) {
          const selection = window.getSelection();
          if (selection.rangeCount !== 0) {
            const range = window.getSelection().getRangeAt(0);
            const preCaretRange = range.cloneRange();
            preCaretRange.selectNodeContents(element);
            preCaretRange.setEnd(range.endContainer, range.endOffset);
            position = preCaretRange.toString().length;
          }
        }
        if (position == 0) {
            return 'Error'
        }
        return position;
    }

    //This function is called when the user clicks the "Create a new Theory" button from the
    //Options menu.
    const createTheory = async () => {
        if (!props.loggedIn) {
            return;
        }
        let timeStamp = moment().utc().toString()
        let occurrence = uuidv4()
        props.createNewTheory(props.user, timeStamp, occurrence).then((id) => {
            navigate('/AddTheory/' + id)
        })
        
    }

    const findCaretChunkAndIndex = () => {
        // console.log('find caret and chunk ran')

        // Get the index of where the caret is.
        let fullCaretIndex = getCaretIndex(textAreaRef.current)
        let chunks = updateTextState()
  
        

        // // Step 1: If there is no selection within the theory text area, dont do anything and end the funciton.
        if (fullCaretIndex === "Error") {
            // console.log('Select a place within the area to insert the Claim Reference')
            return;
        }

        // Step 2: Iterate through each chunk and sum their collective indexes.
        // Find which "chunk" the caret is within
        // Subtract the aggregatedIndex from the fullCaretIndex to get the index within that particular chunk.

        let aggregatedIndex = 0;
        let chunkIndex;
        let i = 0;
        let objectCount = 0;

        while (i < chunks.length) {

            if (typeof chunks[i] == 'string') {
                aggregatedIndex += chunks[i].length
            } else if (typeof chunks[i] == 'object') {
                objectCount += 1
                aggregatedIndex += objectCount.toString().length + 2
            }

            if (aggregatedIndex >= fullCaretIndex) {
                if (typeof chunks[i] == 'string') {
                    aggregatedIndex -= chunks[i].length
                } else {
                    aggregatedIndex -= objectCount.toString().length + 2
                }
                chunkIndex = i
                i = chunks.length
            }
            i += 1;
        }
        
        let indexWithinChunk = fullCaretIndex - aggregatedIndex;

        let data = {
            chunks: chunks,
            chunkIndex: chunkIndex,
            indexWithinChunk: indexWithinChunk,
            fullCaretIndex: fullCaretIndex
        }

        return data;
    }

    function makeTextNode() {
        return document.createTextNode('​') // <-- there a zero-width space between quotes
    }

    function checkBox(number) {
        let checkBoxOne = checkBox1.current;
        let checkBoxTwo = checkBox2.current;
        let checkBoxThree = checkBox3.current;
        let checkBoxFour = checkBox4.current;
        let checkBoxFive = checkBox5.current;

        let allCheckBoxes = [checkBoxOne, checkBoxTwo, checkBoxThree, checkBoxFour, checkBoxFive]

        let i = 0;
        while (i < 5) {
            if (number != i) {
                allCheckBoxes[i].checked = false
            }
            i += 1
        }
    }

    async function voteComprehensiveness() {
        let checkBoxOne = checkBox1.current;
        let checkBoxTwo = checkBox2.current;
        let checkBoxThree = checkBox3.current;
        let checkBoxFour = checkBox4.current;
        let checkBoxFive = checkBox5.current;
        let allCheckBoxes = [checkBoxOne, checkBoxTwo, checkBoxThree, checkBoxFour, checkBoxFive]

        let timeStamp = moment().utc().toString()
        let occurrence = uuidv4();

        if (props.user === '') {
            return;
        }

        let i = 0;
        let k = 0;
        while (i < allCheckBoxes.length) {
            if (allCheckBoxes[i].checked == true) {
                k = i + 1
            }
            i += 1
        }

        await props.startVoteComprehensiveness(props.user, props.id, k, occurrence, timeStamp)
        setPercentLoaded(false)
    }

    const preventDragHandler = (e) => {
        e.preventDefault();
    }

    let specific = false
    function placeCaretInPreviousSpan() {
        // console.log('place caretInPreviousSpan ran')
       
        let data = findCaretChunkAndIndex()
        // console.log(data)
        const range = document.createRange()

        let editableDiv = document.querySelector('#editableDivMax');
        let all = Array.from(editableDiv.childNodes);

        const span = all[data.chunkIndex-1]
        if (span.childNodes.length === 0) {
          span.appendChild(makeTextNode()) 
           // <-- you have to have something in span in order to place caret inside
        }
        range.setStart(span, 1) // <-- offset by 1 to be inside SPAN element and not before it
        let selection = window.getSelection()
        range.collapse(true)
        selection.removeAllRanges()
        selection.addRange(range)
        specific = true
        span.focus()
    }

    function removeSubstring(originalString) {
        let split = originalString.split('/')
        let theURL = '/' + split[3] + '/' + split[4]
        return theURL;
    }

    function handleClick(event) {
        event.preventDefault();
        let URL = removeSubstring(event.target.href, event.target.baseURI)
        navigate(URL)
    }

    return (
        <div className={firstLoad && notDeleted ? "" : "hidden"}>
            {deleteModalState && <DeleteModal id={props.id} closeModal={setDeleteModalState} element='theory' />}
            <div>
                <div className="majorContainerFigureUpdate">
                    <div className="smallFigureContainerHome">
                        <div className='figureUpdatePaddingHome'>
                            <p>- Your <a className='figureUpdateClaimAnchorHome' href={'/Profile/' + props.fellowID} onClick={handleClick}
                            >fellow</a> recently published a theory.</p>
                            <div className='line3Claim'></div>
                            <div className='paperUpdateTitleHomeDiv'>
                                <a className='figureUpdateTitleHome' href={'/Theory/' + props.id} onClick={handleClick}>{title}</a>    
                            </div>
                            <div className='flexTheoryUpdate'>
                                <div className='confidenceAndEngagementClaim'>
                                    <div className='centerVertical'>
                                        <div className='verticalStrecthFigure'>
                                            <div><b>Confidence: </b><Spinner hidden={percentLoaded} /><b hidden={!percentLoaded}>{percentConfidence}</b></div>
                                            <div><b>Engagement: {uniqueEngagement.length}</b></div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div hidden={!props.loggedIn} ref={optionsRefDiv} className='topBarFigureUpdateHome'>
                                    
                                    <Tippy content={followed ? "Unfollow Theory" : "Follow Theory"} >
                                        <svg className={followed ? "followSVGSVGClaimUpdateClicked" : "followSVGSVGClaimUpdate"} 
                                        onClick={followTheory} version="1.1" viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                                            <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path className="color000 svgShape" d="m24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" 
                                                fill={followed ? "#fff" : "#002c2c"}/>
                                            </svg>
                                        </svg>
                                    </Tippy>
                                    <Tippy content='Options' >
                                        <svg hidden={!props.loggedIn} className="input5ClaimUpdate" onClick={() => setOptionsOpen(!optionsOpen)} version="1.1" 
                                        viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                                            <svg width="288" height="288" enableBackground="new 0 0 32.055 32.055" viewBox="0 0 32.055 32.055" 
                                            xmlns="http://www.w3.org/2000/svg">
                                                <path className="color000 svgShape" d="m3.968 12.061c-2.193 0-3.968 1.774-3.968 3.966s1.773 3.967 3.968 3.967c2.189 0 3.966-1.772 3.966-3.967 0-2.192-1.777-3.966-3.966-3.966zm12.265 0c-2.188 0-3.968 1.773-3.968 3.965s1.778 3.967 3.968 3.967 3.97-1.772 3.97-3.967c-2e-3 -2.191-1.78-3.965-3.97-3.965zm11.857 0c-2.192 0-3.969 1.774-3.969 3.967 0 2.19 1.774 3.965 3.969 3.965 2.188 0 3.965-1.772 3.965-3.965s-1.777-3.967-3.965-3.967z" fill="#002c2c"/>
                                            </svg>
                                        </svg>
                                    </Tippy>
                                    <div hidden={!optionsOpen} className='dropDownClaimUpdateHome'>
                                        <div className='optionsAnchorDiv' hidden={props.user != owner} onClick={() => {createTheory()}}>
                                            <SVGaddFigure/>
                                            <a><b>Create a new Theory</b></a>
                                        </div>
                                        <div className='optionsAnchorDiv' onClick={reportTheory}>
                                            <svg className='marginSVG' width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 
                                                0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z" fill="#002c2c"/>
                                            </svg>
                                            <a><b>Report this Theory</b></a>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div>
                                <div className='verticalCenterClaimUpdate'>
                                    <div className='uploadedByHome'>
                                        <div className='divAbove2Figure'>
                                            <div className='addedByFigure'><b>Uploaded by: </b></div>
                                            {
                                                ownerTags.map((tag, index) => {
                                                    return (<a key={tag.displayName + index} className="anchorNameClaim" href={'/Profile/' + tag.id }
                                                    onClick={handleClick}>{tag.displayName}
                                                    </a>)
                                                })
                                            }
                                        </div>
                                        <div className='localDateDivClaimUpdate'><b>- {localDate}</b></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='theFlexHomeTheory'>
                            <div className='TheoryBottomPaddingHome'>
                                <Tippy content ={!openDesc ? 'Hide Theory' : 'View Theory'} >
                                    <svg onClick={() => {setOpenDesc(!openDesc)}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" 
                                    viewBox="0 0 24 24" transform={!openDesc ? "rotate(180)" : ""} className="openDescHome">
                                        <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/>
                                    </svg>
                                </Tippy>
                            </div>
                            <div className={!openDesc ? 'comprehensivenessHome' : 'hidden'}>
                                <div className='theoryHomeDiv'>
                                
                                    {theText.map((theElement, index) => {
                                            if (typeof theElement == "string") {
                                                return (<ClaimReferenceDiv theText={theText} setTheText={setTheText} index={index + theText} onDragStart={preventDragHandler} 
                                                    theElement={theElement} draggable="false" className='theoryTextDiv' contentEditable={true} key={index} 
                                                    suppressContentEditableWarning={true} newEngagementArray={newEngagementArray} 
                                                    setTotalEngagement={setTotalEngagement} published={published}
                                                />)
                                            } else {
                                                return (<ClaimReference title={theElement.title} key={index + theElement.title} footnote={(index + 1) / 2} user={props.user} 
                                                    placeCaretInPreviousSpan={placeCaretInPreviousSpan} claimInfo={claimsList[index]} theoryID={props.id}
                                                    calculateTheoryClaimConfidence={calculateTheoryClaimConfidence} totalEngagement={totalEngagement}
                                                    setTotalEngagement={setTotalEngagement} newEngagementArray={newEngagementArray} published={published}
                                                    loggedIn={props.loggedIn} loaded={loaded} setLoaded={setLoaded} index={index}
                                                    removeEngagement={removeEngagement} setPercentLoaded={setPercentLoaded} setClaimsList={setClaimsList}
                                                    claimsList={claimsList}
                                                    />)
                                            }
                                        })
                                    }
                                </div>
                                <h2 className='comprehensivenessQuestion'>How comprehensive is this theory?</h2>
                                <div className='comprehensivenessDiv'>
                                    <div className='comprehensivenessVote'>
                                        <div className='checkBoxAndNumberDiv'>
                                            <div className='checkBoxAndNumber'>
                                                <input type='radio' className='comprehensivenessCheckbox' ref={checkBox1} onClick={() => checkBox(0)}/>
                                                <div className='comprehensivenessNumberDiv'>1</div>
                                            </div>
                                        </div>
                                        <div className='checkBoxAndNumberDiv'>
                                            <div className='checkBoxAndNumber'>
                                                <input type='radio' className='comprehensivenessCheckbox' ref={checkBox2} onClick={() => checkBox(1)}/>
                                                <div className='comprehensivenessNumberDiv'>2</div>
                                            </div>
                                        </div>
                                        <div className='checkBoxAndNumberDiv'>
                                            <div className='checkBoxAndNumber'>
                                                <input type='radio' className='comprehensivenessCheckbox' ref={checkBox3} onClick={() => checkBox(2)}/>
                                                <div className='comprehensivenessNumberDiv'>3</div>
                                            </div>
                                        </div>
                                        <div className='checkBoxAndNumberDiv'>
                                            <div className='checkBoxAndNumber'>
                                                <input type='radio' className='comprehensivenessCheckbox' ref={checkBox4} onClick={() => checkBox(3)}/>
                                                <div className='comprehensivenessNumberDiv'>4</div>
                                            </div>
                                        </div>
                                        <div className='checkBoxAndNumberDiv'>
                                            <div className='checkBoxAndNumber'>
                                                <input type='radio' className='comprehensivenessCheckbox' ref={checkBox5} onClick={() => checkBox(4)}/>
                                                <div className='comprehensivenessNumberDiv'>5</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='comprehensivenessVoteWrapper'>
                                        <button onClick={() => voteComprehensiveness()} className='comprehensivenessSubmit'>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br/>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state, props) => {
    if (state.profModels.length == 1) {
        return {
            user: state.profModels[0].id,
            userName: state.profModels[0].name,
            tags: state.profModels[0].tags,
            loggedIn: true,
        };
    } else {
        return {
            user: '',
            userName: '',
            tags: [],
            loggedIn: false
        };
    }
}

const mapDispatchToProps = (dispatch) => ({
    startAddTheory: (TheoryData) => dispatch(startAddTheory(TheoryData)),
    startSetTheory: (id) => dispatch(startSetTheory(id)),
    calculateTheoryClaimConfidence: (claimsList) => dispatch(calculateTheoryClaimConfidence(claimsList)),
    startAddTheoryFollow: (user, id, occurrence, timeStamp) => dispatch(startAddTheoryFollow(user, id, occurrence, timeStamp)),
    startRemoveTheoryFollow: (user, id) => dispatch(startRemoveTheoryFollow(user, id)),
    startVoteComprehensiveness: (user, theoryID, checkBoxNumber, occurrence, timeStamp) => dispatch(startVoteComprehensiveness(user, theoryID, checkBoxNumber, occurrence, timeStamp)),
    createNewTheory: (owner, occurrence, timeStamp) => dispatch(createNewTheory(owner, occurrence, timeStamp)),
    updateLastViewedTheory: (userID, figureID, timeStamp) => dispatch(updateLastViewedTheory(userID, figureID, timeStamp)),
    returnTheoryInfoHomeUpdate: (id) => dispatch(returnTheoryInfoHomeUpdate(id))

})

const ConnectedFellowTheoryUpdate = connect(mapStateToProps, mapDispatchToProps)(FellowTheoryUpdate);

export default ConnectedFellowTheoryUpdate;