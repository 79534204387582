import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from "react-router-dom"
import { startSetProfile } from '../actions/profModels'
import Modal from './FigureShowModal'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import SVGoptionsDotsMyTheories from './SVGs/SVGoptionsDotsMyTheories';
import { history } from '../routers/AppRouter'
import useWindowDimensions from '../custom hooks/windowDimensions'
import { returnTheoryInfoMyTheories, createNewTheory } from '../actions/theoryModels'
import Spinner from "react-svg-spinner";
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid';



export const myTheories = (props) => {
    const [whichTabIsIt, setWhichTabIsIt] = useState('Authored Theories')
    const [theoryConfidences, setTheoryConfidences] = useState([])
    const [theoryTitles, setTheoryTitles] = useState([])
    const [theoryEngagements, setTheoryEngagements] = useState([])
    const [theoryMoments, setTheoryMoments] = useState([])
    const [theoryTimeStamps, setTheoryTimeStamps] = useState([])
    const [theoryPublishings, setTheoryPublishings] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [theoriesListAuthored, setTheoriesListAuthroed] = useState(props.myTheories.authoredTheories)
    const [theoriesFollowed, setTheoriesFollowed] = useState(props.myTheories.followedTheories)
    const [theoriesListCurrent, setTheoriesListCurrent] = useState(props.myTheories.authoredTheories)
    const [sortBySelector, setSortBySelector] = useState('Oldest')
    const [selectorOpen, setSelectorOpen] = useState(false)
    const [redo, setRedo] = useState(false)

    let selectorRef = useRef();
    const navigate = useNavigate()

    let loaded2;
    if (props.myTheories != []) {
        loaded2 = false
    } else {
        loaded2 = true
    }

    const whichTab = (tab) => {
        if (loaded != true) {
            return;
        }
        setLoaded(false)
        if (tab === 'Authored Theories') {
            setTheoriesListCurrent(theoriesListAuthored)
            setSortBySelector('Oldest')
        } else if (tab === 'Followed Theories') {
            setTheoriesListCurrent(theoriesFollowed)
            setSortBySelector('Oldest')
        } 
        setWhichTabIsIt(tab)

    }

    const based = (index, id) => {
        let newArray1 = [...theoriesListCurrent]
        let newArray2 = [...theoryConfidences]
        let newArray3 = [...theoryTitles]
        let newArray4 = [...theoryEngagements]
        let newArray5 = [...theoryMoments]
        let newArray6 = [...theoryTimeStamps]
        let newArray7 = [...theoryPublishings]

        newArray1.splice(index, 1)
        newArray2.splice(index, 1)
        newArray3.splice(index, 1)
        newArray4.splice(index, 1)
        newArray5.splice(index, 1)
        newArray6.splice(index, 1)
        newArray7.splice(index, 1)

        //If we are in the Authored Theories tab, this method will delete the theory when called.
        if (whichTabIsIt === 'Authored Theories') {
            let newArray7 = [...theoriesListAuthored]
            let nextList = newArray7.filter((theory) => {
                return theory.id !== id
            })
            setTheoriesListAuthroed(nextList)

            //Also check to see if the theory is in the theoriesFollowed and remove if it is.
            if (theoriesFollowed.includes(id)) {
                let newArray = theoriesFollowed.filter((theory) => {
                    return theory.id !== id;
                })
                setTheoriesFollowed(newArray)
            }
        }

        //If we are in the Followed Theories tab, this method will un-follow the theory when called.
        if (whichTabIsIt === 'Followed Theories') {
            let nextList = theoriesFollowed.filter((theory) => {
                return theory.id !== id
            })
            setTheoriesFollowed(nextList)
        }

        setTheoriesListCurrent(newArray1)
        setTheoryConfidences(newArray2)
        setTheoryTitles(newArray3)
        setTheoryEngagements(newArray4)
        setTheoryMoments(newArray5)
        setTheoryTimeStamps(newArray6)
        setTheoryPublishings(newArray7)

    }
    useEffect(() => {
        let handler = (event) => {
            if(!selectorRef.current.contains(event.target)) {
                setSelectorOpen(false)
            }
        }
        document.addEventListener("mousedown", handler)
        return () => {
            document.removeEventListener("mousedown", handler)
        }
    })

    useEffect(async () => {
        let theTheoryConfidences = []
        let theTitles = []
        let theEngagements = []
        let theMoments = []
        let theoryTimeStamps = []
        let theTheoryPublishings = []
        for (const theory of theoriesListCurrent) {
            let theoryInfo = await props.returnTheoryInfoMyTheories(theory.id)
            theTitles.push(theoryInfo.title)
            theEngagements.push(theoryInfo.engagementNumber)
            theMoments.push(theoryInfo.timeStamp)
            theoryTimeStamps.push(theoryInfo.timeStamp.format('LLL'))
            theTheoryPublishings.push(theoryInfo.published)
            if (isNaN(theoryInfo.confidence)) {
                theTheoryConfidences.push('?')
            } else {
                theTheoryConfidences.push(theoryInfo.confidence)
            }
        }
        setTheoryConfidences(theTheoryConfidences)
        setTheoryTitles(theTitles)
        setTheoryEngagements(theEngagements)
        setTheoryMoments(theMoments)
        setTheoryTimeStamps(theoryTimeStamps)
        setTheoryPublishings(theTheoryPublishings)
        setLoaded(true)
        
    }, [whichTabIsIt, redo])

    
    const openSelector = () => {
        setSelectorOpen(!selectorOpen)
    }

    const sortTheoriesByConfidence = () => {
        //if Confidence is already selected, don't do anything.
        if (sortBySelector === 'Confidence') {
            setSelectorOpen(false)
            return;
        }
        
        //combine all data into array of objects for easy sorting.
        let allArrayData = []
        for (let i = 0; i < theoryTitles.length; i++) {
            let item = {
                title: theoryTitles[i],
                engagements: theoryEngagements[i],
                confidence: theoryConfidences[i],
                id: theoriesListCurrent[i],
                moment: theoryMoments[i],
                timeStamp: theoryTimeStamps[i],
                published: theoryPublishings[i]
            }
            allArrayData.push(item)
        }
        //sort allArrayData on the basis of Engagement
        allArrayData.sort((a,b) => {
            if (a.confidence !== '?' && b.confidence !== '?') {
                return b.confidence - a.confidence; 
            } else if (a.confidence == '?') {
                return 1;
            } else if (b.confidence == '?') {
                return -1;
            }
        })
        
        //create a new array for dates, engagements, confidences, and timeStamps and recreate the state arrays
        let newEngagements = []
        let confidences = []
        let newTitles = []
        let newTheoriesList = []
        let newMoments = []
        let newTimeStamps = []
        let newPublishings = []
        for (let i = 0; i < allArrayData.length; i++) {
            newEngagements.push(allArrayData[i].engagements)
            confidences.push(allArrayData[i].confidence)
            newTitles.push(allArrayData[i].title)
            newTheoriesList.push(allArrayData[i].id)
            newMoments.push(allArrayData[i].moment)
            newTimeStamps.push(allArrayData[i].timeStamp)
            newPublishings.push(allArrayData[i].published)
        }
        
        //all we have to do is set everything to the original list, which is stored when the page is loaded and never changes
        setTheoryConfidences(confidences)
        setTheoryEngagements(newEngagements)
        setTheoriesListCurrent(newTheoriesList)
        setTheoryTitles(newTitles)
        setTheoryMoments(newMoments)
        setTheoryTimeStamps(newTimeStamps)
        setTheoryPublishings(newPublishings)
        setSelectorOpen(false)
        setSortBySelector('Confidence')
    }


    const sortFiguresByEngagement = () => {
        //if Engagement is already selected, don't do anything.
        if (sortBySelector === 'Engagement') {
            setSelectorOpen(false)
            return;
        }

        //combine all data into array of objects for easy sorting.
        let allArrayData = []
        for (let i = 0; i < theoryTitles.length; i++) {
            let item = {
                title: theoryTitles[i],
                engagements: theoryEngagements[i],
                confidence: theoryConfidences[i],
                id: theoriesListCurrent[i],
                moment: theoryMoments[i],
                timeStamp: theoryTimeStamps[i],
                published: theoryPublishings[i]
            }
            allArrayData.push(item)
        }

        //sort allArrayData on the basis of Engagement
        allArrayData.sort((a,b) => b.engagements - a.engagements)

        //create a new array for dates, engagements, acceptancePercentages, and timeStamps and recreate the state arrays
        let newEngagements = []
        let confidences = []
        let newTitles = []
        let newTheoriesList = []
        let newMoments = []
        let newTimeStamps = []
        let newPublishings = []
        for (let i = 0; i < allArrayData.length; i++) {
            newEngagements.push(allArrayData[i].engagements)
            confidences.push(allArrayData[i].confidence)
            newTitles.push(allArrayData[i].title)
            newTheoriesList.push(allArrayData[i].id)
            newMoments.push(allArrayData[i].moment)
            newTimeStamps.push(allArrayData[i].timeStamp)
            newPublishings.push(allArrayData[i].published)
        }
        
        //all we have to do is set everything to the original list, which is stored when the page is loaded and never changes
        setTheoryConfidences(confidences)
        setTheoryEngagements(newEngagements)
        setTheoriesListCurrent(newTheoriesList)
        setTheoryTitles(newTitles)
        setTheoryMoments(newMoments)
        setTheoryTimeStamps(newTimeStamps)
        setTheoryPublishings(newPublishings)
        setSelectorOpen(false)
        setSortBySelector('Engagement')
    }

    const sortClaimsByNewest = (tab) => {
        //if Newest is already selected, don't do anything.
        if (sortBySelector === 'Newest') {
            setSelectorOpen(false)
            return;
        }

        //combine all data into array of objects for easy sorting.
        let allArrayData = []
        for (let i = 0; i < theoryTitles.length; i++) {
            let item = {
                title: theoryTitles[i],
                engagements: theoryEngagements[i],
                confidence: theoryConfidences[i],
                id: theoriesListCurrent[i],
                moment: theoryMoments[i],
                timeStamp: theoryTimeStamps[i],
                published: theoryPublishings[i]
            }
            allArrayData.push(item)
        }

        //sort allArrayData on the basis of Engagement
        allArrayData.sort((a,b) => {
            if (a.moment.isBefore(b.moment)) {
                return 1; 
            } else {
                return -1;
            }
        })


        //create a new array for dates, engagements, confidences, and timeStamps and recreate the state arrays
        let newEngagements = []
        let confidences = []
        let newTitles = []
        let newTheoriesList = []
        let newMoments = []
        let newTimeStamps = []
        let newPublishings = []
        for (let i = 0; i < allArrayData.length; i++) {
            newEngagements.push(allArrayData[i].engagements)
            confidences.push(allArrayData[i].confidence)
            newTitles.push(allArrayData[i].title)
            newTheoriesList.push(allArrayData[i].id)
            newMoments.push(allArrayData[i].moment)
            newTimeStamps.push(allArrayData[i].timeStamp)
            newPublishings.push(allArrayData[i].published)
        }
        
        //all we have to do is set everything to the original list, which is stored when the page is loaded and never changes
        setTheoryConfidences(confidences)
        setTheoryEngagements(newEngagements)
        setTheoriesListCurrent(newTheoriesList)
        setTheoryTitles(newTitles)
        setTheoryMoments(newMoments)
        setTheoryTimeStamps(newTimeStamps)
        setTheoryPublishings(newPublishings)
        setSelectorOpen(false)
        setSortBySelector('Newest')
    }

    const sortClaimsByOldest = (tab) => {
        //if Oldest is already selected, don't do anything.
        if (sortBySelector === 'Oldest') {
            setSelectorOpen(false)
            return;
        }

        //combine all data into array of objects for easy sorting.
        let allArrayData = []
        for (let i = 0; i < theoryTitles.length; i++) {
            let item = {
                title: theoryTitles[i],
                engagements: theoryEngagements[i],
                confidence: theoryConfidences[i],
                id: theoriesListCurrent[i],
                moment: theoryMoments[i],
                timeStamp: theoryTimeStamps[i],
                published: theoryPublishings[i]
            }
            allArrayData.push(item)
        }

        //sort allArrayData on the basis of Engagement
        allArrayData.sort((a,b) => {
            if (a.moment.isBefore(b.moment)) {
                return -1; 
            } else {
                return 1;
            }
        })

        //create a new array for dates, engagements, confidences, and timeStamps and recreate the state arrays
        let newEngagements = []
        let confidences = []
        let newTitles = []
        let newTheoriesList = []
        let newMoments = []
        let newTimeStamps = []
        let newPublishings = []
        for (let i = 0; i < allArrayData.length; i++) {
            newEngagements.push(allArrayData[i].engagements)
            confidences.push(allArrayData[i].confidence)
            newTitles.push(allArrayData[i].title)
            newTheoriesList.push(allArrayData[i].id)
            newMoments.push(allArrayData[i].moment)
            newTimeStamps.push(allArrayData[i].timeStamp)
            newPublishings.push(allArrayData[i].published)
        }
        
        //all we have to do is set everything to the original list, which is stored when the page is loaded and never changes
        setTheoryConfidences(confidences)
        setTheoryEngagements(newEngagements)
        setTheoriesListCurrent(newTheoriesList)
        setTheoryTitles(newTitles)
        setTheoryMoments(newMoments)
        setTheoryTimeStamps(newTimeStamps)
        setTheoryPublishings(newPublishings)
        setSelectorOpen(false)
        setSortBySelector('Oldest')
    }

    const createTheory = async () => {
        if (!props.loggedIn) {
            return;
        }
        let timeStamp = moment().utc().toString()
        let occurrence = uuidv4()
        props.createNewTheory(props.user, timeStamp, occurrence).then((id) => {
            navigate('/AddTheory/' + id)
        })
    }

    function removeSubstring(originalString) {
        let split = originalString.split('/')
        let theURL = '/' + split[3] + '/' + split[4]
        return theURL;
    }

    function replaceString(a, b, c) {
        // Use the replaceAll method to replace all occurrences of 'a' with 'b'
        const result = c.split(a).join(b);
        return result;
    }

    function handleClick(event) {
        event.preventDefault();
        let URL = removeSubstring(event.target.href, event.target.baseURI)
        //Get the data on whether the theory has been published or not. If it has been published, clicking on it will navigate to 
        //"Theory/ID" instead of "AddTheory/ID"
        const index = event.currentTarget.getAttribute('index');
        let published = theoryPublishings[index]

        if (!published) {
            URL = replaceString('Theory', 'AddTheory', URL)
        }
        navigate(URL)
    }

    return ( 
        <div>
            <div hidden={props.loggedIn} className="loginNoticeMyPage" ><a className="anchor" onClick={() => navigate("/Login")}>
                    Login or create an account</a> to start tracking your theories.
            </div>
            <div className='center' hidden={!loaded2 || !props.loggedIn}>
                <div className="myClaimsMajorContainer">
                    <div hidden={!props.loggedIn} className='myClaimsTabContainer'>
                        <div onClick={() => {whichTab('Authored Theories')}} 
                        className={whichTabIsIt == 'Authored Theories' ? "myClaimsTabSelected" : "myClaimsTab"}>
                            Authored Theories
                        </div>
                        <div onClick={() => {whichTab('Followed Theories')}} 
                        className={whichTabIsIt == 'Followed Theories' ? "myClaimsTabSelected" : "myClaimsTab"}>
                            Followed Theories
                        </div>
                    </div>
                    <div className='myClaimsWrapperContainer'>
                        <div hidden={!props.loggedIn} className="myClaimsClaimContainer">
                        <div className='addClaimButtonMyClaims'>
                        <h1 className='myHeadersMyClaims'>{whichTabIsIt}</h1>
                            <Tippy content='Create a new theory'>
                                <div className='addDivMyClaims' onClick={() => createTheory()}>
                                    <svg version="1.1" viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                                        <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path className="color000 svgShape" d="m24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" fill="#fff"/>
                                        </svg>
                                    </svg>
                                </div>
                            </Tippy>
                        </div>  
                            <div className={theoriesListCurrent.length != 0 ? 'sortByDivMyClaimWrapper' : 'hidden'}>
                                <div className='slightSpacerMyClaim'>Sort by:</div>
                                <div className='sortByDivMyClaim'>
                                    <span className='minimumWidthMyClaims'>{sortBySelector}</span>
                                    <span className='tagsSpanMyClaims'>
                                            <svg onClick={() => {openSelector()}} id="tagSVG" xmlns="http://www.w3.org/2000/svg" width="18" 
                                            height="18" viewBox="0 0 24 24">
                                                <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/>
                                            </svg>
                                    </span>
                                    <div className='seletorsDivMyClaims' hidden={!selectorOpen} ref={selectorRef}>
                                        <div className='selectorOptionsMyClaims' onClick={() => {sortTheoriesByConfidence()}}>
                                            Confidence
                                        </div>
                                        <div className='selectorOptionsMyClaims' onClick={() => {sortFiguresByEngagement()}}>Engagement</div>
                                        <div className='selectorOptionsMyClaims' onClick={() => {sortClaimsByOldest()}}>Oldest</div>
                                        <div className='selectorOptionsMyClaims' onClick={() => {sortClaimsByNewest()}}>Newest</div>
                                    </div>
                                </div>
                            </div>
                            {
                                theoriesListCurrent.map((theory, index) => {
                                    return (
                                        <div key={index} className={loaded ? 'userClaimWrapperMyClaims' : 'hidden'}>
                                            <div className='userClaimMyClaims'>
                                                <div className='myClaimsAnchorSpan'>
                                                    <a className='ProfileFontBold' href={'/Theory/' + theoriesListCurrent[index].id} 
                                                    onClick={handleClick} index={index}>
                                                        {theoryTitles[index] !== '' ? theoryTitles[index] : 'No Title'}
                                                    </a>
                                                    <div className='timeStampDivMyClaims'>- {theoryTimeStamps[index]}</div>
                                                </div>
                                                <div className='claimStatsWidgetMyClaims'>
                                                    <div className='confidenceLoader'>  <Spinner hidden={loaded} /></div>
                                                    <div hidden={!loaded}>
                                                        {theoryConfidences[index]}%  | {theoryEngagements[index]}
                                                    </div>
                                                </div>
                                                <div className='optionsClaimWidget'>
                                                    <SVGoptionsDotsMyTheories whichTabIsIt={whichTabIsIt} 
                                                    user={props.user} id={theory.id} index={index} based={based} theoriesFollowed={theoriesFollowed}
                                                   />
                                                </div>
                                                
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div className={loaded ? 'hidden' : 'ProfileComponentsSpinnerDiv'}>
                                <Spinner />
                            </div>
                            <div className={whichTabIsIt === 'Authored Theories' && theoriesListCurrent.length == 0 ? 'noClaimsToShow' : 'hidden'}>
                                You have not authorered any theories yet <div className='noClaimSpacer'></div>
                            </div>
                            <div className={whichTabIsIt === 'Followed Theories' && theoriesListCurrent.length == 0 ? 'noClaimsToShow' : 'hidden'}>
                                You have not followed any theories yet <div className='noClaimSpacer'></div>
                            </div>     
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) 
}

const mapStateToProps = (state, props) => {
    if (state.profModels.length == 1) {
        return { 
            user: state.profModels[0].id,
            userName: state.profModels[0].name,
            tags: state.profModels[0].tags,
            myTheories: state.profModels[0].myTheories,
            loggedIn: true
        }
    } else {
        return {
            user: '',
            userName: '',
            tags: [],
            myTheories: {uploadedFigures: [], followedTheories: [], rejectedFigures: []},
            loggedIn: false,
        };
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    returnTheoryInfoMyTheories: (theory) => dispatch(returnTheoryInfoMyTheories(theory)),
    createNewTheory: (owner, timeStamp, occurrence) => dispatch(createNewTheory(owner, timeStamp, occurrence)),
    startSetProfile: (email) => dispatch(startSetProfile(email))

})

const ConnectedMyTheories = connect(mapStateToProps, mapDispatchToProps)(myTheories);

export default ConnectedMyTheories;