import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from "react-router-dom"
import { history } from '../routers/AppRouter'
import { startAddPaperFollow, startRemovePaperFollow, updateLastViewedPaper, returnPaperInfoHomeFeed} from '../actions/paperModels';
import { returnClaimInfo } from '../actions/claimModels';
import { returnFigureInfo } from '../actions/figModels';
import Tippy from '@tippyjs/react'
import LoadingScreen from './loadingScreen';
import moment from 'moment';
import SVGConnection from './SVGs/SVGConnection';
import SVGaddFigure from './SVGs/SVGaddFigure';
import SVGFigure from './SVGs/SVGFigure';
import SVGClaim from './SVGs/SVGClaim';
import SVGDelete from './SVGs/SVGDelete';
import SVGOptionsDots from './SVGs/SVGoptionsDots';
import DeleteModal from './deleteModalPaper';
import Spinner from "react-svg-spinner";
import SVGoptionsDotsPaper from './SVGs/SVGoptionsDotsPaper';
import { v4 as uuidv4 } from 'uuid';



const Paper = (props) => {
    const [optionsOpen, setOptionsOpen] = useState(false)
    const [extractedClaims, setExtractedClaims] = useState([])
    const [percentageConfidences, setPercentageConfidences] = useState([])
    const [claimTitles, setClaimTitles] = useState([])
    const [claimEngagements, setClaimEngagements] = useState([])
    const [claimMoments, setClaimMoments] = useState([])
    const [claimTimeStamps, setClaimTimeStamps] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [sortBySelector, setSortBySelector] = useState('None')
    const [selectorOpen, setSelectorOpen] = useState(false)

    const [Figures, setFigures] = useState([])
    const [selectorOpenFigures, setSelectorOpenFigures] = useState(false)
    const [sortBySelectorFigures, setSortBySelectorFigures] = useState('None')
    const [acceptancePercentage, setAcceptancePercentage] = useState([])
    const [figureTitles, setFigureTitles] = useState([])
    const [figureEngagements, setFigureEngagements] = useState([])
    const [figureMoments, setFigureMoments] = useState([])
    const [figureTimeStamps, setFigureTimeStamps] = useState([])
    const [figureNumbers, setFigureNumbers] = useState([])
    const [loadedFigures, setLoadedFigures] = useState(false)
    const [notDeleted, setNotDeleted] = useState(true)
    const [paperID, setPaperID] = useState('')
    const [ownerTags, setOwnerTags] = useState([])
    const [owner, setOwner] = useState('')
    const [paperDoi, setPaperDoi] = useState('')
    const [paperJournal, setPaperJournal] = useState('')
    const [follows, setFollows] = useState([])
    const [localDate, setLocalDate] = useState('')
    const [title, setTitle] = useState('')
    let selectorRefFigures = useRef();

    const navigate = useNavigate()
    
    
    
    const [followed, setFollowed] = useState(false)
    const [deleteModalState, setDeleteModalState] = useState(false)
    let optionsRefDiv = useRef();
    let optionsRefIcon = useRef();
    let selectorRef = useRef();

    useEffect(async () => {
        if (props.loggedIn) {
            for(var i = 0; i < follows.length; i++) {
                if (follows[i].id === props.user) {
                    setFollowed(true)
                    break;
                }
            }
            // let timeStamp = moment().utc().toString()
            // await props.updateLastViewedPaper(props.user, props.id, timeStamp)
        }
    }, [follows])

    useEffect(async () => {
        //Get the id of the theory from the URL
        let theSplit = window.location.pathname.split('/')
        let thePaperID = theSplit[2]
        setPaperID(thePaperID)
        try {
            let paperInfo = await props.returnPaperInfoHomeFeed(thePaperID)
            if (paperInfo) {
                const theDate = new Date(paperInfo.timeStamp)
                const newMoment = moment(theDate)
                newMoment.local()
                const theLocalDate = newMoment.format('LLL')
                setLocalDate(theLocalDate)
                let thePercentageConfidences = []
                let theTitles = []
                let theEngagements = []
                let theMoments = []
                let claimTimeStamps = []

                for (const claim of paperInfo.extractedClaims) {
                    let claimInfo = await props.returnClaimInfo(claim.id)
                    theTitles.push(claimInfo.title)
                    theEngagements.push(claimInfo.engagementNumber)
                    theMoments.push(claimInfo.timeStamp)
                    claimTimeStamps.push(claimInfo.timeStamp.format('LLL'))
                    if (isNaN(claimInfo.confidence)) {
                        thePercentageConfidences.push('?')
                    } else {
                        thePercentageConfidences.push(claimInfo.confidence)
                    }
                }
                setPercentageConfidences(thePercentageConfidences)
                setExtractedClaims(paperInfo.extractedClaims)
                setClaimTitles(theTitles)
                setClaimEngagements(theEngagements)
                setClaimMoments(theMoments)
                setClaimTimeStamps(claimTimeStamps)
                setLoaded(true)
                setFigures(paperInfo.figures)
                setOwnerTags(paperInfo.ownerTags)
                setPaperDoi(paperInfo.doi)
                setPaperJournal(paperInfo.journal)
                setFollows(paperInfo.follows)
                setOwner(paperInfo.owner)
                setTitle(paperInfo.title)
            } else {
                setNotDeleted(false)
            }
        } catch (e) {
            // console.log(e)
        }
        
    }, [])

    //When the Paper page is loaded, fetch the data from each figure uploaded from this paper and set the state for the titles,
    //acceptance percentages, engagements, moments, and timestamps etc.
    useEffect(async () => {
        let theAcceptancePercentage = []
        let theTitles = []
        let theEngagements = []
        let theMoments = []
        let theFigureNumbers = []
        let figureTimeStamps = []

        try {
            for (const figure of Figures) {
                let figureInfo = await props.returnFigureInfo(figure.id)
                theTitles.push(figureInfo.title)
                theEngagements.push(figureInfo.engagementNumber)
                theMoments.push(figureInfo.timeStamp)
                theFigureNumbers.push(figureInfo.figureNumber)
                figureTimeStamps.push(figureInfo.timeStamp.format('LLL'))
                if (isNaN(figureInfo.acceptancePercentage)) {
                    theAcceptancePercentage.push('?')
                } else {
                    theAcceptancePercentage.push(figureInfo.acceptancePercentage)
                }
            }
            setAcceptancePercentage(theAcceptancePercentage)
            setFigureTitles(theTitles)
            setFigureEngagements(theEngagements)
            setFigureMoments(theMoments)
            setFigureTimeStamps(figureTimeStamps)
            setFigureNumbers(theFigureNumbers)
            setLoadedFigures(true)
        } catch (e) {
            // console.log(e)
        }
    }, [Figures])

    useEffect(() => {
        let handler = (event) => {
            if(!(optionsRefDiv.current.contains(event.target) || optionsRefIcon.current.contains(event.target))) {
                setOptionsOpen(false)
            }
        }
        document.addEventListener("mousedown", handler)
        return () => {
            document.removeEventListener("mousedown", handler)
        }
    })
    //This useEffect closes the selector options for extractedClaims
    useEffect(() => {
        let handler = (event) => {
            if(!selectorRef.current.contains(event.target)) {
                setSelectorOpen(false)
            }
        }
        document.addEventListener("mousedown", handler)
        return () => {
            document.removeEventListener("mousedown", handler)
        }
    })

    //This useEffect closes the selector options for figures
    useEffect(() => {
        let handler = (event) => {
            if(!selectorRefFigures.current.contains(event.target)) {
                setSelectorOpenFigures(false)
            }
        }
        document.addEventListener("mousedown", handler)
        return () => {
            document.removeEventListener("mousedown", handler)
        }
    })

    const basedClaim = (index) => {
        let newArray1 = [...extractedClaims]
        let newArray2 = [...percentageConfidences]
        let newArray3 = [...claimTitles]
        let newArray4 = [...claimEngagements]
        let newArray5 = [...claimMoments]
        let newArray6 = [...claimTimeStamps]

        newArray1.splice(index, 1)
        newArray2.splice(index, 1)
        newArray3.splice(index, 1)
        newArray4.splice(index, 1)
        newArray5.splice(index, 1)
        newArray6.splice(index, 1)

        setExtractedClaims(newArray1)
        setPercentageConfidences(newArray2)
        setClaimTitles(newArray3)
        setClaimEngagements(newArray4)
        setClaimMoments(newArray5)
        setClaimTimeStamps(newArray6)

    }

    const sortClaimsByConfidence = () => {
        //if Confidence is already selected, don't do anything.
        if (sortBySelector === 'Confidence') {
            setSelectorOpen(false)
            return;
        }
        
        //combine all data into array of objects for easy sorting.
        let allArrayData = []
        for (let i = 0; i < claimTitles.length; i++) {
            let item = {
                title: claimTitles[i],
                engagements: claimEngagements[i],
                confidence: percentageConfidences[i],
                id: extractedClaims[i],
                moment: claimMoments[i],
                timeStamp: claimTimeStamps[i]
            }
            allArrayData.push(item)
        }
        //sort allArrayData on the basis of Engagement
        allArrayData.sort((a,b) => {
            if (a.confidence !== '?' && b.confidence !== '?') {
                return b.confidence - a.confidence; 
            } else if (a.confidence == '?') {
                return 1;
            } else if (b.confidence == '?') {
                return -1;
            }
        })
        
        //create a new array for dates, engagements, confidences, and timeStamps and recreate the state arrays
        let newEngagements = []
        let newConfidences = []
        let newTitles = []
        let newClaimsList = []
        let newMoments = []
        let newTimeStamps = []
        for (let i = 0; i < allArrayData.length; i++) {
            newEngagements.push(allArrayData[i].engagements)
            newConfidences.push(allArrayData[i].confidence)
            newTitles.push(allArrayData[i].title)
            newClaimsList.push(allArrayData[i].id)
            newMoments.push(allArrayData[i].moment)
            newTimeStamps.push(allArrayData[i].timeStamp)
        }
        
        //all we have to do is set everything to the original list, which is stored when the page is loaded and never changes
        setPercentageConfidences(newConfidences)
        setClaimEngagements(newEngagements)
        setExtractedClaims(newClaimsList)
        setClaimTitles(newTitles)
        setClaimMoments(newMoments)
        setClaimTimeStamps(newTimeStamps)
        setSelectorOpen(false)
        setSortBySelector('Confidence')
    }

    const sortClaimsByEngagement = () => {
        //if Engagement is already selected, don't do anything.
        if (sortBySelector === 'Engagement') {
            setSelectorOpen(false)
            return;
        }

        //combine all data into array of objects for easy sorting.
        let allArrayData = []
        for (let i = 0; i < claimTitles.length; i++) {
            let item = {
                title: claimTitles[i],
                engagements: claimEngagements[i],
                confidence: percentageConfidences[i],
                id: extractedClaims[i],
                moment: claimMoments[i],
                timeStamp: claimTimeStamps[i]
            }
            allArrayData.push(item)
        }

        //sort allArrayData on the basis of Engagement
        allArrayData.sort((a,b) => b.engagements - a.engagements)

        //create a new array for dates, engagements, confidences, and timeStamps and recreate the state arrays
        let newEngagements = []
        let newConfidences = []
        let newTitles = []
        let newClaimsList = []
        let newMoments = []
        let newTimeStamps = []
        for (let i = 0; i < allArrayData.length; i++) {
            newEngagements.push(allArrayData[i].engagements)
            newConfidences.push(allArrayData[i].confidence)
            newTitles.push(allArrayData[i].title)
            newClaimsList.push(allArrayData[i].id)
            newMoments.push(allArrayData[i].moment)
            newTimeStamps.push(allArrayData[i].timeStamp)
        }
        
        //all we have to do is set everything to the original list, which is stored when the page is loaded and never changes
        setPercentageConfidences(newConfidences)
        setClaimEngagements(newEngagements)
        setExtractedClaims(newClaimsList)
        setClaimTitles(newTitles)
        setClaimMoments(newMoments)
        setClaimTimeStamps(newTimeStamps)
        setSelectorOpen(false)
        setSortBySelector('Engagement')
    }

    const sortClaimsByNewest = (tab) => {
        //if Oldest is already selected, don't do anything.
        if (sortBySelector === 'Newest') {
            setSelectorOpen(false)
            return;
        }

        //combine all data into array of objects for easy sorting.
        let allArrayData = []
        for (let i = 0; i < claimTitles.length; i++) {
            let item = {
                title: claimTitles[i],
                engagements: claimEngagements[i],
                confidence: percentageConfidences[i],
                id: extractedClaims[i],
                moment: claimMoments[i],
                timeStamp: claimTimeStamps[i]
            }
            allArrayData.push(item)
        }

        //sort allArrayData on the basis of Engagement
        allArrayData.sort((a,b) => {
            if (a.moment.isBefore(b.moment)) {
                return 1; 
            } else {
                return -1;
            }
        })


        //create a new array for dates, engagements, confidences, and timeStamps and recreate the state arrays
        let newEngagements = []
        let newConfidences = []
        let newTitles = []
        let newClaimsList = []
        let newMoments = []
        let newTimeStamps = []
        for (let i = 0; i < allArrayData.length; i++) {
            newEngagements.push(allArrayData[i].engagements)
            newConfidences.push(allArrayData[i].confidence)
            newTitles.push(allArrayData[i].title)
            newClaimsList.push(allArrayData[i].id)
            newMoments.push(allArrayData[i].moment)
            newTimeStamps.push(allArrayData[i].timeStamp)
        }
        
        //all we have to do is set everything to the original list, which is stored when the page is loaded and never changes
        setPercentageConfidences(newConfidences)
        setClaimEngagements(newEngagements)
        setExtractedClaims(newClaimsList)
        setClaimTitles(newTitles)
        setClaimMoments(newMoments)
        setClaimTimeStamps(newTimeStamps)
        setSelectorOpen(false)
        setSortBySelector('Newest')
    }

    const sortClaimsByOldest = (tab) => {
        //if Oldest is already selected, don't do anything.
        if (sortBySelector === 'Oldest') {
            setSelectorOpen(false)
            return;
        }

        //combine all data into array of objects for easy sorting.
        let allArrayData = []
        for (let i = 0; i < claimTitles.length; i++) {
            let item = {
                title: claimTitles[i],
                engagements: claimEngagements[i],
                confidence: percentageConfidences[i],
                id: extractedClaims[i],
                moment: claimMoments[i],
                timeStamp: claimTimeStamps[i]
            }
            allArrayData.push(item)
        }

        //sort allArrayData on the basis of Engagement
        allArrayData.sort((a,b) => {
            if (a.moment.isBefore(b.moment)) {
                return -1; 
            } else {
                return 1;
            }
        })

        //create a new array for dates, engagements, confidences, and timeStamps and recreate the state arrays
        let newEngagements = []
        let newConfidences = []
        let newTitles = []
        let newClaimsList = []
        let newMoments = []
        let newTimeStamps = []
        for (let i = 0; i < allArrayData.length; i++) {
            newEngagements.push(allArrayData[i].engagements)
            newConfidences.push(allArrayData[i].confidence)
            newTitles.push(allArrayData[i].title)
            newClaimsList.push(allArrayData[i].id)
            newMoments.push(allArrayData[i].moment)
            newTimeStamps.push(allArrayData[i].timeStamp)
        }
        
        //all we have to do is set everything to the original list, which is stored when the page is loaded and never changes
        setPercentageConfidences(newConfidences)
        setClaimEngagements(newEngagements)
        setExtractedClaims(newClaimsList)
        setClaimTitles(newTitles)
        setClaimMoments(newMoments)
        setClaimTimeStamps(newTimeStamps)
        setSelectorOpen(false)
        setSortBySelector('Oldest')
    }

    //The following three functions get the tally for each type of vote in order to calculate the initial confidence value.
    const followPaper = async () => {
        setFollowed(!followed)
        if (!followed) {
            let timeStamp = moment().utc().toString()
            let occurrence = uuidv4()
            await props.startAddPaperFollow(props.user, paperID, occurrence, timeStamp).then(() => {
                
            }).catch(() => {
                // console.log('paper was not followed')
            })
        } else if (followed) {
            await props.startRemovePaperFollow(props.user, paperID).then(() => {
                
            }).catch(() => {
                // console.log('paper was not followed')
            })
        } else {
            // console.log('something wrong with paper-follow logic')
        }
        
    }

    const colorList = [
        'rgb(228, 92, 92)',
        'rgb(189, 112, 98)',
        'rgb(189, 180, 98)',
        'rgb(169, 189, 98)',
        'rgb(157, 189, 98)',
        'rgb(134, 189, 98)',
        'rgb(98, 189, 136)',
        'rgb(98, 189, 169)',
        'rgb(98, 189, 184)'
    ]

    const setColor = (support, total) => {
        let ratio = (support/total * 10)-1;
        let ratioIndex = parseInt(ratio)
        const style = {
            fontSize: '120%',
            color: colorList[ratioIndex]
        }
        return style;
    }

    const reportPaper = () => {
        navigate('/Report/Paper/' + paperID)
    }

    const identifyPaper = () => {
        navigate('/AddPaper')
    }

    const uploadFigure = () => {
        navigate('/AddFigure')
    }

    const addClaim = () => {
        navigate('/AddClaim')
    }

    const openSelector = () => {
        setSelectorOpen(!selectorOpen)
    }

    const openSelectorFigures = () => {
        setSelectorOpenFigures(!selectorOpenFigures)
    }

    const basedFigure = (index) => {
        let newArray1 = [...Figures]
        let newArray2 = [...acceptancePercentage]
        let newArray3 = [...figureTitles]
        let newArray4 = [...figureEngagements]
        let newArray5 = [...figureMoments]
        let newArray6 = [...figureTimeStamps]

        newArray1.splice(index, 1)
        newArray2.splice(index, 1)
        newArray3.splice(index, 1)
        newArray4.splice(index, 1)
        newArray5.splice(index, 1)
        newArray6.splice(index, 1)

        setFigures(newArray1)
        setAcceptancePercentage(newArray2)
        setFigureTitles(newArray3)
        setFigureEngagements(newArray4)
        setFigureMoments(newArray5)
        setFigureTimeStamps(newArray6)

    }

    const sortFiguresByAcceptancePercentage = () => {
        //if Acceptance is already selected, don't do anything.
        if (sortBySelectorFigures === 'Acceptance Percentage') {
            setSelectorOpenFigures(false)
            return;
        }
        
        //combine all data into array of objects for easy sorting.
        let allArrayData = []
        for (let i = 0; i < figureTitles.length; i++) {
            let item = {
                title: figureTitles[i],
                engagements: figureEngagements[i],
                acceptancePercentage: acceptancePercentage[i],
                id: Figures[i],
                moment: figureMoments[i],
                timeStamp: figureTimeStamps[i],
                figureNumber: figureNumbers[i]
            }
            allArrayData.push(item)
        }
        //sort allArrayData on the basis of Engagement
        allArrayData.sort((a,b) => {
            if (a.acceptancePercentage !== '?' && b.acceptancePercentage !== '?') {
                return b.acceptancePercentage - a.acceptancePercentage; 
            } else if (a.acceptancePercentage == '?') {
                return 1;
            } else if (b.acceptancePercentage == '?') {
                return -1;
            }
        })
        
        //create a new array for dates, engagements, confidences, and timeStamps and recreate the state arrays
        let newEngagements = []
        let acceptancePercentages = []
        let newTitles = []
        let newClaimsList = []
        let newMoments = []
        let newTimeStamps = []
        let newFigureNumbers = []
        for (let i = 0; i < allArrayData.length; i++) {
            newEngagements.push(allArrayData[i].engagements)
            acceptancePercentages.push(allArrayData[i].acceptancePercentage)
            newTitles.push(allArrayData[i].title)
            newClaimsList.push(allArrayData[i].id)
            newMoments.push(allArrayData[i].moment)
            newTimeStamps.push(allArrayData[i].timeStamp)
            newFigureNumbers.push(allArrayData[i].figureNumber)
        }
        
        //all we have to do is set everything to the original list, which is stored when the page is loaded and never changes
        setAcceptancePercentage(acceptancePercentages)
        setFigureEngagements(newEngagements)
        setFigures(newClaimsList)
        setFigureTitles(newTitles)
        setFigureMoments(newMoments)
        setFigureTimeStamps(newTimeStamps)
        setFigureNumbers(newFigureNumbers)
        setSelectorOpenFigures(false)
        setSortBySelectorFigures('Acceptance Percentage')
    }


    const sortFiguresByEngagement = () => {
        //if Engagement is already selected, don't do anything.
        if (sortBySelectorFigures === 'Engagement') {
            setSelectorOpenFigures(false)
            return;
        }

        //combine all data into array of objects for easy sorting.
        let allArrayData = []
        for (let i = 0; i < figureTitles.length; i++) {
            let item = {
                title: figureTitles[i],
                engagements: figureEngagements[i],
                acceptancePercentage: acceptancePercentage[i],
                id: Figures[i],
                moment: figureMoments[i],
                timeStamp: figureTimeStamps[i],
                figureNumber: figureNumbers[i]

            }
            allArrayData.push(item)
        }

        //sort allArrayData on the basis of Engagement
        allArrayData.sort((a,b) => b.engagements - a.engagements)

        //create a new array for dates, engagements, acceptancePercentages, and timeStamps and recreate the state arrays
        let newEngagements = []
        let newAcceptancePercentages = []
        let newTitles = []
        let newClaimsList = []
        let newMoments = []
        let newTimeStamps = []
        let newFigureNumbers = []
        for (let i = 0; i < allArrayData.length; i++) {
            newEngagements.push(allArrayData[i].engagements)
            newAcceptancePercentages.push(allArrayData[i].acceptancePercentage)
            newTitles.push(allArrayData[i].title)
            newClaimsList.push(allArrayData[i].id)
            newMoments.push(allArrayData[i].moment)
            newTimeStamps.push(allArrayData[i].timeStamp)
            newFigureNumbers.push(allArrayData[i].figureNumber)
        }
        
        //all we have to do is set everything to the original list, which is stored when the page is loaded and never changes
        setAcceptancePercentage(newAcceptancePercentages)
        setFigureEngagements(newEngagements)
        setFigures(newClaimsList)
        setFigureTitles(newTitles)
        setFigureMoments(newMoments)
        setFigureTimeStamps(newTimeStamps)
        setFigureNumbers(newFigureNumbers)
        setSelectorOpenFigures(false)
        setSortBySelectorFigures('Engagement')
    }

    const sortFiguresByNewest = (tab) => {
        //if Newest is already selected, don't do anything.
        if (sortBySelectorFigures === 'Newest') {
            setSelectorOpenFigures(false)
            return;
        }

        //combine all data into array of objects for easy sorting.
        let allArrayData = []
        for (let i = 0; i < figureTitles.length; i++) {
            let item = {
                title: figureTitles[i],
                engagements: figureEngagements[i],
                acceptancePercentage: acceptancePercentage[i],
                id: Figures[i],
                moment: figureMoments[i],
                timeStamp: figureTimeStamps[i],
                figureNumber: figureNumbers[i]
            }
            allArrayData.push(item)
        }

        //sort allArrayData on the basis of Engagement
        allArrayData.sort((a,b) => {
            if (a.moment.isBefore(b.moment)) {
                return 1; 
            } else {
                return -1;
            }
        })


        //create a new array for dates, engagements, confidences, and timeStamps and recreate the state arrays
        let newEngagements = []
        let newAcceptancePercentages = []
        let newTitles = []
        let newClaimsList = []
        let newMoments = []
        let newTimeStamps = []
        let newFigureNumbers = []
        for (let i = 0; i < allArrayData.length; i++) {
            newEngagements.push(allArrayData[i].engagements)
            newAcceptancePercentages.push(allArrayData[i].acceptancePercentage)
            newTitles.push(allArrayData[i].title)
            newClaimsList.push(allArrayData[i].id)
            newMoments.push(allArrayData[i].moment)
            newTimeStamps.push(allArrayData[i].timeStamp)
            newFigureNumbers.push(allArrayData[i].figureNumber)
        }
        
        //all we have to do is set everything to the original list, which is stored when the page is loaded and never changes
        setAcceptancePercentage(newAcceptancePercentages)
        setFigureEngagements(newEngagements)
        setFigures(newClaimsList)
        setFigureTitles(newTitles)
        setFigureMoments(newMoments)
        setFigureTimeStamps(newTimeStamps)
        setFigureNumbers(newFigureNumbers)
        setSelectorOpenFigures(false)
        setSortBySelectorFigures('Newest')
    }

    const sortFiguresByOldest = (tab) => {
        //if Oldest is already selected, don't do anything.
        if (sortBySelectorFigures === 'Oldest') {
            setSelectorOpenFigures(false)
            return;
        }

        //combine all data into array of objects for easy sorting.
        let allArrayData = []
        for (let i = 0; i < figureTitles.length; i++) {
            let item = {
                title: figureTitles[i],
                engagements: figureEngagements[i],
                acceptancePercentage: acceptancePercentage[i],
                id: Figures[i],
                moment: figureMoments[i],
                timeStamp: figureTimeStamps[i],
                figureNumber: figureNumbers[i]
            }
            allArrayData.push(item)
        }

        //sort allArrayData on the basis of Engagement
        allArrayData.sort((a,b) => {
            if (a.moment.isBefore(b.moment)) {
                return -1; 
            } else {
                return 1;
            }
        })

        //create a new array for dates, engagements, confidences, and timeStamps and recreate the state arrays
        let newEngagements = []
        let newAcceptancePercentages = []
        let newTitles = []
        let newClaimsList = []
        let newMoments = []
        let newTimeStamps = []
        let newFigureNumbers = []
        for (let i = 0; i < allArrayData.length; i++) {
            newEngagements.push(allArrayData[i].engagements)
            newAcceptancePercentages.push(allArrayData[i].acceptancePercentage)
            newTitles.push(allArrayData[i].title)
            newClaimsList.push(allArrayData[i].id)
            newMoments.push(allArrayData[i].moment)
            newTimeStamps.push(allArrayData[i].timeStamp)
            newFigureNumbers.push(allArrayData[i].figureNumber)
        }
        
        //all we have to do is set everything to the original list, which is stored when the page is loaded and never changes
        setAcceptancePercentage(newAcceptancePercentages)
        setFigureEngagements(newEngagements)
        setFigures(newClaimsList)
        setFigureTitles(newTitles)
        setFigureMoments(newMoments)
        setFigureTimeStamps(newTimeStamps)
        setFigureNumbers(newFigureNumbers)
        setSelectorOpenFigures(false)
        setSortBySelectorFigures('Oldest')
    }

    function removeSubstring(originalString) {
        let split = originalString.split('/')
        let theURL = '/' + split[3] + '/' + split[4]
        return theURL;
    }

    function handleClick(event) {
        event.preventDefault();
        let URL = removeSubstring(event.target.href, event.target.baseURI)
        navigate(URL)
    }

    return (
     
        <div className="claimShow">
            <div hidden={props.loggedIn} className="loginNotice" ><a className="anchor" onClick={() => navigate("/Login")}>
                Login or register an account</a> to identify papers to be added to Journal Club.
            </div>
            {deleteModalState && <DeleteModal id={paperID} closeModal={setDeleteModalState} element='paper' />}
            <h1 className="notFound" hidden={notDeleted}>Error: Paper does not exist or was deleted</h1>
            <div className='majorContainerPaper' hidden={!notDeleted}>
                <div className='optionsPaper'>
                    <h1 className='myHeadersPapers'>{title}</h1>
                    <div className={props.loggedIn ? "followSVGPaper" : "hidden"}>
                        <Tippy content={followed ? "Unfollow Paper" : "Follow Paper"}>
                            <svg className={followed ? "followSVGSVGClaimClicked" : "followSVGSVGClaim"} onClick={followPaper} version="1.1" 
                            viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                                <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path className="color000 svgShape" d="m24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" 
                                    fill={followed ? "#fff" : "#002c2c"}/>
                                </svg>
                            </svg>
                        </Tippy>
                        <div className='optionsClaim' onClick={() => setOptionsOpen(!optionsOpen)} ref={optionsRefIcon}>
                            <SVGOptionsDots/>
                        </div>
                        <div className="dropDownClaimWrapper" ref={optionsRefDiv} hidden={!optionsOpen} >
                            <div className='dropDownPaper'>
                                <div className='optionsAnchorDiv' hidden={props.user != owner} onClick={() => {addClaim()}}>
                                    <SVGClaim/>                            
                                    <a><b>Extract a Claim</b></a>
                                </div>
                                <div className='optionsAnchorDiv' hidden={props.user != owner} onClick={() => {uploadFigure()}}>
                                    <SVGFigure/>
                                    <a><b>Upload a Figure</b></a>
                                </div>
                                <div className='optionsAnchorDiv' onClick={() => {identifyPaper()}}>
                                    <SVGaddFigure/>
                                    <a><b>Identify a new Paper</b></a>
                                </div>
                                <div className={props.user == owner ? 'optionsAnchorDiv' : 'hidden'} onClick={() => setDeleteModalState(true)} 
                                hidden={props.user != owner} href={props.image}>
                                    <SVGDelete/>
                                    <a><b>Delete this Paper</b></a>
                                </div>
                                <div className='optionsAnchorDiv' onClick={reportPaper}>
                                    <svg className='marginSVG' width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 
                                        0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z" fill="#002c2c"/>
                                    </svg>
                                    <a><b>Report this Paper</b></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='doiPaper'><b>Source: </b>
                    <a className="anchor" href={paperDoi} >{paperDoi}</a>
                </div>
                <div className='journalPaper'><b>Journal: </b>{paperJournal}</div>
                <div className='divAbovePaper'>
                    <div className='addedByPaper'><b>Identified by:</b></div>
                    {
                        ownerTags.map((tag) => {
                            return (<a key={tag.displayName} className="anchorNameClaim" href={'/Profile/' + tag.id }
                            onClick={handleClick}>{tag.displayName}
                             </a>)
                        })
                    }
                </div>
                <div className='divAbove3Theory'><b>- {localDate}</b></div>
                <br/>
                <br/>
                <div className='titleDivPaper'>
                    <div className='flexPaper'>
                        <h2 className='titleHeaderPaper'>Extracted Claims</h2> 
                        <br/>
                        <div className={extractedClaims.length != 0 ? 'sortByDivPaperWrapper' : 'hidden'}>
                            <div className='slightSpacerMyClaim'>Sort by:</div>
                            <div className='sortByDivPaper'>
                                <span className='minimumWidthMyClaims'>{sortBySelector}</span>
                                <span className='tagsSpanMyClaims'>
                                    <Tippy content='Post Tags'>
                                        <svg onClick={() => {openSelector()}} id="tagSVG" xmlns="http://www.w3.org/2000/svg" width="18" 
                                        height="18" viewBox="0 0 24 24">
                                            <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/>
                                        </svg>
                                    </Tippy>
                                </span>
                                <div className='seletorsDivMyClaims' hidden={!selectorOpen} ref={selectorRef}>
                                    <div className='selectorOptionsPaper' onClick={() => {sortClaimsByConfidence()}}>Confidence</div>
                                    <div className='selectorOptionsPaper' onClick={() => {sortClaimsByEngagement()}}>Engagement</div>
                                    <div className='selectorOptionsPaper' onClick={() => {sortClaimsByOldest()}}>Oldest</div>
                                    <div className='selectorOptionsPaper' onClick={() => {sortClaimsByNewest()}}>Newest</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                    {
                        extractedClaims.map((claim, index) => {
                            return (
                                <div key={index} className='userClaimWrapperMyClaims'>
                                    <div className='userClaimMyClaims'>
                                        <div className='anchorSpanPaper'>
                                            <a className='ProfileFontBold' href={'/Claim/' + extractedClaims[index].id} onClick={handleClick}>
                                                {claimTitles[index]}
                                            </a>
                                            <div className='timeStampDivPaper'>- {claimTimeStamps[index]}</div>
                                        </div>
                                        <div className='claimStatsWidgetMyClaims'>
                                            <div className='confidenceLoader'>  <Spinner hidden={loaded} /></div>
                                            <div hidden={!loaded}>{percentageConfidences[index]}%  | {claimEngagements[index]}</div>
                                        </div>
                                        <div className={props.loggedIn ? 'optionsClaimWidget' : 'hidden'}>
                                            <SVGoptionsDotsPaper type='claim' based={basedClaim} paperID={paperID}
                                            user={props.user} id={claim.id} index={index} />
                                        </div>
                                        
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className={extractedClaims.length == 0 ? "noClaimsToShowPaper" : "hidden"}>
                        No claims have been extracted from this paper yet
                    </div>
                </div>
                <br/>
                <div className='titleDivPaper' >
                    <div className='flexPaper'>
                        <h2 className='titleHeaderPaper'>Figures</h2> 
                        <br/>
                        <div className={Figures.length != 0 ? 'sortByDivPaperWrapper' : 'hidden'}>
                            <div className='slightSpacerMyClaim'>Sort by:</div>
                            <div className='sortByDivPaper'>
                                <span className='minimumWidthMyClaims'>{sortBySelectorFigures}</span>
                                <span className='tagsSpanMyClaims'>
                                    <Tippy content='Post Tags'>
                                        <svg onClick={() => {openSelectorFigures()}} id="tagSVG" xmlns="http://www.w3.org/2000/svg" width="18" 
                                        height="18" viewBox="0 0 24 24">
                                            <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/>
                                        </svg>
                                    </Tippy>
                                </span>
                                <div className='seletorsDivMyClaims' hidden={!selectorOpenFigures} ref={selectorRefFigures}>
                                    <div className='selectorOptionsPaper' onClick={() => {sortFiguresByAcceptancePercentage()}}>
                                        Acceptance
                                    </div>
                                    <div className='selectorOptionsPaper' onClick={() => {sortFiguresByEngagement()}}>Engagement</div>
                                    <div className='selectorOptionsPaper' onClick={() => {sortFiguresByOldest()}}>Oldest</div>
                                    <div className='selectorOptionsPaper' onClick={() => {sortFiguresByNewest()}}>Newest</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                    {
                        Figures.map((figure, index) => {
                            return (
                                <div key={index} className='userClaimWrapperMyClaims'>
                                    <div className='userClaimMyClaims'>
                                        <div className='anchorSpanPaper'>
                                            <a className='ProfileFontBold' href={'/Figure/' + Figures[index].id} onClick={handleClick}>
                                                {figureTitles[index]}
                                            </a> ({figureNumbers[index]})
                                            <div className='timeStampDivMyClaims'>- {figureTimeStamps[index]}</div>
                                        </div>
                                        <div className='claimStatsWidgetMyClaims'>
                                            <div className='confidenceLoader'>  <Spinner hidden={loadedFigures} /></div>
                                            <div hidden={!loaded}>{acceptancePercentage[index]}%  | {figureEngagements[index]}</div>
                                        </div>
                                        <div className={props.loggedIn ? 'optionsClaimWidget' : 'hidden'}>
                                            <SVGoptionsDotsPaper type='figure' based={basedFigure}
                                            user={props.user} id={figure.id} index={index} paperID={paperID}/>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className={Figures.length == 0 ? "noClaimsToShowPaper" : "hidden"}>
                        No figures from this paper have been uploaded yet
                    </div>
                </div>
            </div>
        </div>
    )

}

const mapStateToProps = (state, props) => {
    if (state.profModels.length == 1) {
        return { 
            user: state.profModels[0].id,
            userName: state.profModels[0].name,
            tags: state.profModels[0].tags,
            loggedIn: true
        }
    } else {
        return {
            user: '',
            userName: '',
            tags: [],
            loggedIn: false
        };
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    startSetPaper: (id) => dispatch(startSetPaper(id)),
    startAddPaperFollow: (user, paperID, occurrence, timeStamp) => dispatch(startAddPaperFollow(user, paperID, occurrence, timeStamp)),
    startRemovePaperFollow: (user, paperID) => dispatch(startRemovePaperFollow(user, paperID)),
    returnClaimInfo: (claim) => dispatch(returnClaimInfo(claim)),
    returnFigureInfo: (figure) => dispatch(returnFigureInfo(figure)),
    updateLastViewedPaper: (user, id, timeStamp) => dispatch(updateLastViewedPaper(user, id, timeStamp)),
    returnPaperInfoHomeFeed: (paperID) => dispatch(returnPaperInfoHomeFeed(paperID))
})

const ConnectedPaper = connect(mapStateToProps, mapDispatchToProps)(Paper);

export default ConnectedPaper;