import React, { useEffect, useState, useRef } from 'react';
import Tippy from '@tippyjs/react'
import { connect } from 'react-redux'
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../firebase/firebase'
import { getImgsrc } from '../actions/figModels'
import { connectFiguresToClaim } from '../actions/claimModels';
import { useNavigate } from "react-router-dom"
import ImgModal from './ConnectClaimImgModal'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid';
import Spinner from "react-svg-spinner";



export const SearchBarClaim = (props) => {
    const navigate = useNavigate()
    const [filteredData, setFilteredData] = useState([]);
    const allFigures = useRef([])
    const [chosenContra, setChosenContra] = useState([])
    const [chosenSupport, setChosenSupport] = useState([])
    const [unchecked, setUnchecked] = useState(false)
    const [tagsOpen, setTagsOpen] = useState(false)
    const [needToAdd, setNeedToAdd] = useState(false)
    const [needToExplain, setNeedToExplain] = useState(false)
    const [listOfTextRefsSupport, setListOfTextRefsSupport] = useState([])
    const [listOfTextRefsContradict, setListOfTextRefsContradict] = useState([])
    const [loaded, setLoaded] = useState(true)

    //We create an array of the supporting figures that are already connected to the claim.
    //We create an array of the contradicting figures that are already connected to the claim.
    let initialSupports = [];
    let initialContras = []; 
    props.supportFigs.forEach((figure) => {
        return initialSupports.push(figure.id);
    })
    props.contraFigs.forEach((figure) => {
        return initialContras.push(figure.id);
    })
    const [alreadyContradicting, setAlreadyContradict] = useState(initialContras)
    const [alreadySupporting, setAlreadySupport] = useState(initialSupports)
    getDocs(collection(db, "Figures")).then((docs) => {
        let Figures = [];
        docs.forEach((doc) => {
            Figures.push({
                ...doc.data(),
                id: doc.id
            })
        })
        allFigures.current = Figures
    })
    const upOneSupport = (figureID, title) => {

        //Make an array that contains all figures that were previously supporting the claim along with the new ones the user select.
        let supportsList = alreadySupporting.concat(initialSupports);
        //Make an array that contains all figures that were previously contradicting the claim along with the new ones the user select.
        let contrasList = alreadyContradicting.concat(initialContras);
        let figure;

        //If the figure selected is not in the supportingList yet, fetch its info and add it to the ChosenSupport and AlreadySupport state.
        if (!supportsList.includes(figureID)) {
            getImgsrc(figureID).then((object) => {
                figure = {
                    title: title,
                    imgsrc: object.imgsrc,
                    ID: figureID,
                    description: object.description
                }
                const holder1 = chosenSupport.concat([figure])
                const holder2 = alreadySupporting.concat([figureID])
                setChosenSupport(holder1)
                setAlreadySupport(holder2)
                
            }).catch(() => {
                figure = {
                    title: title,
                    ID: figureID
                }
                const holder1 = chosenSupport.concat([figure])
                const holder2 = alreadySupporting.concat([figureID])
                setChosenSupport(holder1)
                setAlreadySupport(holder2)
            })
        }

        //If the figure selected is currently in the contradictingList, remove it from the ChosenContra and AlreadyContradict states.
        //Also remember to remove it from the TextRefsContradict array.
        const currentTextRefs = [...listOfTextRefsContradict]
        if (contrasList.includes(figureID)) {
            const holder1 = chosenContra.filter((figure, index) => {
                if (figure.ID == figureID) {
                    currentTextRefs.splice(index, 1)
                }
                return figure.ID != figureID;
            })
            const holder2 = alreadyContradicting.filter((figure) => {
                return figure != figureID;
            })
            setChosenContra(holder1)
            setAlreadyContradict(holder2)
            setListOfTextRefsContradict(currentTextRefs)
        }
    }
    const upOne = (figureID, title) => {
        let supportsList = alreadySupporting.concat(initialSupports);
        let contrasList = alreadyContradicting.concat(initialContras);
        let figure;
        if (!contrasList.includes(figureID)) {
            getImgsrc(figureID).then((object) => {
                figure = {
                    title: title,
                    imgsrc: object.imgsrc,
                    ID: figureID,
                    description: object.description
                }
                const holder1 = chosenContra.concat([figure])
                const holder2 = alreadyContradicting.concat([figureID])
                setChosenContra(holder1)
                setAlreadyContradict(holder2)
                
            }).catch(() => {
                figure = {
                    title: title,
                    ID: figureID
                }
                const holder1 = chosenContra.concat([figure])
                const holder2 = alreadyContradicting.concat([figureID])
                setChosenContra(holder1)
                setAlreadyContradict(holder2)
            })
        }
        const currentTextRefs = [...listOfTextRefsSupport]
        if (supportsList.includes(figureID)) {
            const holder1 = chosenSupport.filter((figure, index) => {
                if (figure.ID == figureID) {
                    currentTextRefs.splice(index, 1)
                }
                return figure.ID != figureID;
            })
            const holder2 = alreadySupporting.filter((figure) => {
                return figure != figureID;
            })
            setChosenSupport(holder1)
            setAlreadySupport(holder2)
            setListOfTextRefsSupport(currentTextRefs)
        }
    }

    const searching = (event) => {
        const search = event.target.value.toLowerCase()
        if (search.length == 0) {
            setFilteredData([])
        }
        if ((search.indexOf(' ') < 0) && (search.length != 0)) {
            let compile = allFigures.current
            let searched = compile.filter((doc) => {
                return (doc.title.toLowerCase().indexOf(search) >= 0)
            })
            setFilteredData(searched)
        } 
    }


    //This function is run when the SVGs buttons are rendered and simply checks if the initialContras list already posseses the figure in question.
    //If it does, it sets the value of the conttradict tippy to let the user know.
    const checkItContraTippy = (figureID) => {
        if (initialContras.includes(figureID)) {
            return 'This is already a contradicting figure.'
        } else if (alreadyContradicting.includes(figureID)) {
            return 'Already Selected'
        } 
        return 'Select Figure'
    }

    const checkItContraSVG = (figureID) => {
        if (initialContras.includes(figureID)) {
            return 'inputConnectCon'
        } else if (alreadyContradicting.includes(figureID)) {
            return 'inputConnectCon'
        } 
        return 'inputConnectConSelected'
    }

    const checkItContraPath = (figureID) => {
        if (initialContras.includes(figureID)) {
            return "rgb(228, 92, 92)"
        } else if (alreadyContradicting.includes(figureID)) {
            return "#fff"
        } 
        return 'rgb(228, 92, 92)'
    }

    const checkItSupportTippy = (figureID) => {
        if (initialSupports.includes(figureID)) {
            return 'This is already a supporting figure.'
        } else if (alreadySupporting.includes(figureID)) {
            return 'Already Selected'
        } 
        return 'Select Figure'
    }

    const checkItSupportSVG = (figureID) => {
        if (initialSupports.includes(figureID)) {
            return 'inputConnectSup'
        } else if (alreadySupporting.includes(figureID)) {
            return 'inputConnectSup'
        } 
        return 'inputConnectSupSelected'
    }
    const checkItSupportPath = (figureID) => {
        if (initialSupports.includes(figureID)) {
            return "rgb(98, 189, 184)"
        } else if (alreadySupporting.includes(figureID)) {
            return "#fff"
        } 
        return 'rgb(98, 189, 184)'
    }
    const connectFigures = async (e) => {
        e.preventDefault()
        setLoaded(false)
        let contradictingFigures = chosenContra; 
        let supportingFigures = chosenSupport;
        let everyFigureHasExplanation = true;
        let occurrence = uuidv4();

        if (supportingFigures.length == 0 && contradictingFigures.length == 0) {
            setNeedToAdd(true)
            setLoaded(true)
            return;
        }

        let supportingExplanations = []
        listOfTextRefsSupport.forEach((ref) => {
            supportingExplanations.push(ref.current.value)
            if (ref.current.value === '') {
                everyFigureHasExplanation = false
            }
        })

        let contradictingExplanations = []
        listOfTextRefsContradict.forEach((ref) => {
            contradictingExplanations.push(ref.current.value)
            if (ref.current.value === '') {
                everyFigureHasExplanation = false
            }
        })

        if (!everyFigureHasExplanation) {
            setNeedToAdd(false)
            setNeedToExplain(true)
            setLoaded(true)
            return;
        } else {
            setNeedToExplain(false)
        }

        //contradictingFigures is all the figures listed to add, get it from the state.
        
        let values = []
        for (let i = 0; i < e.target.elements.length; i++) {
            if (e.target.elements[i].checked) {
                let ownerTag = {
                    displayName: e.target.elements[i].name,
                    id: e.target.elements[i].id
                }
                values.push(ownerTag)
            }
        }
        if (values.length == 0) {
            setLoaded(true)
            setUnchecked(true)
            return;
        } 
        let timeStamp = moment().utc().toString()
        
        const claimIDPage = await props.connectFiguresToClaim(props.id, supportingFigures, contradictingFigures, props.user, values, props.title, supportingExplanations, contradictingExplanations, timeStamp, occurrence)

        //In theory, the code below should not run until claimIDPage value is derived. However, for some reason, some of the data will not
        //save to Firebase without a tiny delay until navigate is called - I have no idea why this is the case.
        navigate('/Claim/' + claimIDPage)
    }
    function getFirst60Chars(str) {
        if (str.length < 120) {
            return str;
        } else {
            let string;
            let index = str.lastIndexOf(' ', 120);
            if (index == -1) {
                string = str.substring(0, 120);
            } else {
                string = str.substring(0, index) + '...'
            }
            return string;
        }
    }

    function removeSubstring(originalString) {
        let split = originalString.split('/')
        let theURL = '/' + split[3] + '/' + split[4]
        return theURL;
    }

    function handleClick(event) {
        event.preventDefault();
        let URL = removeSubstring(event.target.href, event.target.baseURI)
        console.log('ran')
        navigate(URL)
    }

    return (
        <form className='theSpan2' onSubmit={connectFigures}>
            <div hidden={chosenSupport.length == 0} className='supportingFigures'><b>Supporting Figures:</b></div>
            <br/>
            <div className={chosenSupport.length > 0 ? 'figuresToConnect' : 'hidden'}>
                {(chosenSupport.length > 0) && chosenSupport.map((figure, index) => {
                    return ( <ImgModal key={figure.ID} imgsrc={figure.imgsrc} title={figure.title} ID={figure.ID} chosenContra={chosenSupport}
                        setAlreadyContradict={setAlreadySupport} alreadyContradicting={alreadySupporting} setChosenContra={setChosenSupport}
                        setListOfTextRefs={setListOfTextRefsSupport} listOfTextRefs={listOfTextRefsSupport} direction={'supports'}/> )
                })}
            </div>
            <div hidden={chosenContra.length == 0} className={chosenSupport.length <= 0 ? 'contradictingFigures' : 'contradictingFigures2'}>
                <b>Contradicting Figures:</b>
            </div>
            <br/>
            <div className={chosenContra.length > 0 ? 'figuresToConnect' : 'hidden'}>
                {(chosenContra.length > 0) && chosenContra.map((figure, index) => {
                    return ( <ImgModal key={figure.ID} imgsrc={figure.imgsrc} title={figure.title} ID={figure.ID} chosenContra={chosenContra}
                        setAlreadyContradict={setAlreadyContradict} alreadyContradicting={alreadyContradicting} setChosenContra={setChosenContra}
                        listOfTextRefs={listOfTextRefsContradict} setListOfTextRefs={setListOfTextRefsContradict} direction={'contradicts'}/> )
                })}
            </div>
            <div className='flexBelow'>
                <div className="flexConnectClaim">
                    <div className='red2'>Find a figure: </div>
                    <div className="inputAndSearch">
                        <input onChange={searching} id="search-text3"  type="text" placeholder="search here..."/>
                        {filteredData.length != 0 && (
                            <div className="beingSearched2">{
                                filteredData.map((doc, index) => {
                                    return (
                                            <div className="AddTheoryFlex" key={index + doc.id}>
                                                <div className='AddTheoryClickDiv'>
                                                    <div className='HeaderSVGDiv'>
                                                        <svg className='HeaderSearchSVG' version="1.1" viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                                                            <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                <path className="color000 svgShape" fill="#002c2c" d="m24 3.875l-6 1.221 1.716 1.708-5.351 5.358-3.001-3.002-7.336 7.242 1.41 1.418 5.922-5.834 2.991 2.993 6.781-6.762 1.667 1.66 1.201-6.002zm0 17.125v2h-24v-22h2v20h22z"/>
                                                            </svg>
                                                        </svg>
                                                    </div>
                                                    <div className='ConnectClaimDescDiv'>
                                                        <a className='ConnectClaimLargeDesc' href={'/Figure/' + doc.id} onClick={handleClick}>
                                                            {getFirst60Chars(doc.title)}
                                                        </a>
                                                        <div className='ConnectClaimSmallDesc'>Figure</div>
                                                    </div>
                                                </div>
                                                
                                                <div className="ConnectClaimFigure2">
                                                    <Tippy content ={checkItSupportTippy(doc.id)}>
                                                        <svg version="1.1" id={checkItSupportSVG(doc.id)}
                                                        viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg" 
                                                        onClick={() => {upOneSupport(doc.id, doc.title)}}>
                                                            <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                <path className="color000 svgShape" d="m24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" 
                                                                fill={checkItSupportPath(doc.id)}/>
                                                            </svg>
                                                        </svg>
                                                    </Tippy>
                                                    <Tippy content ={checkItContraTippy(doc.id)}>
                                                        <svg onClick={() => {upOne(doc.id, doc.title)}} 
                                                        id={checkItContraSVG(doc.id)} version="1.1" 
                                                        viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                                                            <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                <path className="color000 svgShape" d="m24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" 
                                                                fill={checkItContraPath(doc.id)}/>
                                                            </svg>
                                                        </svg>
                                                    </Tippy>
                                                </div>
                                            </div>)
                                })
                            }</div>)}
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="centered">
                    <div id="postAs">
                        <span className="postAsSpan">Post as:  </span>
                        <div className='tags'>
                            <span className='firstCheck'>
                                <div className="checkBoxDivFirst">
                                    <div className='FigureDiv1'>
                                        <input status='userName' className="checkBox1" type='checkbox' id={props.user}
                                        name={props.userName} value={props.userName}/>
                                        {props.userName}
                                    </div>
                                    <span className='tagsSpan'>
                                        <Tippy content='Post Tags'>
                                            <svg transform={tagsOpen ? "rotate(180)" : ""} id="tagSVG" onClick={() => setTagsOpen(!tagsOpen)} 
                                            xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                                                <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/>
                                            </svg>
                                        </Tippy>
                                    </span>
                                </div>
                                <div className={tagsOpen ? "postAsDiv" : "hidden"}>
                                    <div>
                                        {
                                            props.tags.map((tag, index) => {
                                                return (
                                                    <div key={tag.displayName + '1'} className="checkBoxDiv">
                                                        <input status='tag' key={tag.displayName} name={tag.displayName} id={tag.id} 
                                                        type='checkbox' className="checkBox" />
                                                        <label key={tag.displayName + '2'} className="label">{tag.displayName}</label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>
                    
                </div>
                <div className="redFontFigure" hidden={!unchecked} >You must check at least one box!</div> 
            </div>
            <h2 hidden={!needToAdd} className="redTextConnectClaim">Add at least one supporting or contradicting figure to connect</h2>
            <h2 hidden={!needToExplain} className="redTextConnectClaim">Each figure needs an explanation as to why it supports or contradicts</h2>
            <label className='connectFiguresButtonLabel'>
                <input hidden={true} type="submit"/>
                <div className={loaded ? 'connectFiguresButton' : 'connectFiguresButtonClicked'}>
                    <b className={loaded ? '' : 'hidden'}>Connect Figures</b>
                    <Spinner hidden={loaded}/>
                </div>
            </label>
            
        </form>
    )
} 

const mapStateToProps = (state, props) => {
    if (state.profModels.length == 1) {
        return {
            user: state.profModels[0].id,
            userName: state.profModels[0].name,
            tags: state.profModels[0].tags,
            loggedIn: true,
        };
    } else {
        return {
            user: '',
            userName: '',
            tags: [],
            loggedIn: false,
        };
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    connectFiguresToClaim: (id, supportingFigures, contradictingFigures, user, values, claimTitle, supportingExplanations, contradictingExplanations, timeStamp, occurrence) =>
    dispatch(connectFiguresToClaim(id, supportingFigures, contradictingFigures, user, values, claimTitle, supportingExplanations, contradictingExplanations, timeStamp, occurrence))
})
const ConnectedSearchBarClaim = connect(mapStateToProps, mapDispatchToProps)(SearchBarClaim);

export default ConnectedSearchBarClaim;
