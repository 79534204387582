import { db, figureCollection, profileCollection, helpRequestCollection, reportedIssuesCollection, app, storage } from '../firebase/firebase'
import { doc, setDoc, getDoc, addDoc, deleteDoc } from 'firebase/firestore'
import { getStorage, ref, uploadBytes, uploadBytesResumable, getDownloadURL } from 'firebase/storage'

export const addProfile = (
    {
      name = '',
      email = '',
      contactEmail = '',
      myFigures = [],
      myClaims = [],
      myTheories = [],
      myPapers = [],
      myFellows = []
    } = {}
  ) => ({
    type: 'ADD_PROFILE',
    profile: {
      name: name,
      email: email,
      myFigures: myFigures,
      myClaims: myClaims,
      myTheories: myTheories,
      myPapers: myPapers,
      myFellows: myFellows,
      contactEmail: contactEmail
    }
  });
  
export const startAddProfile = (ProfileData = {}, id) => {
  const theID = id
  return async (dispatch) => {
    const theProfile = {
      ...ProfileData,
      myFigures: {uploadedFigures: [], acceptedFigures: [], rejectedFigures: []},
      myClaims: {createdClaims: [], figureClaims: [], followedClaims: [], paperClaims: []},
      myPapers: {authoredPapers: [], followedPapers: [], identifiedPapers: []},
      myTheories: {authoredTheories: [], followedTheories: []},
      myFellows: {followedFellows: [{id: '3WA3MmeDnORGxq0G2mDVTo8cb1n1', lastViewed: 'Mon Dec 05 2022 02:29:40 GMT+0000'}]},
      imgsrc: '',
      tags: [{displayName: 'New User - Journal Club', id: 'iOtjDb0jTfsbauyvGrBW'}],
      follows: [],
      location: '',
      occupation: ''
    }
    await setDoc(doc(db, "Profiles", theID), theProfile)
    dispatch(setProfile({
      ...theProfile, 
      id: theID
    }))
    return true;
  }
}

export const setProfile = (
    {
      name = '',
      email = '',
      myFigures = [],
      myClaims = [],
      myPapers = [],
      myTheories = [],
      myFellows =[],
      tags = [],
      imgsrc = '',
      id = '',
      updates = [],
      follows = [],
      location = '',
      occupation = '',
      contactEmail = ''
    } = {}
  ) => ({
    type: 'SET_PROFILE',
    profile: {
      name: name,
      email: email,
      myFigures: myFigures,
      myClaims: myClaims,
      myPapers: myPapers,
      myTheories: myTheories,
      myFellows: myFellows,
      tags: tags,
      imgsrc: imgsrc,
      id: id,
      updates: updates,
      follows: follows,
      location: location,
      occupation: occupation,
      contactEmail: contactEmail
    }
  });
  
  export const startSetProfile = (id) => {
    return async (dispatch) => {
        const docRef = doc(db, "Profiles", id);
        return getDoc(docRef).then((doc) => {
            const profileData = doc.data()
            dispatch(setProfile({
                ...profileData, 
                id: id
            }))
        }).catch(() => {
            // console.log("did not happen")
        })
    }
  }

export const startDeleteAccount = (id) => {
  return async (dispatch) => {
    try {
      //Fetch the profile document from Firebase
      const docRef = doc(db, "Profiles", id)
      //check first that the profile exists and could be fetched from Firebase.
      await deleteDoc(docRef)
    } catch (err) {
      console.log('error in deleting the account.')
    }
  }
}

//Method to follow a fellow will accomplish the following:
//1) Add the fellowID + occurrence to the "follows" array of the Fellow.
//2) Add the fellowID + occurrence to the "follows" array under the "updates" object of the .
//3) Add the fellowID to the followedTheories array under the myTheories object of the fellow.
//Validated 
export const startAddFellowFollow = (user, fellowID, occurrence, timeStamp) => {
  return async (dispatch) => {
    try {
      //First fetch the data from the specific claim from the database.
      const docRef = doc(db, "Profiles", fellowID);
      const fellowDocument = await getDoc(docRef)
      const fellowData = fellowDocument.data()

      //If the Fellow can be fetched from Firebase, run the following:
      if (fellowData) {
        //Add the user's ID to the follows array, which tells us who is following this particular claim.
        fellowData.follows.push({
          id: user,
          occurrence: occurrence,
          timeStamp: timeStamp
        })
        fellowData.updates.follows.push(
          {
            id: user,
            occurrence: occurrence,
            timeStamp: timeStamp
          }
        )
        await setDoc(docRef, fellowData)
        
        //Next, fetch the profile data from the specific profile from the database.
        const profileRef = doc(db, "Profiles", user);
        const profileDocument = await getDoc(profileRef);
        const profileData = profileDocument.data()

        //If the Profile can be fetched from Firebase, run the following:
        if (profileData) {
          //Add the fellow's ID to the myFellows array.
          profileData.myFellows.followedFellows.push({
            id: fellowID,
            lastViewed: timeStamp
          })
          await setDoc(profileRef, profileData)
          dispatch(setProfile({
            ...profileData, 
            id: user
          }))
        }
      }
    } catch (err) {
      console.log('unable to startAddFellowFollow')
    }
  }
}


//Method to reverse following a fellow should do the following:
//1) Remove the fellowID from the followedTheories array under the myFellows object of the fellow.
//2) Remove the fellowID + occurrence from the "follows" array of the Fellow.
//3) Remove the fellowID + occurrence from the "follows" array under the updates object of the Fellow.
//Validated Fellow/:id
export const startRemoveFellowFollow = (user, fellowID) => {
  return async (dispatch) => {
    try {
      //First fetch the fellow data from the specific fellow from the database.
      const fellowRef = doc(db, "Profiles", fellowID);
      const fellowDocument = await getDoc(fellowRef)
      const fellowData = fellowDocument.data()

      //Check to see if the fellowData is there first incase the fellow was deleted.
      if (fellowData) {
        //Create a new "follows" array and remove the user's ID from it.
        const removeUser = fellowData.follows.filter((theUser) => {
          return theUser.id != user
        })
        fellowData.follows = removeUser

        //Create a new follows array for the updates and remove the fellow's ID from it:
        const removeUser2 = fellowData.updates.follows.filter((theUser) => {
          return theUser.id != user
        })
        fellowData.updates.follows = removeUser2
        
        //Set the follows array to the new array.
        await setDoc(fellowRef, fellowData)
      }
      //Additionally, remove fellow ID from "myFellows" array in the user Profile.
      //First fetch the profile data from the specific profile from the database.
      const profileRef = doc(db, "Profiles", user);
      const profileDocument = await getDoc(profileRef);
      const profileData = profileDocument.data()

      //If the profile can be fetched from Firebase, run the following:
      if (profileData) {
        //Create a new "myFellows" array and remove the fellow's ID from it.
        const removeFellow = profileData.myFellows.followedFellows.filter((theFellow) => {
          return theFellow.id != fellowID
        })

        //Set the myFellows array to the new array.
        profileData.myFellows.followedFellows = removeFellow
        await setDoc(profileRef, profileData)
        dispatch(setProfile({
          ...profileData, 
          id: theOwner
        }))

      }
    } catch (err) {
      console.log('unable to startRemoveFellowFollow')
    }
  }
}

export const updateLastViewedFellow = (user, fellowID, timeStamp) => {
  return async (dispatch) => {
    try {
      //Fetch the profile document of the owner.
      const profRef = doc(db, "Profiles", user);
      const document = await getDoc(profRef)
      const profileData = document.data()

      if (profileData) {
        const isInFollowed = profileData.myFellows.followedFellows.findIndex((fellow) => {
          return fellow.id == fellowID
        })

        //Search through all fellows within fellowFellows, createdFellows, and followedFellows for the ID
        if (isInFollowed > -1) {
          profileData.myFellows.followedFellows[isInFollowed].lastViewed = timeStamp
        }

        await setDoc(profRef, profileData)
        return;
      }

    } catch (err) {
      console.log('Not able to update lastViewed in myFellows.')
    }
  }
}

export const returnFellowInfoHome = (fellowID) => {
  return async (dispatch) => {
    try {
      //Fetch the profile document of the owner.
      const profRef = doc(db, "Profiles", fellowID);
      const document = await getDoc(profRef)
      const profileData = document.data()

      if (profileData) {
        return profileData;
      }

    } catch (err) {
      console.log('Did not get fellow document from Firebase, possibly deleted.')
    }
  }
}

export const returnTagInfoSettings = (tagID) => {
  return async (dispatch) => {
    try {
      //Fetch the profile document of the owner.
      const tagRef = doc(db, "Profiles", tagID);
      const document = await getDoc(tagRef)
      const tagData = document.data()

      tagData.id = tagID

      if (tagData) {
        return tagData;
      }

    } catch (err) {
      console.log('Did not get tag document from Firebase, possibly deleted.')
    }
  }
}


export const updateGeneralProfileSettings = (profileUpdates) => {
  return async (dispatch) => {
    try {
      //Fetch the profile document of the owner.
      const profRef = doc(db, "Profiles", profileUpdates.id);
      const document = await getDoc(profRef)
      const profileData = document.data()

      profileData.name = profileUpdates.name
      profileData.email = profileUpdates.email
      profileData.occupation = profileUpdates.occupation
      profileData.location = profileUpdates.location

      await setDoc(profRef, profileData)
      
    } catch (err) {
      console.log('Did not get tag document from Firebase, possibly deleted.')
    }
  }
}

export const updateProfilePicture = (profileID, imgsrc) => {
  return async (dispatch) => {
    try {
      //Fetch the profile document of the owner.
      const profRef = doc(db, "Profiles", profileID);
      const document = await getDoc(profRef)
      const profileData = document.data()

      profileData.imgsrc = imgsrc
      await setDoc(profRef, profileData)
      dispatch(setProfile({
        ...profileData, 
        id: profileID
      }))
      return imgsrc;
    } catch (err) {
      console.log('Did not update imgsrc on user Profile')
    }
  }
}

export const startAddAnonTag = (TagData = {}) => {
  return async (dispatch) => {
    console.log(TagData)
    const AnonTag = {
      ...TagData,
    }
    const tagDoc = await addDoc(profileCollection, AnonTag)
    return tagDoc.id;
  }
}

export const updateAnonTagsOnProfile = (tagInfo = {}, profileID) => {
  return async (dispatch) => {
    try {
      const newTag = {
        ...tagInfo
      }
      //Fetch the profile document of the owner.
      const profRef = doc(db, "Profiles", profileID);
      const document = await getDoc(profRef)
      const profileData = document.data()
      profileData.tags.push(newTag)
      await setDoc(profRef, profileData)
      dispatch(setProfile({
        ...profileData, 
        id: profileID
      }))
      return;
    } catch (err) {
      console.log('Did not update tags on user Profile')
    }
  }
}

export const startRequestHelp = (helpRequest = {}) => {
  return async (dispatch) => {
    try {
      const newHelpRequest = {
        ...helpRequest
      }
      const helpDoc = await addDoc(helpRequestCollection, newHelpRequest)
      
      return helpDoc.id;
    } catch (err) {
      console.log('Did not submit help request')
    }
  }
}

export const startReportIssue = (reportRequest = {}) => {
  return async (dispatch) => {
    try {
      const newReportRequest = {
        ...reportRequest
      }
      const reportDoc = await addDoc(reportedIssuesCollection, newReportRequest)
      
      return reportDoc.id;
    } catch (err) {
      console.log('Did not submit report request')
    }
  }
}


export const reportCriticism = (reportRequest = {}) => {
  return async (dispatch) => {
    try {
      const newReportRequest = {
        ...reportRequest
      }
      const reportDoc = await addDoc(reportedIssuesCollection, newReportRequest)

      return reportDoc.id;
    } catch (err) {
      console.log('Did not submit report request')
    }
  }
}
