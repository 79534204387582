import React, { useEffect, useState, useRef } from 'react';

import Modal from './FigureShowModalClaim'


export const ImgModal = (props) => {
    const [openModal, setOpenModal] = useState(false)
    const textAreaRef = useRef()

    useEffect(() => {
        const currentTextRefs = [...props.listOfTextRefs]
        currentTextRefs.push(textAreaRef)
        props.setListOfTextRefs(currentTextRefs)
    }, [])

    const removeIt = () => {
        let newAlreadyContradicting = props.alreadyContradicting.filter((figure) => {
            return figure != props.ID
        })
        let newChosenContra = props.chosenContra.filter((figure) => {
            return figure.ID != props.ID
        })

        const currentTextRefs = [...props.listOfTextRefs]
        currentTextRefs.splice(props.index, 1)
        props.setListOfTextRefs(currentTextRefs)
        props.setAlreadyContradict(newAlreadyContradicting)
        props.setChosenContra(newChosenContra)
        
    }
        return (
            <div className='contraFigConnect2'>
            {openModal && <Modal closeModal={setOpenModal} src={props.imgsrc} />} 
                <div className='borderConnect'>         
                    <a key={props.ID} className="figureTitleConnectClaim" href={"/Figure/" + props.ID}>{props.title}</a>
                    <div className="lineConnectClaim"></div>
                    <div  className='centerItConnect'>
                        <img onClick={() => {setOpenModal(true)}} className='figureConnectClaim' src={props.imgsrc} />
                    </div>
                    <div className="line2ConnectClaim"></div>
                    <div className='modalButton'><button onClick={removeIt}>Remove</button></div>
                </div>
                <div className="explanationDivConnectClaim">
                    <div className="verticalCenterConnectClaim">
                        <div className="horizontalCenterConnectClaim">
                            <h2 >Briefly explain how this figure {props.direction} the claim</h2>
                        </div>
                        <textarea className="textAreaExplanationConnectClaim" ref={textAreaRef}></textarea>
                    </div>
                </div>
            </div>
        )
} 

export default ImgModal;
