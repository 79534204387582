import React, { useEffect, useState, useRef, useCallback } from 'react'
import { connect } from 'react-redux'
import { returnTagInfoSettings, updateGeneralProfileSettings } from '../../actions/profModels';
import SVGGeneralProfileEdit from '../SVGs/SVGGeneralProfileEdit';
import { getAuth, reauthenticateWithCredential, EmailAuthProvider, sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import DeleteModal from '../deleteModalAccount';


export const ProfileInformation = (props) => {
    const [tagsList, setTagsList] = useState([])
    const [generalEdit, setGeneralEdit] = useState(false)
    const [currentName, setCurrentName] = useState(props.userName)
    const [savedName, setSavedName] = useState(props.userName)
    const [currentEmail, setCurrentEmail] = useState(props.email)
    const [savedEmail, setSavedEmail] = useState(props.email)
    const [currentOccupation, setCurrentOccupation] = useState(props.occupation)
    const [savedOccupation, setSavedOccupation] = useState(props.occupation)
    const [currentLocation, setCurrentLocation] = useState(props.location)
    const [savedLocation, setSavedLocation] = useState(props.location)
    const [selectedTimezone, setSelectedTimezone] = useState('');
    const [anontagEdit, setAnontagEdit] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [saveGeneralEdit, setSaveGeneralEdit] = useState(false)
    const [user, setUser] = useState('')
    const [deleteModalState, setDeleteModalState] = useState(false)
    const [deletingAccount, setDeletingAccount] = useState(false)
    const [passwordInvalidDelete, setPasswordInvalidDelete] = useState(false)
    const [passwordResetLinkSent, setPasswordResetLinkSent] = useState(false)

    const nameRef = useRef(null);
    const emailRef = useRef(null);
    const occupationRef = useRef(null);
    const locationRef = useRef(null);
    const deletePasswordCheckRef = useRef(null);


    const handleTimezoneChange = (timezone) => {
        setSelectedTimezone(timezone);
        // console.log('Selected timezone:', timezone);
    };

    useEffect(async () => {
        const auth = getAuth();
        const user = auth.currentUser;
        setUser(user)
    }, [])

    useEffect(() => {
        setCurrentName(props.userName)
        setSavedName(props.userName)
        setCurrentEmail(props.email)
        setSavedEmail(props.email) 
        setCurrentOccupation(props.occupation)
        setSavedOccupation(props.occupation)
        setCurrentLocation(props.location)  
        setSavedLocation(props.location)    
    }, [props.email])

    //This useEffect will take in the list of tagIDs that was passed in and loop through it to 
    //fetch the data from each.
    useEffect(async () => {
        let theTagsList = []
        for (const tag of props.tags) {
            let tagInfo = await props.returnTagInfoSettings(tag.id)
            //If the claim is unavailable from Firebase or if it is anonymous and it is not a user viewing their own profile,
            //dont add the claim to the claimsList.
            if (tagInfo) {
                theTagsList.push(tagInfo)
            }
        }
        
        setTagsList(theTagsList)
        setLoaded(true)
    }, [props.whichTabIsIt, props.loaded])


    //This function will handle general profile updates when the user makes changes and clicks the "save" button.
    const saveProfileEdits = async () => {
        //Gather all the appropriate updates from the state values:
        let profileUpdates = {}
        profileUpdates.name = currentName
        profileUpdates.email = currentEmail
        profileUpdates.occupation = currentOccupation
        profileUpdates.location = currentLocation
        profileUpdates.id = props.user

        props.updateGeneralProfileSettings(profileUpdates).then(() => {
            setSavedName(currentName)
            setSavedEmail(currentEmail)
            setSavedOccupation(currentOccupation)  
            setSavedLocation(currentLocation)
        }).catch((error) => {
            // console.log(error)
        })
    }

    const updateName = (event) => {
        setCurrentName(event.target.value)
    }
    const updateEmail = (event) => {
        setCurrentEmail(event.target.value)
    }
    const updateOccupation = (event) => {
        setCurrentOccupation(event.target.value)
    }
    const updateLocation = (event) => {
        setCurrentLocation(event.target.value)
    }
    
    //This function will check to make sure the user put in the correct password. If they did, the account with me recently
    //authenticated and the deleteModal will pop up and they can proceed to deleting it.
    const deleteAccount = () => {
        if (deletePasswordCheckRef.current.value) {
            const credential = EmailAuthProvider.credential(
                props.email,
                deletePasswordCheckRef.current.value
            );
            reauthenticateWithCredential(user, credential).then(() => {
                setPasswordInvalidDelete(false)
                setDeleteModalState(true)
            }).catch((error) => {
                // console.log(error)
                setPasswordInvalidDelete(true)
            });
        }
        
    }

    const cancelEdits = () => {
        setGeneralEdit(false)
        setCurrentName(savedName)
        setCurrentEmail(savedEmail)
        setCurrentOccupation(savedOccupation)
        setCurrentLocation(savedLocation)

        if (nameRef.current) {
            nameRef.current.value = savedName
        }

        if (emailRef.current) {
            emailRef.current.value = savedEmail
        }
        
        if (occupationRef.current) {
            occupationRef.current.value = savedOccupation
        }

        if (locationRef.current) {
            locationRef.current.value = savedLocation
        }
    }
    
    const resetPassword = () => {
        try {
            const auth = getAuth();
            sendPasswordResetEmail(auth, props.email).then(() => {
                setPasswordResetLinkSent(true)
                // console.log('password reset email sent')
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // console.log(errorMessage)
            });
        
        } catch (error) {
            // console.log(error)
        }
    }

    return (  
        <div className={props.whichTabIsIt == 'Profile Information' ? 'myClaimsWrapperContainer' : 'hidden'}>
            {deleteModalState && <DeleteModal userToDelete={user} closeModal={setDeleteModalState} />}
            <div className="myClaimsClaimContainer">
                <div className='addClaimButtonMyClaims'>
                    <h1 className='myHeadersMyClaims'>Profile Information</h1>
                </div>
                <div className="SettingsProfileLine"></div>
                <div className='SettingsSubheader'>
                    <h2>General</h2>
                    <div className='SettingsEditSVGDiv'>
                        <button className={generalEdit ? 'SettingsCancelEdits': 'hidden'} onClick={cancelEdits}>Cancel</button>
                        <SVGGeneralProfileEdit generalEdit={generalEdit} setGeneralEdit={setGeneralEdit} 
                        saveProfileEdits={saveProfileEdits}/>
                        
                    </div>
                </div>
                <div className={generalEdit ? 'hidden' : ''}>
                    <div className='SettingsProfileInformation'>
                        Name: <b>{currentName}</b>
                    </div>
                    <div className='SettingsProfileInformation'>
                        Email Contact: <span className='SettingsEmailUnderline'>{currentEmail}</span>
                    </div>
                    <div className='SettingsProfileInformation'>
                        Occupation: {currentOccupation}
                    </div>
                    <div className='SettingsProfileInformation'>
                        Location: {currentLocation}
                    </div>
                    <br/>
                    <br/>
                    
                </div>
                <form className={generalEdit ? '' : 'hidden'}>
                    <div className='SettingsProfileInformation2' >
                        Name: <input defaultValue={currentName} onChange={updateName} name='name' className='SettingsNameEdit'
                        ref={nameRef}></input>
                    </div>
                    <div className='SettingsProfileInformation2'>
                        Email: <input defaultValue={currentEmail} onChange={updateEmail} name='email' className='SettingsNameEdit'
                        ref={emailRef}></input>
                    </div>
                    <div className='SettingsProfileInformation2'>
                        Occupation: <input defaultValue={currentOccupation} onChange={updateOccupation} name='occupation' 
                        ref={occupationRef} className='SettingsNameEdit'></input>
                    </div>
                    <div className='SettingsProfileInformation2'>
                        Location: <input defaultValue={currentLocation} onChange={updateLocation} name='location' className='SettingsNameEdit'
                        ref={locationRef}></input>
                    </div>
                    
                    <br/>
                    <br/>
                </form>
                
                <div className="SettingsProfileLine2"></div>
                <div className='SettingsSubheader'>
                    <h2>Account</h2>
                </div>
                <div className='SettingsProfileInformation'>
                    UserName: {props.email}
                </div>
                <div className='SettingsResetPassword'>
                    <button className='SettingsResetPasswordButton' onClick={resetPassword}>Reset Password</button>
                    <div className='SettingsVerticalCenter'>
                        <div className={passwordResetLinkSent ? 'SettingsLinkSentText' : 'hidden'}>
                            A link has been sent to your email to reset your password.
                        </div>
                    </div>
                </div>
                <div className={deletingAccount ? 'SettingsDeletePadding' : 'hidden'}>
                    <div>Input account Password in order to delete your account:</div>
                    <div className='SettingsPasswordEnterWrapper'>
                        <input className='SettingsPasswordEnterDelete' ref={deletePasswordCheckRef} type='password'></input>
                        
                    </div>
                    <div className={passwordInvalidDelete ? 'SettingsPasswordInvalid' : 'hidden'}>Password Invalid</div>
                </div>

                <div className='SettingsDeleteButtonPadding'>
                    <button onClick={() => {setDeletingAccount(true)}} className={!deletingAccount ? 'SettingsDeleteAccountButton' : 'hidden'}>
                        Delete Account
                    </button>
                    <button onClick={deleteAccount} className={deletingAccount ? 'SettingsDeleteAccountButton' : 'hidden'}>
                        Delete Account
                    </button>
                </div>
            </div>
        </div>
    ) 
}

const mapStateToProps = (state, props) => {
    if (state.profModels.length == 1) {
        return {
            user: state.profModels[0].id,
            userName: state.profModels[0].name,
            tags: state.profModels[0].tags,
            loggedIn: true,
            email: state.profModels[0].email,
            occupation: state.profModels[0].occupation,
            location: state.profModels[0].location,
            contactEmail: state.profModels[0].contactEmail
        };
    } else {
        return {
            user: '',
            userName: '',
            tags: [],
            loggedIn: false,
            email: '',
            occupation: '',
            location: '',
            contactEmail: ''
        };
    }
}

const mapDispatchToProps = (dispatch, profileProps) => ({
    returnTagInfoSettings: (tagID) => dispatch(returnTagInfoSettings(tagID)),
    updateGeneralProfileSettings: (profileUpdates) => dispatch(updateGeneralProfileSettings(profileUpdates))
})

const ConnectedProfileInformation = connect(mapStateToProps, mapDispatchToProps)(ProfileInformation);

export default ConnectedProfileInformation;