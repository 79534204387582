import React, { useEffect, useState, useRef, useCallback } from 'react'
import { connect } from 'react-redux'
import { returnFigureInfoProfile } from '../../actions/figModels';
import Spinner from "react-svg-spinner";
import SVGoptionsDotsProfileFigures from '../SVGs/SVGoptionsDotsProfileFigures';
import { useNavigate } from 'react-router-dom';

export const FiguresList = (props) => {
    const [selectorOpen, setSelectorOpen] = useState(false)
    const [sortBySelectorFigure, setSortBySelectorClaim] = useState('None')
    const [figuresList, setFiguresList] = useState([])
    let selectorRef = useRef();
    const [loaded, setLoaded] = useState(false)
    const [change, setChange] = useState(false)
    const navigate = useNavigate()

    //This useEffect will take in the list of figureIDs that was passed in and loop through it to 
    //fetch the data from each.
    useEffect(async () => {
        if (!props.loaded) {
            setLoaded(false)
            return;
        }
        try {
            let theFiguresList = []
            for (const figure of props.uploadedFigures) {
                let figureInfo = await props.returnFigureInfoProfile(figure.id)
                //If the figure is unavailable from Firebase or if it is anonymous and it is not a user viewing their own profile,
                //dont add the figure to the figuresList.
                if (figureInfo.unavailable || (figureInfo.anonymous && props.user != props.fellowID)) {

                } else {
                    theFiguresList.push(figureInfo)
                }
            }
            await theFiguresList.all
            setFiguresList(theFiguresList)
            if (props.loaded) {
                setLoaded(true)
            }
        } catch (e) {
            // console.log(e)
        }
    }, [props.whichTabIsIt, props.loaded])

    useEffect(() => {
        let handler = (event) => {
            if(!selectorRef.current.contains(event.target)) {
                setSelectorOpen(false)
            }
        }
        document.addEventListener("mousedown", handler)
        return () => {
            document.removeEventListener("mousedown", handler)
        }
    })

    const sortFiguresByAcceptancePercentage = () => {
        //if Acceptance Percentage is already selected, don't do anything.
        if (sortBySelectorFigure === 'Acceptance Percentage') {
            setSelectorOpen(false)
            return;
        }

        //combine all data into array of objects for easy sorting.
        let allArrayData = [...figuresList]

        //sort allArrayData on the basis of acceptance percentage.
        allArrayData.sort((a,b) => {
            if (a.acceptancePercentage !== '?' && b.acceptancePercentage !== '?') {
                return b.acceptancePercentage - a.acceptancePercentage; 
            } else if (a.acceptancePercentage == '?') {
                return 1;
            } else if (b.acceptancePercentage == '?') {
                return -1;
            }
        })
        
        setFiguresList(allArrayData)
        setSelectorOpen(false)
        setSortBySelectorClaim('Acceptance Percentage')
    }

    const sortFiguresByOldest = (tab) => {
        //if Oldest is already selected, don't do anything.
        if (sortBySelectorFigure === 'Oldest') {
            setSelectorOpen(false)
            return;
        }

        //combine all data into array of objects for easy sorting.
        let allArrayData = [...figuresList]
        
        //sort allArrayData on the basis of what is oldest
        allArrayData.sort((a,b) => {
            if (a.moment.isBefore(b.moment)) {
                return -1; 
            } else {
                return 1;
            }
        })

        setFiguresList(allArrayData)
        setSelectorOpen(false)
        setSortBySelectorClaim('Oldest')
    }

    const sortFiguresByNewest = (tab) => {
        //if Oldest is already selected, don't do anything.
        if (sortBySelectorFigure === 'Newest') {
            setSelectorOpen(false)
            return;
        }

        //combine all data into array of objects for easy sorting.
        let allArrayData = [...figuresList]
        
        //sort allArrayData on the basis of what is newest
        allArrayData.sort((a,b) => {
            if (a.moment.isBefore(b.moment)) {
                return 1; 
            } else {
                return -1;
            }
        })

        setFiguresList(allArrayData)
        setSelectorOpen(false)
        setSortBySelectorClaim('Newest')
    }

    const sortFiguresByEngagement = (tab) => {
        //if Oldest is already selected, don't do anything.
        if (sortBySelectorFigure === 'Engagement') {
            setSelectorOpen(false)
            return;
        }

        //combine all data into array of objects for easy sorting.
        let allArrayData = [...figuresList]
        
        //sort allArrayData on the basis of its engagement number.
        allArrayData.sort((a,b) => b.engagementNumber - a.engagementNumber)

        setFiguresList(allArrayData)
        setSelectorOpen(false)
        setSortBySelectorClaim('Engagement')
    }

    function removeSubstring(originalString, stringToRemove) {
        let split = originalString.split('/')
        let theURL = '/' + split[3] + '/' + split[4]
        return theURL;
    }

    function handleClick(event) {
        event.preventDefault();
        let URL = removeSubstring(event.target.href, event.target.baseURI)
        navigate(URL)
    }

    return (  
        <div className={props.whichTabIsIt == 'Figures' ? 'myClaimsWrapperContainer' : 'hidden'}>
            <div className="myClaimsClaimContainer">
                <div className='addClaimButtonMyClaims'>
                    <h1 className='myHeadersMyClaims'>Uploaded Figures</h1>
                </div>  
                <div className={figuresList.length != 0 ? 'sortByDivMyClaimWrapper' : 'hidden'}>
                    <div className='slightSpacerMyClaim'>Sort by:</div>
                    <div className='sortByDivProfile'>
                        <span className='minimumWidthMyClaims'>{sortBySelectorFigure}</span>
                        <span className='tagsSpanMyClaims'>
                            <svg onClick={() => {setSelectorOpen(!selectorOpen)}} id="tagSVG" xmlns="http://www.w3.org/2000/svg" width="18" 
                            height="18" viewBox="0 0 24 24">
                                <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/>
                            </svg>
                        </span>
                        <div className='seletorsDivMyClaims' hidden={!selectorOpen} ref={selectorRef}>
                            <div className='selectorOptionsMyClaims' onClick={() => {sortFiguresByAcceptancePercentage()}}>Acceptance Percentage</div>
                            <div className='selectorOptionsMyClaims' onClick={() => {sortFiguresByEngagement()}}>Engagement</div>
                            <div className='selectorOptionsMyClaims' onClick={() => {sortFiguresByOldest()}}>Oldest</div>
                            <div className='selectorOptionsMyClaims' onClick={() => {sortFiguresByNewest()}}>Newest</div>
                        </div>
                    </div>
                </div>
                <div>
                    {
                        figuresList.map((figure, index) => {
                            return (
                                <div key={index + figure.id} className='userClaimWrapperMyClaims'>
                                    <div className='userClaimMyClaims'>
                                        <div className='myClaimsAnchorSpan'>
                                            <a className="ProfileFontBold" href={"/Figure/" + figure.id} onClick={handleClick}>{figure.title}</a>
                                            <div className='timeStampDivMyClaims'>- {figure.timeStamp}</div>
                                        </div>
                                        <div className='claimStatsWidgetMyClaims'>
                                            <div className='confidenceLoader'>  <Spinner hidden={loaded} /></div>
                                            <div hidden={!loaded}>{figure.acceptancePercentage}%  | {figure.engagementNumber}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className={loaded ? 'hidden' : 'ProfileComponentsSpinnerDiv'}>
                    <Spinner />
                </div>
                <div className={props.whichTabIsIt === 'Figures' && figuresList.length == 0 && loaded ? 'noClaimsToShow' : 'hidden'}>
                    {props.fellowName == props.userName ? 'You have ' : props.fellowName + ' has '}not uploaded any figures yet.
                </div>
            </div>
        </div>
    ) 
}

const mapStateToProps = (state, props) => {
    if (state.profModels.length == 1) {
        return {
            user: state.profModels[0].id,
            userName: state.profModels[0].name,
            tags: state.profModels[0].tags,
            loggedIn: true,
        };
    } else {
        return {
            user: '',
            userName: '',
            tags: [],
            loggedIn: false
        };
    }
}

const mapDispatchToProps = (dispatch, profileProps) => ({
    returnFigureInfoProfile: (figure) => dispatch(returnFigureInfoProfile(figure))
})

const ConnectedFiguresList = connect(mapStateToProps, mapDispatchToProps)(FiguresList);

export default ConnectedFiguresList;