import React, { useState, useEffect, useRef } from 'react';
import Tippy from '@tippyjs/react'
import { connect } from 'react-redux'
import { reverseVoteNotImportant, startVoteNotImportant, reverseVoteSomewhatImportant, startVoteSomewhatImportant,
reverseVoteVeryImportant, startVoteVeryImportant } from '../actions/theoryModels'
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment'


export const ClaimReference = (props) => {

    const [notImportantEngagement, setNotImportantEngagement] = useState(false)
    const [somewhatImportantEngagement, setSomewhatImportantEngagement] = useState(false)
    const [veryImportantEngagement, setVeryImportantEngagement] = useState(false)
    const [voteCountX, setVoteCountX] = useState(props.claimInfo.notImportant.length)
    const [voteCountC, setVoteCountC] = useState(props.claimInfo.somewhatImportant.length)
    const [voteCountCC, setVoteCountCC] = useState(props.claimInfo.veryImportant.length)
    
    const preventDragHandler = (e) => {
        e.preventDefault();
    }

    useEffect(() => {
        const notImportantIndex = props.claimInfo.notImportant.findIndex((vote) => {
            return vote.id == props.user
        })
        if (notImportantIndex > -1) {
            setNotImportantEngagement([props.claimInfo.notImportant[notImportantIndex]])
        }

        const somewhatImportantIndex = props.claimInfo.somewhatImportant.findIndex((vote) => {
            return vote.id == props.user
        })
        if (somewhatImportantIndex > -1) {
            setSomewhatImportantEngagement([props.claimInfo.somewhatImportant[somewhatImportantIndex]])
        }

        const veryImportantIndex = props.claimInfo.veryImportant.findIndex((vote) => {
            return vote.id == props.user
        })
        if (veryImportantIndex > -1) {
            setVeryImportantEngagement([props.claimInfo.veryImportant[veryImportantIndex]])
        }

    }, [])

    const voteNotImportant = (e) => {
        e.preventDefault()

        //If the user is not logged in or the theory is not published yet, they cannot vote.
        if (!props.loggedIn || !props.published) {
            return;
        }

        let timeStamp = moment().utc().toString()
        let occurrence = uuidv4();

        const Vote = {
            claimID: props.claimInfo.id,
            theoryID: props.theoryID,
            user: props.user,
            index: props.index,
            timeStamp: timeStamp,
            occurrence: occurrence
        }

        let newEngagement = {
            id: props.user,
            occurrence: occurrence,
            timeStamp: timeStamp
        }

        if (notImportantEngagement.length > 0) {
            setNotImportantEngagement([])
            setVoteCountX(prev => prev - 1)
            props.setTotalEngagement(prev => props.removeEngagement([...prev], notImportantEngagement))
            props.reverseVoteNotImportant(Vote).then(() => {
                
            }).catch(() => {
                // console.log('claimRef not voted on')
            })
        } else {
            setVoteCountX(prev => prev + 1)
            if (somewhatImportantEngagement.length > 0) {
                setVoteCountC(prev => prev - 1)
                props.setTotalEngagement(prev => props.removeEngagement([...prev], somewhatImportantEngagement))
            }
            if (veryImportantEngagement.length > 0) {
                setVoteCountCC(prev => prev - 1)
                props.setTotalEngagement(prev => props.removeEngagement([...prev], veryImportantEngagement))
            }
            setNotImportantEngagement([newEngagement])
            setSomewhatImportantEngagement([])
            setVeryImportantEngagement([])
            props.setTotalEngagement(prev => [...prev, newEngagement])
            props.startVoteNotImportant(Vote).then(() => {
                if(somewhatImportantEngagement.length > 0) {
                    props.reverseVoteSomewhatImportant(Vote).then(() => {
                        
                    }).catch(() => {
                        // console.log('claimRef not voted on')
                    })
                } else if (veryImportantEngagement.length > 0) {
                    props.reverseVoteVeryImportant(Vote).then(() => {

                    }).catch(() => {
                        // console.log('claimRef not voted on')
                    })
                }
                // console.log('claimRef voted on')
            }).catch(() => {
                // console.log('claimRef not voted on')
            })
        }
        props.setPercentLoaded(false)
    }

    const voteSomewhatImportant = (e) => {
        e.preventDefault()
        //If the user is not logged in or the theory is not published yet, they cannot vote.
        if (!props.loggedIn || !props.published) {
            return;
        }

        let timeStamp = moment().utc().toString()
        let occurrence = uuidv4();

        const Vote = {
            claimID: props.claimInfo.id,
            theoryID: props.theoryID,
            user: props.user,
            index: props.index,
            timeStamp: timeStamp,
            occurrence: occurrence
        }

        let newEngagement = {
            id: props.user,
            occurrence: occurrence,
            timeStamp: timeStamp
        }

        if (somewhatImportantEngagement.length > 0) {
            setVoteCountC(prev => prev - 1)
            props.setTotalEngagement(prev => props.removeEngagement([...prev], somewhatImportantEngagement))
            setSomewhatImportantEngagement([])
            props.reverseVoteSomewhatImportant(Vote).then(() => {
                // console.log('figure un-accepted')
            }).catch(() => {
                // console.log('figure not un-accepted')
            })
        } else {
            setVoteCountC(prev => prev + 1)
            if (notImportantEngagement.length > 0) {
                setVoteCountX(prev => prev - 1)
                props.setTotalEngagement(prev => props.removeEngagement([...prev], notImportantEngagement))
            }
            if (veryImportantEngagement.length > 0) {
                setVoteCountCC(prev => prev - 1)
                props.setTotalEngagement(prev => props.removeEngagement([...prev], veryImportantEngagement))
            }
            setNotImportantEngagement([])
            setSomewhatImportantEngagement([newEngagement])
            setVeryImportantEngagement([])
            props.setTotalEngagement(prev => [...prev, newEngagement])
            props.startVoteSomewhatImportant(Vote).then(() => {
                if(notImportantEngagement.length > 0) {
                    props.reverseVoteNotImportant(Vote).then(() => {
                        // console.log('claimRef voted on')
                    }).catch(() => {
                        // console.log('claimRef not voted on')
                    })
                } else if (veryImportantEngagement.length > 0) {
                    props.reverseVoteVeryImportant(Vote).then(() => {
                        // console.log('claimRef voted on')
                    }).catch(() => {
                        // console.log('claimRef not voted on')
                    })
                }
                // console.log('claimRef voted on')
            }).catch(() => {
                // console.log('claimRef not voted on')
            })
        }
        props.setPercentLoaded(false)
    }

    const voteVeryImportant = (e) => {
        e.preventDefault()

        //If the user is not logged in or the theory is not published yet, they cannot vote.
        if (!props.loggedIn || !props.published) {
            return;
        }

        let timeStamp = moment().utc().toString()
        let occurrence = uuidv4();

        const Vote = {
            claimID: props.claimInfo.id,
            theoryID: props.theoryID,
            user: props.user,
            index: props.index,
            timeStamp: timeStamp,
            occurrence: occurrence
        }

        let newEngagement = {
            id: props.user,
            occurrence: occurrence,
            timeStamp: timeStamp
        }

        if (veryImportantEngagement.length > 0) {
            setVoteCountCC(prev => prev - 1)
            props.setTotalEngagement(prev => props.removeEngagement([...prev], veryImportantEngagement))
            setVeryImportantEngagement([])
            props.reverseVoteVeryImportant(Vote).then(() => {
                // console.log('figure un-accepted')
            }).catch(() => {
                // console.log('figure not un-accepted')
            })
        } else {
            setVoteCountCC(prev => prev + 1)
            if (notImportantEngagement.length > 0) {
                setVoteCountX(prev => prev - 1)
                props.setTotalEngagement(prev => props.removeEngagement([...prev], notImportantEngagement))
            }
            if (somewhatImportantEngagement.length > 0) {
                setVoteCountC(prev => prev - 1)
                props.setTotalEngagement(prev => props.removeEngagement([...prev], somewhatImportantEngagement))
            }
            setNotImportantEngagement([])
            setSomewhatImportantEngagement([])
            setVeryImportantEngagement([newEngagement])
            props.setTotalEngagement(prev => [...prev, newEngagement])
            props.startVoteVeryImportant(Vote).then(() => {
                if(notImportantEngagement.length > 0) {
                    props.reverseVoteNotImportant(Vote).then(() => {

                    }).catch(() => {
                        // console.log('claimRef not voted on') 
                    })
                } else if (somewhatImportantEngagement.length > 0) {
                    props.reverseVoteSomewhatImportant(Vote).then(() => {

                    }).catch(() => {
                        // console.log('claimRef not voted on')
                    })
                }
            }).catch(() => {
                // console.log('claimRef not voted on')
            })
        }
        props.setPercentLoaded(false)
    }
    
    
    return (
        <span onDragStart={preventDragHandler} className='claimReference' 
            contentEditable={false} draggable="false" >
           <a onClick={() => {props.placeCaretInPreviousSpan()}} className='claimReferenceFootnote'>[{props.footnote}]</a>
           <div className='claimReferenceHover'>
                <a className='claimReferenceAnchor' href={'/Claim/' + props.claimInfo.id}>{props.claimInfo.title}</a>
                <br className={!props.published ? "" : 'hidden'}/>
                <br className={!props.published ? "" : 'hidden'}/>
                <div>
                    <button type="button" className={props.published ? 'hidden' : 'removeReferenceButtonAddTheory'} 
                    onClick={() => {props.removeClaimReference(props.index)}}>Remove</button>
                </div>
                <br className={props.published ? "" : 'hidden'}/>
                <div className={props.published ? 'howImportantTheoryDiv' : 'hidden'}>How important is this reference to the validity of the theory?</div>
                <div className={props.published ? "theFlexTheory" : 'hidden'}>
                    <Tippy content='Not Important' >
                        <div className={"supportFigButtons"} onClick={voteNotImportant} >
                            <svg id={notImportantEngagement.length > 0 ? "input3ClaimClicked" : "input3Claim"} version="1.1" viewBox="0 0 288 288" 
                            xmlns="http://www.w3.org/2000/svg">
                                <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path className="color000 svgShape" 
                                    d="m24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" 
                                    fill={notImportantEngagement.length > 0 ? "#FFFFFF" : "#62BDB8"}/>
                                </svg>
                            </svg>
                            {voteCountX}
                        </div>
                    </Tippy>
                    <Tippy content='Somewhat Important'>
                        <div className={"supportFigButtons"} onClick={voteSomewhatImportant} >
                            <svg id={somewhatImportantEngagement.length > 0 ? "input3ClaimClicked" : "input3Claim"} name = {'props.image'} version="1.1" 
                            viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                                <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path className="color000 svgShape" d="m20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" 
                                    fill={somewhatImportantEngagement.length > 0 ? "#FFFFFF" : "#62BDB8"}/>
                                </svg>
                            </svg>
                            {voteCountC}
                        </div>
                    </Tippy>
                    <Tippy content ='Very Important' >
                        <div className={"supportFigButtons"} onClick={voteVeryImportant} >
                            <svg id={veryImportantEngagement.length > 0 ? "input6ClaimClicked" : "input6Claim"} version="1.1" viewBox="0 0 288 288" 
                            xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <svg xmlns="http://www.w3.org/2000/svg" width="288" height="288" viewBox="0 0 1200 1200">
                                    <path className="color000 svgShape" d="M900 465.1L1034.9 600 480 1154.9 165.11 840 300 705.1l180 
                                    180zM1034.9 180L900 45.1l-420 420-180-180L165.11 420 480 734.9z" 
                                    fill={veryImportantEngagement.length > 0 ? "#FFFFFF" : "#62BDB8"}></path>
                                </svg>
                            </svg>
                            {voteCountCC}
                        </div>
                    </Tippy>
                </div>  
           </div>
        </span>
    )
    
}

const mapStateToProps = (state, props) => {
    
}

const mapDispatchToProps = (dispatch, props) => ({
    reverseVoteNotImportant: (vote) => dispatch(reverseVoteNotImportant(vote)),
    startVoteNotImportant: (vote) => dispatch(startVoteNotImportant(vote)),
    reverseVoteSomewhatImportant: (vote) => dispatch(reverseVoteSomewhatImportant(vote)),
    startVoteSomewhatImportant: (vote) => dispatch(startVoteSomewhatImportant(vote)),
    reverseVoteVeryImportant: (vote) => dispatch(reverseVoteVeryImportant(vote)),
    startVoteVeryImportant: (vote) => dispatch(startVoteVeryImportant(vote)),
})

const ConnectedClaimReference = connect(undefined, mapDispatchToProps)(ClaimReference);

export default ConnectedClaimReference;
