import React, { useState, useEffect, useRef } from 'react';
import Tippy from '@tippyjs/react'
import { connect } from 'react-redux'
import DeleteModal from './deleteModalClaimCriticism';
import { startAcceptCriticismClaim, reverseAcceptCriticismClaim, reverseRejectCriticismClaim,
    startRejectCriticismClaim } from '../actions/claimModels';
import SVGdeleteCriticism from './SVGs/SVGDelete';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import ReportCriticismModal from './reportCriticismModal';



export const CriticismStateless = (props) => {
    const [deleteModalState, setDeleteModalState] = useState(false)
    const [upTally, setUpTally] = useState(props.upvotes.length)
    const [downTally, setDownTally] = useState(props.downvotes.length)
    const [critOptionsOpen, setCritOptionsOpen] = useState(false)
    const [criticismRemoved, setCriticismRemoved] = useState(false)
    const [critEngagement, setCritEngagement] = useState({id: props.owner, occurrence: props.occurrence, timeStamp: props.timeStamp})
    const [upVoteEngagement, setUpVoteEngagement] = useState([])
    const [downVoteEngagement, setDownVoteEngagement] = useState([])
    const [upVotes, setUpVotes] = useState(props.upvotes)
    const [downVotes, setDownVotes] = useState(props.downvotes)
    const [reportCriticismModalState, setReportCriticismModalState] = useState(false)



    let critOptionsRefDiv = useRef();
    let critOptionsRefIcon = useRef();

    const theDate = new Date(props.timeStamp)
    const newMoment = moment(theDate)
    newMoment.local()
    const localDate = newMoment.format('LLL')

    // console.log('sorted indices from ClaimCriticism ' + props.critIndices)
    useEffect(() => {
        // console.log("use effect ran")
        let handler = (event) => {
            if(!(critOptionsRefDiv.current.contains(event.target) || critOptionsRefIcon.current.contains(event.target))) {
                setCritOptionsOpen(false)
            }
        }
        document.addEventListener("mousedown", handler)
        return () => {
            document.removeEventListener("mousedown", handler)
        }
    })


    useEffect(() => {
        const upVoteIndex = props.upvotes.findIndex((vote) => {
            return vote.id == props.user
        })
        if (upVoteIndex > -1) {
            setUpVoteEngagement([props.upvotes[upVoteIndex]])
        }
        
        const downVoteIndex = props.downvotes.findIndex((vote) => {
            return vote.id == props.user
        })
        if (downVoteIndex > -1) {
            setDownVoteEngagement([props.downvotes[downVoteIndex]])
        }
        
    }, [])    

    //The function below simply returns true if the user is the one who wrote the criticism. 
    //If they are the owner, they will be able to delete the criticism.
    const checkIfOwner = () => {
        if (props.user === props.owner) {
            return true;
        } else {
            return false;
        }
    }

    const reportCriticism = () => {
        setReportCriticismModalState(true)
    }

    const upVoteCriticism = () => {

        if (!props.loggedin) {
            return;
        }

        let timeStamp = moment().utc().toString()
        let occurrence = uuidv4();

        let newEngagement = {
            id: props.user,
            occurrence: occurrence,
            timeStamp: timeStamp
        }

        if (upVoteEngagement.length > 0) {
            setUpTally(prev => prev - 1)
            props.setTotalEngagement(prev => props.removeEngagement([...prev], upVoteEngagement))
            setUpVoteEngagement([])
            setUpVotes(prev => props.removeEngagement([...prev], upVoteEngagement))
            props.reverseAcceptCriticismClaim(props.occurrence, props.figid, props.figType, props.user, props.claimID)
            
        } else if (downVoteEngagement.length > 0) {
            setUpTally(prev => prev + 1)
            setUpVoteEngagement([newEngagement])
            setUpVotes(prev => [...prev, newEngagement])
            setDownTally(prev => prev - 1)
            setDownVoteEngagement([])
            setDownVotes(prev => props.removeEngagement([...prev], downVoteEngagement))
            props.setTotalEngagement(prev => props.removeEngagement([...prev, newEngagement], downVoteEngagement))
            props.reverseRejectCriticismClaim(props.occurrence, props.figid, props.figType, props.user, props.claimID).then(() => {
                props.startAcceptCriticismClaim(props.occurrence, props.figid, props.figType, props.user, props.claimID, timeStamp, occurrence)
            })
        } else {
            setUpTally(prev => prev + 1)
            setUpVoteEngagement([newEngagement])
            setUpVotes(prev => [...prev, newEngagement])
            props.setTotalEngagement(prev => [...prev, newEngagement])
            props.startAcceptCriticismClaim(props.occurrence, props.figid, props.figType, props.user, props.claimID, timeStamp, occurrence)
        }
    }
    const downVoteCriticism = () => {

        if (!props.loggedin) {
            return;
        }

        let timeStamp = moment().utc().toString()
        let occurrence = uuidv4();

        let newEngagement = {
            id: props.user,
            occurrence: occurrence,
            timeStamp: timeStamp
        }

        if (downVoteEngagement.length > 0) {
            setDownTally(prev => prev - 1)
            props.setTotalEngagement(prev => props.removeEngagement([...prev], downVoteEngagement))
            setDownVoteEngagement([])
            setDownVotes(prev => props.removeEngagement([...prev], downVoteEngagement))
            props.reverseRejectCriticismClaim(props.occurrence, props.figid, props.figType, props.user, props.claimID)
        } else if (upVoteEngagement.length > 0) {
            setUpTally(prev => prev - 1)
            setUpVoteEngagement([])
            setUpVotes(prev => props.removeEngagement([...prev], upVoteEngagement))
            setDownTally(prev => prev + 1)
            setDownVoteEngagement([newEngagement])
            setDownVotes(prev => [...prev, newEngagement])
            props.setTotalEngagement(prev => props.removeEngagement([...prev, newEngagement], upVoteEngagement))
            props.reverseAcceptCriticismClaim(props.occurrence, props.figid, props.figType, props.user, props.claimID).then(() => {
                props.startRejectCriticismClaim(props.occurrence, props.figid, props.figType, props.user, props.claimID, timeStamp, occurrence)
            })
        } else {
            setDownTally(prev => prev + 1)
            setDownVoteEngagement([newEngagement])
            setDownVotes(prev => [...prev, newEngagement])
            props.setTotalEngagement(prev => [...prev, newEngagement])
            props.startRejectCriticismClaim(props.occurrence, props.figid, props.figType, props.user, props.claimID, timeStamp, occurrence)
        }
    }

    const criticismOptions = () => {
        if (!props.loggedin) {
            return;
        }

        setCritOptionsOpen(!critOptionsOpen)
    }

    
    return (
        <div hidden={criticismRemoved}>
        <div className="critDivClaim" key={props.text} hidden={criticismRemoved}>
        {deleteModalState && <DeleteModal critIndex={props.critIndex} figType={props.figType} claimID={props.claimID} figIndex={props.figIndex}
        closeModal={setDeleteModalState} element='criticism' criticismRemoved={setCriticismRemoved} sortedIndices={props.critIndices} 
        sortedArray={props.sortedArray} setSortedArray={props.setSortedArray} setSortedIndices={props.setSortedIndices} 
        occurrence={props.occurrence} user={props.user} figid={props.figid} totalEngagement={props.totalEngagement} 
        removeEngagement={props.removeEngagement} setTotalEngagement={props.setTotalEngagement} critEngagement={critEngagement}
        downVotes={downVotes} upVotes={upVotes}/>} 
        {reportCriticismModalState && <ReportCriticismModal id={props.claimID} occurrence={props.occurrence} critText={props.text}
        closeModal={setReportCriticismModalState} user={props.user} criticismOwner={props.owner} elementType={'claim'}/>}
            <div className="critClaim">
                <div className="critDivClaim2">
                    {
                        props.ownerTags.map((tag) => {
                            return (<a key={tag.displayName} className="anchorNameClaim2" href={'/Profile/' + tag.id }>{tag.displayName}</a>)
                        })
                    }
                    <br/>
                    <br/>
                    {props.text}
                    <br/>
                    <div className='criticismTimeStamp'><b>- {localDate}</b></div>
                </div>
                <div className="critButtonsClaim">
                    <Tippy content="Endorse criticism">
                        <div className='critClaimButtonUp' onClick={() => {upVoteCriticism()}}>
                            <svg className = {upVoteEngagement.length > 0 ? "UpClickedClaim" : "UpClaim"} name= {props.text} version="1.1" viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                                <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path className="color000 svgShape" d="m20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" 
                                    fill={upVoteEngagement.length > 0 ? "#FFFFFF" : "rgb(98, 189, 184)"}/>
                                </svg>
                            </svg>
                            {upTally}
                        </div>
                    </Tippy>
                    <Tippy content="Reject criticism">
                        <div className='critClaimButton1' onClick={() => {downVoteCriticism()}}>
                            <svg className={downVoteEngagement.length > 0 ? "DownClickedClaim" : "DownClaim"}
                            name = {props.text} version="1.1" viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                                <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path className="color000 svgShape" d="m24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 
                                    3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" 
                                    fill={downVoteEngagement.length > 0 ? "#FFFFFF" : "rgb(228, 92, 92)"}/>
                                </svg>
                            </svg>
                            {downTally}
                        </div>
                    </Tippy>
                </div>
            </div>
            <div className={props.loggedin ? 'optionsDivClaim' : 'hidden'}>
                <Tippy content="Options">
                    <div className="critClaimButtonDelete" ref={critOptionsRefIcon} onClick={() => criticismOptions()} >
                        <svg className="deleteCritClaimSVG" version="1.1" viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                            <svg width="288" height="288" enableBackground="new 0 0 32.055 32.055" viewBox="0 0 32.055 32.055" xmlns="http://www.w3.org/2000/svg">
                                <path className="color000 svgShape" d="m3.968 12.061c-2.193 0-3.968 1.774-3.968 3.966s1.773 3.967 3.968 3.967c2.189 0 3.966-1.772 
                                3.966-3.967 0-2.192-1.777-3.966-3.966-3.966zm12.265 0c-2.188 0-3.968 1.773-3.968 3.965s1.778 3.967 3.968 3.967 
                                3.97-1.772 3.97-3.967c-2e-3 -2.191-1.78-3.965-3.97-3.965zm11.857 0c-2.192 0-3.969 1.774-3.969 3.967 0 2.19 1.774 3.965 3.969 
                                3.965 2.188 0 3.965-1.772 3.965-3.965s-1.777-3.967-3.965-3.967z" fill="#002c2c"/>
                            </svg>
                        </svg>
                    </div>
                </Tippy>
                <div hidden={!critOptionsOpen} ref={critOptionsRefDiv} className='dropDownClaimCrit'>
                    <div className={checkIfOwner() ? 'optionsAnchorDiv' : 'hidden'} onClick={() => {setDeleteModalState(true)}}>
                        <SVGdeleteCriticism/>
                        <a><b>Delete Criticism</b></a>
                    </div>
                    <div className='optionsAnchorDiv' onClick={reportCriticism}>
                        <svg className='marginSVG' width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 
                            0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z" fill="#002c2c"/>
                        </svg>
                        <a><b>Report this criticism</b></a>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

const mapStateToProps = (state, props) => {
    if (state.profModels.length == 1) {
        if (state.claimModels.length == 0) {
            return {
                engagement: []
            };
        } else {
            return { 
                engagement: state.claimModels[0].engagement
            }
        }
    } else {
        if (state.claimModels.length == 0) {
            return {
                engagement: []
            };
        } else {
            return { 
                engagement: state.claimModels[0].engagement
            }
        }
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    // startDeleteCriticism: (critText, id, imgsrc) => dispatch(startDeleteCriticism(critText, id, imgsrc)),
    startAcceptCriticismClaim: (critOccurrence, FigureID, figType, user, claimID, timeStamp, voteOccurrence) => dispatch(startAcceptCriticismClaim(critOccurrence, FigureID, figType, user, claimID, timeStamp, voteOccurrence)),
    reverseAcceptCriticismClaim: (critOccurrence, FigureID, figType, user, claimID) => dispatch(reverseAcceptCriticismClaim(critOccurrence, FigureID, figType, user, claimID)),
    startRejectCriticismClaim: (critOccurrence, FigureID, figType, user, claimID, timeStamp, voteOccurrence) => dispatch(startRejectCriticismClaim(critOccurrence, FigureID, figType, user, claimID, timeStamp, voteOccurrence)),
    reverseRejectCriticismClaim: (critOccurrence, FigureID, figType, user, claimID) => dispatch(reverseRejectCriticismClaim(critOccurrence, FigureID, figType, user, claimID))
})

const ConnectedCriticism = connect(mapStateToProps, mapDispatchToProps)(CriticismStateless);

export default ConnectedCriticism;
