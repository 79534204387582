import React, {useState, useRef } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from "react-router-dom"
import SearchBarAddClaim from './searchBarAddClaim.js'
import { history } from '../routers/AppRouter'

const AddClaim = (props) => {
    return (
        <div className="claimShow">
            <div hidden={props.loggedIn} className="loginNotice" ><a className="anchor" href="/Login">
                Login or create an account</a> to create new claims.
            </div>
            <div hidden={!props.loggedIn} className='AddClaimMajorContainer2'>
                <h1>Create your claim and connect figures to it</h1>
                <SearchBarAddClaim/>
            </div>
            
        </div>
    )

}

const mapStateToProps = (state, props) => {
    if (state.profModels.length == 1) {
        if (state.claimModels.length == 0) {
            return {
                loggedIn: true,
            };
        } else {
            return { 
                loggedIn: true,
            }
        }
    } else {
        if (state.claimModels.length == 0) {
            return {
                loggedIn: false,
            };
        } else {
            return { 
                loggedIn: false,
            }
        }
    }
}



const ConnectedAddClaim = connect(mapStateToProps, undefined)(AddClaim);

export default ConnectedAddClaim;