import React, { useEffect, useState, useRef } from "react";
import { propTypes } from "react-svg-spinner";
import SVGDelete from './SVGDelete';
import Tippy from '@tippyjs/react'
import DeleteModal from '../modalMyPapers';




function SVGoptionsDotsMyPapers(props) {
    const [deleteModalState, setDeleteModalState] = useState(false)
    const [optionsOpen, setOptionsOpen] = useState(false)
    const [theText, setTheText] = useState()
    let optionsRefDiv = useRef();
    let optionsRefIcon = useRef();
    useEffect(() => {
        let handler = (event) => {
            if(!(optionsRefDiv.current.contains(event.target) || optionsRefIcon.current.contains(event.target))) {
                setOptionsOpen(false)
            }
        }
        document.addEventListener("mousedown", handler)
        return () => {
            document.removeEventListener("mousedown", handler)
        }
    })

    useEffect(() => {
        if (props.whichTabIsIt === 'Identified Papers') {
            setTheText('Delete this Paper')
        } else if (props.whichTabIsIt === 'Followed Papers') {
            setTheText('Unfollow this Paper')
        }
    })

  return (
    <div >
        {deleteModalState && <DeleteModal closeModal={setDeleteModalState} element='paper' id={props.id} whichTabIsIt={props.whichTabIsIt}
        index={props.index} based={props.based} user={props.user} papersListFollowed={props.papersListFollowed} />}
        <Tippy content="Options">
            <div onClick={() => setOptionsOpen(!optionsOpen)} ref={optionsRefIcon}>
                <svg className='input21MyClaims'  version="1.1" viewBox="0 0 288 288" 
                xmlns="http://www.w3.org/2000/svg">
                    <svg width="288" height="288"  viewBox="0 0 32.055 32.055" xmlns="http://www.w3.org/2000/svg">
                        <path  d="m3.968 12.061c-2.193 0-3.968 1.774-3.968 3.966s1.773 3.967 3.968 3.967c2.189 0 3.966-1.772 3.966-3.967 0-2.192-1.777-3.966-3.966-3.966zm12.265 0c-2.188 0-3.968 1.773-3.968 3.965s1.778 3.967 3.968 3.967 3.97-1.772 3.97-3.967c-2e-3 -2.191-1.78-3.965-3.97-3.965zm11.857 0c-2.192 0-3.969 1.774-3.969 3.967 0 2.19 1.774 3.965 3.969 3.965 2.188 0 3.965-1.772 3.965-3.965s-1.777-3.967-3.965-3.967z" 
                    />
                    </svg>
                </svg>
            </div>
        </Tippy>
        <div className="dropDownMyClaimsWrapper" ref={optionsRefDiv} hidden={!optionsOpen} >
            <div className='dropDownMyClaims'>
                
                <div className='optionsAnchorDivMyClaims' onClick={() => setDeleteModalState(true)}>
                    <SVGDelete/>
                    <a><b>{theText}</b></a>
                </div>
                
            </div>
        </div>
    </div>

  );
}

export default SVGoptionsDotsMyPapers;