import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom"
import { connect } from 'react-redux'
import { startDeleteClaim, startRemoveClaimFollow } from '../actions/claimModels';
import { ref, deleteObject } from 'firebase/storage'
import { storage } from '../firebase/firebase'
import { history } from '../routers/AppRouter'
import Spinner from "react-svg-spinner";


function DeleteModal (props) {
    const [message, setTheMessage] = useState('')
    const [message2, setTheMessage2] = useState('')
    const [loaded, setLoaded] = useState(true)

    useEffect(() => {
        if (props.whichTabIsIt === 'Created Claims') {
            setTheMessage('Are you sure you would like to delete this Claim?')
            setTheMessage2('Delete')
        } else if (props.whichTabIsIt === 'Followed Claims') {
            setTheMessage('Are you sure you would like to unfollow this Claim?')
            setTheMessage2('Unfollow')
        } 
    })

    //This action is called can tells the based function to either delete or unfollow the claim.
    const action = async (index) => {
        try {
            //If the button was already clicked, dont do anything
            if (!loaded) {
                return;
            }
            setLoaded(false)
            
            //If we are deleting a claim, first check to see if it needs to be unfollowed, if so, call the unfollow function.
            if (message2 === 'Delete') {
                if (props.claimsListFollowed.includes(props.id)) {
                    await props.startRemoveClaimFollow(props.user, props.id)
                }
                
                //call the startDeleteClaim method from claimModels with the claimID.
                props.startDeleteClaim(props.id).then(() => {
                    props.closeModal(false)
                    //Next remove the element from the array list on the parent state by calling the based method and passing 
                    //in the correct index and claim id.
                    props.based(index, props.id)
                }).catch((err) => {
                    // console.log('Unable to delete the claim')
                })
            }
            
            //If we are just unfollowing a claim, call the startRemoveClaimFollow method from claimModels with the userID and claimID.
            if (message2 === 'Unfollow') {
                props.startRemoveClaimFollow(props.user, props.id).then(() => {
                props.closeModal(false)

                //Next remove the element from the array list on the parent state by calling the based method and passing 
                //in the correct index and claim id.
                props.based(index, props.id)
                }).catch((err) => {
                    // console.log('Unable to delete the claim')
                })
            }
        } catch (err) {
            // console.log('unable to delete/unfollow claim')
        }
    }
    
    return (
        <div>
            <div onClick={() => {props.closeModal(false)}} className="blackBackground"></div>
            <div className='deleteModal'>
                <div className="deleteFlex">
                    <h1>{message2}?</h1>
                    <svg onClick={() => {props.closeModal(false)}} className="deleteX" version="1.1" viewBox="0 0 288 288" 
                    xmlns="http://www.w3.org/2000/svg">
                        <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path className="color000 svgShape" 
                            d="m24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" 
                            fill="#002c2c"/>
                        </svg>
                    </svg>
                </div>
                <div className='withinDelete'>
                    <div>{message}</div>
                </div>
                <div className='deleteButtonMyClaimWrapper'>
                    <button className={loaded ? "deleteButtonMyClaim": 'deleteButtonMyClaimClicked'} onClick={() => {action(props.index)}} >
                        <div hidden={!loaded}>{message2}</div>
                        <Spinner hidden={loaded} />
                    </button>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch, props) => ({
    startDeleteClaim: (id) => dispatch(startDeleteClaim(id)),
    startRemoveClaimFollow: (userID, id) => dispatch(startRemoveClaimFollow(userID, id))
})

const DeleteModalConnected = connect(undefined, mapDispatchToProps)(DeleteModal);


export default DeleteModalConnected