import React, { useEffect, useState } from 'react'
import { createUserWithEmailAndPassword, onAuthStateChanged, signOut, signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../firebase/firebase.js'
import { useNavigate } from "react-router-dom"
import { connect } from 'react-redux'
import Spinner from "react-svg-spinner";

const Login = (props) => {
    const navigate = useNavigate()
    const [loginEmail, setLoginEmail] = useState("")
    const [loginPassword, setLoginPassword] = useState("")
    const [user, setUser] = useState({})
    const [loginClicked, setLoginClicked] = useState(false)
    const [loginError, setLoginError] = useState(false)
    const [loaded, setLoaded] = useState(true)
    const [loggingIn, setLoggingIn] = useState(false)

    useEffect(() => {
        if (props.loggedIn) {
            setLoaded(false)
            navigate('/')
        }
    }, [props.loggedIn])
    
    
    const register = async (e) => {
        e.preventDefault()
        navigate('/Register')
    }

    const forgotPassword = async () => {
        navigate('/PasswordReset')
    }

    const login = async () => {
        setLoaded(false)
        setLoginClicked(true)
        setLoggingIn(true)
        try {
            await signInWithEmailAndPassword(auth, loginEmail, loginPassword)
            navigate('/')
        } catch (error) {
            setLoginError(true)
            setLoggingIn(false)
            setLoaded(true)
            setLoginClicked(false)
        }
    }
    
    return (
        <div>
            <div className="theHeader">
                <div className="head">
                    <div id="imgWrap">
                        <img id="LogoImg" src="/Images/Book2.jpg"/>
                    </div>
                    <br/>
                    <br/>
                    <div className='websiteTitleLogin'>
                        <div>
                            <div id="div3">Outwit</div>
                            <div id="div4">Channel the power of collective minds</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='spacerLogin'></div>
            <div className="login2">
                <h2 className="Login">Log In</h2>
                <div action="/Login"  className='loginFormLogin'>
                    <div className="RegisterSubtitleDiv">
                        <div>
                            <div className='RegisterSubtitle'>Email</div>
                            <input className="email2" type="text" name="email" required placeholder='Enter your Email'
                            onChange={(event) => {setLoginEmail(event.target.value)}}/>
                        </div>
                    </div>
                    <div className="RegisterSubtitleDiv">
                        <div>
                            <div className='RegisterSubtitle'>Password</div>
                            <input className="fullName2" type="password" name="password" required placeholder='Enter your Password'
                            onChange={(event) => {setLoginPassword(event.target.value)}}/>
                        </div>
                    </div>
                    <div hidden={!loginError || loggingIn} className='invalidLogin'>Invalid email/password</div>
                    <div className="RegisterCenterButton">
                        <button className={loaded ? "RegisterButtonLogin" : "RegisterButtonLoginLoading"} onClick={login}>
                            <b className={loginClicked ? 'hidden' : ''}>Log In</b>
                            <div className={loaded ? 'hidden' : 'RegisterSpinnerDiv'}>
                                <Spinner/>
                            </div>
                        </button>
                        

                    </div>
                    
                    <div className='RegisterForgotPassword'>
                        <span onClick={forgotPassword} className='RegisterForgotPasswordSpan'>Forgot Password?</span>
                    </div>
                    <div className='RegisterPadding'>
                        <div className='RegisterLine'></div>
                    </div>
                    
                    <div className='RegisterCenterButton2'>
                        <button className="RegisterButtonSignUp" type="button" onClick={register}><b>Create new account</b></button>
                    </div>
                    
                </div>
                
                
            </div>
        </div>
    )
}

const mapStateToProps = (state, props) => {
    if (state.profModels.length == 1) {
        return { 
            user: state.profModels[0].id,
            userName: state.profModels[0].name,
            loggedIn: true
        }
    } else {
        return {
            user: '',
            userName: '',
            loggedIn: false
        };
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    startSetProfile: (email) => dispatch(startSetProfile(email))
})

const ConnectedLogin = connect(mapStateToProps, mapDispatchToProps)(Login);

export default ConnectedLogin;