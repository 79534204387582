import React, { useEffect, useState, useRef, useCallback } from 'react'
import { connect } from 'react-redux'
import { returnClaimInfoProfile } from '../../actions/claimModels';
import Spinner from "react-svg-spinner";
import SVGoptionsDotsProfileClaims from '../SVGs/SVGoptionsDotsProfileClaims';
import { useNavigate } from 'react-router-dom';

export const ClaimsList = (props) => {
    const [selectorOpen, setSelectorOpen] = useState(false)
    const [sortBySelectorClaim, setSortBySelectorClaim] = useState('None')
    const [claimsList, setClaimsList] = useState([])
    let selectorRef = useRef();
    const [loaded, setLoaded] = useState(false)
    const navigate = useNavigate()
    //This useEffect will take in the list of claimIDs that was passed in and loop through it to 
    //fetch the data from each.
    useEffect(async () => {
        if (!props.loaded) {
            setLoaded(false)
            return;
        }
        try {
            let theClaimsList = []
            for (const claim of props.createdClaims) {
                let claimInfo = await props.returnClaimInfoProfile(claim.id)
                //If the claim is unavailable from Firebase or if it is anonymous and it is not a user viewing their own profile,
                //dont add the claim to the claimsList.
                if (claimInfo.unavailable || (claimInfo.anonymous && props.user != props.fellowID)) {

                } else {
                    theClaimsList.push(claimInfo)
                }
            }
            await theClaimsList.all
            setClaimsList(theClaimsList)
            if (props.loaded) {
                setLoaded(true)
            }
        } catch (e) {
            // console.log(e)
        }

    }, [props.whichTabIsIt, props.loaded])

    useEffect(() => {
        let handler = (event) => {
            if(!selectorRef.current.contains(event.target)) {
                setSelectorOpen(false)
            }
        }
        document.addEventListener("mousedown", handler)
        return () => {
            document.removeEventListener("mousedown", handler)
        }
    })

    const sortClaimsByConfidence = () => {
        //if Confidence is already selected, don't do anything.
        if (sortBySelectorClaim === 'Confidence') {
            setSelectorOpen(false)
            return;
        }
        
        //combine all data into array of objects for easy sorting.
        let allArrayData = [...claimsList]

        //sort allArrayData on the basis of Confidence
        allArrayData.sort((a,b) => {
            if (a.confidence !== '?' && b.confidence !== '?') {
                return b.confidence - a.confidence; 
            } else if (a.confidence == '?') {
                return 1;
            } else if (b.confidence == '?') {
                return -1;
            }
        })
        
        setClaimsList(allArrayData)
        setSelectorOpen(false)
        setSortBySelectorClaim('Confidence')
    }

    const sortClaimsByOldest = (tab) => {
        //if Oldest is already selected, don't do anything.
        if (sortBySelectorClaim === 'Oldest') {
            setSelectorOpen(false)
            return;
        }

        //combine all data into array of objects for easy sorting.
        let allArrayData = [...claimsList]
        
        //sort allArrayData on the basis of Engagement
        allArrayData.sort((a,b) => {
            if (a.moment.isBefore(b.moment)) {
                return -1; 
            } else {
                return 1;
            }
        })

        setClaimsList(allArrayData)
        setSelectorOpen(false)
        setSortBySelectorClaim('Oldest')
    }

    const sortClaimsByNewest = (tab) => {
        //if Oldest is already selected, don't do anything.
        if (sortBySelectorClaim === 'Newest') {
            setSelectorOpen(false)
            return;
        }

        //combine all data into array of objects for easy sorting.
        let allArrayData = [...claimsList]
        
        //sort allArrayData on the basis of Engagement
        allArrayData.sort((a,b) => {
            if (a.moment.isBefore(b.moment)) {
                return 1; 
            } else {
                return -1;
            }
        })

        setClaimsList(allArrayData)
        setSelectorOpen(false)
        setSortBySelectorClaim('Newest')
    }

    const sortClaimsByEngagement = (tab) => {
        //if Oldest is already selected, don't do anything.
        if (sortBySelectorClaim === 'Engagement') {
            setSelectorOpen(false)
            return;
        }

        //combine all data into array of objects for easy sorting.
        let allArrayData = [...claimsList]
        
        //sort allArrayData on the basis of Engagement
        allArrayData.sort((a,b) => b.engagementNumber - a.engagementNumber)

        setClaimsList(allArrayData)
        setSelectorOpen(false)
        setSortBySelectorClaim('Engagement')
    }

    function removeSubstring(originalString, stringToRemove) {
        let split = originalString.split('/')
        let theURL = '/' + split[3] + '/' + split[4]
        return theURL;
    }

    function handleClick(event) {
        event.preventDefault();
        let URL = removeSubstring(event.target.href, event.target.baseURI)
        navigate(URL)
    }

    return (  
        <div className={props.whichTabIsIt == 'Claims' ? 'myClaimsWrapperContainer' : 'hidden'}>
            <div className="myClaimsClaimContainer">
                <div className='addClaimButtonMyClaims'>
                    <h1 className='myHeadersMyClaims'>Created Claims</h1>
                </div>  
                <div className={claimsList.length != 0 ? 'sortByDivMyClaimWrapper' : 'hidden'}>
                    <div className='slightSpacerMyClaim'>Sort by:</div>
                    <div className='sortByDivProfile'>
                        <span className='minimumWidthMyClaims'>{sortBySelectorClaim}</span>
                        <span className='tagsSpanMyClaims'>
                            <svg onClick={() => {setSelectorOpen(!selectorOpen)}} id="tagSVG" xmlns="http://www.w3.org/2000/svg" width="18" 
                            height="18" viewBox="0 0 24 24">
                                <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/>
                            </svg>
                        </span>
                        <div className='seletorsDivMyClaims' hidden={!selectorOpen} ref={selectorRef}>
                            <div className='selectorOptionsMyClaims' onClick={() => {sortClaimsByConfidence()}}>Confidence</div>
                            <div className='selectorOptionsMyClaims' onClick={() => {sortClaimsByEngagement()}}>Engagement</div>
                            <div className='selectorOptionsMyClaims' onClick={() => {sortClaimsByOldest()}}>Oldest</div>
                            <div className='selectorOptionsMyClaims' onClick={() => {sortClaimsByNewest()}}>Newest</div>
                        </div>
                    </div>
                </div>
                <div>
                    {
                        claimsList.map((claim, index) => {
                            return (
                                <div key={index + claim.id} className='userClaimWrapperMyClaims'>
                                    <div className='userClaimMyClaims'>
                                        <div className='myClaimsAnchorSpan'>
                                            <a className="ProfileFontBold" href={"/Claim/" + claim.id} onClick={handleClick}>{claim.title}</a>
                                            <div className='timeStampDivMyClaims'>- {claim.timeStamp}</div>
                                        </div>
                                        <div className='claimStatsWidgetMyClaims'>
                                            <div className='confidenceLoader'>  <Spinner hidden={loaded} /></div>
                                            <div hidden={!loaded}>{claim.confidence}%  | {claim.engagementNumber}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className={loaded ? 'hidden' : 'ProfileComponentsSpinnerDiv'}>
                    <Spinner />
                </div>
                <div className={props.whichTabIsIt === 'Claims' && claimsList.length == 0 ? 'noClaimsToShow' : 'hidden'}>
                    {props.fellowName == props.userName ? 'You have ' : props.fellowName + ' has '}not created any claims yet.
                </div>
            </div>
        </div>
    ) 
}

const mapStateToProps = (state, props) => {
    if (state.profModels.length == 1) {
        return {
            user: state.profModels[0].id,
            userName: state.profModels[0].name,
            tags: state.profModels[0].tags,
            loggedIn: true,
        };
    } else {
        return {
            user: '',
            userName: '',
            tags: [],
            loggedIn: false
        };
    }
}

const mapDispatchToProps = (dispatch, profileProps) => ({
    returnClaimInfoProfile: (claim) => dispatch(returnClaimInfoProfile(claim))
})

const ConnectedClaimsList = connect(mapStateToProps, mapDispatchToProps)(ClaimsList);

export default ConnectedClaimsList;