const theoriesReducerDefaultState = [];

export default (state = theoriesReducerDefaultState, action) => {
  switch (action.type) {
    // case 'ADD_CLAIM':
    //   return [
    //     ...state,
    //     action.claim
    //   ];
    // case 'REMOVE_CLAIM':
    //   return state.filter(({ title }) => title !== action.title);
    // case 'EDIT_CLAIM':
    //   return state.map((claim) => {
    //     if (claim.id === action.id) {
    //       return {
    //         ...claim,
    //         ...action.updates
    //       };
    //     } else {
    //       return claim;
    //     };
    //   });
    case 'SET_THEORY':
      return [
        action.theory
      ];
    default:
      return state;
  }
};