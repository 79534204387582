import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom"
import { connect } from 'react-redux'
import { startDeletePaper, startRemovePaperFollow } from '../actions/paperModels';
import { ref, deleteObject } from 'firebase/storage'
import { storage } from '../firebase/firebase'
import { history } from '../routers/AppRouter'
import Spinner from "react-svg-spinner";


function DeleteModal (props) {

    const [loaded, setLoaded] = useState(true)
    function matchIdInObjects(arrayOfObjects, targetId) {
        for (let obj of arrayOfObjects) {
            if (obj.id === targetId) {
                return true;
            }
        }
        return false;
    }

    function removeObjectAtIndex(arrayOfObjects, index) {
        if (index >= 0 && index < arrayOfObjects.length) {
            arrayOfObjects.splice(index, 1);
        }
        return arrayOfObjects;
    }

    //This action is called can tells the based function to either delete or unfollow the paper.
    const action = async (index) => {
        try {
            //If the button was already clicked, dont do anything
            if (!loaded) {
                return;
            }
            setLoaded(false)
            //If we are deleting a paper, first check to see if it is in the followedPapers list
            //If it is in the list, remove the user's ID from it.
            if (matchIdInObjects(props.papersFollowsList, props.id)) {
                await props.startRemovePaperFollow(props.user, props.id)
            }
            
            //Lastly, call the startDeletePaper method from figModels with the paperID.
            props.startDeletePaper(props.id, props.user).then(() => {
                props.closeModal(false)
                //Next remove the element from the array list on the parent state by calling the based method and passing 
                //in the correct index and paper id.
                let newPapersList = removeObjectAtIndex([...props.papersList], props.index)
                props.setPapersList(newPapersList)
            }).catch((err) => {
                // console.log('Unable to delete the paper')
            })
        } catch(err) {
            // console.log('error deleting paper')
        } 
    }
    
    return (
        <div>
            <div onClick={() => {props.closeModal(false)}} className="blackBackground"></div>
            <div className='deleteModal'>
                <div className="deleteFlex">
                    <h1>Delete?</h1>
                    <svg onClick={() => {props.closeModal(false)}} className="deleteX" version="1.1" viewBox="0 0 288 288" 
                    xmlns="http://www.w3.org/2000/svg">
                        <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path className="color000 svgShape" 
                            d="m24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" 
                            fill="#002c2c"/>
                        </svg>
                    </svg>
                </div>
                <div className='withinDelete'>
                    <div>Are you sure you would like to delete this Paper?</div>
                </div>
                <div className='deleteButtonMyClaimWrapper'>
                    <button className={loaded ? "deleteButtonMyFigure": 'deleteButtonMyFigureClicked'} onClick={() => {action(props.index)}} >
                        <div hidden={!loaded}>Delete</div>
                        <Spinner hidden={loaded} />
                    </button>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch, props) => ({
    startDeletePaper: (id, theOwner) => dispatch(startDeletePaper(id, theOwner)),
    startRemovePaperFollow: (user, paperID) => dispatch(startRemovePaperFollow(user, paperID))
})

const DeleteModalConnected = connect(undefined, mapDispatchToProps)(DeleteModal);


export default DeleteModalConnected