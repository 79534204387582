import React, { useState } from "react";
import { startDeleteCriticism } from '../actions/figModels';
import { connect } from 'react-redux'
import Spinner from "react-svg-spinner"

//This component is a delete model for deleting criticisms on a figure.
function DeleteModal (props) {
    const [loading, setLoading] = useState(true)
    const deleteCriticism = () => {
        setLoading(false)
        props.startDeleteCriticism(props.occurrence, props.id, props.imgsrc, props.user).then(() => {

            //Gather all occurrences of critUpVotes and critDownVotes in one array:
            let allCritVoteEngagements = props.upVotes.concat(props.downVotes)

            //Remove them from total Engagement.
            let newTotalEngagement = props.removeEngagement(props.totalEngagement, allCritVoteEngagements)

            //Using the occurrence of the criticism find the correct index in the engagement array for the engagement that you need to delete.
            let crticismEngagement = newTotalEngagement.find(engagement => engagement.occurrence == props.occurrence)
            let arrayCriticismEngagement = [crticismEngagement]

            //Remove this specific engagement from the total engagements array.
            newTotalEngagement = props.removeEngagement(newTotalEngagement, arrayCriticismEngagement)

            //Lastly set the total engagement using the states that was passed in.
            props.setTotalEngagement(newTotalEngagement)

            //Create a new criticisms array by removing the criticism from the array that needs to be deleted.
            let newCriticisms = props.criticisms
            newCriticisms.splice(props.index, 1)

            //Use the states that are that was passed in to set up the new criticisms array and then close the modle.
            props.setTheCriticisms(newCriticisms)
            props.closeModal(false)
        })
    }
    return (
        <div>
            <div onClick={() => {props.closeModal(false)}} className="blackBackground"></div>
            <div className='deleteModal'>
                <div className="deleteFlex">
                    <h1>Delete?</h1>
                    <svg onClick={() => {props.closeModal(false)}} className="deleteX" version="1.1" viewBox="0 0 288 288" 
                    xmlns="http://www.w3.org/2000/svg">
                        <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path className="color000 svgShape" d="m24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" 
                            fill="#002c2c"/>
                        </svg>
                    </svg>
                </div>
                <div className='withinDelete'>
                    <div>Are you sure you would like to delete this {props.element}?</div>
                </div>
                <div className='deleteButtonClaimWrapper'>
                    <button className={loading ? "deleteButtonClaim": 'deleteButtonClaimClicked'} onClick={() => {deleteCriticism()}} >
                        <div hidden={!loading}>Delete</div>
                        <Spinner hidden={loading} />
                    </button>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch, props) => ({
    startDeleteCriticism: (occurrence, id, imgsrc, user) => dispatch(startDeleteCriticism(occurrence, id, imgsrc, user))
})

const DeleteModalConnected = connect(undefined, mapDispatchToProps)(DeleteModal);


export default DeleteModalConnected