import React from 'react'
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import AppRouter, { history } from './routers/AppRouter'
import 'normalize.css/normalize.css'
import './styles/styles.scss'
import { auth } from './firebase/firebase.js'
import configureStore from './store/configureStore'
import "regenerator-runtime/runtime.js"
import { createUserWithEmailAndPassword, onAuthStateChanged, signOut, signInWithEmailAndPassword } from 'firebase/auth'
import { startSetProfile } from './actions/profModels';

const store = configureStore()

//get the history
//Call the correct dispatch based off of the URL
//Get data off of firebase
//set the state
//render based off of the state

let URLpath = history.location.pathname
let split = URLpath.split('/')
let id;
if (split.length == 3) {
    id = split[2]
}
// console.log(URLpath)
// console.log('the URLpath is ' + URLpath)
let userID;
let theCheck = 0

//The redux state setter function takes the address from the URL.
//It uses the address to find out which type of page is being visited and the ID of that page.
//From there, it sets the correct redux state for that page.

// console.log('app.js running')

// const reduxStateSetter = (pageType, theID) => {
//     // console.log('how many times this gets run?')
//     if (split.length == 3) {
//         id = split[2]
//     }

//     if (split.length == 2) {
//         ReactDOM.render(<div>
//             <div className='loadingScreen'>
//                 Journal Club
//             </div>
//             <div className='loadingBarParent'>
//                 <div className='block1'></div>
//                 <div className='block2'></div>
//                 <div className='block3'></div>
//                 <div className='block4'></div>
//                 <div className='block5'></div>
//                 <div className='block6'></div>
//                 <div className='block7'></div>
//             </div>
//         </div>, appRoot)
//         ReactDOM.render(jsx, appRoot)
//         // console.log(store.getState())
//     }
//     if (split[1] == 'Figure') {
//         ReactDOM.render(<div>
//             <div className='loadingScreen'>
//                 Journal Club
//             </div>
//             <div className='loadingBarParent'>
//                 <div className='block1'></div>
//                 <div className='block2'></div>
//                 <div className='block3'></div>
//                 <div className='block4'></div>
//                 <div className='block5'></div>
//                 <div className='block6'></div>
//                 <div className='block7'></div>
//             </div>
//         </div>, appRoot)
//         store.dispatch(startSetFigure(id)).then(() => {
//             ReactDOM.render(jsx, appRoot)
//             // console.log(store.getState())
//             // console.log('App Render')
//             // console.log(theCheck)
//         }).catch(() => {
//             console.log('startSetFigure not working from app.js')
//             // console.log(store.getState())
//             ReactDOM.render(jsx, appRoot)
//         })
//     } else if (split[1] == 'Claim') {
//         ReactDOM.render(<div>
//             <div className='loadingScreen'>
//                 Journal Club
//             </div>
//             <div className='loadingBarParent'>
//                 <div className='block1'></div>
//                 <div className='block2'></div>
//                 <div className='block3'></div>
//                 <div className='block4'></div>
//                 <div className='block5'></div>
//                 <div className='block6'></div>
//                 <div className='block7'></div>
//             </div>
//         </div>, appRoot)
//         // console.log('worked?')
//         store.dispatch(startSetClaim(id)).then(() => {
//             ReactDOM.render(jsx, appRoot)
//             // console.log('App render')
//             // console.log(theCheck)
//             // console.log(store.getState())
//         }).catch(() => {
//             console.log('startSetClaim not working from app.js')
//             ReactDOM.render(jsx, appRoot)
//         })
//     } else if (split[1] == 'ConnectClaim') {
//         ReactDOM.render(<div>
//             <div className='loadingScreen'>
//                 Journal Club
//             </div>
//             <div className='loadingBarParent'>
//                 <div className='block1'></div>
//                 <div className='block2'></div>
//                 <div className='block3'></div>
//                 <div className='block4'></div>
//                 <div className='block5'></div>
//                 <div className='block6'></div>
//                 <div className='block7'></div>
//             </div>
//         </div>, appRoot)
//         store.dispatch(startSetClaim(id)).then(() => {
//             ReactDOM.render(jsx, appRoot)
//             // console.log('App render')
//             // console.log(theCheck)
//             // console.log(store.getState())
//         }).catch(() => {
//             console.log('startSetClaim not working from app.js')
//             ReactDOM.render(jsx, appRoot)
//         })
//     } else if (split[1] == 'myClaims') {
//         ReactDOM.render(jsx, appRoot)
//     } else if (split[1] == 'myFigures') {
//         ReactDOM.render(jsx, appRoot)
//     } else if (split[1] == 'Paper') {
//         ReactDOM.render(<div>
//             <div className='loadingScreen'>
//                 Journal Club
//             </div>
//             <div className='loadingBarParent'>
//                 <div className='block1'></div>
//                 <div className='block2'></div>
//                 <div className='block3'></div>
//                 <div className='block4'></div>
//                 <div className='block5'></div>
//                 <div className='block6'></div>
//                 <div className='block7'></div>
//             </div>
//         </div>, appRoot)
//         // console.log('worked?')
//         store.dispatch(startSetPaper(id)).then(() => {
//             ReactDOM.render(jsx, appRoot)
//             // console.log(store.getState())
//         }).catch(() => {
//             console.log('startSetPaper not working from app.js')
//             ReactDOM.render(jsx, appRoot)
//         })
//     } else if (split[1] == 'AddTheory') {
//         ReactDOM.render(<div>
//             <div className='loadingScreen'>
//                 Journal Club
//             </div>
//             <div className='loadingBarParent'>
//                 <div className='block1'></div>
//                 <div className='block2'></div>
//                 <div className='block3'></div>
//                 <div className='block4'></div>
//                 <div className='block5'></div>
//                 <div className='block6'></div>
//                 <div className='block7'></div>
//             </div>
//         </div>, appRoot)
//         // console.log('worked?')
//         store.dispatch(startSetTheory(id)).then(() => {
//             ReactDOM.render(jsx, appRoot)
//         }).catch(() => {
//             console.log('startSetTheory not working from app.js')
//             ReactDOM.render(jsx, appRoot)
//         })
//     } else if (split[1] == 'Theory') {
//         ReactDOM.render(<div>
//             <div className='loadingScreen'>
//                 Journal Club
//             </div>
//             <div className='loadingBarParent'>
//                 <div className='block1'></div>
//                 <div className='block2'></div>
//                 <div className='block3'></div>
//                 <div className='block4'></div>
//                 <div className='block5'></div>
//                 <div className='block6'></div>
//                 <div className='block7'></div>
//             </div>
//         </div>, appRoot)
//         // console.log('worked?')
//         store.dispatch(startSetTheory(id)).then(() => {
//             ReactDOM.render(jsx, appRoot)
//             // console.log(store.getState())
//         }).catch(() => {
//             console.log('startSetAddTheory not working from app.js')
//             ReactDOM.render(jsx, appRoot)
//         })
//     }
//     else {
//         ReactDOM.render(jsx, appRoot)
//     }
// }

//If there is a user currently logged in, have redux set the profile state.
onAuthStateChanged(auth, (currentUser) => {
    
    if (currentUser) {
        
        // console.log('auth state changed with user (from app.js)')
        userID = currentUser.uid
        store.dispatch(startSetProfile(userID)).then(() => {
            ReactDOM.render(jsx, appRoot)
            // console.log(userID)
            // console.log(split[1])
            // console.log(id)
            // console.log('auth state change fired')
        }).catch(() => {
            // console.log('error: profile not set')
        })
    } else {
        ReactDOM.render(jsx, appRoot)
        // console.log('auth state change fired no current user (from app.js)')
    }
    
})

const jsx = (
    <Provider store={store}>
        <AppRouter />
    </Provider>
)
const appRoot = document.getElementById('app')
ReactDOM.render(
    <div>
        <div className='loadingScreen'>
            Outwit
        </div>
        <div className='loadingBarParent'>
            <div className='block1'></div>
            <div className='block2'></div>
            <div className='block3'></div>
            <div className='block4'></div>
            <div className='block5'></div>
            <div className='block6'></div>
            <div className='block7'></div>
        </div>
    </div>, appRoot)







