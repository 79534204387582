import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom"
import { connect } from 'react-redux'
import { startDeleteFigure, reverseAcceptFigure, reverseRejectFigure } from '../actions/figModels';
import { ref, deleteObject } from 'firebase/storage'
import { storage } from '../firebase/firebase'
import { history } from '../routers/AppRouter'
import Spinner from "react-svg-spinner";
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

function DeleteModal (props) {
    const [loaded, setLoaded] = useState(true)

    function matchIdInObjects(arrayOfObjects, targetId) {
        for (let obj of arrayOfObjects) {
            if (obj.id === targetId) {
                return true;
            }
        }
        return false;
    }

    function removeObjectAtIndex(arrayOfObjects, index) {
        if (index >= 0 && index < arrayOfObjects.length) {
            arrayOfObjects.splice(index, 1);
        }
        return arrayOfObjects;
    }
    

    //This action is called can tells the based function to either delete, un-accept, or un-reject the figure.
    const action = async (index) => {
        try {
            //If the button was already clicked, dont do anything
            if (!loaded) {
                return;
            }
            setLoaded(false)
            let timeStamp = moment().utc().toString()
            let occurrence = uuidv4();
            //If we are deleting a figure, first check to see if it is in the accepted figures list or rejected figures list
            //If it is in one of these lists, call either the reverseAcceptFigure or reverseRejectFigure method.
            //We do this mainly so that it gets removed from the user's list on their profile in Firebase
            const vote = {
                figureID: props.id,
                user: props.user,
                timeStamp: timeStamp,
                occurrence: occurrence
            }
            //If in the figuresListAccepted, update Firebase data by removing it from user's accepted figures list
            if (matchIdInObjects(props.figuresListAccepted, props.id)) {
                await props.reverseAcceptFigure(vote)
            }
            //If in the figuresListRejected, update Firebase data by removing it from user's rejected figures list
            if (matchIdInObjects(props.figuresListRejected, props.id)) {
                await props.reverseRejectFigure(vote)
            }
            //Lastly, call the startDeleteFigure method from figModels with the figureID.
            props.startDeleteFigure(props.id, props.user).then(() => {
                props.closeModal(false)
                //Remove the figure from the figuresList and update the state.
                let newFiguresList = removeObjectAtIndex([...props.figuresList], props.index)
                props.setFiguresList(newFiguresList)
            }).catch((err) => {
                // console.log('Unable to delete the claim')
            })
        } catch(err) {
            // console.log('error deleting figure')
        } 
    }
    
    return (
        <div>
            <div onClick={() => {props.closeModal(false)}} className="blackBackground"></div>
            <div className='deleteModal'>
                <div className="deleteFlex">
                    <h1>Delete?</h1>
                    <svg onClick={() => {props.closeModal(false)}} className="deleteX" version="1.1" viewBox="0 0 288 288" 
                    xmlns="http://www.w3.org/2000/svg">
                        <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path className="color000 svgShape" 
                            d="m24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" 
                            fill="#002c2c"/>
                        </svg>
                    </svg>
                </div>
                <div className='withinDelete'>
                    <div>Are you sure you would like to delete this Figure?</div>
                </div>
                <div className='deleteButtonMyClaimWrapper'>
                    <button className={loaded ? "deleteButtonMyFigure": 'deleteButtonMyFigureClicked'} onClick={() => {action(props.index)}} >
                        <div hidden={!loaded}>Delete</div>
                        <Spinner hidden={loaded} />
                    </button>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch, props) => ({
    startDeleteFigure: (id, theOwner) => dispatch(startDeleteFigure(id, theOwner)),
    reverseAcceptFigure: (vote) => dispatch(reverseAcceptFigure(vote)),
    reverseRejectFigure: (vote) => dispatch(reverseRejectFigure(vote))
})

const DeleteModalConnected = connect(undefined, mapDispatchToProps)(DeleteModal);


export default DeleteModalConnected