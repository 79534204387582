import React, { useEffect, useState, useRef } from 'react';
import Tippy from '@tippyjs/react'
import { connect } from 'react-redux'
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../firebase/firebase'
import { getImgsrc } from '../actions/figModels'
import { createClaim } from '../actions/claimModels';
import { startIdentifyPaper, searchForPaper, getPaperDois } from '../actions/paperModels';
import { useNavigate } from "react-router-dom"
import ImgModal from './ConnectClaimImgModal'
import Spinner from "react-svg-spinner";
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid';


export const SearchBarAddClaim = (props) => {
    const navigate = useNavigate()
    const [title, setTitle] = useState()
    const [filteredData, setFilteredData] = useState([]);
    const allFigures = useRef([])
    const allPapers = useRef()
    const [chosenContra, setChosenContra] = useState([])
    const [chosenSupport, setChosenSupport] = useState([])
    const [unchecked, setUnchecked] = useState(false)
    const [tagsOpen, setTagsOpen] = useState(false)
    const [needToAdd, setNeedToAdd] = useState(false)
    const [needToExplain, setNeedToExplain] = useState(false)
    const [listOfTextRefsSupport, setListOfTextRefsSupport] = useState([])
    const [listOfTextRefsContradict, setListOfTextRefsContradict] = useState([])
    const [extractedClaim, setExtractedClaim] = useState(false)
    const [paperFound, setPaperFound] = useState(true)
    const [loaded, setLoaded] = useState(true)
    const [loaded2, setLoaded2] = useState(true)
    const [paperTitle, setPaperTitle] = useState('')
    const [paperJournal, setPaperJournal] = useState('')
    const [thePaperID, setThePaperID] = useState('')

    let doiRef = useRef();
    let exactTextRef = useRef();
    let paperTitleRef = useRef();
    let paperJournalRef = useRef();
    let initialSupports = [];
    let initialContras = []; 
    const [alreadyContradicting, setAlreadyContradict] = useState(initialContras)
    const [alreadySupporting, setAlreadySupport] = useState(initialSupports)

    //This useEffect grabs an array of paperIDs and paperDois so that they can be searched on the allPapers ref
    //It also grabs an array of all figures with their data so that they can be searched on the allFigures ref
    useEffect( async () => {
        let papers = await props.getPaperDois()
        allPapers.current = papers
        getDocs(collection(db, "Figures")).then((docs) => {
            let Figures = [];
            docs.forEach((doc) => {
                Figures.push({
                    ...doc.data(),
                    id: doc.id
                })
            }) 
            allFigures.current = Figures
        })
    }, [])

    
    const upOneSupport = (figureID, title) => {
        let supportsList = alreadySupporting.concat(initialSupports);
        let contrasList = alreadyContradicting.concat(initialContras);
        let figure;

        if (!supportsList.includes(figureID)) {
            getImgsrc(figureID).then((object) => {
                figure = {
                    title: title,
                    imgsrc: object.imgsrc,
                    ID: figureID,
                    description: object.description
                }
                const holder1 = chosenSupport.concat([figure])
                const holder2 = alreadySupporting.concat([figureID])
                setChosenSupport(holder1)
                setAlreadySupport(holder2)
                
            }).catch(() => {
                figure = {
                    title: title,
                    ID: figureID
                }
                const holder1 = chosenSupport.concat([figure])
                const holder2 = alreadySupporting.concat([figureID])
                setChosenSupport(holder1)
                setAlreadySupport(holder2)
            })
        }
        const currentTextRefs = [...listOfTextRefsContradict]
        if (contrasList.includes(figureID)) {
            const holder1 = chosenContra.filter((figure, index) => {
                if (figure.ID == figureID) {
                    currentTextRefs.splice(index, 1)
                }
                return figure.ID != figureID;
            })
            const holder2 = alreadyContradicting.filter((figure) => {
                return figure != figureID;
            })
            setChosenContra(holder1)
            setAlreadyContradict(holder2)
            setListOfTextRefsContradict(currentTextRefs)
        }
    }
    const upOne = (figureID, title) => {
        let supportsList = alreadySupporting.concat(initialSupports);
        let contrasList = alreadyContradicting.concat(initialContras);
        let figure;
        if (!contrasList.includes(figureID)) {
            getImgsrc(figureID).then((object) => {
                figure = {
                    title: title,
                    imgsrc: object.imgsrc,
                    ID: figureID,
                    description: object.description
                }
                const holder1 = chosenContra.concat([figure])
                const holder2 = alreadyContradicting.concat([figureID])
                setChosenContra(holder1)
                setAlreadyContradict(holder2)
            }).catch(() => {
                figure = {
                    title: title,
                    ID: figureID
                }
                const holder1 = chosenContra.concat([figure])
                const holder2 = alreadyContradicting.concat([figureID])
                setChosenContra(holder1)
                setAlreadyContradict(holder2)
            })
        }
        const currentTextRefs = [...listOfTextRefsSupport]
        if (supportsList.includes(figureID)) {
            const holder1 = chosenSupport.filter((figure, index) => {
                if (figure.ID == figureID) {
                    currentTextRefs.splice(index, 1)
                }
                return figure.ID != figureID;
            })
            const holder2 = alreadySupporting.filter((figure) => {
                return figure != figureID;
            })
            setChosenSupport(holder1)
            setAlreadySupport(holder2)
            setListOfTextRefsSupport(currentTextRefs)
        }
    }

    const searching = (event) => {
        const search = event.target.value.toLowerCase()
        if (search.length == 0) {
            setFilteredData([])
        }
        if ((search.indexOf(' ') < 0) && (search.length != 0)) {
            let compile = allFigures.current
            let searched = compile.filter((doc) => {
                return (doc.title.toLowerCase().indexOf(search) >= 0)
            })
            setFilteredData(searched)
        } 
    }


    //This function is run when the SVGs buttons are rendered and simply checks if the initialContras list already posseses the figure in question.
    //If it does, it sets the value of the conttradict tippy to let the user know.
    const checkItContraTippy = (figureID) => {
        if (initialContras.includes(figureID)) {
            return 'This is already a contradicting figure.'
        } else if (alreadyContradicting.includes(figureID)) {
            return 'Already Selected'
        } 
        return 'Select Figure'
    }

    const checkItContraSVG = (figureID) => {
        if (initialContras.includes(figureID)) {
            return 'inputConnectCon'
        } else if (alreadyContradicting.includes(figureID)) {
            return 'inputConnectCon'
        } 
        return 'inputConnectConSelected'
    }

    const checkItContraPath = (figureID) => {
        if (initialContras.includes(figureID)) {
            return "rgb(228, 92, 92)"
        } else if (alreadyContradicting.includes(figureID)) {
            return "#fff"
        } 
        return 'rgb(228, 92, 92)'
    }

    const checkItSupportTippy = (figureID) => {
        if (initialSupports.includes(figureID)) {
            return 'This is already a supporting figure.'
        } else if (alreadySupporting.includes(figureID)) {
            return 'Already Selected'
        } 
        return 'Select Figure'
    }

    const checkItSupportSVG = (figureID) => {
        if (initialSupports.includes(figureID)) {
            return 'inputConnectSup'
        } else if (alreadySupporting.includes(figureID)) {
            return 'inputConnectSup'
        } 
        return 'inputConnectSupSelected'
    }
    const checkItSupportPath = (figureID) => {
        if (initialSupports.includes(figureID)) {
            return "rgb(98, 189, 184)"
        } else if (alreadySupporting.includes(figureID)) {
            return "#fff"
        } 
        return 'rgb(98, 189, 184)'
    }
    const createClaim = async (e) => {
        //contradictingFigures is all the figures listed to add, get it from the state.
        e.preventDefault()
        let contradictingFigures = chosenContra; 
        let supportingFigures = chosenSupport;
        let timeStamp = moment().utc().toString()
        let everyFigureHasExplanation = true;
        if (supportingFigures.length == 0 && contradictingFigures.length == 0) {
            setNeedToAdd(true)
            setNeedToExplain(false)
            return;
        } else {
            setNeedToAdd(false)
        }

        let supportingExplanations = []
        listOfTextRefsSupport.forEach((ref) => {
            supportingExplanations.push(ref.current.value)
            if (ref.current.value === '') {
                everyFigureHasExplanation = false
            }
        })

        let contradictingExplanations = []
        listOfTextRefsContradict.forEach((ref) => {
            contradictingExplanations.push(ref.current.value)
            if (ref.current.value === '') {
                everyFigureHasExplanation = false
            }
        })

        if (!everyFigureHasExplanation) {
            setNeedToAdd(false)
            setNeedToExplain(true)
            return;
        } else {
            setNeedToExplain(false)
        }


        let values = []
        for (let i = 0; i < e.target.elements.length; i++) {
            if (e.target.elements[i].checked) {
                let ownerTag = {
                    displayName: e.target.elements[i].name,
                    id: e.target.elements[i].id
                }
                if (e.target.elements[i].name !== 'fromPaper') {
                    values.push(ownerTag)
                }
            }
        }
        if (values.length == 0) {
            setUnchecked(true)
            return;
        } else {
            setUnchecked(false)
        }

        let paperID = '';
        let occurrence = uuidv4();
        setLoaded2(false)

        //First, if the claim is extracted from a paper that hasn't been identified on JournalClub yet, that paper needs to be added
        //and the paperID set accordingly.
        try {
            if (extractedClaim && !paperFound) {
                const paperInfo = {
                    title: paperTitleRef.current.value.trim(),
                    doi: doiRef.current.value.trim(),
                    journal: paperJournalRef.current.value.trim(),
                    owner: props.user,
                    ownerTags: values,
                    timeStamp: timeStamp,
                    figures: [],
                    follows: [],
                    extractedClaims: [],
                    updates: {
                        claimExtractions: [],
                        comments: [],
                        figureUploads: [],
                        follows: [],
                        identified: [
                            {
                                timeStamp: timeStamp,
                                occurrence: occurrence,
                                id: props.user
                            }
                        ]
                    }
                }
                paperID = await props.startIdentifyPaper(paperInfo, props.user)
            }

            //If the claim is extracted from a paper which was found, set the correct paperID from the state.
            if (extractedClaim && paperFound) {
                paperID = thePaperID
            }

            //Create the claim to be passed into the createClaim function.
            let claimInfo = {
                supportingFigures: supportingFigures,
                contradictingFigures: contradictingFigures,
                user: props.user,
                values: values,
                title: title,
                timeStamp: timeStamp,
                supportingExplanations: supportingExplanations,
                contradictingExplanations: contradictingExplanations,
                extractedClaim: false,
                exactText: '',
                paperID: paperID,
                paperDoi: '',
                updates: {
                    comments: [],
                    figureConnections: [],
                    theoryAdditions: [],
                    engagement: [],
                    follows: [],
                    created: [
                        {
                            timeStamp: timeStamp,
                            occurrence: occurrence,
                            id: props.user
                        }
                    ]
                }
            }
        
            //If it is a claim extracted from a paper, fill out the four values at the bottom.
            //If it is not extracted from a paper, don't change the values.
            if (extractedClaim) {
                claimInfo.extractedClaim = true
                claimInfo.exactText = exactTextRef.current.value
                claimInfo.paperDoi = doiRef.current.value.trim()
            }

            //Create the claim in firebase and then navigate to the claim page.
            let claimID = await props.createClaim(claimInfo)
            let claimURL = '/Claim/' + claimID
            // window.location.href = claimURL
            navigate(claimURL)

        } catch (error) {
            console.log(error)
        }
        
    }

    const setTheTitle = (e) => {
        
        setTitle(e.target.value)
    }

    const extractedClaimBox = () => {
        setExtractedClaim(!extractedClaim)
        setPaperTitle('')
        setPaperJournal('')
        setPaperFound(true)
        doiRef.current.value = ''
        exactTextRef.current.value = ''
        paperTitleRef.current.value = ''
        paperJournalRef.current.value = ''
    }

    const searchForPaper = async () => {
        setLoaded(false)
        //When the doi is pasted into the field, search Firebase to see if it has already been identified. If it has, gather its data.
        let doi = doiRef.current.value.trim()
        let paperInfo = await props.searchForPaper(doi, allPapers.current)
        //If the paper has already been identified, autopopulate 
        if (!paperInfo && doi !== '') {
            setPaperFound(false)
            setPaperTitle('')
            setPaperJournal('')
            setThePaperID('')
        } else {
            setPaperFound(true)
            setPaperTitle(paperInfo.title)
            setPaperJournal(paperInfo.journal)
            setThePaperID(paperInfo.id)
        }
        setLoaded(true)
    }

    return (
        <form className='theSpan2' onSubmit={createClaim}>
            <div >
                <div className='AddClaimTitleDiv' >
                    <h2 className='AddClaimTitleHeader'>Claim Title</h2> 
                </div>
                <br/>
                <div>
                    <textarea name="title" className="AddClaimTitle" placeholder="Write the title of your claim here..."  required
                    onChange={(e) => {setTheTitle(e)}}>
                    </textarea>
                </div>
                <div className='extractedDivAddClaim'>
                    <input status='userName' className="checkBox1Claim" type='checkbox' id='fromPaper' name='fromPaper' 
                    value='fromPaper' onClick={() => {extractedClaimBox()}}/>
                    <span>This is a claim that was made in a paper</span>
                </div>
                <div hidden={!extractedClaim}>
                    <br/>
                    <br/>
                    <div className='AddClaimTitleDiv' >
                        <h2 className='AddClaimTitleHeader'>Exact Text </h2> 
                    </div>
                    <br/>
                    <div>
                        <textarea name="exactText" placeholder="Please paste the exact text of the claim as written in the paper"
                          required={extractedClaim} className="AddClaimTitle" ref={exactTextRef}>
                        </textarea>
                    </div>
                    <br/>
                    <br/>
                    <div className='AddClaimTitleDiv' >
                        <h2 className='AddClaimTitleHeader'>Paper Source <Spinner hidden={loaded} /></h2> 
                    </div>
                    <br/>
                    <div>
                        <textarea name="doi" placeholder="Please paste the full doi link of the paper where this claim was made..."
                            required={extractedClaim} onChange={(e) => {searchForPaper(e)}} className="AddClaimTitle" ref={doiRef}>
                        </textarea>
                    </div>
                </div>  
                <div hidden={paperFound}>
                    <br/>
                    <br/>
                    <span hidden={paperFound}>This paper has not been identified in Journal Club yet, please add the following info:</span>
                    <br/>
                    <br/>
                    <div className='AddClaimTitleDiv' >
                        <h2 className='AddClaimTitleHeader'>Paper Title</h2> 
                    </div>
                    <br/>
                    <div>
                        <textarea name="paperTitle" placeholder="Paste the title of the paper where this claim is extracted from..."
                        required={extractedClaim && !paperFound} className="AddClaimTitle" onChange={(e) => {setPaperTitle(e)}} ref={paperTitleRef}>
                        </textarea>
                    </div>
                    <br/>
                    <br/>
                    <div className='AddClaimTitleDiv' >
                        <h2 className='AddClaimTitleHeader'>Journal</h2> 
                    </div>
                    <br/>
                    <div>
                        <textarea name="paperJournal" placeholder="Paste the journal where the paper was published..."
                        required={extractedClaim && !paperFound} className="AddClaimTitle" onChange={(e) => {setPaperJournal(e)}} 
                        ref={paperJournalRef}>
                        </textarea>
                    </div>
                </div>
            </div>
            <br/>
            <div hidden={chosenSupport.length == 0} className='supportingFigures'><b>Supporting Figures:</b></div>
            <br/>
            <div className={chosenSupport.length > 0 ? 'figuresToConnect' : 'hidden'}>
                {(chosenSupport.length > 0) && chosenSupport.map((figure) => {
                    return ( <ImgModal key={figure.ID} imgsrc={figure.imgsrc} title={figure.title} ID={figure.ID} chosenContra={chosenSupport}
                        setAlreadyContradict={setAlreadySupport} alreadyContradicting={alreadySupporting} setChosenContra={setChosenSupport}
                        setListOfTextRefs={setListOfTextRefsSupport} listOfTextRefs={listOfTextRefsSupport} direction={'supports'}/> )
                })}
            </div>
            <div hidden={chosenContra.length == 0} className={chosenSupport.length <= 0 ? 'contradictingFigures' : 'contradictingFigures2'}>
                <b>Contradicting Figures:</b>
            </div>
            <br/>
            <div className={chosenContra.length > 0 ? 'figuresToConnect' : 'hidden'}>
                    {(chosenContra.length > 0) && chosenContra.map((figure) => {
                        return ( <ImgModal key={figure.ID} imgsrc={figure.imgsrc} title={figure.title} ID={figure.ID} chosenContra={chosenContra}
                            setAlreadyContradict={setAlreadyContradict} alreadyContradicting={alreadyContradicting} 
                            setChosenContra={setChosenContra}
                            listOfTextRefs={listOfTextRefsContradict} setListOfTextRefs={setListOfTextRefsContradict} direction={'contradicts'}/> )
                    })}
            </div>
            
            
            <div className='flexBelow'>
                <div className="flexConnectClaim">
                    
                    <div className='red2'>Find a figure: </div>
                    <div className="inputAndSearch">
                        <input onChange={searching} id="search-text3"  type="text" placeholder="search here..."/>
                        {filteredData.length != 0 && (
                            <div className="beingSearched2">{
                                filteredData.map((doc) => {
                                    return (<div className="searchBarDivs" key={doc.id}>
                                                <div className='searchWidth'>Figure - <a className="blueFont" href={'/Figure/' + doc.id}>{doc.title}</a>
                                                </div>
                                                <div className="pickFigures">
                                                    <Tippy content ={checkItSupportTippy(doc.id)}>
                                                        <svg version="1.1" id={checkItSupportSVG(doc.id)}
                                                        viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg" 
                                                        onClick={() => {upOneSupport(doc.id, doc.title)}}>
                                                            <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                <path className="color000 svgShape" d="m24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" 
                                                                fill={checkItSupportPath(doc.id)}/>
                                                            </svg>
                                                        </svg>
                                                    </Tippy>
                                                    <Tippy content ={checkItContraTippy(doc.id)}>
                                                        <svg onClick={() => {upOne(doc.id, doc.title)}} 
                                                        id={checkItContraSVG(doc.id)} version="1.1" 
                                                        viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                                                            <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                <path className="color000 svgShape" d="m24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" 
                                                                fill={checkItContraPath(doc.id)}/>
                                                            </svg>
                                                        </svg>
                                                    </Tippy>
                                                </div>
                                            </div>)
                                })
                            }</div>)}
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="centered">
                    <div id="postAs">
                        <span className="postAsSpan">Post as:  </span>
                        <div className='tags'>
                            <span className='firstCheck'>
                                <div className="checkBoxDivFirst">
                                    <div className='FigureDiv1'>
                                        <input status='userName' className="checkBox1" type='checkbox' id={props.user}
                                        name={props.userName} value={props.userName}/>
                                        {props.userName}
                                    </div>
                                    <span className='tagsSpan'>
                                        <Tippy content='Post Tags'>
                                            <svg transform={tagsOpen ? "rotate(180)" : ""} id="tagSVG" onClick={() => setTagsOpen(!tagsOpen)} 
                                            xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                                                <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/>
                                            </svg>
                                        </Tippy>
                                    </span>
                                </div>
                                <div className={tagsOpen ? "postAsDiv" : "hidden"}>
                                    <div>
                                        {
                                            props.tags.map((tag, index) => {
                                                return (
                                                    <div key={tag.displayName + '1'} className="checkBoxDivAddClaim">
                                                        <input status='tag' key={tag.displayName} name={tag.displayName} id={tag.id} 
                                                        type='checkbox' className="checkBox" />
                                                        <label key={tag.displayName + '2'} className="label">{tag.displayName}</label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="redFontFigure" hidden={!unchecked} >You must check at least one box!</div> 
            </div>
            <h2 hidden={!needToAdd} className="redTextConnectClaim">Add at least one supporting or contradicting figure to connect</h2>
            <h2 hidden={!needToExplain} className="redTextConnectClaim">Each figure needs an explanation why it supports or contradicts your claim</h2>
            <label className='connectFiguresButtonLabel'>
                <input hidden={true} type="submit"/>
                <div className={loaded2 ? 'connectFiguresButton' : 'connectFiguresButtonClicked'}>
                    <b hidden={!loaded2}>Create Claim</b>
                    <Spinner hidden={loaded2} />
                </div>
            </label>
            
        </form>
    )
} 

const mapStateToProps = (state, props) => {
    if (state.profModels.length == 1) {
        return { 
            user: state.profModels[0].id,
            userName: state.profModels[0].name,
            tags: state.profModels[0].tags,
            loggedIn: true
        }
    } else {
        return {
            user: '',
            userName: '',
            tags: [],
            loggedIn: false
        };
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    createClaim: (claimInfo) => dispatch(createClaim(claimInfo)),
    searchForPaper: (paperDoi, allPapers) => dispatch(searchForPaper(paperDoi, allPapers)),
    startIdentifyPaper: (paperInfo, theOwner) => dispatch(startIdentifyPaper(paperInfo, theOwner)),
    getPaperDois: () => dispatch(getPaperDois())
})
const ConnectedSearchBarAddClaim = connect(mapStateToProps, mapDispatchToProps)(SearchBarAddClaim);

export default ConnectedSearchBarAddClaim;
