import React, { Component, useState, useRef, useMemo, useEffect } from 'react'
import MyComponent from './ContentEditableDiv'
import { connect } from 'react-redux'
import { storage } from '../firebase/firebase'
import { ref, uploadBytesResumable } from 'firebase/storage'
import { useNavigate } from "react-router-dom"
import { history } from '../routers/AppRouter'

import { startAddTheory, startSetTheory, startSaveTheory, startPublishTheory, startSaveTitleTheory,
 returnTheoryInfoHomeUpdate} from '../actions/theoryModels'
import ClaimReference from './ClaimReference'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import { ClaimReferenceDiv } from './ClaimRefDiv'
import SearchBarTheory from './searchBarTheory.js'
import SVGDelete from './SVGs/SVGDelete';
import SVGOptionsDots from './SVGs/SVGoptionsDotsTheories';
import DeleteModal from './deleteModalAddTheory';
import LoadingScreen from './loadingScreen';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import Spinner from "react-svg-spinner";


export const AddTheory = (props) => {
    const [unchecked, setUnchecked] = useState(false)
    const [tagsOpen, setTagsOpen] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [theText, setTheText] = useState([])
    const [percentConfidence, setThePercentConfidence] = useState(0)
    const [optionsOpen, setOptionsOpen] = useState(false)
    const [deleteModalState, setDeleteModalState] = useState(false)
    const [loaded, setLoaded] = useState(true)
    const [loaded2, setLoaded2] = useState(true)
    let optionsRefDiv = useRef();
    let optionsRefIcon = useRef();
    const [totalEngagement, setTotalEngagement] = useState([])
    const [title, setTitle] = useState('')
    const [owner, setOwner] = useState('')
    const [ownerTags, setOwnerTags] = useState([])
    const [notDeleted, setNotDeleted] = useState(false)
    const [claimsList, setClaimsList] = useState([])
    const [published, setPublished] = useState(true)
    const [comprehensiveness, setComprehensiveness] = useState({five: [], four: [], three: [], two: [], one: []})
    const [timeStampPageVisit, setTimeStampPageVisit] = useState('')
    const [percentLoaded, setPercentLoaded] = useState(false)
    const [theoryID, setTheoryID] = useState('')
    const [localDate, setLocalDate] = useState('')
    const [firstLoad, setFirstLoad] = useState(false)





    const titleRef = useRef(title)

    useEffect(() => {
        titleRef.current.value = title
    }, [title])
    let navigate = useNavigate()


    useEffect( async () => {
        //Get the id of the theory from the URL
        let theSplit = window.location.pathname.split('/')
        let theoryID = theSplit[2]
        //Make call to Firebase with returnFigureInfoUpdates function to fetch info off of database.
        let theoryInfo = await props.returnTheoryInfoHomeUpdate(theoryID)
        //If info is able to be fetched, update all relevant state values.
        setTheoryID(theoryID)
        if (theoryInfo.published) {
            navigate(`/Theory/${theoryID}`)
        }

        if (theoryInfo) {
            const theDate = new Date(theoryInfo.timeStamp)
            const newMoment = moment(theDate)
            newMoment.local()
            const theLocalDate = newMoment.format('LLL')
            //Here we record the time that the info of this figure was pulled off of Firebase. This will
            //be used as the time the user last viewed this figure. We call the appropriate function:
            let timeStamp = moment().utc().toString()
            setTimeStampPageVisit(timeStamp)
            setPublished(true)
            setComprehensiveness(theoryInfo.comprehensiveness)
            setClaimsList(theoryInfo.claimsList)
            setTotalEngagement(theoryInfo.engagement)
            setTheText(theoryInfo.claimsList)
            setTitle(theoryInfo.title)
            setLocalDate(theLocalDate)
            setOwnerTags(theoryInfo.ownerTags)
            setOwner(theoryInfo.owner)
            setNotDeleted(true)
            setFirstLoad(true)
            setPercentLoaded(true)
        } else {
            //If the figure is not available on Firebase, it is probably because it was deleted.
            //If it was deleted, nothing should be shown.

            //Also set to false if there are no updates to be shown for this claim so that it does
            //not show up.
            setNotDeleted(false)
        }

        
    }, [])

    useEffect(() => {
        let handler = (event) => {
            if(!(optionsRefDiv.current.contains(event.target) || optionsRefIcon.current.contains(event.target))) {
                setOptionsOpen(false)
            }
        }
        //Hello
        document.addEventListener("mousedown", handler)
        return () => {
            document.removeEventListener("mousedown", handler)
        }
    })
    
    const textAreaRef = useRef()
    const newEngagementArray = () => {
        let engagementHolderArray = [...totalEngagement]
        if (!(engagementHolderArray.includes(props.user))) {
            engagementHolderArray.push(props.user)
            return engagementHolderArray;
        }
        setTotalEngagement(engagementHolderArray)
        return engagementHolderArray;
    }
    function getCaretIndex(element) {
        let position = 0;
        const isSupported = typeof window.getSelection !== "undefined";
        if (isSupported) {
          const selection = window.getSelection();
          if (selection.rangeCount !== 0) {
            const range = window.getSelection().getRangeAt(0);
            const preCaretRange = range.cloneRange();
            preCaretRange.selectNodeContents(element);
            preCaretRange.setEnd(range.endContainer, range.endOffset);
            position = preCaretRange.toString().length;
          }
        }
        // console.log(position)
        if (position == 0) {
            return 'Error'
        }
        return position;
    }

   

    function updateTextState() {
        let editableDiv = document.querySelector('#editableDivMax');
        if (!editableDiv) {
            return;
        }
        let all = Array.from(editableDiv.childNodes);
        let everything = [...theText];
        let i = 0;
        //Remember that claim references will always be an odd index, so no need to check even indexes.
        
        while (i < all.length) {
            if (all[i].innerText != everything[i] && (i % 2 == 0)) {
                everything[i] = all[i].innerText
            }
            i += 1;
        }

        // all.forEach((node) => {
        //     if (node.innerText != undefined && node.nodeName !== 'SPAN') {
        //         everything.push(node.innerText)
        //     }
        //     if (node.nodeName === 'SPAN') {
        //         everything.push({})
        //     }
        // })
        return everything;  
    }

    function getChunkLengthsArray() {
        let editableDiv = document.querySelector('#editableDivMax');
        if (!editableDiv) {
            return;
        }
        let all = Array.from(editableDiv.childNodes);
        let everything = [];
        all.forEach((node) => {
            if (node.innerText != undefined && node.nodeName !== 'SPAN') {
                everything.push(node.innerText.length)
            }
            if (node.nodeName === 'SPAN') {
                everything.push(node.textContent.length)
            }
        })
        return everything;  
    }

    function getCaretPosition(editableDiv) {
        var caretPos = 0,
          sel, range;
        if (window.getSelection) {
          sel = window.getSelection();
          if (sel.rangeCount) {
            range = sel.getRangeAt(0);
            if (range.commonAncestorContainer.parentNode == editableDiv) {
              caretPos = range.endOffset;
            }
          }
        } 
        return caretPos;
    }


    const findCaretChunkAndIndex = () => {
        // Get the index of where the caret is.
        let fullCaretIndex = getCaretIndex(textAreaRef.current)
        let chunks = updateTextState()
        let arrayLengths = getChunkLengthsArray()

        // // Step 1: If there is no selection within the theory text area, dont do anything and end the funciton.
        if (fullCaretIndex === "Error") {
            return;
        }

        // Step 2: Iterate through each chunk and sum their collective indexes.
        // Find which "chunk" the caret is within
        // Subtract the aggregatedIndex from the fullCaretIndex to get the index within that particular chunk.

        let aggregatedIndex = 0;
        let chunkIndex;
        let i = 0;

        while (i < chunks.length) {
            aggregatedIndex += arrayLengths[i]
            if (aggregatedIndex >= fullCaretIndex) {
                aggregatedIndex -= arrayLengths[i]
                chunkIndex = i
                i = chunks.length
            }
            i += 1;
        }
        //In the event that the caret was in a claimRef, simply return null so that nothing happens.
        let indexWithinChunk = fullCaretIndex - aggregatedIndex;
        if (chunkIndex % 2 == 1 && indexWithinChunk == arrayLengths[chunkIndex]) {
            chunkIndex += 1
            indexWithinChunk = 0
        }
        let data = {
            chunks: chunks,
            chunkIndex: chunkIndex,
            indexWithinChunk: indexWithinChunk,
            fullCaretIndex: fullCaretIndex
        }
        return data;
    }

    const addClaimReference = (theClaimToAdd) => {
        //Make sure that inserting a claimReference will update the state, but no firestore until the save button is pressed.
        let data = findCaretChunkAndIndex()
        if (!data) {
            return;
        }
        if (typeof data.chunkIndex === 'undefined') {
            return;
        }
        let claimReference = {
            title: theClaimToAdd.title,
            id: theClaimToAdd.id,
            notImportant: [],
            somewhatImportant: [],
            veryImportant: []
        }
        if (data == null && theText[0] == '') {
            let newState = ['', claimReference, '']
            setTheText(newState)
            return;
        }        
        if (data.chunkIndex == (data.chunks.length-2) && (data.chunks[data.chunkIndex + 1] === '')) {
            data.chunks[data.chunkIndex + 1] = ''
            data.chunks.push(claimReference)
            data.chunks.push('')
        } else if (data.chunkIndex % 2 == 1) {
            
            let part1 = ''
            let part2 = data.chunks[data.chunkIndex + 1]
            let addition = claimReference;
            data.chunks[data.chunkIndex + 1] = part1
            data.chunks.splice(data.chunkIndex + 2, 0, addition, part2);
        } else {
            let part1 = data.chunks[data.chunkIndex].substring(0, data.indexWithinChunk)
            let part2 = data.chunks[data.chunkIndex].substring(data.indexWithinChunk, data.chunks[data.chunkIndex].length)
            let addition = claimReference;
            data.chunks[data.chunkIndex] = part1
            data.chunks.splice(data.chunkIndex + 1, 0, addition, part2);
        }
        setTheText(data.chunks)
    }

    const removeClaimReference = (index) => {
        //Make sure that inserting a claimReference will update the state, but no firestore until the save button is pressed.
        let data = updateTextState()
        if (!data) {
            return;
        }
        data[index-1] = data[index-1] + data[index+1]
        data.splice(index, 2)
        setTheText(data)
    }



    function makeTextNode() {
        return document.createTextNode('​') // <-- there a zero-width space between quotes
    }

    let specific = false
    function placeCaretInPreviousSpan() {
        let data = findCaretChunkAndIndex()
        const range = document.createRange()

        let editableDiv = document.querySelector('#editableDivMax');
        let all = Array.from(editableDiv.childNodes);

        const span = all[data.chunkIndex-1]
        if (span.childNodes.length === 0) {
          span.appendChild(makeTextNode()) 
           // <-- you have to have something in span in order to place caret inside
        }
        range.setStart(span, 1) // <-- offset by 1 to be inside SPAN element and not before it
        let selection = window.getSelection()
        range.collapse(true)
        selection.removeAllRanges()
        selection.addRange(range)
        specific = true
        span.focus()
    }

    const preventDragHandler = (e) => {
        e.preventDefault();
    }

    

    const publishTheory = async (e) => {
        e.preventDefault()
        let values = []
        for (let i = 0; i < e.target.elements.length; i++) {
            if (e.target.elements[i].checked) {
                let ownerTag = {
                    displayName: e.target.elements[i].name,
                    id: e.target.elements[i].id
                }
                values.push(ownerTag)
            }
        }
        if (values.length == 0) {
            setUnchecked(true)
            return;
        } 
        let updatedText = updateTextState()
        setSubmitted(true)
        let timeStamp = moment().utc().toString()
        let occurrence = uuidv4();
        const theoryData = {
            title: e.target.title.value,
            owner: props.user,
            ownerTags: values,
            claimsList: updatedText,
            theoryID: theoryID,
            occurrence: occurrence,
            timeStamp: timeStamp
        }
        
        await props.startPublishTheory(theoryData).then((doc) => {
            navigate('/Theory/' + doc)
        }).catch(() => {
            // console.log('unable to publish theory')
        })
    }

    const saveTheory = async () => {
        setLoaded2(false)
        let updatedText = updateTextState()
        try {
            await props.startSaveTheory(updatedText, theoryID)
            setLoaded2(true)
        } catch (error) {
            console.error(error)
        }

    }

    const saveTitle = async () => {
        setLoaded(false)
        let updatedTitle = titleRef.current.value
        try {
            await props.startSaveTitleTheory(updatedTitle, theoryID)
            setLoaded(true)
        } catch (error) {
            console.error(error)
        }
    }
    return (
        
        <form className="AddFigureForm" onSubmit={publishTheory}>
            {deleteModalState && <DeleteModal id={theoryID} closeModal={setDeleteModalState} element='claim' owner={owner} user={props.user}/>}
            <div hidden={props.loggedIn} className="addFigureLogin" ><a className="anchor" onClick={() => {navigate("/Login")}}>
                Login or create an account</a> to write your own theories
            </div>
            <div hidden={props.user == owner || !firstLoad} className="addFigureLogin" >
                Only the author of this theory can view and edit it before publication.
            </div>
            <div hidden={!props.loggedIn || props.user != owner} className={firstLoad && notDeleted ? '' : 'hidden'}>
                <div className="containerAddTheory">
                    <div className='flexTitleAndOptionsAddTheory'>
                        <h1>Write a new theory!</h1>
                        <div className='centerOptionsAddTheory'>
                            <div className='optionsClaim' onClick={() => setOptionsOpen(!optionsOpen)} ref={optionsRefIcon}>
                                <SVGOptionsDots/>
                            </div>
                            <div className="dropDownTheoryWrapper" ref={optionsRefDiv} hidden={!optionsOpen} >
                                <div className='dropDownAddTheory'>
                                    <div className={props.user == owner ? 'optionsAnchorDiv' : 'hidden'} onClick={() => setDeleteModalState(true)} 
                                    hidden={props.user != owner} 
                                    href={props.image}>
                                        <SVGDelete/>
                                        <a><b>Delete this Theory</b></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <h2>Title</h2> 
                    <br/>
                    <div>
                        <textarea ref={titleRef} name="title" className="halfTextAreaTheory" placeholder="Give your theory a title here..." required>
                        </textarea>
                    </div>
                    <div className="saveTheoryButtonDiv">
                        <button className={loaded ? 'saveTheoryButton' : 'saveTheoryButtonClicked'} type="button" onClick={() => saveTitle()}>
                            <div className={loaded ? '' : 'hidden'}>Save</div>
                            <div className={loaded ? 'hidden' : 'centerDiv'}>
                                <Spinner />
                            </div>
                        </button>
                    </div>   
                </div>
                <div className="containerAddTheory2">
                    <h2>Theory</h2>
                    <br/>
                    <div draggable="false" id="editableDivMax" className='halfTextAreaTheory' ref={textAreaRef}>
                        {theText.map((theElement, index) => {
                                if (typeof theElement == "string") {
                                    return (<ClaimReferenceDiv theText={theText} setTheText={setTheText} index={index} 
                                        onDragStart={preventDragHandler} theElement={theElement} draggable="false" className='theoryTextDiv' 
                                        contentEditable={true} key={index + 'div'} suppressContentEditableWarning={true} 
                                        newEngagementArray={newEngagementArray} setTotalEngagement={setTotalEngagement}
                                    />)
                                } else {
                                    return (<ClaimReference title={theElement.title} key={index} footnote={(index + 1) / 2}
                                        user={props.user} placeCaretInPreviousSpan={placeCaretInPreviousSpan} 
                                        claimInfo={theText[index]} theoryID={theoryID} index={index}
                                        totalEngagement={totalEngagement} removeClaimReference={removeClaimReference}
                                        setTotalEngagement={setTotalEngagement} newEngagementArray={newEngagementArray} 
                                        published={published}
                                        />)
                                }
                            })
                        }
                    </div>
                    <div className="saveTheoryButtonDiv">
                        <button className={loaded2 ? 'saveTheoryButton' : 'saveTheoryButtonClicked'} type="button" onClick={() => saveTheory()}>
                            <div className={loaded2 ? '' : 'hidden'}>Save</div>
                            <div className={loaded2 ? 'hidden' : 'centerDiv'}>
                                <Spinner />
                            </div>
                        </button>
                    </div>
                    <SearchBarTheory addClaimReference={addClaimReference}/>
                </div>
                <div className="container">
                    <div className="centered">
                        <div id="postAsSmall">
                            <span className="postAsSpan">Post as:  </span>
                            <div className='tags'>
                                <span className='firstCheck'>
                                    <div className="checkBoxDivFirst">
                                        <div className='FigureDiv1'>
                                            <input status='userName' className="checkBox1" type='checkbox' id={props.user + '/' + theoryID}
                                            name={props.userName} value={props.userName}/>
                                            {props.userName}
                                        </div>
                                        <span className='tagsSpan'>
                                            <Tippy content='Post Tags'>
                                                <svg transform={tagsOpen ? "rotate(180)" : ""} id="tagSVG" 
                                                onClick={() => setTagsOpen(!tagsOpen)} xmlns="http://www.w3.org/2000/svg" width="18" 
                                                height="18" viewBox="0 0 24 24">
                                                    <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/>
                                                </svg>
                                            </Tippy>
                                        </span>
                                    </div>
                                    <div className={tagsOpen ? "postAsDiv" : "hidden"}>
                                        <div>
                                            {
                                                props.tags.map((tag, index) => {
                                                    return (
                                                        <div key={tag.displayName + index + '1'} className="checkBoxDiv">
                                                            <input status='tag' key={tag.displayName + index} 
                                                            name={tag.displayName + '/' + tag.id} 
                                                            id={tag.id + '/' + theoryID} type='checkbox' className="checkBox" />
                                                            <label key={tag.displayName + '2'} className="label">{tag.displayName}</label>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        </div>
                    <div className='centerDiv'>
                        <div className="redFontFigure3" hidden={!unchecked} >You must check at least one box!</div> 
                    </div>
                </div>
                <div className="centerButtonDiv"><button id="SubmitNewFigureButton"><b>Publish New Theory</b></button></div>
            </div>
            <br/>
            <br/>
        </form>
    )
}

const mapStateToProps = (state, props) => {
    if (state.profModels.length == 1) {
        return {
            user: state.profModels[0].id,
            userName: state.profModels[0].name,
            tags: state.profModels[0].tags,
            loggedIn: true,
        };
    } else {
        return {
            user: '',
            userName: '',
            tags: [],
            loggedIn: false,
        };
    }
}

const mapDispatchToProps = (dispatch) => ({
    startAddTheory: (TheoryData) => dispatch(startAddTheory(TheoryData)),
    startSetTheory: (id) => dispatch(startSetTheory(id)),
    startSaveTheory: (updatedClaimsList, theoryID) => dispatch(startSaveTheory(updatedClaimsList, theoryID)),
    startPublishTheory: (theoryData) => dispatch(startPublishTheory(theoryData)),
    startSaveTitleTheory: (updatedTitle, theoryID) => dispatch(startSaveTitleTheory(updatedTitle, theoryID)),
    returnTheoryInfoHomeUpdate: (theoryID) => dispatch(returnTheoryInfoHomeUpdate(theoryID))
})

const ConnectedAddTheory = connect(mapStateToProps, mapDispatchToProps)(AddTheory);

export default ConnectedAddTheory;