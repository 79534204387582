import React, { useState, useEffect, useRef } from 'react';
import Tippy from '@tippyjs/react'
import { connect } from 'react-redux'
import DeleteModal from './deleteModalClaimCriticism';
import SVGaddCriticism from './SVGs/SVGaddCriticism';
import SVGremoveConnection from './SVGs/SVGremoveConnection';
// import { startDeleteCriticismClaim, startAcceptCriticismClaim, reverseAcceptCriticismClaim, 
//     startRejectCriticismClaim, reverseRejectCriticismClaim, startAddCriticismClaim } from '../actions/claimModels';
import { startAcceptCriticismClaim, reverseAcceptCriticismClaim, reverseRejectCriticismClaim,
    startRejectCriticismClaim } from '../actions/claimModels';
import SVGdeleteCriticism from './SVGs/SVGDelete';

export const ClaimReferenceDiv = (props) => {
    
    const preventDragHandler = (e) => {
        e.preventDefault();
    }
    

    const theRef = useRef()
   

    // useEffect(() => {

    //     //For some reason, in AddTheory maps the claimReferences while passing in "undefined" as all values apparently.
    //     //I break the useEffect here to prevent it from throwing an error and crashing the page. Othwerwise, this serves no purpose.
    //     let currentSpanText = theRef.current.innerText
    //     if (props.index == null || currentSpanText == props.theElement) {
    //         return;
    //     }
        
    //     let currentText = [...props.theText]
    //     currentText[props.index] = currentSpanText
    //     props.setTheText(currentText)
    //     console.log(props.index + ' span is updating')
    // }, [props.updater])

    return (
        <div onDragStart={preventDragHandler} draggable="false" className='theoryTextDiv' contentEditable={!props.published}
         key={props.index} suppressContentEditableWarning={true} ref={theRef}
        >{props.theElement}</div>
    )
}

const mapStateToProps = (state, props) => {
    
}

const mapDispatchToProps = (dispatch, props) => ({
    
})

const ConnectedClaimReferenceDiv = connect(undefined, undefined)(ClaimReferenceDiv);

export default ConnectedClaimReferenceDiv;
