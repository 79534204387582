import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from "react-router-dom"
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import SVGoptionsDotsMyFigures from './SVGs/SVGoptionsDotsMyFigures';
import { returnFigureInfoMyFigures } from '../actions/figModels'
import Spinner from "react-svg-spinner";
import moment from 'moment'

export const myFigures = (props) => {
    const [whichTabIsIt, setWhichTabIsIt] = useState('Uploaded Figures')
    const [acceptancePercentage, setAcceptancePercentage] = useState([])
    const [figureTitles, setFigureTitles] = useState([])
    const [figureEngagements, setFigureEngagements] = useState([])
    const [figureMoments, setFigureMoments] = useState([])
    const [figureTimeStamps, setFigureTimeStamps] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [figuresListUploaded, setFiguresListUploaded] = useState(props.myFigures.uploadedFigures)
    const [figuresListAccepted, setFiguresListAccepted] = useState(props.myFigures.acceptedFigures)
    const [figuresListRejected, setFiguresListRejected] = useState(props.myFigures.rejectedFigures)
    const [figuresListCurrent, setFiguresListCurrent] = useState(props.myFigures.uploadedFigures)
    const [sortBySelector, setSortBySelector] = useState('None')
    const [selectorOpen, setSelectorOpen] = useState(false)
    const [redo, setRedo] = useState(false)
    let selectorRef = useRef();
    const navigate = useNavigate()


    let loaded2;
    if (props.myFigures != []) {
        loaded2 = false
    } else {
        loaded2 = true
    }

    const whichTab = (tab) => {
        if (loaded != true) {
            return;
        }
        setLoaded(false)
        if (tab === 'Uploaded Figures') {
            setFiguresListCurrent(figuresListUploaded)
            setSortBySelector('None')
        } else if (tab === 'Accepted Figures') {
            setFiguresListCurrent(figuresListAccepted)
            setSortBySelector('None')
        } else if (tab === 'Rejected Figures') {
            setFiguresListCurrent(figuresListRejected)
            setSortBySelector('None')
        }
        setWhichTabIsIt(tab)
    }

    const based = (index, id) => {
        let newArray1 = [...figuresListCurrent]
        let newArray2 = [...acceptancePercentage]
        let newArray3 = [...figureTitles]
        let newArray4 = [...figureEngagements]
        let newArray5 = [...figureMoments]
        let newArray6 = [...figureTimeStamps]

        newArray1.splice(index, 1)
        newArray2.splice(index, 1)
        newArray3.splice(index, 1)
        newArray4.splice(index, 1)
        newArray5.splice(index, 1)
        newArray6.splice(index, 1)

        //If we are in the Uploaded Figures tab, this method will delete the figure when called.
        if (whichTabIsIt === 'Uploaded Figures') {
            let newArray7 = [...figuresListUploaded]
            let nextList = newArray7.filter((figure) => {
                return figure.id !== id
            })
            setFiguresListUploaded(nextList)
            //Also check to see if the figure is in the AcceptedFigures or RejectedFigures and remove if it is.
            if (figuresListAccepted.includes(id)) {
                let newArray = figuresListAccepted.filter((figure) => {
                    return figure.id !== id;
                })
                setFiguresListAccepted(newArray)
            }
            if (figuresListRejected.includes(id)) {
                let newArray = figuresListRejected.filter((figure) => {
                    return figure.id !== id;
                })
                setFiguresListRejected(newArray)
            }
        }

        //If we are in the Accepted Figures tab, this method will un-accept the figure when called.
        if (whichTabIsIt === 'Accepted Figures') {
            let newArray7 = [...figuresListAccepted]
            let nextList = newArray7.filter((figure) => {
                return figure.id !== id
            })
            setFiguresListAccepted(nextList)
        }

        //If we are in the Accepted Figures tab, this method will un-accept the figure when called.
        if (whichTabIsIt === 'Rejected Figures') {
            let newArray7 = [...figuresListRejected]
            let nextList = newArray7.filter((figure) => {
                return figure.id !== id
            })
            setFiguresListRejected(nextList)
        }

        setFiguresListCurrent(newArray1)
        setAcceptancePercentage(newArray2)
        setFigureTitles(newArray3)
        setFigureEngagements(newArray4)
        setFigureMoments(newArray5)
        setFigureTimeStamps(newArray6)

    }

    const removeFigure = (index, whichTabIsIt) => {
        if (whichTabIsIt === 'Uploaded Figures') {
            based(index)
        } else if (whichTabIsIt === 'Accepted Figures') {

        } else if (whichTabIsIt === 'Rejected Figures') {

        }
    }
   
    useEffect(() => {
        let handler = (event) => {
            if(!selectorRef.current.contains(event.target)) {
                setSelectorOpen(false)
            }
        }
        document.addEventListener("mousedown", handler)
        return () => {
            document.removeEventListener("mousedown", handler)
        }
    })

    useEffect(async () => {
        let theAcceptancePercentage = []
        let theTitles = []
        let theEngagements = []
        let theMoments = []
        let figureTimeStamps = []
        for (const figure of figuresListCurrent) {
            let figureInfo = await props.returnFigureInfoMyFigures(figure.id)
            theTitles.push(figureInfo.title)
            theEngagements.push(figureInfo.engagementNumber)
            theMoments.push(figureInfo.timeStamp)
            figureTimeStamps.push(figureInfo.timeStamp.format('LLL'))
            if (isNaN(figureInfo.acceptancePercentage)) {
                theAcceptancePercentage.push('?')
            } else {
                theAcceptancePercentage.push(figureInfo.acceptancePercentage)
            }
        }
        setAcceptancePercentage(theAcceptancePercentage)
        setFigureTitles(theTitles)
        setFigureEngagements(theEngagements)
        setFigureMoments(theMoments)
        setFigureTimeStamps(figureTimeStamps)
        setLoaded(true)
        
    }, [whichTabIsIt, redo])
    
    const openSelector = () => {
        setSelectorOpen(!selectorOpen)
    }

    const sortFiguresByAcceptancePercentage = () => {
        //if Acceptance is already selected, don't do anything.
        if (sortBySelector === 'Acceptance Percentage') {
            setSelectorOpen(false)
            return;
        }
        
        //combine all data into array of objects for easy sorting.
        let allArrayData = []
        for (let i = 0; i < figureTitles.length; i++) {
            let item = {
                title: figureTitles[i],
                engagements: figureEngagements[i],
                acceptancePercentage: acceptancePercentage[i],
                id: figuresListCurrent[i],
                moment: figureMoments[i],
                timeStamp: figureTimeStamps[i]
            }
            allArrayData.push(item)
        }
        //sort allArrayData on the basis of Engagement
        allArrayData.sort((a,b) => {
            if (a.acceptancePercentage !== '?' && b.acceptancePercentage !== '?') {
                return b.acceptancePercentage - a.acceptancePercentage; 
            } else if (a.acceptancePercentage == '?') {
                return 1;
            } else if (b.acceptancePercentage == '?') {
                return -1;
            }
        })
        
        //create a new array for dates, engagements, confidences, and timeStamps and recreate the state arrays
        let newEngagements = []
        let acceptancePercentages = []
        let newTitles = []
        let newFiguresList = []
        let newMoments = []
        let newTimeStamps = []
        for (let i = 0; i < allArrayData.length; i++) {
            newEngagements.push(allArrayData[i].engagements)
            acceptancePercentages.push(allArrayData[i].acceptancePercentage)
            newTitles.push(allArrayData[i].title)
            newFiguresList.push(allArrayData[i].id)
            newMoments.push(allArrayData[i].moment)
            newTimeStamps.push(allArrayData[i].timeStamp)
        }
        
        //all we have to do is set everything to the original list, which is stored when the page is loaded and never changes
        setAcceptancePercentage(acceptancePercentages)
        setFigureEngagements(newEngagements)
        setFiguresListCurrent(newFiguresList)
        setFigureTitles(newTitles)
        setFigureMoments(newMoments)
        setFigureTimeStamps(newTimeStamps)
        setSelectorOpen(false)
        setSortBySelector('Acceptance Percentage')
    }

    const sortFiguresByEngagement = () => {
        //if Engagement is already selected, don't do anything.
        if (sortBySelector === 'Engagement') {
            setSelectorOpen(false)
            return;
        }

        //combine all data into array of objects for easy sorting.
        let allArrayData = []
        for (let i = 0; i < figureTitles.length; i++) {
            let item = {
                title: figureTitles[i],
                engagements: figureEngagements[i],
                acceptancePercentage: acceptancePercentage[i],
                id: figuresListCurrent[i],
                moment: figureMoments[i],
                timeStamp: figureTimeStamps[i]
            }
            allArrayData.push(item)
        }

        //sort allArrayData on the basis of Engagement
        allArrayData.sort((a,b) => b.engagements - a.engagements)

        //create a new array for dates, engagements, acceptancePercentages, and timeStamps and recreate the state arrays
        let newEngagements = []
        let newAcceptancePercentages = []
        let newTitles = []
        let newFiguresList = []
        let newMoments = []
        let newTimeStamps = []

        for (let i = 0; i < allArrayData.length; i++) {
            newEngagements.push(allArrayData[i].engagements)
            newAcceptancePercentages.push(allArrayData[i].acceptancePercentage)
            newTitles.push(allArrayData[i].title)
            newFiguresList.push(allArrayData[i].id)
            newMoments.push(allArrayData[i].moment)
            newTimeStamps.push(allArrayData[i].timeStamp)
        }
        
        //all we have to do is set everything to the original list, which is stored when the page is loaded and never changes
        setAcceptancePercentage(newAcceptancePercentages)
        setFigureEngagements(newEngagements)
        setFiguresListCurrent(newFiguresList)
        setFigureTitles(newTitles)
        setFigureMoments(newMoments)
        setFigureTimeStamps(newTimeStamps)
        setSelectorOpen(false)
        setSortBySelector('Engagement')
    }

    const sortFiguresByNewest = (tab) => {
        //if Newest is already selected, don't do anything.
        if (sortBySelector === 'Newest') {
            setSelectorOpen(false)
            return;
        }

        //combine all data into array of objects for easy sorting.
        let allArrayData = []
        for (let i = 0; i < figureTitles.length; i++) {
            let item = {
                title: figureTitles[i],
                engagements: figureEngagements[i],
                acceptancePercentage: acceptancePercentage[i],
                id: figuresListCurrent[i],
                moment: figureMoments[i],
                timeStamp: figureTimeStamps[i]
            }
            allArrayData.push(item)
        }

        //sort allArrayData on the basis of Engagement
        allArrayData.sort((a,b) => {
            if (a.moment.isBefore(b.moment)) {
                return 1; 
            } else {
                return -1;
            }
        })


        //create a new array for dates, engagements, confidences, and timeStamps and recreate the state arrays
        let newEngagements = []
        let newAcceptancePercentages = []
        let newTitles = []
        let newFiguresList = []
        let newMoments = []
        let newTimeStamps = []
        for (let i = 0; i < allArrayData.length; i++) {
            newEngagements.push(allArrayData[i].engagements)
            newAcceptancePercentages.push(allArrayData[i].acceptancePercentage)
            newTitles.push(allArrayData[i].title)
            newFiguresList.push(allArrayData[i].id)
            newMoments.push(allArrayData[i].moment)
            newTimeStamps.push(allArrayData[i].timeStamp)
        }
        
        //all we have to do is set everything to the original list, which is stored when the page is loaded and never changes
        setAcceptancePercentage(newAcceptancePercentages)
        setFigureEngagements(newEngagements)
        setFiguresListCurrent(newFiguresList)
        setFigureTitles(newTitles)
        setFigureMoments(newMoments)
        setFigureTimeStamps(newTimeStamps)
        setSelectorOpen(false)
        setSortBySelector('Newest')
    }

    const sortFiguresByOldest = (tab) => {
        //if Oldest is already selected, don't do anything.
        if (sortBySelector === 'Oldest') {
            setSelectorOpen(false)
            return;
        }

        //combine all data into array of objects for easy sorting.
        let allArrayData = []
        for (let i = 0; i < figureTitles.length; i++) {
            let item = {
                title: figureTitles[i],
                engagements: figureEngagements[i],
                acceptancePercentage: acceptancePercentage[i],
                id: figuresListCurrent[i],
                moment: figureMoments[i],
                timeStamp: figureTimeStamps[i]
            }
            allArrayData.push(item)
        }

        //sort allArrayData on the basis of Engagement
        allArrayData.sort((a,b) => {
            if (a.moment.isBefore(b.moment)) {
                return -1; 
            } else {
                return 1;
            }
        })

        //create a new array for dates, engagements, confidences, and timeStamps and recreate the state arrays
        let newEngagements = []
        let newAcceptancePercentages = []
        let newTitles = []
        let newFiguresList = []
        let newMoments = []
        let newTimeStamps = []
        for (let i = 0; i < allArrayData.length; i++) {
            newEngagements.push(allArrayData[i].engagements)
            newAcceptancePercentages.push(allArrayData[i].acceptancePercentage)
            newTitles.push(allArrayData[i].title)
            newFiguresList.push(allArrayData[i].id)
            newMoments.push(allArrayData[i].moment)
            newTimeStamps.push(allArrayData[i].timeStamp)
        }
        
        //all we have to do is set everything to the original list, which is stored when the page is loaded and never changes
        setAcceptancePercentage(newAcceptancePercentages)
        setFigureEngagements(newEngagements)
        setFiguresListCurrent(newFiguresList)
        setFigureTitles(newTitles)
        setFigureMoments(newMoments)
        setFigureTimeStamps(newTimeStamps)
        setSelectorOpen(false)
        setSortBySelector('Oldest')
    }

    function removeSubstring(originalString) {
        let split = originalString.split('/')
        let theURL = '/' + split[3] + '/' + split[4]
        return theURL;
    }

    function handleClick(event) {
        event.preventDefault();
        let URL = removeSubstring(event.target.href, event.target.baseURI)
        navigate(URL)
    }

    return ( 
        <div>
            <div hidden={props.loggedIn} className="loginNoticeMyPage" ><a className="anchor" href="/Login">
                    Login or create an account</a> and to start tracking your claims.
            </div>
            <div className='center' hidden={!loaded2 || !props.loggedIn}>
                <div className="myClaimsMajorContainer">
                    <div hidden={!props.loggedIn} className='myClaimsTabContainer'>
                        <div onClick={() => {whichTab('Uploaded Figures')}} 
                        className={whichTabIsIt == 'Uploaded Figures' ? "myClaimsTabSelected" : "myClaimsTab"}>
                            Uploaded Figures
                        </div>
                        <div onClick={() => {whichTab('Accepted Figures')}} 
                        className={whichTabIsIt == 'Accepted Figures' ? "myClaimsTabSelected" : "myClaimsTab"}>
                            Accepted Figures
                        </div>
                        <div onClick={() => {whichTab('Rejected Figures')}} 
                        className={whichTabIsIt == 'Rejected Figures' ? "myClaimsTabSelected" : "myClaimsTab"}>
                            Rejected Figures
                        </div>
                    </div>
                    <div className='myClaimsWrapperContainer'>
                        <div hidden={!props.loggedIn} className="myClaimsClaimContainer">
                        <div className='addClaimButtonMyClaims'>
                        <h1 className='myHeadersMyClaims'>{whichTabIsIt}</h1>
                            <Tippy content='Upload a figure'>
                                <div className='addDivMyClaims' onClick={() => {navigate('/AddFigure')}}>
                                    <svg version="1.1" viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                                        <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path className="color000 svgShape" d="m24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" fill="#fff"/>
                                        </svg>
                                    </svg>
                                </div>
                        </Tippy>
                        </div>  
                            <div className={figuresListCurrent.length != 0 ? 'sortByDivMyClaimWrapper' : 'hidden'}>
                                <div className='slightSpacerMyClaim'>Sort by:</div>
                                <div className='sortByDivMyClaim'>
                                    <span className='minimumWidthMyClaims'>{sortBySelector}</span>
                                    <span className='tagsSpanMyClaims'>
                                        <svg onClick={() => {openSelector()}} id="tagSVG" xmlns="http://www.w3.org/2000/svg" width="18" 
                                        height="18" viewBox="0 0 24 24">
                                            <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/>
                                        </svg>
                                    </span>
                                    <div className='seletorsDivMyClaims' hidden={!selectorOpen} ref={selectorRef}>
                                        <div className='selectorOptionsMyClaims' onClick={() => {sortFiguresByAcceptancePercentage()}}>
                                            Acceptance
                                        </div>
                                        <div className='selectorOptionsMyClaims' onClick={() => {sortFiguresByEngagement()}}>Engagement</div>
                                        <div className='selectorOptionsMyClaims' onClick={() => {sortFiguresByOldest()}}>Oldest</div>
                                        <div className='selectorOptionsMyClaims' onClick={() => {sortFiguresByNewest()}}>Newest</div>
                                    </div>
                                </div>
                            </div>
                            {
                                figuresListCurrent.map((figure, index) => {
                                    return (
                                        <div key={index} className={loaded ? 'userClaimWrapperMyClaims' : 'hidden'}>
                                            <div className='userClaimMyClaims'>
                                                <div className='myClaimsAnchorSpan'>
                                                    <a className='ProfileFontBold' href={'/Figure/' + figuresListCurrent[index].id}
                                                    onClick={handleClick}>{figureTitles[index]}
                                                    </a>
                                                    <div className='timeStampDivMyClaims'>- {figureTimeStamps[index]}</div>
                                                </div>
                                                <div className='claimStatsWidgetMyClaims'>
                                                    <div className='confidenceLoader'>  <Spinner hidden={loaded} /></div>
                                                    <div hidden={!loaded}>{acceptancePercentage[index]}%  | {figureEngagements[index]}</div>
                                                </div>
                                                <div className='optionsClaimWidget'>
                                                    <SVGoptionsDotsMyFigures whichTabIsIt={whichTabIsIt}  
                                                    user={props.user} id={figure.id} index={index} based={based} figuresListAccepted={figuresListAccepted}
                                                    figuresListRejected={figuresListRejected}/>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div className={loaded ? 'hidden' : 'ProfileComponentsSpinnerDiv'}>
                                <Spinner />
                            </div>
                            <div className={whichTabIsIt === 'Uploaded Figures' && figuresListCurrent.length == 0 ? 'noClaimsToShow' : 'hidden'}>
                                You have not uploaded any figures yet <div className='noClaimSpacer'></div>
                            </div>
                            <div className={whichTabIsIt === 'Accepted Figures' && figuresListCurrent.length == 0 ? 'noClaimsToShow' : 'hidden'}>
                                You have not accepted any figures yet <div className='noClaimSpacer'></div>
                            </div>     
                            <div className={whichTabIsIt === 'Rejected Figures' && figuresListCurrent.length == 0 ? 'noClaimsToShow' : 'hidden'}>
                                You have not rejected any figures yet <div className='noClaimSpacer'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) 
}

const mapStateToProps = (state, props) => {
    if (state.profModels.length == 1) {
        return { 
            user: state.profModels[0].id,
            userName: state.profModels[0].name,
            tags: state.profModels[0].tags,
            myFigures: state.profModels[0].myFigures,
            loggedIn: true
        }
    } else {
        return {
            user: '',
            userName: '',
            tags: [],
            myFigures: {uploadedFigures: [], acceptedFigures: [], rejectedFigures: []},
            loggedIn: false,
        };
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    returnFigureInfoMyFigures: (figure) => dispatch(returnFigureInfoMyFigures(figure))
})

const ConnectedMyFigures = connect(mapStateToProps, mapDispatchToProps)(myFigures);

export default ConnectedMyFigures;