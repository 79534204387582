import React, { useEffect, useState, useRef, useCallback } from 'react'
import { connect } from 'react-redux'
import { returnTheoryInfoProfile } from '../../actions/theoryModels';
import Spinner from "react-svg-spinner";
import SVGoptionsDotsProfileTheories from '../SVGs/SVGoptionsDotsProfileTheories';
import { useNavigate } from 'react-router-dom';


export const TheoriesList = (props) => {
    const [selectorOpen, setSelectorOpen] = useState(false)
    const [sortBySelectorTheory, setSortBySelectorTheory] = useState('None')
    const [theoriesList, setTheoriesList] = useState([])
    let selectorRef = useRef();
    const [loaded, setLoaded] = useState(false)
    const navigate = useNavigate()

    //This useEffect will take in the list of theoryIDs that was passed in and loop through it to 
    //fetch the data from each.
    useEffect(async () => {
        if (!props.loaded) {
            setLoaded(false)
            return;
        }
        try {
            let theTheoriesList = []
            for (const theory of props.authoredTheories) {
                let theoryInfo = await props.returnTheoryInfoProfile(theory.id)
                //If the claim is unavailable from Firebase or if it is anonymous and it is not a user viewing their own profile,
                //dont add the claim to the claimsList.
                if (theoryInfo.unavailable || (theoryInfo.anonymous && props.user != props.fellowID)) {

                } else {
                    theTheoriesList.push(theoryInfo)
                }
            }
            await theTheoriesList.all
            setTheoriesList(theTheoriesList)
            if (props.loaded) {
                setLoaded(true)
            }

        } catch (error) {
            // console.log('something wrong with theory-follow logic')
        }
    }, [props.whichTabIsIt, props.loaded])
    useEffect(() => {
        let handler = (event) => {
            if(!selectorRef.current.contains(event.target)) {
                setSelectorOpen(false)
            }
        }
        document.addEventListener("mousedown", handler)
        return () => {
            document.removeEventListener("mousedown", handler)
        }
    })

    const sortTheoriesByConfidence = () => {
        //if Confidence is already selected, don't do anything.
        if (sortBySelectorTheory === 'Confidence') {
            setSelectorOpen(false)
            return;
        }

        //combine all data into array of objects for easy sorting.
        let allArrayData = [...theoriesList]

        //sort allArrayData on the basis of Confidence
        allArrayData.sort((a,b) => {
            if (a.confidence !== '?' && b.confidence !== '?') {
                return b.confidence - a.confidence; 
            } else if (a.confidence == '?') {
                return 1;
            } else if (b.confidence == '?') {
                return -1;
            }
        })
        
        setTheoriesList(allArrayData)
        setSelectorOpen(false)
        setSortBySelectorTheory('Confidence')
    }

    const sortTheoriesByOldest = (tab) => {
        //if Oldest is already selected, don't do anything.
        if (sortBySelectorTheory === 'Oldest') {
            setSelectorOpen(false)
            return;
        }

        //combine all data into array of objects for easy sorting.
        let allArrayData = [...theoriesList]
        
        //sort allArrayData on the basis of what is oldest
        allArrayData.sort((a,b) => {
            if (a.moment.isBefore(b.moment)) {
                return -1; 
            } else {
                return 1;
            }
        })

        setTheoriesList(allArrayData)
        setSelectorOpen(false)
        setSortBySelectorTheory('Oldest')
    }

    const sortTheoriesByNewest = (tab) => {
        //if Oldest is already selected, don't do anything.
        if (sortBySelectorTheory === 'Newest') {
            setSelectorOpen(false)
            return;
        }

        //combine all data into array of objects for easy sorting.
        let allArrayData = [...theoriesList]
        
        //sort allArrayData on the basis of what is newest
        allArrayData.sort((a,b) => {
            if (a.moment.isBefore(b.moment)) {
                return 1; 
            } else {
                return -1;
            }
        })

        setTheoriesList(allArrayData)
        setSelectorOpen(false)
        setSortBySelectorTheory('Newest')
    }

    const sortTheoriesByEngagement = (tab) => {
        //if Oldest is already selected, don't do anything.
        if (sortBySelectorTheory === 'Engagement') {
            setSelectorOpen(false)
            return;
        }

        //combine all data into array of objects for easy sorting.
        let allArrayData = [...theoriesList]
        
        //sort allArrayData on the basis of Number of Theories
        allArrayData.sort((a,b) => b.engagementNumber - a.engagementNumber)

        setTheoriesList(allArrayData)
        setSelectorOpen(false)
        setSortBySelectorTheory('Engagement')
    }

    function removeSubstring(originalString, stringToRemove) {
        let split = originalString.split('/')
        let theURL = '/' + split[3] + '/' + split[4]
        return theURL;
    }

    function replaceString(a, b, c) {
        // Use the replaceAll method to replace all occurrences of 'a' with 'b'
        const result = c.split(a).join(b);
        return result;
    }

    function handleClick(event) {
        event.preventDefault();
        let URL = removeSubstring(event.target.href, event.target.baseURI)

        //Get the data on whether the theory has been published or not. If it has been published, clicking on it will navigate to 
        //"Theory/ID" instead of "AddTheory/ID"
        const dataValue = event.currentTarget.getAttribute('published');
        let published;
        if (dataValue == "false") {
            published = false
        } else {
            published = true
        }

        if (!published) {
            URL = replaceString('Theory', 'AddTheory', URL)
        }
        navigate(URL)

    }
    

    return (  
        <div className={props.whichTabIsIt == 'Theories' ? 'myClaimsWrapperContainer' : 'hidden'}>
            <div className="myClaimsClaimContainer">
                <div className='addClaimButtonMyClaims'>
                    <h1 className='myHeadersMyClaims'>Authored Theories</h1>
                </div>  
                <div className={theoriesList.length != 0 ? 'sortByDivMyClaimWrapper' : 'hidden'}>
                    <div className='slightSpacerMyClaim'>Sort by:</div>
                    <div className='sortByDivProfile'>
                        <span className='minimumWidthMyClaims'>{sortBySelectorTheory}</span>
                        <span className='tagsSpanMyClaims'>
                            <svg onClick={() => {setSelectorOpen(!selectorOpen)}} id="tagSVG" xmlns="http://www.w3.org/2000/svg" width="18" 
                            height="18" viewBox="0 0 24 24">
                                <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/>
                            </svg>
                        </span>
                        <div className='seletorsDivMyClaims' hidden={!selectorOpen} ref={selectorRef}>
                            <div className='selectorOptionsMyClaims' onClick={() => {sortTheoriesByConfidence()}}>Confidence</div>
                            <div className='selectorOptionsMyClaims' onClick={() => {sortTheoriesByEngagement()}}>Engagement</div>
                            <div className='selectorOptionsMyClaims' onClick={() => {sortTheoriesByOldest()}}>Oldest</div>
                            <div className='selectorOptionsMyClaims' onClick={() => {sortTheoriesByNewest()}}>Newest</div>
                        </div>
                    </div>
                </div>
                <div>
                    {
                        theoriesList.map((theory, index) => {
                            return (
                                <div key={index} className='userClaimWrapperMyClaims'>
                                    <div className='userClaimMyClaims'>
                                        <div className='myClaimsAnchorSpan'>
                                            <a className='ProfileFontBold' href={'/Theory/' + theory.id } published={theory.published.toString()} onClick={handleClick}>
                                                {theory.title !== '' ? theory.title : 'No Title'}
                                            </a>
                                            <div className='timeStampDivMyClaims'>- {theory.timeStamp}</div>
                                        </div>
                                        <div className='claimStatsWidgetMyClaims'>
                                            <div className='confidenceLoader'>  <Spinner hidden={props.loaded} /></div>
                                            <div hidden={!props.loaded}>
                                                {theory.confidence}%  | {theory.engagementNumber}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                
                </div>
                <div className={loaded ? 'hidden' : 'ProfileComponentsSpinnerDiv'}>
                    <Spinner />
                </div>
                <div className={props.whichTabIsIt === 'Theories' && theoriesList.length == 0 && loaded ? 'noClaimsToShow' : 'hidden'}>
                    {props.fellowName == props.userName ? 'You have ' : props.fellowName + ' has '}not written any theories yet.
                </div>
            </div>
        </div>
    ) 
}

const mapStateToProps = (state, props) => {
    if (state.profModels.length == 1) {
        return {
            user: state.profModels[0].id,
            userName: state.profModels[0].name,
            tags: state.profModels[0].tags,
            loggedIn: true,
        };
    } else {
        return {
            user: '',
            userName: '',
            tags: [],
            loggedIn: false
        };
    }
}

const mapDispatchToProps = (dispatch, profileProps) => ({
    returnTheoryInfoProfile: (theory) => dispatch(returnTheoryInfoProfile(theory))
})

const ConnectedTheoriesList = connect(mapStateToProps, mapDispatchToProps)(TheoriesList);

export default ConnectedTheoriesList;