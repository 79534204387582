import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { returnTagInfoSettings, updateGeneralProfileSettings } from '../../actions/profModels';
import { useNavigate } from 'react-router-dom'
import SVGInformation from '../SVGs/SVGInformation'

export const ProfileInformation = (props) => {
    const [tagsList, setTagsList] = useState([])
    const [anontagEdit, setAnontagEdit] = useState(false)
    const [loaded, setLoaded] = useState(false)

    const navigate = useNavigate();
    //This useEffect will take in the list of tagIDs that was passed in and loop through it to 
    //fetch the data from each.
    useEffect(async () => {
        let theTagsList = []
        for (const tag of props.tags) {
            let tagInfo = await props.returnTagInfoSettings(tag.id)
            //If the claim is unavailable from Firebase or if it is anonymous and it is not a user viewing their own profile,
            //dont add the claim to the claimsList.
            if (tagInfo) {
                theTagsList.push(tagInfo)
            }
        }
        
        setTagsList(theTagsList)
        setLoaded(true)
    }, [props.whichTabIsIt, props.loaded])

    const apply = () => {
        navigate('/ApplyForAnonymousCredentials')
    }

    function removeSubstring(originalString) {
        let split = originalString.split('/')
        let theURL = '/' + split[3] + '/' + split[4]
        return theURL;
    }

    function handleClick(event) {
        event.preventDefault();
        let URL = removeSubstring(event.target.href, event.target.baseURI)
        navigate(URL)
    }


    return (  
        <div className={props.whichTabIsIt == 'AnonTags' ? 'myClaimsWrapperContainer' : 'hidden'}>
            <div className="myClaimsClaimContainer">
                <div className='addClaimButtonMyClaims'>
                    <h1 className='myHeadersMyClaims'>Profile Information</h1>
                </div>
                <div className="SettingsProfileLine"></div>
                <div className='SettingsSubheader3'>
                    <div className='ApplyAnonHeader'>
                        <h2>Anon-tags</h2>
                        <div className="ApplySmallInfoIcon" onClick={() => {navigate('/About/Anon-tags')}}>
                            <SVGInformation/>
                        </div>
                    </div>
                    <div>
                        <button className='SettingsApplyButton' onClick={apply}>Apply for Anon-tag</button>
                    </div>
                </div>
                <div className={anontagEdit ? 'hidden' : ''}>
                    {
                        tagsList.map((tag, index) => {
                            return (
                                <div key={tag.id + index} className='SettingsAnon-tagDiv'>
                                    <a key={tag.displayName} className='SettingsAnon-tag' href={'/Profile/' + tag.id } onClick={handleClick}>
                                        {tag.displayName}
                                    </a>
                                    <p className='SettingsAnon-tagDesc'>{tag.description}</p>
                                </div>)
                        })
                    }
                    <div className={tagsList.length == 0 ? '' : 'hidden'}>You currently have no anon-tags</div>
                </div>
            </div>
        </div>
    ) 
}

const mapStateToProps = (state, props) => {
    if (state.profModels.length == 1) {
        return {
            user: state.profModels[0].id,
            userName: state.profModels[0].name,
            tags: state.profModels[0].tags,
            loggedIn: true,
            email: state.profModels[0].email,
            occupation: state.profModels[0].occupation,
            location: state.profModels[0].location
        };
    } else {
        return {
            user: '',
            userName: '',
            tags: [],
            loggedIn: false,
            email: '',
            occupation: '',
            location: ''
        };
    }
}

const mapDispatchToProps = (dispatch, profileProps) => ({
    returnTagInfoSettings: (tagID) => dispatch(returnTagInfoSettings(tagID)),
    updateGeneralProfileSettings: (profileUpdates) => dispatch(updateGeneralProfileSettings(profileUpdates))
})

const ConnectedProfileInformation = connect(mapStateToProps, mapDispatchToProps)(ProfileInformation);

export default ConnectedProfileInformation;