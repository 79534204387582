import React, { useState } from "react";
import { useNavigate } from "react-router-dom"
import { connect } from 'react-redux'
import { startDeleteAddTheory } from '../actions/theoryModels';
import { ref, deleteObject } from 'firebase/storage'
import { storage } from '../firebase/firebase'
import { history } from '../routers/AppRouter'
import Spinner from "react-svg-spinner";


function DeleteModal (props) {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const deleteTheory = () => {
        setLoading(false)
        //Should not be an issue, but check to make sure the user is the owner of the theory
        if (props.user != props.owner) {
            return;
        }
        props.startDeleteAddTheory(props.id, props.user).then(() => {
            navigate('/')
            // console.log('it got deleted')
        }).catch(() => {
            // console.log('Theory did not delete')
        })
    }
    
    return (
        <div>
            <div onClick={() => {props.closeModal(false)}} className="blackBackground"></div>
            <div className='deleteModal'>
                <div className="deleteFlex">
                    <h1>Delete?</h1>
                    <svg onClick={() => {props.closeModal(false)}} className="deleteX" version="1.1" viewBox="0 0 288 288" 
                    xmlns="http://www.w3.org/2000/svg">
                        <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path className="color000 svgShape" 
                            d="m24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" 
                            fill="#002c2c"/>
                        </svg>
                    </svg>
                </div>
                <div className='withinDelete'>
                    <div>Are you sure you would like to delete this Theory?</div>
                </div>
                <div className='deleteButtonClaimWrapper'>
                    <button className={loading ? "deleteButtonClaim": 'deleteButtonClaimClicked'} onClick={deleteTheory} type="button">
                        <div hidden={!loading}>Delete</div>
                        <Spinner hidden={loading} />
                    </button>
                </div>
            </div>
        </div>
    )
}



const mapDispatchToProps = (dispatch, props) => ({
    startDeleteAddTheory: (id, user) => dispatch(startDeleteAddTheory(id, user))
})

const DeleteModalConnected = connect(undefined, mapDispatchToProps)(DeleteModal);


export default DeleteModalConnected