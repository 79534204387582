import React, { Component, useState, useRef, useMemo, useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from "react-router-dom"
import 'tippy.js/dist/tippy.css'
import 'react-circular-progressbar/dist/styles.css';


export const AboutAnonTags = (props) => {
    let LoggedIn;
    if (props.user) {
        LoggedIn = true
    } else {
        LoggedIn = false
    }
    let navigate = useNavigate()
       
    return (
        <div className="theoryMajorContainer" >
            <div className="loginAbout" >
                <div hidden={LoggedIn}>Create an account<a className="anchorAbout" href="/Login">here</a></div>
            </div>
            <div className='centerWrapperAbout'>
                <div className="containerAbout">
                    <div draggable="false" className='aboutText' >
                        <h1>Anon-tags</h1>
                    </div>
                    <div draggable="false" className='aboutSubheaderAnon' >
                        <h2>What are "Anon-tags"?</h2>
                    </div>
                    <p className='paragraphsAbout'>
                        Anon-tags are a way for fellows to attach their real credentials to their activity anonymously. For myriad reasons 
                        {' (intense competition, nepotism, whistle blowing, political controversy)'}, a fellow 
                        may wish to generate an interaction or content without disclosing their individual identity, but in a way that 
                        still establishes their expertise/qualifications to discuss the topic at hand. For example, a student may want a venue 
                        to be critical of the research of a professor in their department without fear of potential blowback. When a fellow 
                        writes theories, creates claims, identifies papers, uploads figures, or makes comments, they will have the option to 
                        post with all of their anon-tags displayed along with their name, or without their name and any combination of anon-tags 
                        they wish to have displayed. Naturally, when a fellow's credentials are verified, their individual identity will be 
                        verified and will be displayed as a tag as well.
                    </p>
                    <div draggable="false" className='aboutSubheaderAnon' >
                        <h2>Anon-tag format</h2>
                    </div>
                    <p className='paragraphsAbout'>
                        In order for easy recognition of anon-tags, the format for naming them is the general status {' (job title or academic title) '}
                        of the fellow, followed by their area of focus, and then any academic credentials. For example, a fellow who is an assistant professor researching biochemistry can make an anon-tag 
                        such as "Assistant Professor - Biochemistry - PhD". An industry scientist developing flu vaccines can create a tag that reads 
                        "Scientist - Vaccine Development - BS". A patent lawyer may create an anon-tag such as "Attorney - Patent Law - JD", and so on. 
                    </p>
                    <div draggable="false" className='aboutSubheaderAnon' >
                        <h2>How are anon-tags granted to fellows?</h2>
                    </div>
                    <p className='paragraphsAbout'>
                        Anon-tags can be applied for through the fellow's profile settings page. When applying for an anon-tag, fellows will provide 
                        a name and description for the tag, followed by a document{'(s)'} that verifies the associated credentials. For example, a student 
                        can submit their registration for the semester. Employment can be verified with a job offer displaying the fellow's name 
                        and the job title. Degrees can be verified simply with an image of the fellow's diploma. After submitting the anon-tag application 
                        we will review the verification documents quickly and send an email to the fellow afterward stating their anon-tag is ready for use 
                        or that the documents provided in the application are insufficient for verification.
                    </p>
                    <div draggable="false" className='aboutSubheaderAnon' >
                        <h2>If you have a Trusemble account, you can apply for anon-tags <a onClick={() => {navigate('/ApplyForAnonymousCredentials')}}
                        className='ApplyAnonLink'>
                            here</a>.</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state, props) => {
    if (state.profModels.length == 1) {
        if (state.theoryModels.length == 0) {
            return {
                user: state.profModels[0].id,
                userName: state.profModels[0].name,
                tags: state.profModels[0].tags,
                loggedIn: true,
            };
        } else {
            return { 
                user: state.profModels[0].id,
                userName: state.profModels[0].name,
                tags: state.profModels[0].tags,
                loggedIn: true,
            }
        }
    } else {
        if (state.theoryModels.length == 0) {
            return {
                user: '',
                userName: '',
                tags: [],
                loggedIn: false,
            };
        } else {
            return { 
                user: '',
                userName: '',
                tags: [],
                loggedIn: false,
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => ({

})

const ConnectedAboutAnonTags = connect(mapStateToProps, mapDispatchToProps)(AboutAnonTags);

export default ConnectedAboutAnonTags;