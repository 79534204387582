import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from "react-router-dom"
import { history } from '../../routers/AppRouter'
import { startAddPaperFollow, startRemovePaperFollow, 
    updateLastViewedPaper, returnPaperInfoHomeFeed} from '../../actions/paperModels';
import { returnClaimInfo } from '../../actions/claimModels';
import { returnFigureInfo } from '../../actions/figModels';
import moment from 'moment';
import DeleteModal from '../deleteModalPaper';
import Spinner from "react-svg-spinner";
import { v4 as uuidv4 } from 'uuid';


const Paper = (props) => {
    const [optionsOpen, setOptionsOpen] = useState(false)
    const [extractedClaims, setExtractedClaims] = useState([])
    const [Figures, setFigures] = useState([])
    const [percentageConfidences, setPercentageConfidences] = useState([])
    const [claimTitles, setClaimTitles] = useState([])
    const [claimEngagements, setClaimEngagements] = useState([])
    const [claimMoments, setClaimMoments] = useState([])
    const [claimTimeStamps, setClaimTimeStamps] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [sortBySelector, setSortBySelector] = useState('None')
    const [selectorOpen, setSelectorOpen] = useState(false)
    const [timeStampPageVisit, setTimeStampPageVisit] = useState()
    const [notDeleted, setNotDeleted] = useState(false)
    const [selectorOpenFigures, setSelectorOpenFigures] = useState(false)
    const [sortBySelectorFigures, setSortBySelectorFigures] = useState('None')
    const [acceptancePercentage, setAcceptancePercentage] = useState([])
    const [figureTitles, setFigureTitles] = useState([])
    const [figureEngagements, setFigureEngagements] = useState([])
    const [figureMoments, setFigureMoments] = useState([])
    const [figureTimeStamps, setFigureTimeStamps] = useState([])
    const [figureNumbers, setFigureNumbers] = useState([])
    const [loadedFigures, setLoadedFigures] = useState(false)
    const [localDate, setLocalDate] = useState()
    const [title, setTitle] = useState('')
    const [messages, setMessages] = useState([])
    const [paperDoi, setPaperDoi] = useState('')
    const [owner, setOwner] = useState('')
    const [ownerTags, setOwnerTags] = useState([])
    const [journal, setJournal] = useState('')

    const navigate = useNavigate()
    const [followed, setFollowed] = useState(false)
    const [deleteModalState, setDeleteModalState] = useState(false)

    // useEffect(async () => {
    //     if (props.loggedIn) {
    //         for(var i = 0; i < props.follows.length; i++) {
    //             if (props.follows[i].id === props.user) {
    //                 setFollowed(true)
    //                 break;
    //             }
    //         }
    //         let timeStamp = moment().utc().toString()
    //         await props.updateLastViewedPaper(props.user, props.id, timeStamp)
    //     }
    // }, [])



    //This method determines if there were comments on the paper, if claims were extracted from the paper,
    //or if figures were extracted from the paper. It takes the whole paper document
    //as its first argument and the time the user last viewed the paper as its second argument and
    //then returns an object with the figures and extractedClaims that were recently added, as well as
    //the appropriate messages to display at the top of the paperUpdate.
    const getLatestUpdatesPaper = (thePaper, lastViewed) => {
        //Create arrays of all figures and claims to be displayed on the update based on when they were uploaded
        //or extracted from the paper or uploaded
        let theFigures = [];
        let theClaims = [];
        //Here we create a messages array that will be displayed at the top of the paperUpdate that will state
        //what updates there are to report.
        let theMessages = [];
        //The anyUpdate value lets us know if there is any update on the paper since the user has last viewed it.
        let anyUpdate = false
        for (const figure of thePaper.figures) {
            const date1 = new Date(figure.timeStamp)
            const date2 = new Date(lastViewed)
            if (date1 > date2) {
                theMessages.push('- Figures were recently uploaded from this paper .')
                anyUpdate = true;
                theFigures.push(figure)
            }
        }
        for (const claim of thePaper.extractedClaims) {
            const date1 = new Date(claim.timeStamp)
            const date2 = new Date(lastViewed)
            if (date1 > date2) {
                theMessages.push('- Claims were recently extracted from this paper.')
                anyUpdate = true;
                theClaims.push(claim)
            }
        }
        return { 
            figures: theFigures,
            claims: theClaims,
            messages: theMessages,
            anyUpdate: anyUpdate
         }

    }

    //In this useEffect, we load in all the relevant information on the paper that needs to be displayed
    //on the paperUpdate.
    useEffect( async () => {
        //Make call to Firebase with returnPaperInfoHomeFeed function to fetch paper document off of database.
        let paperInfo = await props.returnPaperInfoHomeFeed(props.id)
        //If info is able to be fetched, update all relevant state values.
        if (paperInfo) {
            const theDate = new Date(paperInfo.timeStamp)
            const newMoment = moment(theDate)
            newMoment.local()
            const theLocalDate = newMoment.format('LLL')
            const updates = getLatestUpdatesPaper(paperInfo, props.lastViewed)

            //Here we record the time that the info of this paper was pulled off of Firebase. This will
            //be used as the time the user last viewed this paper. We call the appropriate function:
            let timeStamp = moment().utc().toString()
            setTimeStampPageVisit(timeStamp)
            //Here we check the "anyUpdate" value from calling the getLastestUpdatesPaper to see if there are any
            //updates. If there are, we set the state for all the variables we need.
            if (updates.anyUpdate) {
                for(var i = 0; i < paperInfo.follows.length; i++) {
                    if (paperInfo.follows[i].id === props.user) {
                        setFollowed(true)
                        break;
                    }
                }
                setFigures(updates.figures)
                setExtractedClaims(updates.claims)
                setMessages(updates.messages)
                setTitle(paperInfo.title)
                setLocalDate(theLocalDate)
                setPaperDoi(paperInfo.doi)
                setJournal(paperInfo.journal)
                setOwnerTags(paperInfo.ownerTags)
                setOwner(paperInfo.owner)
                setNotDeleted(true)
                setLoaded(true)
            } 

        } else {
            //If the figure is not available on Firebase, it is probably because it was deleted.
            //If it was deleted, nothing should be shown.

            //Also set to false if there are no updates to be shown for this claim so that it does
            //not show up.
            // setNotDeleted(false)
        }
    }, [])



    //After the list of claim updates is set in the useEffect above, we use this useEffect to gather
    //and calculate the information needed to display them on the claimUpdate.
    useEffect(async () => {
        let thePercentageConfidences = []
        let theTitles = []
        let theEngagements = []
        let theMoments = []
        let claimTimeStamps = []

        for (const claim of extractedClaims) {
            let claimInfo = await props.returnClaimInfo(claim.id)
            theTitles.push(claimInfo.title)
            theEngagements.push(claimInfo.engagementNumber)
            theMoments.push(claimInfo.timeStamp)
            claimTimeStamps.push(claimInfo.timeStamp.format('LLL'))
            if (isNaN(claimInfo.confidence)) {
                thePercentageConfidences.push('?')
            } else {
                thePercentageConfidences.push(claimInfo.confidence)
            }
        }
        setPercentageConfidences(thePercentageConfidences)
        setClaimTitles(theTitles)
        setClaimEngagements(theEngagements)
        setClaimMoments(theMoments)
        setClaimTimeStamps(claimTimeStamps)
        setLoaded(true)
        
    }, [extractedClaims])

    //When the Paper page is loaded, fetch the data from each figure uploaded from this paper and set the state for the titles,
    //acceptance percentages, engagements, moments, and timestamps etc.
    useEffect(async () => {
        let theAcceptancePercentage = []
        let theTitles = []
        let theEngagements = []
        let theMoments = []
        let theFigureNumbers = []
        let figureTimeStamps = []

        for (const figure of Figures) {
            let figureInfo = await props.returnFigureInfo(figure.id)
            theTitles.push(figureInfo.title)
            theEngagements.push(figureInfo.engagementNumber)
            theMoments.push(figureInfo.timeStamp)
            theFigureNumbers.push(figureInfo.figureNumber)
            figureTimeStamps.push(figureInfo.timeStamp.format('LLL'))
            if (isNaN(figureInfo.acceptancePercentage)) {
                theAcceptancePercentage.push('?')
            } else {
                theAcceptancePercentage.push(figureInfo.acceptancePercentage)
            }
        }
        setAcceptancePercentage(theAcceptancePercentage)
        setFigureTitles(theTitles)
        setFigureEngagements(theEngagements)
        setFigureMoments(theMoments)
        setFigureTimeStamps(figureTimeStamps)
        setFigureNumbers(theFigureNumbers)
        setLoadedFigures(true)
        
    }, [Figures])
   

    const basedClaim = (index) => {
        let newArray1 = [...extractedClaims]
        let newArray2 = [...percentageConfidences]
        let newArray3 = [...claimTitles]
        let newArray4 = [...claimEngagements]
        let newArray5 = [...claimMoments]
        let newArray6 = [...claimTimeStamps]

        newArray1.splice(index, 1)
        newArray2.splice(index, 1)
        newArray3.splice(index, 1)
        newArray4.splice(index, 1)
        newArray5.splice(index, 1)
        newArray6.splice(index, 1)

        setExtractedClaims(newArray1)
        setPercentageConfidences(newArray2)
        setClaimTitles(newArray3)
        setClaimEngagements(newArray4)
        setClaimMoments(newArray5)
        setClaimTimeStamps(newArray6)

    }


    //The following three functions get the tally for each type of vote in order to calculate the initial confidence value.
    const followPaper = () => {
        setFollowed(!followed)
        if (!followed) {
            let timeStamp = moment().utc().toString()
            let occurrence = uuidv4()
            props.startAddPaperFollow(props.user, props.id, occurrence, timeStamp).then(() => {
                
            }).catch(() => {
                // console.log('paper was not followed')
            })
        } else if (followed) {
            props.startRemovePaperFollow(props.user, props.id).then(() => {
                
            }).catch(() => {
                // console.log('paper was not followed')
            })
        } else {
            // console.log('something wrong with paper-follow logic')
        }
        
    }

    const reportFigure = () => {
        navigate('/ReportFigure/' + props.id)
    }

    const identifyPaper = () => {
        navigate('/AddPaper')
    }

    const uploadFigure = () => {
        navigate('/AddFigure')
    }

    const addClaim = () => {
        navigate('/AddClaim')
    }

    const openSelector = () => {
        setSelectorOpen(!selectorOpen)
    }

    const openSelectorFigures = () => {
        setSelectorOpenFigures(!selectorOpenFigures)
    }

    const basedFigure = (index) => {
        let newArray1 = [...Figures]
        let newArray2 = [...acceptancePercentage]
        let newArray3 = [...figureTitles]
        let newArray4 = [...figureEngagements]
        let newArray5 = [...figureMoments]
        let newArray6 = [...figureTimeStamps]

        newArray1.splice(index, 1)
        newArray2.splice(index, 1)
        newArray3.splice(index, 1)
        newArray4.splice(index, 1)
        newArray5.splice(index, 1)
        newArray6.splice(index, 1)

        setFigures(newArray1)
        setAcceptancePercentage(newArray2)
        setFigureTitles(newArray3)
        setFigureEngagements(newArray4)
        setFigureMoments(newArray5)
        setFigureTimeStamps(newArray6)

    }


    function removeSubstring(originalString) {
        let split = originalString.split('/')
        let theURL = '/' + split[3] + '/' + split[4]
        return theURL;
    }

    function handleClick(event) {
        event.preventDefault();
        let URL = removeSubstring(event.target.href, event.target.baseURI)
        navigate(URL)
    }


    return (
        <div className={loaded && notDeleted ? "" : "hidden"}>
            {deleteModalState && <DeleteModal id={props.id} closeModal={setDeleteModalState} element='paper' />}
            <div>
                <div className="majorContainerFigureUpdate">
                    <div className="smallFigureContainerHomeTest">
                        <div className='figureUpdatePaddingHome'>
                            <p className={props.lastViewed == 'unknown' ? '' : 'hidden'}>- from elsewhere.</p>

                            {
                                messages.map((message, index) => {
                                    return <p key={index + message}>{message}</p>
                                })
                            }
                            <div className={messages.length > 0 ? 'line3Claim' : 'hidden'}></div>
                            <div className='paperUpdateTitleHomeDiv'>
                                <a className='figureUpdateTitleHome' href={'/Paper/' + props.id} onClick={handleClick}>{title}</a>    
                            </div>
                            <div className='doiPaperHomeUpdate'><b>Source: </b>
                                <a className="anchor" href={paperDoi} >{paperDoi}</a>
                            </div>
                            <div className='journalPaper'><b>Journal: </b>{journal}</div>
                            <div className='divAbovePaper'>
                                <div className='addedByPaper'><b>Identified by:</b></div>
                                {
                                    ownerTags.map((tag) => {
                                        return (<a key={tag.displayName} className="anchorNameClaim" onClick={handleClick}
                                        href={'/Profile/' + tag.id }>{tag.displayName}</a>)
                                    })
                                }
                            </div>
                            <div className='divAbove3PaperUpdate'><b>- {localDate}</b></div>
                        </div>
                        {
                            extractedClaims.map((claim, index) => {
                                return (
                                    <div key={claim.id + index} 
                                    className={((extractedClaims.length - 1) == index) && Figures.length == 0 ? "smallFigureContainerUpdateLast" : "smallFigureContainerUpdate"}>
                                        <div className='verticalCenterPaperUpdate'>
                                            <div className='anchorSpanPaperUpdate'>
                                                <b>Claim: </b>
                                                <a className='paperUpdateAnchor' onClick={handleClick} href={'/Claim/' + extractedClaims[index].id }>
                                                    <b>{claimTitles[index]}</b>
                                                </a>
                                            </div>
                                            <div className='claimStatsWidgetPaperUpdate'>
                                                <div className='confidenceLoader'>  <Spinner hidden={loaded} /></div>
                                                <div hidden={!loaded}>{percentageConfidences[index]}%  | {claimEngagements[index]}</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {
                            Figures.map((figure, index) => {
                                return (
                                    <div key={figure.id + index} 
                                    className={(Figures.length - 1) > index ? "smallFigureContainerUpdate" : "smallFigureContainerUpdateLast"}>
                                        <div className='verticalCenterPaperUpdate'>
                                            <div className='anchorSpanPaperUpdate'>
                                                <b>Figure: </b>
                                                <a className='paperUpdateAnchor' onClick={handleClick} href={'/Figure/' + Figures[index].id }>
                                                    <b>{figureTitles[index]}</b>
                                                </a> ({figureNumbers[index]})
                                            </div>
                                            <div className='claimStatsWidgetPaperUpdate'>
                                                <div className='confidenceLoader'>  <Spinner hidden={loadedFigures} /></div>
                                                <div hidden={!loaded}>{acceptancePercentage[index]}%  | {figureEngagements[index]}</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state, props) => {
    if (state.profModels.length == 1) {
        return {
            user: state.profModels[0].id,
            userName: state.profModels[0].name,
            tags: state.profModels[0].tags,
            loggedIn: true,
        };
    } else {
        return {
            user: '',
            userName: '',
            tags: [],
            loggedIn: false
        };
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    startSetPaper: (id) => dispatch(startSetPaper(id)),
    startAddPaperFollow: (user, paperID, occurrence, timeStamp) => dispatch(startAddPaperFollow(user, paperID, occurrence, timeStamp)),
    startRemovePaperFollow: (user, paperID) => dispatch(startRemovePaperFollow(user, paperID)),
    returnClaimInfo: (claim) => dispatch(returnClaimInfo(claim)),
    returnFigureInfo: (figure) => dispatch(returnFigureInfo(figure)),
    updateLastViewedPaper: (user, id, timeStamp) => dispatch(updateLastViewedPaper(user, id, timeStamp)),
    returnPaperInfoHomeFeed: (paper) => dispatch(returnPaperInfoHomeFeed(paper))
})

const ConnectedPaper = connect(mapStateToProps, mapDispatchToProps)(Paper);

export default ConnectedPaper;