import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import { storage } from '../firebase/firebase'
import { ref, uploadBytesResumable } from 'firebase/storage'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid';
import { startAddAnonTag, updateAnonTagsOnProfile } from '../actions/profModels'
import Spinner from "react-svg-spinner";
import { useNavigate } from 'react-router-dom'


export const ApplyForAnonymousCredentials = (props) => {
    const [submitted, setSubmitted] = useState(false)
    const [newTag, setNewTag] = useState(true)
    const [applied, setApplied] = useState(false)

    const navigate = useNavigate();

    const uploadVerification = async (file, id) => {
        if (!file) return;
        const storageRef = ref(storage, `/tagVerifications/${id}`)
        let uploaded = await uploadBytesResumable(storageRef, file)
    }

    const applyForExistingTag = async (e) => { 
        e.preventDefault()
        let values = []
        setSubmitted(true)
        let timeStamp = moment().utc().toString()
        let occurrence = uuidv4()

        const file = e.target[0].files[0]

        let doc = await props.startAddAnonTag(values, props.user)
        let TagID = doc.id
        let a = await uploadthem(file, TagID)
    }
    
    const applyForNewTag = async (e) => {
        e.preventDefault()
        setSubmitted(true)
        let timeStamp = moment().utc().toString()
        let fileID = uuidv4()

        try {
            //First upload the verification file to the tagVerifications folder in Firebase storage.
            let upload = await uploadVerification(e.target[1].files[0], fileID)

            //Create the tagInfo to be included on the new tag document in Firebase.
            let tagInfo = {
                name: e.target.name.value,
                displayName: e.target.name.value,
                description: e.target.description.value,
                timeStamp: timeStamp,
                tagHolders: [
                    {
                        fellow: props.user,
                        timeStamp: timeStamp,
                        file: fileID
                    }
                ],
            }

            let tagID = await props.startAddAnonTag(tagInfo)
            let newTag = {
                displayName: tagInfo.displayName,
                id: tagID,
                verified: false
            }
            await props.updateAnonTagsOnProfile(newTag, props.user)
            setSubmitted(false)
            setApplied(true)
        } catch (error) {
            console.error(error)
        }
        
    }

    const apply = async (e) => { 
        e.preventDefault()
        if (newTag) {
            applyForNewTag(e)
        } else {
            applyForExistingTag(e)
        }
    }
       
    
    
    return (
        
        <form className="AddFigureForm" onSubmit={apply}>
            <div hidden={props.loggedIn} className="addFigureLogin" ><a className="anchor" href="/Login">
                Login or create an account</a> to post your own figures or criticisms
            </div>
            <div hidden={!props.loggedIn} className="AddFigureMajorContainer2">
                <div className={!applied ? '' : 'hidden'}>
                    <h1>Apply for a new Anon-tag</h1>
                    <br/>
                    <h2 className='ApplyTitleHeader'>{'Name your new anon-tag using the '}
                    <a className='ApplyAnchorNamingConvention' onClick={() => {navigate('/About/Anon-tags')}}>
                         anon-tag format</a>
                    </h2> 
                    <div>
                        <textarea required name='name' className="ApplyHalfTextArea" placeholder='examples: "Graduate Student - Bioengineering - BS", "Assistant Professor - Neurology - MD", "Lawyer - Intellectual Property - JD"'>
                        </textarea>
                    </div>
                    <br/>
                    <h2 className='ApplyTitleHeader'>Upload verification that proves you fit the description of the Anon-tag</h2>
                    <div className="ApplyUpload">
                        <input className="fileUpload" type="file" required/>
                    </div>
                    <br />
                    <h2 className='ApplyTitleHeader'>Anon-tag Description</h2>
                    <div>
                        <textarea name='description' required className="ApplyFullTextArea" placeholder="Describe in plain words what this Anon-tag will signify about you" >
                        </textarea>
                    </div>
                </div>
                <div className={applied ? 'ApplyApplied' : 'hidden'}>
                    <div>
                        We will review the verification documents that you provided and send you an email when you can start posting with your new Anon-tag.
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <button className='SettingsResetPasswordButton' onClick={() => {setApplied(false)}}>Apply for Another</button>
                </div>
            </div>

            <div className={props.loggedIn ? "centerButtonDiv" : "hidden"}>
                <div className={applied ? 'hidden' : ''}>
                    <button className={!submitted ? 'ApplySubmitNewTag' : 'ApplySubmitNewTagSubmitted'}>
                        <b className={submitted ? 'hidden' : ''}>Apply</b>
                        <Spinner hidden={!submitted}/>
                    </button>
                </div>
            </div>
        </form>
    )
}

const mapStateToProps = (state, props) => {
    if (state.profModels.length == 1) {
        return {
            user: state.profModels[0].id,
            userName: state.profModels[0].name,
            tags: state.profModels[0].tags,
            loggedIn: true
        };
    } else {
        return {
            user: '',
            userName: '',
            tags: [],
            loggedIn: false
        };
    }
}

const mapDispatchToProps = (dispatch) => ({
    startAddAnonTag: (tag) => dispatch(startAddAnonTag(tag)),
    updateAnonTagsOnProfile: (tagInfo, profileID) => dispatch(updateAnonTagsOnProfile(tagInfo, profileID))

})

const ConnectedApplyForAnonymousCredentials = connect(mapStateToProps, mapDispatchToProps)(ApplyForAnonymousCredentials);

export default ConnectedApplyForAnonymousCredentials;