import React, { useEffect, useState, useRef, useCallback } from 'react'
import { connect } from 'react-redux'
import { returnPaperInfoProfile } from '../../actions/paperModels';
import Spinner from "react-svg-spinner";
import SVGoptionsDotsProfilePapers from '../SVGs/SVGoptionsDotsProfilePapers';
import { useNavigate } from 'react-router-dom';


export const PapersList = (props) => {
    const [selectorOpen, setSelectorOpen] = useState(false)
    const [sortBySelectorPaper, setSortBySelectorClaim] = useState('None')
    const [papersList, setPapersList] = useState([])
    let selectorRef = useRef();
    const [loaded, setLoaded] = useState(false)
    const navigate = useNavigate()


    useEffect(async () => {
        if (!props.loaded) {
            setLoaded(false)
            return;
        }
        try {
            let thePapersList = []
            for (const paper of props.identifiedPapers) {
                let paperInfo = await props.returnPaperInfoProfile(paper.id)
                //If the paper is unavailable from Firebase or if it is anonymous and it is not a user viewing their own profile,
                //dont add the paper to the papersList.
                if (paperInfo.unavailable || (paperInfo.anonymous && props.user != props.fellowID)) {

                } else {
                    thePapersList.push(paperInfo)
                }
            }
            await thePapersList.all
            setPapersList(thePapersList)
            if (props.loaded) {
                setLoaded(true)
            }
        } catch (e) {
            // console.log(e)
        }
    }, [props.whichTabIsIt, props.loaded])

    useEffect(() => {
        let handler = (event) => {
            if(!selectorRef.current.contains(event.target)) {
                setSelectorOpen(false)
            }
        }
        document.addEventListener("mousedown", handler)
        return () => {
            document.removeEventListener("mousedown", handler)
        }
    })

    const sortPapersByExtractedClaims = () => {
        //if Extracted Claims is already selected, don't do anything.
        if (sortBySelectorPaper === '# of Extracted Claims') {
            setSelectorOpen(false)
            return;
        }

        //combine all data into array of objects for easy sorting.
        let allArrayData = [...papersList]

        //sort allArrayData on the basis of Confidence
        allArrayData.sort((a,b) => {
            if (a.numberOfExtractedClaims !== '?' && b.numberOfExtractedClaims !== '?') {
                return b.numberOfExtractedClaims - a.numberOfExtractedClaims; 
            } else if (a.numberOfExtractedClaims == '?') {
                return 1;
            } else if (b.numberOfExtractedClaims == '?') {
                return -1;
            }
        })
        
        setPapersList(allArrayData)
        setSelectorOpen(false)
        setSortBySelectorClaim('# of Extracted Claims')
    }

    const sortPapersByOldest = (tab) => {
        //if Oldest is already selected, don't do anything.
        if (sortBySelectorPaper === 'Oldest') {
            setSelectorOpen(false)
            return;
        }

        //combine all data into array of objects for easy sorting.
        let allArrayData = [...papersList]
        
        //sort allArrayData on the basis of what is oldest
        allArrayData.sort((a,b) => {
            if (a.moment.isBefore(b.moment)) {
                return -1; 
            } else {
                return 1;
            }
        })

        setPapersList(allArrayData)
        setSelectorOpen(false)
        setSortBySelectorClaim('Oldest')
    }

    const sortPapersByNewest = (tab) => {
        //if Oldest is already selected, don't do anything.
        if (sortBySelectorPaper === 'Newest') {
            setSelectorOpen(false)
            return;
        }

        //combine all data into array of objects for easy sorting.
        let allArrayData = [...papersList]
        
        //sort allArrayData on the basis of what is newest
        allArrayData.sort((a,b) => {
            if (a.moment.isBefore(b.moment)) {
                return 1; 
            } else {
                return -1;
            }
        })

        setPapersList(allArrayData)
        setSelectorOpen(false)
        setSortBySelectorClaim('Newest')
    }

    const sortPapersByNumberOfFigures = (tab) => {
        //if Oldest is already selected, don't do anything.
        if (sortBySelectorPaper === '# of Figures') {
            setSelectorOpen(false)
            return;
        }

        //combine all data into array of objects for easy sorting.
        let allArrayData = [...papersList]
        
        //sort allArrayData on the basis of Number of Figures
        allArrayData.sort((a,b) => b.numberOfFigures - a.numberOfFigures)

        setPapersList(allArrayData)
        setSelectorOpen(false)
        setSortBySelectorClaim('# of Figures')
    }

    function removeSubstring(originalString) {
        let split = originalString.split('/')
        let theURL = '/' + split[3] + '/' + split[4]
        return theURL;
    }

    function handleClick(event) {
        event.preventDefault();
        let URL = removeSubstring(event.target.href, event.target.baseURI)
        navigate(URL)
    }

    return (  
        <div className={props.whichTabIsIt == 'Papers' ? 'myClaimsWrapperContainer' : 'hidden'}>
            <div className="myClaimsClaimContainer">
                <div className='addClaimButtonMyClaims'>
                    <h1 className='myHeadersMyClaims'>Identified Papers</h1>
                </div>  
                <div className={papersList.length != 0 ? 'sortByDivMyClaimWrapper' : 'hidden'}>
                    <div className='slightSpacerMyClaim'>Sort by:</div>
                    <div className='sortByDivProfile'>
                        <span className='minimumWidthMyClaims'>{sortBySelectorPaper}</span>
                        <span className='tagsSpanMyClaims'>
                            <svg onClick={() => {setSelectorOpen(!selectorOpen)}} id="tagSVG" xmlns="http://www.w3.org/2000/svg" width="18" 
                            height="18" viewBox="0 0 24 24">
                                <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/>
                            </svg>
                        </span>
                        <div className='seletorsDivMyClaims' hidden={!selectorOpen} ref={selectorRef}>
                            <div className='selectorOptionsMyClaims' onClick={() => {sortPapersByExtractedClaims()}}># of Extracted Claims</div>
                            <div className='selectorOptionsMyClaims' onClick={() => {sortPapersByNumberOfFigures()}}># of Figures</div>
                            <div className='selectorOptionsMyClaims' onClick={() => {sortPapersByOldest()}}>Oldest</div>
                            <div className='selectorOptionsMyClaims' onClick={() => {sortPapersByNewest()}}>Newest</div>
                        </div>
                    </div>
                </div>
                <div>
                    {
                        papersList.map((paper, index) => {
                            return (
                                <div key={index + paper.id} className='userClaimWrapperMyClaims'>
                                    <div className='userClaimMyClaims'>
                                        <div className='myClaimsAnchorSpan'>
                                            <a className="ProfileFontBold" href={'/Paper/' + paper.id } onClick={handleClick}>{paper.title}</a>
                                            <div className='timeStampDivMyClaims'>- {paper.timeStamp}</div>
                                        </div>
                                        <div className='claimStatsWidgetMyPapers'>
                                            <div className='confidenceLoader'>  <Spinner hidden={loaded} /></div>
                                            <div hidden={!loaded}>
                                                <div className='countMyPapers'>
                                                    Claims: {paper.numberOfExtractedClaims} 
                                                </div>
                                                <div >
                                                    Figures: {paper.numberOfFigures}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className={loaded ? 'hidden' : 'ProfileComponentsSpinnerDiv'}>
                    <Spinner />
                </div>
                <div className={props.whichTabIsIt === 'Papers' && papersList.length == 0 && loaded ? 'noClaimsToShow' : 'hidden'}>
                    {props.fellowName == props.userName ? 'You have ' : props.fellowName + ' has '}not identified any papers yet.
                </div>
            </div>
        </div>
    ) 
}

const mapStateToProps = (state, props) => {
    if (state.profModels.length == 1) {
        return {
            user: state.profModels[0].id,
            userName: state.profModels[0].name,
            tags: state.profModels[0].tags,
            loggedIn: true,
        };
    } else {
        return {
            user: '',
            userName: '',
            tags: [],
            loggedIn: false
        };
    }
}

const mapDispatchToProps = (dispatch, profileProps) => ({
    returnPaperInfoProfile: (paper) => dispatch(returnPaperInfoProfile(paper))
})

const ConnectedPapersList = connect(mapStateToProps, mapDispatchToProps)(PapersList);

export default ConnectedPapersList;