import React, { useEffect, useState, useRef } from 'react';
import Tippy from '@tippyjs/react'
import { connect } from 'react-redux'
import Modal from './FigureShowModalClaim'
import SVGaddCriticism from './SVGs/SVGaddCriticism';
import SVGremoveConnection from './SVGs/SVGremoveConnection';
import DeleteModalConnected from './removeClaimConnectionModal';
import { useNavigate } from 'react-router-dom';
import Criticism from './ClaimCriticism'
import { startReallyContras, startSomewhatContras, startDoesNotContra,
        reverseReallyContras, reverseSomewhatContras, reverseDoesNotContra,
        startRemoveConnection, startAddCriticismClaim } from '../actions/claimModels';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';


export const FigureClaim = (props) => {
    
    
    const [openModal, setOpenModal] = useState(false)
    const [openDesc, setOpenDesc] = useState(true)
    const [optionsOpen, setOptionsOpen] = useState(false)
    const [doesNotSupportUserEngagement, setDoesNotSupportUserEngagement] = useState([])
    const [somewhatSupportsUserEngagement, setSomewhatSupportsUserEngagement] = useState([])
    const [reallySupportsUserEngagement, setReallySupportsUserEngagement] = useState([])
    const [voteCountCC, setVoteCountCC] = useState(props.reallySupports.length)
    const [voteCountC, setVoteCountC] = useState(props.somewhatSupports.length)
    const [voteCountX, setVoteCountX] = useState(props.doesNotSupport.length)
    const [figureRemoved, setFigureRemoved] = useState(false)
    const [deleteModalState, setDeleteModalState] = useState(false)
    const [tagsOpen, setTagsOpen] = useState(false)
    const [unchecked, setUnchecked] = useState(false)
    const [sortedArray, setSortedArray] = useState([...props.criticisms].sort((a,b) => {return (b.critUpVotes.length - b.critDownVotes.length) - (a.critUpVotes.length - a.critDownVotes.length);}))

    //The code below creates a new array with the criticisms that is sorted by its highest net approval.
    //It also creates an array of indexes to pass into the Criticism component so that it knows which index to update when a new vote is cast.

    let test = [...props.criticisms]
    let len = test.length;
    let indices = new Array(len);
    for (var i = 0; i < len; ++i) indices[i] = i;
    indices.sort(function (a, b) { return (test[a].critUpVotes.length - test[a].critDownVotes.length) < (test[b].critUpVotes.length - test[b].critDownVotes.length) ? 1 : (test[a].critUpVotes.length - test[a].critDownVotes.length) > (test[b].critUpVotes.length - test[b].critDownVotes.length) ? -1 : 0; });
    
    const [sortedIndices, setSortedIndices] = useState(indices)
    
    let optionsRefIcon = useRef();
    let optionsRefDiv = useRef();
    useEffect(() => {
        let handler = (event) => {
            if(!(optionsRefDiv.current.contains(event.target) || optionsRefIcon.current.contains(event.target))) {
                setOptionsOpen(false)
            }
        }
        document.addEventListener("mousedown", handler)
        return () => {
            document.removeEventListener("mousedown", handler)
        }
    })

    useEffect(() => {
        const doesNotSupportIndex = props.doesNotSupport.findIndex((vote) => {
            return vote.id == props.user
        })
        if (doesNotSupportIndex > -1) {
            setDoesNotSupportUserEngagement([props.doesNotSupport[doesNotSupportIndex]])
        }

        const somewhatSupportsIndex = props.somewhatSupports.findIndex((vote) => {
            return vote.id == props.user
        })
        if (somewhatSupportsIndex > -1) {
            setSomewhatSupportsUserEngagement([props.somewhatSupports[somewhatSupportsIndex]])
        }

        const reallySupportsIndex = props.reallySupports.findIndex((vote) => {
            return vote.id == props.user
        })
        if (reallySupportsIndex > -1) {
            setReallySupportsUserEngagement([props.reallySupports[reallySupportsIndex]])
        }
    }, [])


    const checkIfOwner = () => {
        if (props.user === props.connector) {
            return true;
        } else {
            return false;
        }
    }
    const doNotSupport = (e) => {
        e.preventDefault()
        if (!props.loggedIn) {
            return;
        }

        let timeStamp = moment().utc().toString()
        let occurrence = uuidv4();
        const Vote = {
            claimID: props.claimID,
            figureID: props.figid,
            user: props.user,
            imgsrc: props.imgsrc,
            occurrence: occurrence,
            timeStamp: timeStamp
        }

        let newEngagement = {
            id: props.user,
            occurrence: occurrence,
            timeStamp: timeStamp
        }

        if (doesNotSupportUserEngagement.length > 0) {
            setDoesNotSupportUserEngagement([])
            setVoteCountX(prev => prev - 1)
            props.setTotalEngagement(prev => props.removeEngagement(prev, doesNotSupportUserEngagement))
            props.setDoesNotContradictTally(props.doesNotContradictTally - 1)
            props.reverseDoesNotContra(Vote).then(() => {

            }).catch(() => {
                // console.log('figure not un-accepted')
            })
        } else {
            setVoteCountX(prev => prev + 1)
            if (somewhatSupportsUserEngagement.length > 0) {
                setVoteCountC(prev => prev - 1)
                props.setSomewhatContradictsTally(props.somewhatContradictsTally - 1)
                props.setTotalEngagement(prev => props.removeEngagement(prev, somewhatSupportsUserEngagement))
            }
            if (reallySupportsUserEngagement.length > 0) {
                setVoteCountCC(prev => prev - 1)
                props.setReallyContradictsTally(props.reallyContradictsTally - 1)
                props.setTotalEngagement(prev => props.removeEngagement(prev, reallySupportsUserEngagement))
            }
            setDoesNotSupportUserEngagement([newEngagement])
            setSomewhatSupportsUserEngagement([])
            setReallySupportsUserEngagement([])
            props.setTotalEngagement(prev => [...prev, newEngagement])
            props.setDoesNotContradictTally(props.doesNotContradictTally + 1)
            props.startDoesNotContra(Vote).then(() => {
                if(somewhatSupportsUserEngagement.length > 0) {
                    props.reverseSomewhatContras(Vote).then(() => {

                    }).catch(() => {
                        // console.log('figure not un-rejected')
                    })
                } else if (reallySupportsUserEngagement.length > 0) {
                    props.reverseReallyContras(Vote).then(() => {

                    }).catch(() => {
                        // console.log('figure not un-rejected')
                    })
                }
                // console.log('figure accepted')
            }).catch(() => {
                // console.log('figure not rejected')
            })
        }
    }
    
    const somewhatSupport = (e) => {
        e.preventDefault()
        if (!props.loggedIn) {
            return;
        }

        let timeStamp = moment().utc().toString()
        let occurrence = uuidv4();
        const Vote = {
            claimID: props.claimID,
            figureID: props.figid,
            user: props.user,
            imgsrc: props.imgsrc,
            occurrence: occurrence,
            timeStamp: timeStamp
        }

        let newEngagement = {
            id: props.user,
            occurrence: occurrence,
            timeStamp: timeStamp
        }
        if (somewhatSupportsUserEngagement.length > 0) {
            setSomewhatSupportsUserEngagement([])
            setVoteCountC(prev => prev - 1)
            props.setTotalEngagement(prev => props.removeEngagement(prev, somewhatSupportsUserEngagement))
            props.setSomewhatContradictsTally(props.somewhatContradictsTally - 1)
            props.reverseSomewhatContras(Vote).then(() => {

            }).catch(() => {
                // console.log('figure not un-accepted')
            })
        } else {
            setVoteCountC(prev => prev + 1)
            if (doesNotSupportUserEngagement.length > 0) {
                setVoteCountX(prev => prev - 1)
                props.setDoesNotContradictTally(props.doesNotContradictTally - 1)
                props.setTotalEngagement(prev => props.removeEngagement(prev, doesNotSupportUserEngagement))
            }
            if (reallySupportsUserEngagement.length > 0) {
                setVoteCountCC(prev => prev - 1)
                props.setReallyContradictsTally(props.reallyContradictsTally - 1)
                props.setTotalEngagement(prev => props.removeEngagement(prev, reallySupportsUserEngagement))
            }
            setDoesNotSupportUserEngagement([])
            setSomewhatSupportsUserEngagement([newEngagement])
            setReallySupportsUserEngagement([])
            props.setTotalEngagement(prev => [...prev, newEngagement])
            props.setSomewhatContradictsTally(props.somewhatContradictsTally + 1)
            props.startSomewhatContras(Vote).then(() => {
                if(doesNotSupportUserEngagement.length > 0) {
                    props.reverseDoesNotContra(Vote).then(() => {
                        
                    }).catch(() => {
                        // console.log('figure not un-rejected')
                    })
                } else if (reallySupportsUserEngagement.length > 0) {
                    props.reverseReallyContras(Vote).then(() => {
                        
                    }).catch(() => {
                        // console.log('figure not un-rejected')
                    })
                }
                // console.log('figure accepted')
            }).catch(() => {
                // console.log('figure not rejected')
            })
        }
    }

    const reallySupport = (e) => {
        e.preventDefault()
        if (!props.loggedIn) {
            return;
        }

        let timeStamp = moment().utc().toString()
        let occurrence = uuidv4();
        const Vote = {
            claimID: props.claimID,
            figureID: props.figid,
            user: props.user,
            imgsrc: props.imgsrc,
            occurrence: occurrence,
            timeStamp: timeStamp
        }

        let newEngagement = {
            id: props.user,
            occurrence: occurrence,
            timeStamp: timeStamp
        }
        if (reallySupportsUserEngagement.length > 0) {
            setReallySupportsUserEngagement([])
            setVoteCountCC(prev => prev - 1)
            props.setTotalEngagement(prev => props.removeEngagement(prev, reallySupportsUserEngagement))
            props.setReallyContradictsTally(props.reallyContradictsTally - 1)
            props.reverseReallyContras(Vote).then(() => {

            }).catch(() => {
                // console.log('figure not un-accepted')
            })
        } else {
            setVoteCountCC(prev => prev + 1)
            if (doesNotSupportUserEngagement.length > 0) {
                setVoteCountX(prev => prev - 1)
                props.setDoesNotContradictTally(props.doesNotContradictTally - 1)
                props.setTotalEngagement(prev => props.removeEngagement(prev, doesNotSupportUserEngagement))
            }
            if (somewhatSupportsUserEngagement.length > 0) {
                setVoteCountC(prev => prev - 1)
                props.setSomewhatContradictsTally(props.somewhatContradictsTally - 1)
                props.setTotalEngagement(prev => props.removeEngagement(prev, somewhatSupportsUserEngagement))
            }
            setDoesNotSupportUserEngagement([])
            setSomewhatSupportsUserEngagement([])
            setReallySupportsUserEngagement([newEngagement])
            props.setTotalEngagement(prev => [...prev, newEngagement])
            props.setReallyContradictsTally(props.reallyContradictsTally + 1)
            props.startReallyContras(Vote).then(() => {
                if(doesNotSupportUserEngagement.length > 0) {
                    props.reverseDoesNotContra(Vote).then(() => {

                    }).catch(() => {
                        // console.log('figure not un-rejected')
                    })
                } else if (somewhatSupportsUserEngagement.length > 0) {
                    props.reverseSomewhatContras(Vote).then(() => {

                    }).catch(() => {
                        // console.log('figure not un-rejected')
                    })
                }
                // console.log('figure accepted')
            }).catch(() => {
                // console.log('figure not rejected')
            })
        }
    }

    const addCriticism = (e) => {
        e.preventDefault()
        if (!props.loggedIn) {
            return;
        }
        let values = []
        let anonymous = true
        for (let i = 0; i < e.target.elements.length; i++) {
            if (e.target.elements[i].checked) {
                let array = e.target.elements[i].name.split('/')
                let ownerTag = {
                    displayName: array[0],
                    id: array[1]
                }
                values.push(ownerTag)
                if (ownerTag.displayName == props.userName) {
                    anonymous = false
                }
            }
        }
        if (values.length == 0) {
            setUnchecked(true)
            return;
        } 

        let timeStamp = moment().utc().toString()
        let occurrence = uuidv4();
        const criticism = {
            claimID: props.claimID,
            owner: props.user,
            ownerTags: values,
            critDownVotes: [],
            critUpVotes: [],
            text: e.target.critText.value,
            imgsrc: props.imgsrc,
            figIndex: props.figIndex,
            figType: 'contradict',
            occurrence: occurrence,
            timeStamp: timeStamp,
            anonymous: anonymous
        }
       
        const criticismToAddToState = {
            owner: props.user,
            ownerTags: values,
            critDownVotes: [],
            critUpVotes: [],
            text: e.target.critText.value,
            claimID: props.claimID,
            figType: 'contradict',
            occurrence: occurrence,
            timeStamp: timeStamp,
            anonymous: anonymous
        }
        const newEngagement = {
            id: props.user,
            occurrence: occurrence,
            timeStamp: timeStamp
        }

        setSortedArray(prev => [...prev, criticismToAddToState])
        setSortedIndices(prev => [...prev, prev.length])
        props.startAddCriticismClaim(criticism)
        props.setTotalEngagement(prev => [...prev, newEngagement])
        e.target.critText.value = ''
        setUnchecked(false)
    }
    const navigate = useNavigate()
    const reportFigure = () => {
        navigate('/ReportFigure/' + props.figid)
    }
    
    function removeSubstring(originalString) {
        let split = originalString.split('/')
        let theURL = '/' + split[3] + '/' + split[4]
        return theURL;
    }

    function handleClick(event) {
        event.preventDefault();
        let URL = removeSubstring(event.target.href, event.target.baseURI)
        navigate(URL)
    }
    
        return (
            <div hidden={figureRemoved}>
            {openModal && <Modal closeModal={setOpenModal} src={props.imgsrc} />}
            {deleteModalState && <DeleteModalConnected figureRemoved={setFigureRemoved} closeModal={setDeleteModalState}
            claimID={props.claimID} figid={props.figid} direction="contradicting" setDoesNotContradictTally={props.setDoesNotContradictTally} 
            setSomewhatContradictsTally={props.setSomewhatContradictsTally} setReallyContradictsTally={props.setReallyContradictsTally} voteCountX={voteCountX}
            voteCountC={voteCountC} voteCountCC={voteCountCC} doesNotContradictTally={props.doesNotContradictTally} 
            somewhatContradictsTally={props.somewhatContradictsTally} reallyContradictsTally={props.reallyContradictsTally}
            contraFigsToShow={props.contraFigsToShow} setContraFigsToShow={props.setContraFigsToShow} 
            critIndices={indices} setSortedArray={setSortedArray} setSortedIndices={setSortedIndices} sortedArray={sortedArray}
            fromHomePage={false}/>}
            <div className="smallFigureContainer">
            <div className="floatRightClaim">
                <div className='margin-right'>
                    <div hidden={!optionsOpen} ref={optionsRefDiv} className='dropDownClaim'>
                        
                        <div className={checkIfOwner() ? 'optionsAnchorDiv' : 'hidden'} onClick={() => {setDeleteModalState(true)}}>
                            <SVGremoveConnection />
                            <a  className={checkIfOwner() ? '' : 'hidden'}><b>Remove Connection</b></a>
                        </div>
                        
                        <div className='optionsAnchorDiv' onClick={reportFigure}>
                            
                                <svg className='marginSVG' width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 
                                    0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z" fill="#002c2c"/>
                                </svg>
                            
                            <a><b>Report this figure</b></a>
                        </div>
                        
                    </div>
                </div>
            </div>
                <div className='divAbove'>
                    <div className="titleDiv">
                        <a className='RestCursorPointer' href={'/Figure/' + props.figid} onClick={handleClick}>
                            {props.title}
                        </a>
                    </div>
                    <nav hidden={!props.loggedIn} ref={optionsRefIcon} >
                        <ul>
                            <Tippy content='Options'>
                                <svg hidden={!props.loggedIn} id="input5" onClick={() => setOptionsOpen(!optionsOpen)} version="1.1" viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                                    <svg width="288" height="288" enableBackground="new 0 0 32.055 32.055" viewBox="0 0 32.055 32.055" xmlns="http://www.w3.org/2000/svg">
                                        <path className="color000 svgShape" d="m3.968 12.061c-2.193 0-3.968 1.774-3.968 3.966s1.773 3.967 3.968 3.967c2.189 0 3.966-1.772 
                                        3.966-3.967 0-2.192-1.777-3.966-3.966-3.966zm12.265 0c-2.188 0-3.968 1.773-3.968 3.965s1.778 3.967 3.968 3.967 3.97-1.772 3.97-3.967c-2e-3 
                                        -2.191-1.78-3.965-3.97-3.965zm11.857 0c-2.192 0-3.969 1.774-3.969 3.967 0 2.19 1.774 3.965 3.969 3.965 2.188 0 3.965-1.772 
                                        3.965-3.965s-1.777-3.967-3.965-3.967z" fill="#002c2c"/>
                                    </svg>
                                </svg>
                                
                            </Tippy>
                            
                        </ul>
                    </nav>
                </div>
                <div className='divAbove2'>
                    
                    <div className='addedBy'>Connected by: </div>
                    {
                        props.ownerTags.map((tag) => {
                            return (<a key={tag.displayName} className="anchorNameClaim" onClick={() => {navigate('/Profile/' + tag.id)}}>{tag.displayName}</a>)
                        })
                    }
                </div>
                    
                <div className='smallFigConImage'>
                    <img onClick={() => {setOpenModal(true)}} className="figureShowImage2" 
                    src={props.imgsrc} alt="Figure image not found..."/>
                </div>
                <div className='claimFigDescExplanation'>
                    <h2>Explanation</h2>
                    <br/>
                    <p>{props.explanation}</p>
                    <br/>
                </div>
                <div className="claimFigDesc" hidden={openDesc}>
                    <h2>Description </h2>
                    <br/>
                    <p>{props.description}</p>
                    <br/>
                    <div className='line3'></div>
                    <h2>Criticisms </h2>
                    <br/>
                    <div className='criticismsDiv'>
                        {
                            sortedArray.map((crit, critIndex) => {
                                return (
                                    <Criticism key={crit.text + critIndex} ownerTags={crit.ownerTags} text={crit.text} downvotes={crit.critDownVotes}
                                    owner={crit.owner} imgsrc={props.imgsrc} figid={props.figid} loggedin={props.loggedIn} user={props.user}
                                    upvotes={crit.critUpVotes} critIndex={critIndex} figIndex={props.figIndex} figType={props.figType} 
                                    claimID={props.claimID} critIndices={sortedIndices} 
                                    setSortedArray={setSortedArray} sortedArray={sortedArray} setSortedIndices={setSortedIndices}
                                    occurrence={crit.occurrence} timeStamp={crit.timeStamp} totalEngagement={props.totalEngagement}
                                    removeEngagement={props.removeEngagement} setTotalEngagement={props.setTotalEngagement}/>
                                )   
                            })
                        }
                    </div>
                    <div className="noCriticismsClaim" hidden={!(sortedArray == 0)}>No criticisms yet...</div>
                    <br hidden={!(sortedArray == 0)} />
                    <div className='line3'></div>
                    <div className="claimCritDiv5">
                        <form className='formDivClaim' hidden={!props.loggedIn} onSubmit={addCriticism}>
                            <textarea  required={true} className="newCritClaim" 
                            placeholder="Add your own criticism..." name="critText"></textarea>
                                <label>
                                <input hidden={true} type="submit"/>
                                <div className='addDivClaim'>
                                
                                    <svg version="1.1" viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                                        <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path className="color000 svgShape" d="m24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" fill="#fff"/>
                                        </svg>
                                    </svg>
                                </div>
                                </label>
                            <div id="postAs">
                                <span className="postAsSpan">Post as:  </span>
                                <div className='tags'>
                                    
                                    <span className='firstCheck'>
                                        <div className="checkBoxDivFirst">
                                            <div className='FigureDiv1'>
                                                <input status='userName' className="checkBox1" type='checkbox'
                                                name={props.userName + '/' + props.user} value={props.userName}/>
                                                {props.userName}
                                            </div>
                                            <span className='tagsSpan'>
                                                <Tippy content='Post Tags'>
                                                    <svg transform={tagsOpen ? "rotate(180)" : ""} id="tagSVG" onClick={() => setTagsOpen(!tagsOpen)} 
                                                    xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                                                        <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/>
                                                    </svg>
                                                </Tippy>
                                            </span>
                                        </div>
    
                                        <div className={tagsOpen ? "postAsDiv" : "hidden"}>
                                            <div>
                                                {
                                                    props.tags.map((tag, index) => {
                                                        return (
                                                            <div key={tag.displayName + props.figid + '1'} className="checkBoxDiv">
                                                                <input status='tag' key={tag.displayName + props.figid} 
                                                                name={tag.displayName + '/' + tag.id} 
                                                                type='checkbox' className="checkBox" />
                                                                <label key={tag.displayName + '2'} className="label">
                                                                    {tag.displayName}
                                                                </label>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div className="ClaimRedFont" hidden={!unchecked} >You must check at least one box!</div> 
                        </form>
                    </div>
                </div>
                <div className="flexAndDesc">
                    <Tippy content ='View Description'>
                        <svg onClick={() => {setOpenDesc(!openDesc)}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" 
                        viewBox="0 0 24 24" transform={!openDesc ? "rotate(180)" : ""} id="openDesc">
                            <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/>
                        </svg>
                    </Tippy>
                    <div className="theFlexClaim">
                        <Tippy content='Does Not Contradict' >
                            <div hidden={!props.loggedIn} className='contraFigButtons' onClick={doNotSupport}>
                                <svg  id={doesNotSupportUserEngagement.length > 0 ? "input3ClaimClickedC" : "input3ClaimC"} version="1.1" viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                                    <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path className="color000 svgShape" d="m24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" fill={doesNotSupportUserEngagement.length > 0 ? "#FFFFFF" : "rgb(228, 92, 92)"}/>
                                    </svg>
                                </svg>
                            {voteCountX}
                            </div>
                        </Tippy>
                        <Tippy content='Somewhat Contradicts'>
                            <div hidden={!props.loggedIn} className='contraFigButtons' onClick={somewhatSupport}>
                                <svg id={somewhatSupportsUserEngagement.length > 0 ? "input3ClaimClickedC" : "input3ClaimC"}  name = {'props.image'} version="1.1" viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                                    <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path className="color000 svgShape" d="m20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" fill={somewhatSupportsUserEngagement.length > 0 ? "#FFFFFF" : "rgb(228, 92, 92)"}/>
                                    </svg>
                                </svg>
                            {voteCountC}
                            </div>
                        </Tippy>
                        <Tippy content ='Strongly Contradicts' >
                            <div hidden={!props.loggedIn} className='contraFigButtons' onClick={reallySupport}>
                                <svg id={reallySupportsUserEngagement.length > 0 ? "input6ClaimClickedC" : "input6ClaimC"}  version="1.1" viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg"  xmlnsXlink="http://www.w3.org/1999/xlink">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="288" height="288" viewBox="0 0 1200 1200">
                                        
                                        <path className="color000 svgShape" d="M900 465.1L1034.9 600 480 1154.9 165.11 840 300 705.1l180 180zM1034.9 180L900 45.1l-420 420-180-180L165.11 420 480 734.9z" fill={reallySupportsUserEngagement.length > 0 ? "#FFFFFF" : "rgb(228, 92, 92)"}></path>
                                    </svg>
                                </svg>
                            {voteCountCC}
                            </div>
                        </Tippy>
                    </div>  
                </div>
            </div>
            </div>
        )
    
} 


const mapDispatchToProps = (dispatch, props) => ({
    startDoesNotContra: (vote) => dispatch(startDoesNotContra(vote)),
    reverseDoesNotContra: (vote) => dispatch(reverseDoesNotContra(vote)),
    startSomewhatContras: (vote) => dispatch(startSomewhatContras(vote)),
    reverseSomewhatContras: (vote) => dispatch(reverseSomewhatContras(vote)),
    startReallyContras: (vote) => dispatch(startReallyContras(vote)),
    reverseReallyContras: (vote) => dispatch(reverseReallyContras(vote)),
    startRemoveConnection: (claimID, figureID, direction) => dispatch(startRemoveConnection(claimID, figureID, direction)),
    startAddCriticismClaim: (criticism) => dispatch(startAddCriticismClaim(criticism))
})

const ConnectedFigureClaim = connect(undefined, mapDispatchToProps)(FigureClaim);

export default ConnectedFigureClaim;
