import React from "react";


function SVGaddFigure() {
  return (
    <svg className='marginSVG' viewBox="0 0 24 24" width="20" height="20" xmlns="http://www.w3.org/2000/svg">
        <path fill="#002c2c" d="m7 2c1.695 1.942 2.371 3 4 3h13v17h-24v-20h7zm4 5c-2.339 0-3.537-1.388-4.917-3h-4.083v16h20v-13h-11zm2 6h3v2h-3v3h-2v-3h-3v-2h3v-3h2v3z"/>
    </svg>
  );
}

export default SVGaddFigure;