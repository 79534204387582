import React, { Component, useState, useRef, useMemo, useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from "react-router-dom"
import 'tippy.js/dist/tippy.css'
import 'react-circular-progressbar/dist/styles.css';

export const Theory = (props) => {
    let LoggedIn;
    if (props.user) {
        LoggedIn = true
    } else {
        LoggedIn = false
    }
    let navigate = useNavigate()

    function removeSubstring(originalString) {
        let split = originalString.split('/')
        let theURL = '/' + split[3] + '/' + split[4]
        return theURL;
    }

    function handleClick(event) {
        event.preventDefault();
        let URL = removeSubstring(event.target.href, event.target.baseURI)
        navigate(URL)
    }
       
    return (
        <div className="theoryMajorContainer" >
            <div className="loginAbout" >
                <div hidden={LoggedIn}>Create an account<a className="anchorAbout" href="/Login">here</a></div>
            </div>
            <div className='centerWrapperAbout'>
                <div className="containerAbout">
                    <div draggable="false" className='aboutText' >
                        <h1>Outwit</h1>
                        <br/>
                    </div>
                    
                    <div draggable="false" className='aboutTextH2' >
                        <h2>What is Outwit?</h2>
                        <br/>
                    </div>
                    <p className='paragraphsAbout'>
                        Outwit is a web app {'(under development)'} designed to illuminate scientific truth through guided collective participation and
                        streamlined access to data. Additionally, it tackles the issue of integrity in science by providing users with features that allow them to 
                        showcase their credentials in an anonymous way, shielding them from potential retaliation and ultimately rewarding superior scientific reasoning. 
                        This is achieved by bringing points of disagreement among experts to the forefront using an easily-interactable UI which allows them to quickly pinpoint 
                        the nature of discrepancies because the app allows users to find them easily. Outwit is designed to aid anyone who seeks greater scientific understanding 
                        from the micro to the mega scale all on one app that conveniently organizes and keeps a track record of their thoughts, and even their data, for them.
                    </p>
                    <div draggable="false" className='aboutTextH2' >
                        <h2>What is the unmet need on the web that Outwit will address?</h2>
                        <br/>
                    </div>
                    <p className='paragraphsAbout'>
                        <li>Lack of scientific consensus and reproducibility</li>
                        <li>Streamlined integration of individual ideas into collective ideas</li>
                        <li>Persecutation/Retaliation, either professional, financial, or political</li>
                        <li>Elitism/lack of access</li>
                    </p>
                    <div draggable="false" className='aboutTextH2' >
                        <h2>How does it work?</h2>
                        <br/>
                    </div>
                    <p className='paragraphsAbout'>
                        Outwit uses four fundamental elements to organize interaction on the app. These are Figures, Claims, Theories, and 
                        Papers. By breaking apart the different pieces of a scientific idea, areas of discrepancy are more easily pin-pointed,
                        thus expediting user's ability to address them. The app uses the relative strength of these individual elements in 
                        algorithms to calculate and display the confidence of different ideas. In this way, users can craft their ideas to present
                        the strongs cases possible for them.
                    </p>
                    <div draggable="false" className='aboutTextH2' >
                        <h2>What is a figure?</h2>
                        <br/>
                    </div>
                    <p className='paragraphsAbout'>
                        A figure is a statement that was explicitly-tested in one experiment and validated by empirically-derived data. These data are generally provided 
                        from some peer-reviewed publication, but not always. On the figure pagethe data are displayed along with the description of 
                        the experiment with its methods. The methods are any and all written instructions that tell the reader everything they need
                        to know, within reason, to replicate the experiment themself. Figures are not designed to provide scientific context about 
                        the broader implications of its findings, it simply tells users what the experiment was and its results. Context is derived
                        from connecting figures to claims. The Figure pages track its overall acceptance and list user criticisms.
                    </p>
                    <br/>
                    <p className='paragraphsAbout'>
                        Here is an example of a <a className='exampleAnchorAbout' onClick={handleClick} href="/Figure/1mWXPxP01xY1VsWMi5JW">figure</a>
                    </p>
                    <div draggable="false" className='aboutTextH2' >
                        <h2>What is a claim?</h2>
                        <br/>
                    </div>
                    <p className='paragraphsAbout'>
                        A claim is a statement that is supported or contradicted by multiple tested statements (figures) and is generally more 
                        difficult to explicitly test than a figure. It can have pieces of data that both support its validity as well as contradict 
                        its validity. Hence, claims have both a “support” and a “contradict” column. There are two metrics of the claim that are tracked at the 
                        top of the claim page. These are the claim's engagement (which reflects how many users have interacted with the claim) and the confidence.
                        The confidence percentage reflects the ratio of upvotes to downvotes as users review the figures attached to the claim.
                    </p>
                    <br/>
                    <p className='paragraphsAbout'>
                        Here is an example of a <a className='exampleAnchorAbout' onClick={handleClick} href="/Claim/5qC3lBWko44faIhnBeQc">claim</a>
                    </p>
                    <div draggable="false" className='aboutTextH2' >
                        <h2>What is a theory?</h2>
                        <br/>
                    </div>
                    <p className='paragraphsAbout'>
                    A theory is an essay that describes in comprehensive fashion an idea that requires much writing and contextualization to hash 
                    out. The validity of a theory is dependent upon the validity of multiple statements (claims) as each citation is a claim. When
                    a theory is published, the aggregate confidence scores of all the cited claims are used along with an overall "comprehensivity"
                    score of the theory to calculate its overall confidence.
                    </p>
                    <br/>
                    <p className='paragraphsAbout'>
                        Here is an example of a <a className='exampleAnchorAbout' href="/Theory/Fh8CtWmlbCFVABm8ywz1">theory</a>
                    </p>
                    <div draggable="false" className='aboutTextH2' >
                        <h2>How will Outwit protect against political persecution?</h2>
                        <br/>
                    </div>
                    <p className='paragraphsAbout'>
                    Outwit users will have the option of anonymous, credentialed posting. This is a service that Outwit provides to users
                    who (for a fee) will have any common credentials they seek to have displayed about themselves be verified by Outwit. Users
                    provide the necessary documentation and ID to verify the credential(s), then Outwit will issue them a tag to make posts 
                    with that displays the credential. Common credentials will be job titles, academic titles, or awards.
                    </p>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state, props) => {
    if (state.profModels.length == 1) {
        if (state.theoryModels.length == 0) {
            return {
                user: state.profModels[0].id,
                userName: state.profModels[0].name,
                tags: state.profModels[0].tags,
                loggedIn: true,
            };
        } else {
            return { 
                user: state.profModels[0].id,
                userName: state.profModels[0].name,
                tags: state.profModels[0].tags,
                loggedIn: true,
            }
        }
    } else {
        if (state.theoryModels.length == 0) {
            return {
                user: '',
                userName: '',
                tags: [],
                loggedIn: false,
            };
        } else {
            return { 
                user: '',
                userName: '',
                tags: [],
                loggedIn: false,
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => ({

})

const ConnectedTheory = connect(mapStateToProps, mapDispatchToProps)(Theory);

export default ConnectedTheory;