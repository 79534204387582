import React, { useEffect, useState, useRef, useCallback } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from "react-router-dom"
import { startAddCriticism, startRejectFigure, startDeleteCriticism, startSetFigure, 
    startDeleteFigure, reverseRejectFigure, startAcceptFigure, reverseAcceptFigure,
    updateLastViewedFigure, returnFigureInfoUpdates } from '../../actions/figModels';
import Modal from '../FigureShowModalClaim'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import Criticism from '../CriticismFigure';
import DeleteModal from '../deleteModalFigureUpdate';
import { history } from '../../routers/AppRouter'
import LoadingScreen from '../loadingScreen';
import SVGaddFigure from '../SVGs/SVGaddFigure';
import SVGDelete from '../SVGs/SVGDelete';
import SVGConnection from '../SVGs/SVGConnection';
import moment from 'moment';
import useWindowDimensions from '../../custom hooks/windowDimensions'
import { createNewTheory } from '../../actions/theoryModels'
import { v4 as uuidv4 } from 'uuid';


export const FigureUpdate = (props) => {
    //This function when used will check to see if the user's ID is in either the downVotes or upVotes of
    //the figure.

    const [upVoteEngagement, setUpVoteEngagement] = useState([{}])
    const [downVoteEngagement, setDownVoteEngagement] = useState([{}])
    const checkArray = (array) => {
        let check = false;
        for (let i = 0; i < array.length; i++) {
            if (array[i].id === props.user) {
                check = true;
            } 
        }
        return check;
    }

    
    //Check the figModel to see if the upVotes array contains the ID of the current profModel
    //Check the figModel to see if the downVotes array contains the ID of the current profModel
    const [downVoteFigure, setdownVoteFigure] = useState(false)
    const [upVoteFigure, setupVoteFigure] = useState(false)
    const [criticisms, setTheCriticisms] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const [unchecked, setUnchecked] = useState(false)
    const [optionsOpen, setOptionsOpen] = useState(false)
    const [tagsOpen, setTagsOpen] = useState(false)
    const [deleteModalState, setDeleteModalState] = useState(false)
    const [voteCountC, setVoteCountC] = useState(0)
    const [voteCountX, setVoteCountX] = useState(0)
    const [acceptanceRate, setAcceptanceRate] = useState(0)
    const [uniqueEngagement, setUniqueEngagement] = useState([])
    const [totalEngagement, setTotalEngagement] = useState([])

    

    const [alreadyEngaged, setAlreadyEngaged] = useState(false)
    const [openDesc, setOpenDesc] = useState(true)
    const [loaded, setLoaded] = useState(false)
    const [localDate, setLocalDate] = useState()
    const [messages, setTheMessages] = useState([])
    const [imgsrc, setImgsrc] = useState('')
    const [fromPaper, setFromPaper] = useState(false)
    const [title, setTitle] = useState('')
    const [paperID, setPaperID] = useState('')
    const [paperDoi, setPaperDoi] = useState('')
    const [ownerTags, setOwnerTags] = useState([])
    const [owner, setOwner] = useState('')
    const [image, setImage] = useState('')
    const [description, setDescription] = useState('')
    const [notDeleted, setNotDeleted] = useState(true)
    const [newConnectedClaims, setNewConnectedClaims] = useState([])
    const [updatedLastViewedAlready, setUpdatedLastViewedAlready] = useState(false)
    const [timeStampPageVisit, setTimeStampPageVisit] = useState()
    
    let optionsRef = useRef();

    //This function takes the update object of any given figure off Firebase as well as the last time
    //the figure was viewed by a user. Then it returns an object with a messages array and a types array.
    //The messages are displayed on the update to let the user know the nature of the update with that figure.
    //The 
    const getLatestUpdatesFigure = (updates, lastViewed) => {
        let types = [];
        let messages = [];
        let accepts = false;
        let claimConnections = false;
        let theClaims = [];
        let comments = false;
        let rejects = false;
        for (const update of updates.accepts) {
            const date1 = new Date(update.timeStamp)
            const date2 = new Date(lastViewed)
            if (date1 > date2) {
                accepts = true;
            }
        }
        if (accepts) {
            messages.push('More fellows are accepting this figure.')
            types.push('accepts')
        }
        for (const update of updates.claimConnections) {
            const date1 = new Date(update.timeStamp)
            const date2 = new Date(lastViewed)
            if (date1 > date2) {
                claimConnections = true;
                if (!theClaims.includes(update.id)) {
                    theClaims.push(update.id)
                }
            }
        }

        

        if (claimConnections) {

            //Don't actually need this part anymore because we list each individual claim that the 
            //figure is connected to.
            // messages.push('Your figure was recently connected to claims: ')

            types.push('claimConnections')
        }
        for (const update of updates.comments) {
            const date1 = new Date(update.timeStamp)
            const date2 = new Date(lastViewed)
            if (date1 > date2) {
                comments = true;
            }
        }
        if (comments) {
            messages.push('This figure has new comments.')
            types.push('comments')
        }
        for (const update of updates.rejects) {
            const date1 = new Date(update.timeStamp)
            const date2 = new Date(lastViewed)
            if (date1 > date2) {
                rejects = true;
            }
        }
        if (rejects) {
            messages.push('More fellows are rejecting this figure.')
            types.push('rejects')
        }
        return { messages: messages,
                types: types,
                theClaims: theClaims }
    }

    //This useEffect fetches the relevant information of the figure and then updates all the state values
    //so that the user can easily-interact with it from the home page.
    useEffect( async () => {
        //Make call to Firebase with returnFigureInfoUpdates function to fetch info off of database.
        let figureInfo = await props.returnFigureInfoUpdates(props.id)
        //If info is able to be fetched, update all relevant state values.
        if (figureInfo) {
            const theDate = new Date(figureInfo.timeStamp)
            const newMoment = moment(theDate)
            newMoment.local()
            const theLocalDate = newMoment.format('LLL')
            const updates = getLatestUpdatesFigure(figureInfo.updates, props.lastViewed)


            //Here we record the time that the info of this figure was pulled off of Firebase. This will
            //be used as the time the user last viewed this figure. We call the appropriate function:
            let timeStamp = moment().utc().toString()
            setTimeStampPageVisit(timeStamp)
            

            if (updates.types.length != 0) {
                setdownVoteFigure(checkArray(figureInfo.downVotes))
                setupVoteFigure(checkArray(figureInfo.upVotes))
                setDownVoteEngagement(checkArray(figureInfo.downVotes))
                setUpVoteEngagement(checkArray(figureInfo.upVotes))
                setVoteCountC(figureInfo.upVotes.length)
                setVoteCountX(figureInfo.downVotes.length)
                setTheCriticisms(figureInfo.criticisms)
                setTotalEngagement(figureInfo.engagement)
                setAlreadyEngaged(figureInfo.engagement.includes(props.user))
                setLocalDate(theLocalDate)
                setTheMessages(updates.messages)
                setImgsrc(figureInfo.imgsrc)
                setFromPaper(figureInfo.fromPaper)
                setTitle(figureInfo.title)
                setPaperID(figureInfo.paperID)
                setPaperDoi(figureInfo.paperDoi)
                setOwnerTags(figureInfo.ownerTags)
                setOwner(figureInfo.owner)
                setImage(figureInfo.image)
                setDescription(figureInfo.description)
                setNewConnectedClaims(updates.theClaims)
                setLoaded(true)
            }
        } else {
            //If the figure is not available on Firebase, it is probably because it was deleted.
            //If it was deleted, nothing should be shown.
            setNotDeleted(false)
        }
    }, [])


    //This useEffect simply calculates the acceptance rate of the figure so that it can be displayed.
    useEffect(() => {
        let upVotes = voteCountC
        let downVotes = voteCountX
        let calculatedAcceptanceRate = Math.round(upVotes/(downVotes + upVotes)*100*100/100)
        if (isNaN(calculatedAcceptanceRate)) {
            calculatedAcceptanceRate = "?"
        }
        setAcceptanceRate(calculatedAcceptanceRate)
    }, [voteCountC, voteCountX])

    //This function counts the unique engagement on the figure. It makes sure it doesnt double-count
    //any given user if they have multiple interactions with the figure.
    function getUniqueEngagement (objects) {
        const seenIds = new Set();
        return objects.filter(obj => {
            if (seenIds.has(obj.id)) {
            return false;
            } else {
            seenIds.add(obj.id);
            return true;
            }
        });
    }

    //Use and useEffect here to calculate the unique engagement using the function above and set that
    //equal to the totalEngagement which will be the numner displayed on the figure.
    useEffect(() => {
        let theUniqueEngagement = getUniqueEngagement(totalEngagement)
        setUniqueEngagement(theUniqueEngagement)
    }, [totalEngagement])


    //When an engagement needs to be removed, like when a comment is deleted or the figure is unaccepted,
    //we use this function to remove that particular engagement from the total engagements array.
    function removeEngagement (array1, array2) {
        return array1.filter(obj1 => !array2.some(obj2 => obj1.occurrence === obj2.occurrence));
    }

    

    //This useEffect mediates the response to the user clicking on the options button of the figure.
    useEffect(() => {
        let handler = (event) => {
            if(!optionsRef.current.contains(event.target)) {
                setOptionsOpen(false)
            }
        }
        document.addEventListener("mousedown", handler)
        return () => {
            document.removeEventListener("mousedown", handler)
        }
    })

    // useEffect(async () => {
    //     if (props.loggedIn) {
    //         let timeStamp = moment().utc().toString()
    //         await props.updateLastViewedFigure(props.user, props.id, timeStamp)
    //     }
    //     // props.updateClaimsAssFirebase()
    // }, [])

    // let timeStamp1 = moment().utc().toString()

    //This function below logs when the user has viewed the figure so that the "lastViewed" value
    //can be updated in Firebase.
    const observer = useRef()
    const lastUpdateElementRef = useCallback((node) => {
        // if (observer.current) {
        //     observer.current.disconnect()
        // }
        // let timeStamp2 = moment().utc().toString()
        // observer.current = new IntersectionObserver((entry) => {
        //     if (entry[0].isIntersecting && !updatedLastViewedAlready) {
        //         props.updateLastViewedFigure(props.user, props.id, timeStamp2)
        //         setUpdatedLastViewedAlready(true)
        //         // console.log('viewing')
        //     }
        // })
        // if (node) {
        //     observer.current.observe(node)
        // }
        
    }, [updatedLastViewedAlready])


    //Check the Redux store for the profModel to see if user logged in.

    //Check the Redux store to see if a figModel was found and loaded, given the ID in the URL.
    

    // const increaseEngagementArray = () => {
    //     if (!alreadyEngaged) {
    //         let newEngagementArray = totalEngagement + 1 
    //         setTotalEngagement(newEngagementArray)
    //         setAlreadyEngaged(true)
    //     }
    // }


    //Code to reject the figure on home page if the user wishes to.
    const rejectFigure = (e) => {
        e.preventDefault()
        if (!props.loggedIn) {
            return;
        }
        let timeStamp = moment().utc().toString()
        let occurrence = uuidv4();

        const Vote = {
            figureID: props.id,
            user: props.user,
            imgsrc: imgsrc,
            timeStamp: timeStamp,
            occurrence: occurrence,
            lastViewed: timeStampPageVisit
        }

        let newEngagement = {
            id: props.user,
            occurrence: occurrence,
            timeStamp: timeStamp
        }
        
        if (downVoteFigure) {
            setdownVoteFigure(false)
            setVoteCountX(prev => prev - 1)
            setTotalEngagement(prev => removeEngagement([...prev], [...downVoteEngagement]))
            setDownVoteEngagement([{}])
            props.reverseRejectFigure(Vote).then(() => {
                
            }).catch(() => {
                console.log('figure not un-rejected')
            })
        } else {
            setdownVoteFigure(true)
            if (upVoteFigure) {
                setTotalEngagement(prev => removeEngagement([...prev], [...upVoteEngagement]))
                setVoteCountC(prev => prev - 1)
                setUpVoteEngagement([])
            }
            setupVoteFigure(false)
            setVoteCountX(prev => prev + 1)
            setDownVoteEngagement([newEngagement])
            setTotalEngagement(prev => [...prev, newEngagement])
            props.startRejectFigure(Vote).then(() => {
                if (upVoteFigure) {
                    props.reverseAcceptFigure(Vote).then(() => {
                        
                    }).catch(() => {
                        console.log('figure not un-accepted')
                    })
                }
            }).catch(() => {
                console.log('figure not rejected')
            })
        }
    }

    //Code to accept the figure on home page if the user wishes to.
    const acceptFigure = (e) => {
        e.preventDefault()
        // increaseEngagementArray()
        if (!props.loggedIn) {
            return;
        }

        let timeStamp = moment().utc().toString()
        let occurrence = uuidv4();

        const Vote = {
            figureID: props.id,
            user: props.user,
            imgsrc: imgsrc,
            timeStamp: timeStamp,
            occurrence: occurrence,
            lastViewed: timeStampPageVisit
        }

        let newEngagement = {
            id: props.user,
            occurrence: occurrence,
            timeStamp: timeStamp
        }

        if (upVoteFigure) {
            setupVoteFigure(false)
            setVoteCountC(prev => prev - 1)
            setTotalEngagement(prev => removeEngagement([...prev], [...upVoteEngagement]))
            props.reverseAcceptFigure(Vote).then(() => {
                // console.log('figure un-accepted')
            }).catch(() => {
                console.log('figure not un-accepted')
            })
        } else {
            setdownVoteFigure(false)
            if (downVoteFigure) {
                setTotalEngagement(prev => removeEngagement([...prev], [...downVoteEngagement]))
                setVoteCountX(prev => prev - 1)
                setDownVoteEngagement([])
            }
            setupVoteFigure(true)
            setVoteCountC(prev => prev + 1)
            setUpVoteEngagement([newEngagement])
            setTotalEngagement(prev => [...prev, newEngagement])
            props.startAcceptFigure(Vote).then(() => {
                if(downVoteFigure) {
                    props.reverseRejectFigure(Vote).then(() => {

                    }).catch(() => {
                        console.log('figure not un-rejected')
                    })
                }
                // console.log('figure accepted')
            }).catch(() => {
                console.log('figure not accepted')
            })
        }
    }
    const navigate = useNavigate()
    const ConnectFigure = () => {
        
        navigate('/ConnectFigure')
    }


    //This function simply removes the portion of the HTML element that we dont need in order to store the info
    //on which user ID is posting the criticism. I only added the extra portion in the first place because otherwhise,
    //there would be multiple input elements on the home page that have the same id.
    function extractBeforeSlash(inputString) {
        // Finding the index of the first occurrence of "/"
        const slashIndex = inputString.indexOf("/");
    
        // If "/" is not found, return the entire string
        if (slashIndex === -1) {
            return inputString;
        }
    
        // Otherwise, return the substring up to the index of "/"
        return inputString.substring(0, slashIndex);
    }
    
    //Code for adding and deleteing Criticisms on home page if the user wishes to.
    const addCriticism = (e) => {

        e.preventDefault()
        // increaseEngagementArray()
        let values = []
        let anonymous = true
        for (let i = 0; i < e.target.elements.length; i++) {
            // console.log(e.target.elements[i])
            if (e.target.elements[i].checked) {
                let ownerTag = {
                    displayName: e.target.elements[i].name,
                    id: extractBeforeSlash(e.target.elements[i].id)
                }
                values.push(ownerTag)
                // console.log(ownerTag)

                if (ownerTag.displayName == props.userName) {
                    anonymous = false
                }
            }
        }
        if (values.length == 0) {
            // console.log('didnt check any boxes')
            setUnchecked(true)
            return;
        } 
        // console.log(values)
        let timeStamp = moment().utc().toString()
        let theOccurrence = uuidv4()
        const newCriticism = {
            figureID: props.id,
            owner: props.user,
            ownerTags: values,
            critDownVotes: [],
            critUpVotes: [],
            text: e.target.critText.value,
            imgsrc: imgsrc,
            timeStamp: timeStamp,
            occurrence: theOccurrence,
            anonymous: anonymous
        }
        let newEngagement = {
            id: props.user,
            occurrence: theOccurrence,
            timeStamp: timeStamp
        }
        setTotalEngagement(prev => [...prev, newEngagement])
        e.target.critText.value = ''
        setTheCriticisms(prev => [...prev, newCriticism])
        setUnchecked(false)
        props.startAddCriticism(newCriticism).then(() => {
            
        }).catch((err) => {
            console.log(err)
        })
    }


    //Function to send user to page to report a figure if they would like to report it.
    const reportFigure = () => {
        navigate('/Report/Figure/' + props.id)
    }

    function removeSubstring(originalString) {
        let split = originalString.split('/')
        let theURL = '/' + split[3] + '/' + split[4]
        return theURL;
    }

    function handleClick(event) {
        event.preventDefault();
        let URL = removeSubstring(event.target.href, event.target.baseURI)
        navigate(URL)
    }


    return (  
        <div className={loaded && notDeleted ? "" : "hidden"}>
        {openModal && <Modal closeModal={setOpenModal} src={imgsrc} />}
        {deleteModalState && <DeleteModal id={props.id} imgsrc={imgsrc} setNotDeleted={setNotDeleted}
        closeModal={setDeleteModalState} element='figure' user={props.user}/>}
            <div hidden={!loaded} className="majorContainerFigureUpdate">
                <div className="smallFigureContainerHome">
                    <div className='figureUpdatePaddingHome'>
                        <div className='updateLine'>
                            <p className={props.lastViewed == 'unknown' ? '' : 'hidden'}>- from elsewhere.</p>

                            {
                                messages.map((message, index) => {
                                    return <p key={index}>- {message}</p>
                                })
                            }
                            {
                                newConnectedClaims.map((claim, index) => {
                                    return <p key={index + claim}>- This figure was connected to a <a className='figureUpdateClaimAnchorHome' onClick={handleClick}
                                    href={'/Claim/' + claim}>claim</a>.</p>
                                })
                            }
                        </div>
                        <div className='line3'></div>
                        <div className='figureUpdateTitleHomeDiv'>
                            <a className='figureUpdateTitleHome' onClick={handleClick} href={'/Figure/' + props.id}>{title}</a>    
                        </div>
                        <div className={!fromPaper ? 'hidden' : 'fromPaper'}><b>Source: <a className="paperAnchor" href={'/Paper/' + paperID}
                        onClick={handleClick}>
                            Paper</a></b>
                        </div>
                        <div hidden={!fromPaper} id="doi"><b>DOI: </b>
                            <a className="anchor" href={paperDoi} >{paperDoi}</a>
                        </div>
                        <div className='divAbove2Figure'>
                            <div className='addedByFigure'><b>Uploaded by: </b></div>
                            {
                                ownerTags.map((tag, index) => {
                                    return (<a key={tag.displayName + index} className="anchorNameClaim" href={'/Profile/' + tag.id }
                                    onClick={handleClick}>{tag.displayName}
                                    </a>)
                                })
                            }
                        </div>
                        <div className='divAbove3Theory'><b>- {localDate}</b></div>
                    </div>
                    <br/>
                    <div className="figureContainerHome">
                        <div hidden={!props.loggedIn} ref={optionsRef} className='topBarFigureUpdateHome'>
                            <div className='centerVertical'>
                                <div className='verticalStrecthFigure'>
                                    <div><b>Acceptance Rate: {acceptanceRate}%</b></div>
                                    <div><b>Engagement: {uniqueEngagement.length}</b></div>
                                </div>
                            </div>
                            <div>
                                <Tippy content='Options'>
                                    <svg hidden={!props.loggedIn} id="input5" onClick={() => setOptionsOpen(!optionsOpen)} version="1.1" 
                                    viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                                        <svg width="288" height="288" enableBackground="new 0 0 32.055 32.055" viewBox="0 0 32.055 32.055" 
                                        xmlns="http://www.w3.org/2000/svg">
                                            <path className="color000 svgShape" d="m3.968 12.061c-2.193 0-3.968 1.774-3.968 3.966s1.773 3.967 3.968 3.967c2.189 0 3.966-1.772 3.966-3.967 0-2.192-1.777-3.966-3.966-3.966zm12.265 0c-2.188 0-3.968 1.773-3.968 3.965s1.778 3.967 3.968 3.967 3.97-1.772 3.97-3.967c-2e-3 -2.191-1.78-3.965-3.97-3.965zm11.857 0c-2.192 0-3.969 1.774-3.969 3.967 0 2.19 1.774 3.965 3.969 3.965 2.188 0 3.965-1.772 3.965-3.965s-1.777-3.967-3.965-3.967z" fill="#002c2c"/>
                                        </svg>
                                    </svg>
                                </Tippy>
                                <div hidden={!optionsOpen} className='dropDownFigure'>
                                    <div className='optionsAnchorDiv' hidden={props.user != owner} 
                                    onClick={() => {navigate('/AddFigure')}}>
                                        <SVGaddFigure/>
                                        <a><b>Add a figure</b></a>
                                    </div>
                                    <div className='optionsAnchorDiv' onClick={() => setDeleteModalState(true)} 
                                    hidden={props.user != owner} href={image}>
                                        <SVGDelete/>
                                        <a><b>Delete this figure</b></a>
                                    </div>
                                    <div className='optionsAnchorDiv' onClick={reportFigure}>
                                        <svg className='marginSVG' width="20" height="20" viewBox="0 0 24 24" 
                                        xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 
                                            0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z" fill="#002c2c"/>
                                        </svg>
                                        <a><b>Report this figure</b></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img onClick={() => {setOpenModal(true)}} className="figureShowImage" 
                        src={imgsrc} alt="Figure image not found..."/>
                    </div>
                    <div className="theFlexHome">
                        <Tippy content ={!openDesc ? 'Hide Info' : 'View Info'} >
                            <svg onClick={() => {setOpenDesc(!openDesc)}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" 
                            viewBox="0 0 24 24" transform={!openDesc ? "rotate(180)" : ""} className="openDescHomeFigure">
                                <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/>
                            </svg>
                        </Tippy>
                        <div ref={lastUpdateElementRef} className='centerFigureVotesHome'> 
                            <Tippy content='Reject figure'>
                                <div className="supportFigButtons" onClick={rejectFigure}>
                                    <svg name ={image} className={downVoteFigure ? "rejectFigureHomeClicked" : "rejectFigureHome"} 
                                    version="1.1" viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                                        <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path className="color000 svgShape" d="m24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" 
                                            fill={downVoteFigure ? "#FFFFFF" : "#002c2c"}/>
                                        </svg>
                                    </svg>
                                    {voteCountX}
                                </div>
                            </Tippy>
                            <Tippy content='Accept figure'>
                                <div className="supportFigButtons" onClick={acceptFigure}>
                                    <svg  className={upVoteFigure ? "acceptFigureHomeClicked" : "acceptFigureHome"} name = {image} 
                                    version="1.1" viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                                        <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path className="color000 svgShape" d="m20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" fill={upVoteFigure ? "#FFFFFF" : "#002c2c"}/>
                                        </svg>
                                    </svg>
                                    {voteCountC}
                                </div>
                            </Tippy>
                        </div>
                    </div>
                    <div className='paddingLineHome' hidden={openDesc}>
                        <div className='line3' ></div>
                    </div>
                    <div className="figDescHome" hidden={openDesc}>
                        <h2>Description </h2>
                        <br/>
                        <p>{description}</p>
                        <br/>
                        <div className='line3'></div>
                        <h2>Criticisms </h2>
                        <br/>
                        <div className="theTestClaimCrit">
                            <div className='criticismsDiv'>
                                {
                                    criticisms.map((crit, index) => {
                                        return (
                                            <Criticism key={crit.text} ownerTags={crit.ownerTags} text={crit.text} downvotes={crit.critDownVotes}
                                            owner={crit.owner} imgsrc={imgsrc} id={props.id} loggedin={props.loggedIn} 
                                            user={props.user} upvotes={crit.critUpVotes} timeStamp={crit.timeStamp} occurrence={crit.occurrence}
                                            criticisms={[...criticisms]} setTheCriticisms={setTheCriticisms} index={index}
                                            totalEngagement={totalEngagement} setTotalEngagement={setTotalEngagement} removeEngagement={removeEngagement} />
                                        )   
                                    })
                                }
                            </div>
                        </div>
                        <div className="noCriticismsClaim" hidden={!(criticisms.length == 0)}>
                            No criticisms of this figure have been made yet...
                        </div>
                        <br hidden={!(criticisms.length == 0)} />
                        <div className='line3'></div>
                        <div className="claimCritDiv5">
                        
                            <form className='formDivClaim' hidden={!props.loggedIn} onSubmit={addCriticism}>
                                <textarea  required={true} className="newCritClaim" 
                                placeholder="Add your own criticism..." name="critText"></textarea>
                                <label>
                                    <input hidden={true} type="submit"/>
                                    <div className='addDivClaim'>
                                        <svg version="1.1" viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                                            <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path className="color000 svgShape" d="m24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" fill="#fff"/>
                                            </svg>
                                        </svg>
                                    </div>
                                </label>
                                <div id="postAsSmall">
                                    <span className="postAsSpan">Post as:  </span>
                                    <div className='tags'>
                                        <span className='firstCheck'>
                                            <div className="checkBoxDivFirst">
                                                <div className='FigureDiv1'>
                                                    <input status='userName' className="checkBox1" type='checkbox' id={props.user + '/' + props.id}
                                                    name={props.userName} value={props.userName}/>
                                                    {props.userName}
                                                </div>
                                                <span className='tagsSpan'>
                                                    <Tippy content='Post Tags'>
                                                        <svg transform={tagsOpen ? "rotate(180)" : ""} id="tagSVG" 
                                                        onClick={() => setTagsOpen(!tagsOpen)} xmlns="http://www.w3.org/2000/svg" width="18" 
                                                        height="18" viewBox="0 0 24 24">
                                                            <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/>
                                                        </svg>
                                                    </Tippy>
                                                </span>
                                            </div>
                                            <div className={tagsOpen ? "postAsDiv" : "hidden"}>
                                                <div>
                                                    {
                                                        props.tags.map((tag, index) => {
                                                            return (
                                                                <div key={tag.displayName + index + 'div'} className="checkBoxDiv">
                                                                    <input status='tag' key={tag.displayName + 'input'} name={tag.displayName} 
                                                                    id={tag.id + '/' + props.id} type='checkbox' className="checkBox" />
                                                                    <label key={tag.displayName + '2'} className="label">{tag.displayName}</label>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className="redFontFigure2" hidden={!unchecked} >You must check at least one box!</div> 
                            </form>
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
            </div>
        </div>
    ) 
}

const mapStateToProps = (state, props) => {
    if (state.profModels.length == 1) {
        return {
            user: state.profModels[0].id,
            userName: state.profModels[0].name,
            tags: state.profModels[0].tags,
            loggedIn: true,
        };
    } else {
        return {
            user: '',
            userName: '',
            tags: [],
            loggedIn: false
        };
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    startSetFigure: (id) => dispatch(startSetFigure(id)),
    startAddCriticism: (criticism) => dispatch(startAddCriticism(criticism)),
    startDeleteCriticism: (critText, id, imgsrc) => dispatch(startDeleteCriticism(critText, id, imgsrc)),
    startDeleteFigure: (id, user) => dispatch(startDeleteFigure(id, user)),
    startRejectFigure: (downVote) => dispatch(startRejectFigure(downVote)),
    reverseRejectFigure: (downVote) => dispatch(reverseRejectFigure(downVote)),
    startAcceptFigure: (upVote) => dispatch(startAcceptFigure(upVote)),
    reverseAcceptFigure: (upVote) => dispatch(reverseAcceptFigure(upVote)),
    updateLastViewedFigure: (userID, figureID, timeStamp) => dispatch(updateLastViewedFigure(userID, figureID, timeStamp)),
    createNewTheory: (owner, timeStamp) => dispatch(createNewTheory(owner, timeStamp)),
    returnFigureInfoUpdates: (figure) => dispatch(returnFigureInfoUpdates(figure)),
    updateLastViewedFigure: (userID, figureID, timeStamp) => dispatch(updateLastViewedFigure(userID, figureID, timeStamp))
})

const ConnectedFigureUpdate = connect(mapStateToProps, mapDispatchToProps)(FigureUpdate);

export default ConnectedFigureUpdate;