import React from "react";

function Modal(props) {

    

    return (
        <div onClick={() => {props.closeModal(false)}} className="modalBackgroundClaim">
            <img  src={props.src}></img>
        </div>
    )
}

export default Modal