import uuid from 'uuid';
import { db, claimCollection, figureCollection } from '../firebase/firebase'
import { doc, setDoc, getDoc, addDoc, deleteDoc } from 'firebase/firestore'
import { setProfile } from './profModels';
import moment from 'moment'
// import configureStore from '../store/configureStore'
// const store = configureStore()

// function that returns the dispatch call you need to make to the store
export const addClaim = (claim) => ({
  type: 'ADD_CLAIM',
  claim
});



// export const startSetClaim = (id) => {
//   return (dispatch) => {
//   const docRef = doc(db, "Claims", id);
//     return getDoc(docRef).then((doc) => {
//       const ClaimData = doc.data()
//       // console.log('this called')
//       dispatch(setClaim({
//         ...ClaimData,
//         id: doc.id 
//       }))
//       }).catch(() => {
//         console.log('startSetClaim not working')
//     })
//   }
// }

export const startSetClaim = (id) => {
  return async (dispatch) => {
    try {
      const docRef = doc(db, "Claims", id);
      const ClaimDocument = await getDoc(docRef)
      const ClaimData = ClaimDocument.data()
      dispatch(setClaim({
        ...ClaimData,
        id: ClaimDocument.id 
      }))
    } catch (err) {
      console.log(err)
    }
  }
}

function removeEngagement (array1, array2) {
  return array1.filter(obj1 => !array2.some(obj2 => obj1.occurrence === obj2.occurrence));
}

function getUniqueEngagement (objects) {
  const seenIds = new Set();
  return objects.filter(obj => {
      if (seenIds.has(obj.id)) {
      return false;
      } else {
      seenIds.add(obj.id);
      return true;
      }
  });
}

//Method for connecting figures to a claim should do the following:
//1) Add a contraFig and supportFig object to those arrays for each contradicting and supporting figure respectively. - check
//2) Add an update to the figureConnections array under the updates object for each figure being connected. - check
//3) Add an associatedClaim object to the associatedClaims array of each figure that represents the claim. - check
//4) Add a claimConnection update to the "claimConnections" array under the updates object of each figure being connected. - check
//Validated ConnectClaimPage/:id
export const connectFiguresToClaim = (claimID, supportingFigures, contradictingFigures, theOwner, theOwnerTags, claimTitle, supportingExplanations, 
  contradictingExplanations, timeStamp, occurrence) => {
  return async (dispatch) => {
    try {
      //Fetch the document for the Claim being connected to.
      const docRef = doc(db, "Claims", claimID);
      const document = await getDoc(docRef)
      //If the claim document is able to be fetched, add the new figures to the
      //contraFig and supportFig arrays.
      const ClaimData = document.data()

      //If the claim was able to be fetched, do the following:
      if (ClaimData) {
        contradictingFigures.forEach((contraFig) => {
          ClaimData.updates.figureConnections.push(
            {
              timeStamp: timeStamp,
              occurrence: occurrence,
              id: contraFig.ID
            }
          )
        })
        supportingFigures.forEach((supportFig) => {
          ClaimData.updates.figureConnections.push(
            {
              timeStamp: timeStamp,
              occurrence: occurrence,
              id: supportFig.ID
            }
          )
        })
        //First make an array of new contraFig objects that will concated onto the current contraFig array
        let newContraFigs = [];
        for (let i = 0; i < contradictingFigures.length; i++) {
          let newfigure = {
            description: contradictingFigures[i].description,
            doesNotSupport: [],
            id: contradictingFigures[i].ID,
            imgsrc: contradictingFigures[i].imgsrc,
            owner: theOwner,
            ownerTags: theOwnerTags,
            reallySupports: [],
            somewhatSupports: [],
            title: contradictingFigures[i].title,
            criticisms: [],
            explanation: contradictingExplanations[i],
            occurrence: occurrence,
            timeStamp: timeStamp
          }
          newContraFigs.push(newfigure)
        }
        //Next make an array of new supportFig objects that will concated onto the current supportFig array
        let newSupportFigs = [];
        for (let i = 0; i <supportingFigures.length; i++) {
          let newfigure = {
            description: supportingFigures[i].description,
            doesNotSupport: [],
            id: supportingFigures[i].ID,
            imgsrc: supportingFigures[i].imgsrc,
            owner: theOwner,
            ownerTags: theOwnerTags,
            reallySupports: [],
            somewhatSupports: [],
            title: supportingFigures[i].title,
            criticisms: [],
            explanation: supportingExplanations[i],
            occurrence: occurrence,
            timeStamp: timeStamp
          }
          newSupportFigs.push(newfigure)
        }

        //Have to use these holders for some reason because cannot just set cotnraFigs and supportFigs equal to them directly.
        const holder1 = ClaimData.contraFigs.concat(newContraFigs)
        const holder2 = ClaimData.supportFigs.concat(newSupportFigs)
        ClaimData.contraFigs = holder1
        ClaimData.supportFigs = holder2
        await setDoc(docRef, ClaimData)

        //Add the claim title and id to the "associatedClaims" array for each figure.
        //Get all of the docRefs for all of the support Figs.
        let supportingFigDocRefs = [] 
        supportingFigures.forEach((figure) => {
          supportingFigDocRefs.push(doc(db, "Figures", figure.ID))
        })

        //Get all of the docRefs for all of the contra Figs.
        let contradictingFigDocRefs = [] 
        contradictingFigures.forEach((figure) => {
          contradictingFigDocRefs.push(doc(db, "Figures", figure.ID))
        })

        //Fetch each support Figure, add the claim to its associatedClaims array, update the updates array, then re-save
        await supportingFigDocRefs.forEach( async (ref) => {
          const document = await getDoc(ref)
          const figureData = document.data()
          const newAssociation = {
            direction: 'support',
            id: claimID,
            title: claimTitle,
            connector: theOwner,
            connectorTags: theOwnerTags,
            occurrence: occurrence
          }
          figureData.associatedClaims.push(newAssociation)
          figureData.updates.claimConnections.push(
            {
              timeStamp: timeStamp,
              occurrence: occurrence,
              id: claimID
            }
          )
          await setDoc(ref, figureData)
        })

        // //Fetch each contra Figure, add the claim to its associatedClaims array, update the updates array, then re-save
        await contradictingFigDocRefs.forEach( async (ref) => {
          const document = await getDoc(ref)
          const figureData = document.data()
          const newAssociation = {
            direction: 'contradict',
            id: claimID,
            title: claimTitle,
            connector: theOwner,
            connectorTags: theOwnerTags,
            occurrence: occurrence
          }
          figureData.associatedClaims.push(newAssociation)
          figureData.updates.claimConnections.push(
            {
              timeStamp: timeStamp,
              occurrence: occurrence,
              id: claimID
            }
          )
          await setDoc(ref, figureData)
        })

        //Below is needed for when redirecting to the claim page.
        // dispatch(setClaim({
        //   ...ClaimData,
        //   id: claimID
        // }))

        return claimID;
      }
    } catch (err) {
      // console.log('connecting figures to claims didnt work')
    }
  }
}


//Method for creating a claim should do the following:
//1) Create the claim in the "Claims" collection on Firebase with all the pertinent information. - check
//2) Add an update to the figureConnections array under the updates object for each figure being connected. - check
//3) Add an associatedClaim object to the associatedClaims array of each figure that represents the claim. - check
//4) Add a claimConnection update to the "claimConnections" array under the updates object of each figure being connected. - check
//5) Add an update under the "createdClaims" array of the "updates" object of the fellow. - check
//6) Add the claim under the "createdClaims" array of the "myFigures" object of the fellow. - check
//7) If the claim is extracted from a paper, add the claimID to the "extractedClaims" array of the paper.
//8) If the claim is extracted from a paper, add the claimID to the "claimExtractions" array under the updates object of the paper.
//Validated AddClaim
export const createClaim = (claimInfo) => {
  return async (dispatch) => {
    try {
      //Create the document for the Claim being created.
      let ClaimData = {
        contraFigs: [],
        supportFigs: [],
        engagement: [],
        follows: [],
        relatedClaims: [],
        associatedTheories: [],
        owner: claimInfo.user,
        ownerTags: claimInfo.values,
        title: claimInfo.title,
        timeStamp: claimInfo.timeStamp,
        extractedClaim: claimInfo.extractedClaim,
        exactText: claimInfo.exactText,
        paperID: claimInfo.paperID,
        paperDoi: claimInfo.paperDoi,
        updates: claimInfo.updates
      }
      const theOccurrence = claimInfo.updates.created[claimInfo.updates.created.length - 1].occurrence
      //Add an occurrence of a figure being connected to the claim for each figure being connected.
      claimInfo.contradictingFigures.forEach((contraFig) => {
        ClaimData.updates.figureConnections.push(
          {
            timeStamp: claimInfo.timeStamp,
            occurrence: theOccurrence,
            id: contraFig.ID
          }
        )
      })
      claimInfo.supportingFigures.forEach((supportFig) => {
        ClaimData.updates.figureConnections.push(
          {
            timeStamp: claimInfo.timeStamp,
            occurrence: theOccurrence,
            id: supportFig.ID
          }
        )
      })
      // First make an array of new contraFig objects that will concated onto the current contraFig array
      let newContraFigs = [];
      for (let i = 0; i < claimInfo.contradictingFigures.length; i++) {
        let newfigure = {
          description: claimInfo.contradictingFigures[i].description,
          doesNotSupport: [],
          id: claimInfo.contradictingFigures[i].ID,
          imgsrc: claimInfo.contradictingFigures[i].imgsrc,
          owner: claimInfo.user,
          ownerTags: claimInfo.values,
          reallySupports: [],
          somewhatSupports: [],
          title: claimInfo.contradictingFigures[i].title,
          criticisms: [],
          explanation: claimInfo.contradictingExplanations[i],
          occurrence: theOccurrence,
          timeStamp: claimInfo.timeStamp
        }
        newContraFigs.push(newfigure)
      }

      //Next make an array of new supportFig objects that will concated onto the current supportFig array
      let newSupportFigs = [];
      for (let i = 0; i <claimInfo.supportingFigures.length; i++) {
        let newfigure = {
          description: claimInfo.supportingFigures[i].description,
          doesNotSupport: [],
          id: claimInfo.supportingFigures[i].ID,
          imgsrc: claimInfo.supportingFigures[i].imgsrc,
          owner: claimInfo.user,
          ownerTags: claimInfo.values,
          reallySupports: [],
          somewhatSupports: [],
          title: claimInfo.supportingFigures[i].title,
          criticisms: [],
          explanation: claimInfo.supportingExplanations[i],
          occurrence: theOccurrence,
          timeStamp: claimInfo.timeStamp
        }
        newSupportFigs.push(newfigure)
      }

      //Have to use these holders for some reason because cannot just set cotnraFigs and supportFigs equal to them directly.
      const holder1 = ClaimData.contraFigs.concat(newContraFigs)
      const holder2 = ClaimData.supportFigs.concat(newSupportFigs)
      ClaimData.contraFigs = holder1
      ClaimData.supportFigs = holder2
      let theClaim = await addDoc(claimCollection, ClaimData)
      let claimID = theClaim.id
      //Add the claim title and id to the "associatedClaims" array for each figure.
      //Get all of the docRefs for all of the support Figs.
      let supportingFigDocRefs = [] 
      claimInfo.supportingFigures.forEach((figure) => {
        supportingFigDocRefs.push(doc(db, "Figures", figure.ID))
      })

      //Get all of the docRefs for all of the contra Figs.
      let contradictingFigDocRefs = [] 
      claimInfo.contradictingFigures.forEach((figure) => {
        contradictingFigDocRefs.push(doc(db, "Figures", figure.ID))
      })

      //Fetch each support Figure, add the claim to its associatedClaims array, update the updates array, then re-save
      supportingFigDocRefs.forEach( async (ref) => {
        const document = await getDoc(ref)
        const figureData = document.data()
        const newAssociation = {
          direction: 'support',
          id: claimID,
          title: claimInfo.title,
          connector: claimInfo.user,
          connectorTags: claimInfo.values,
          occurrence: theOccurrence
        }
        figureData.associatedClaims.push(newAssociation)
        figureData.updates.claimConnections.push(
          {
            timeStamp: claimInfo.timeStamp,
            occurrence: theOccurrence,
            id: claimID
          }
        )
        await setDoc(ref, figureData)
      })

      //Fetch each contra Figure, add the claim to its associatedClaims array, update the updates array, then re-save
      contradictingFigDocRefs.forEach( async (ref) => {
        const document = await getDoc(ref)
        const figureData = document.data()
        const newAssociation = {
          direction: 'contradict',
          id: claimID,
          title: claimInfo.title,
          connector: claimInfo.user,
          connectorTags: claimInfo.values,
          occurrence: theOccurrence
        }
        figureData.associatedClaims.push(newAssociation)
        figureData.updates.claimConnections.push(
          {
            timeStamp: claimInfo.timeStamp,
            occurrence: theOccurrence,
            id: claimID
          }
        )
        await setDoc(ref, figureData)
      })

      //Fetch the profile document of the owner and add the id to the createdClaims array.
      const docRef2 = doc(db, "Profiles", claimInfo.user);
      const document2 = await getDoc(docRef2)
      let profileData = document2.data()
      profileData.myClaims.createdClaims.push(
        {
          id: claimID,
          lastViewed: claimInfo.timeStamp
        }
      )
      profileData.updates.createdClaims.push(
        {
          id: claimID,
          occurrence: theOccurrence,
          timeStamp: claimInfo.timeStamp
        }
      )
      await setDoc(docRef2, profileData)
      dispatch(setProfile({
        ...profileData, 
        id: claimInfo.user
      }))

      //Lastly, if the claim is extracted from a paper, fetch that paper and add the id to the extractedClaims array.
      if (claimInfo.extractedClaim == true) {
        const docRef3 = doc(db, "Papers", claimInfo.paperID);
        const document3 = await getDoc(docRef3)
        let paperData = document3.data()
        paperData.extractedClaims.push(
          {
            id: claimID,
            occurrence: theOccurrence,
            timeStamp: claimInfo.timeStamp
          }
        )
        paperData.updates.claimExtractions.push(
          {
            id: claimID,
            occurrence: theOccurrence,
            timeStamp: claimInfo.timeStamp
          }
        )
        await setDoc(docRef3, paperData)
      }
      
      // dispatch(setClaim({
      //   ...ClaimData,
      //   id: claimID
      // }))

      return claimID;
    } catch (err) {
      // console.log('creating claim did not work')
    }
  }
}


//function to remove a figure from being connected to a claim (either supporting on contradicting) will do the following:
//1) Remove the appropriate associatedClaim object from the "associatedClaims" array of that figure. - check.
//2) Remove the update under the "claimConnections" array from the "updates" object of that figure. - check.
//3) Remove the correct figure from either the contraFigs or supportFigs array. - check.
//4) Remove the update under the "figureConnections" array under the updates object that is associated with that figure. - check.
//Validated Claim/:id
export const startRemoveConnection = (claimID, figureID, direction) => {
  return async (dispatch) => {
    //fetch the figure document and get the data
    const figureDocRef = doc(db, "Figures", figureID)
    const figureDocument = await getDoc(figureDocRef)
    const figureData = figureDocument.data()

    let theDirection;
    if (direction === 'supporting') {
      theDirection = 'support'
    } else if (direction === 'contradicting') {
      theDirection = 'contradict'
    }

    if (figureData) {
      //Remove one instance of the claim from the associatedClaims array and re-save to Firebase.
      //Also remove one instance of the update from the claimConnections array and re-save to Firebase.
      let associatedClaimIndex = figureData.associatedClaims.findIndex((claim) => {
        return (claim.id == claimID && claim.direction === theDirection)
      })
      if (associatedClaimIndex != -1) {
        let theOccurrenceAssociated = figureData.associatedClaims[associatedClaimIndex].occurrence
        figureData.associatedClaims.splice(associatedClaimIndex, 1)
        let updatesIndex = figureData.updates.claimConnections.findIndex((update) => {
          return update.occurrence == theOccurrenceAssociated
        })
        figureData.updates.claimConnections.splice(updatesIndex, 1)
        await setDoc(figureDocRef, figureData)
      }
    }

    //fetch the claim document and get the data
    const claimDocRef = doc(db, "Claims", claimID);
    const ClaimDocument = await getDoc(claimDocRef)
    const ClaimData = ClaimDocument.data()

    //Make sure the claim still exists:
    if (ClaimData) {
      let allCriticismAndVoteOccurrences = [];
      //Remove the figure from the correct array (either the contraFigs or the supportFigs array) and re-save to Firebase
      if (direction === "contradicting") {
        let theIndex = ClaimData.contraFigs.findIndex((contraFig) => {
          return contraFig.id == figureID
        })
        if (theIndex != -1) {
          let theOccurrence = ClaimData.contraFigs[theIndex].occurrence

          //Gather all the occurrences from the criticisms and their votes.
          ClaimData.contraFigs[theIndex].criticisms.forEach((criticism) => {
            allCriticismAndVoteOccurrences.push(criticism.occurrence)
            criticism.critDownVotes.forEach((vote) => {
              allCriticismAndVoteOccurrences.push(vote.occurrence)
            })
            criticism.critUpVotes.forEach((vote) => {
              allCriticismAndVoteOccurrences.push(vote.occurrence)
            })
          })

          //Gather all the occurrences of the doesNotSupport votes. 
          ClaimData.contraFigs[theIndex].doesNotSupport.forEach((vote) => {
            allCriticismAndVoteOccurrences.push(vote.occurrence)
          })

          //Gather all the occurrences of the somewhatSupports votes. 
          ClaimData.contraFigs[theIndex].somewhatSupports.forEach((vote) => {
            allCriticismAndVoteOccurrences.push(vote.occurrence)
          })

          //Gather all the occurrences of the reallySupports votes. 
          ClaimData.contraFigs[theIndex].reallySupports.forEach((vote) => {
            allCriticismAndVoteOccurrences.push(vote.occurrence)
          })

          ClaimData.contraFigs.splice(theIndex, 1)
          let theUpdateIndex = ClaimData.updates.figureConnections.findIndex((update) => {
            return (update.occurrence == theOccurrence && update.id == figureID)
          })
          ClaimData.updates.figureConnections.splice(theUpdateIndex, 1)

          //Remove every instance of occurrence in the engagements array.
          let newArray = ClaimData.engagement.filter(obj1 => !allCriticismAndVoteOccurrences.some(obj2 => obj1.occurrence === obj2))
          ClaimData.engagement = newArray

          //Remove every instance of occurrence in the updates.engagements array.
          let newArray2 = ClaimData.updates.engagement.filter(obj1 => !allCriticismAndVoteOccurrences.some(obj2 => obj1.occurrence === obj2))
          ClaimData.updates.engagement = newArray2

          //Remove every instance of occurrence in the updates.comments array.
          let newArray3 = ClaimData.updates.comments.filter(obj1 => !allCriticismAndVoteOccurrences.some(obj2 => obj1.occurrence === obj2))
          ClaimData.updates.comments = newArray3

          return setDoc(claimDocRef, ClaimData).then(() => {
            // dispatch(setClaim({
            //   ...ClaimData,
            //   id: claimID
            // }))
            }).catch(() => {
              // console.log('startSetClaim not working')
          })
        }
      } else if (direction === "supporting") {
        let theIndex = ClaimData.supportFigs.findIndex((supportFig) => {
          return supportFig.id == figureID
        })
        if (theIndex != -1) {
          let theOccurrence = ClaimData.supportFigs[theIndex].occurrence
          //Gather all the occurrences from the criticisms and their votes.
          ClaimData.supportFigs[theIndex].criticisms.forEach((criticism) => {
            allCriticismAndVoteOccurrences.push(criticism.occurrence)
            criticism.critDownVotes.forEach((vote) => {
              allCriticismAndVoteOccurrences.push(vote.occurrence)
            })
            criticism.critUpVotes.forEach((vote) => {
              allCriticismAndVoteOccurrences.push(vote.occurrence)
            })
          })

          //Gather all the occurrences of the doesNotSupport votes. 
          ClaimData.supportFigs[theIndex].doesNotSupport.forEach((vote) => {
            allCriticismAndVoteOccurrences.push(vote.occurrence)
          })

          //Gather all the occurrences of the somewhatSupports votes. 
          ClaimData.supportFigs[theIndex].somewhatSupports.forEach((vote) => {
            allCriticismAndVoteOccurrences.push(vote.occurrence)
          })

          //Gather all the occurrences of the reallySupports votes. 
          ClaimData.supportFigs[theIndex].reallySupports.forEach((vote) => {
            allCriticismAndVoteOccurrences.push(vote.occurrence)
          })

          ClaimData.supportFigs.splice(theIndex, 1)

          let theUpdateIndex = ClaimData.updates.figureConnections.findIndex((update) => {
            return (update.occurrence == theOccurrence && update.id == figureID)
          })
          ClaimData.updates.figureConnections.splice(theUpdateIndex, 1)

          //Remove every instance of occurrence in the engagements array.
          let newArray = ClaimData.engagement.filter(obj1 => !allCriticismAndVoteOccurrences.some(obj2 => obj1.occurrence === obj2))
          ClaimData.engagement = newArray

          //Remove every instance of occurrence in the updates.engagements array.
          let newArray2 = ClaimData.updates.engagement.filter(obj1 => !allCriticismAndVoteOccurrences.some(obj2 => obj1.occurrence === obj2))
          ClaimData.updates.engagement = newArray2

          //Remove every instance of occurrence in the updates.comments array.
          let newArray3 = ClaimData.updates.comments.filter(obj1 => !allCriticismAndVoteOccurrences.some(obj2 => obj1.occurrence === obj2))
          ClaimData.updates.comments = newArray3
          return setDoc(claimDocRef, ClaimData).then(() => {
            // dispatch(setClaim({
            //   ...ClaimData,
            //   id: claimID
            // }))
            // console.log('startSetClaim is working')
            }).catch(() => {
              // console.log('startSetClaim not working')
          })
        }
      }
    }
  }
}

export const setClaim = (
  {
    associatedTheories = [],
    title = '',
    owner = '',
    ownerTags = [],
    criticisms = [],
    upVotes = [],
    downVotes = [],
    id,
    relatedClaims =[],
    timeStamp = 0,
    contraFigs = [],
    supportFigs = [],
    follows = [],
    engagement = [],
    exactText = '',
    extractedClaim = false,
    paperDoi = '',
    paperID = '',
    updates = []
  } = {claim}
) => ({
  type: 'SET_CLAIM',
  claim: {
    associatedTheories: associatedTheories,
    title: title,
    owner: owner,
    ownerTags: ownerTags,
    criticisms: criticisms,
    upVotes: upVotes,
    downVotes: downVotes,
    id: id,
    relatedClaims: relatedClaims,
    timeStamp: timeStamp,
    contraFigs: contraFigs,
    supportFigs: supportFigs,
    follows: follows,
    engagement: engagement,
    exactText: exactText,
    extractedClaim: extractedClaim,
    paperDoi: paperDoi,
    paperID: paperID,
    updates: updates
  }
});


//Method for voting DoesNotSupport on a supportFig connected to a claim will do the following:
//1) Add the user's vote to the doesNotSupport array of the supportFig - check
//2) Adds the occurrence to the engagement array on the claim with the fellow's ID + occurrence. - check
//3) Adds the occurrence to the engagement array under the updates object with the fellow's ID + occurrence. - check
//Validated Claim/:id
export const startDoesNotSupport = (vote) => {
  return async (dispatch) => {
    const docRef = doc(db, "Claims", vote.claimID);
    const claimDocument = await getDoc(docRef)
    const ClaimData = claimDocument.data()
    if (ClaimData) {
      ClaimData.supportFigs.forEach((fig) => {
        if (fig.id == vote.figureID) {
          fig.doesNotSupport.push(
            {
              id: vote.user,
              occurrence: vote.occurrence,
              timeStamp: vote.timeStamp
            }
          )
        }
      })
      ClaimData.engagement.push(
        {
          id: vote.user,
          occurrence: vote.occurrence,
          timeStamp: vote.timeStamp
        }
      )
      ClaimData.updates.engagement.push(
        {
          id: vote.user,
          occurrence: vote.occurrence,
          timeStamp: vote.timeStamp
        }
      )
      return setDoc(docRef, ClaimData).then(() => {
        // dispatch(setClaim({
        //   ...ClaimData,
        //   id: vote.claimID
        // }))
        // console.log('startSetClaim is working')
        }).catch(() => {
          // console.log('startSetClaim not working')
      })
    }
  }
}

//Method for reversing a DoesNotSupport vote on a supportFig should do the following:
//1) Remove the vote from the "doesNotSupport" array on the supportFig
//2) Remove the occurrence from the engagment array.
//3) Remove the occurrence from the engagement array under the updates object.
//Validated Claim/:id
export const reverseDoesNotSupport = (vote) => {
  return async (dispatch) => {
    const docRef = doc(db, "Claims", vote.claimID);
    const claimDocument = await getDoc(docRef)
    //Get the document for the Claim
    const ClaimData = claimDocument.data()
    if (ClaimData) {
      //Function for finding the correct supporting figure.
      const findSupportFig = (supportFig) => {
        return supportFig.id == vote.figureID
      }
      //Get index of correct supporting figure.
      const index = ClaimData.supportFigs.findIndex(findSupportFig)
      //Get the index of the doesNotSupportVote.
      const voteIndex = ClaimData.supportFigs[index].doesNotSupport.findIndex((theVote) => {
        return theVote.id == vote.user
      })

      //Get the occurrence of the doesNotSupportVote.
      let theOccurrence = ClaimData.supportFigs[index].doesNotSupport[voteIndex].occurrence

      //Generate new array with the user's doesNotSupport vote removed.
      const remove = ClaimData.supportFigs[index].doesNotSupport.filter((oneVote) => {
        return oneVote.id != vote.user
      })
    
      //Replace the doesNotSupport array with the new one we just generated.
      ClaimData.supportFigs[index].doesNotSupport = remove

      //Remove the occurrence from the engagement array
      const newEngagements = ClaimData.engagement.filter((engagement) => {
        return engagement.occurrence != theOccurrence;
      })
      ClaimData.engagement = newEngagements

      //Remove the occurrence from the engagement array under the updates object
      const newEngagementsUpdate = ClaimData.updates.engagement.filter((engagement) => {
        return engagement.occurrence != theOccurrence;
      })
      ClaimData.updates.engagement = newEngagementsUpdate
      return setDoc(docRef, ClaimData).then(() => {
        // dispatch(setClaim({
        //   ...ClaimData,
        //   id: vote.claimID
        // }))
        // console.log('startSetClaim is working')
        }).catch(() => {
          // console.log('startSetClaim not working')
      })
    }
  }
}


//Method for voting SomewhatSupports on a supportFig connected to a claim will do the following:
//1) Add the user's vote to the somewhatSupports array of the supportFig - check
//2) Adds the occurrence to the engagement array on the claim with the fellow's ID + occurrence. - check
//3) Adds the occurrence to the engagement array under the updates object with the fellow's ID + occurrence. - check
//Validated Claim/:id
export const startSomewhatSupports = (vote) => {
  return async (dispatch) => {
    const docRef = doc(db, "Claims", vote.claimID);
    const claimDocument = await getDoc(docRef)
    const ClaimData = claimDocument.data()
    if (ClaimData) {
      ClaimData.supportFigs.forEach((fig) => {
        if (fig.id == vote.figureID) {
          fig.somewhatSupports.push(
            {
              id: vote.user,
              occurrence: vote.occurrence,
              timeStamp: vote.timeStamp
            }
          )
        }
      })
      ClaimData.engagement.push(
        {
          id: vote.user,
          occurrence: vote.occurrence,
          timeStamp: vote.timeStamp
        }
      )
      ClaimData.updates.engagement.push(
        {
          id: vote.user,
          occurrence: vote.occurrence,
          timeStamp: vote.timeStamp
        }
      )
      return setDoc(docRef, ClaimData).then(() => {
        // dispatch(setClaim({
        //   ...ClaimData,
        //   id: vote.claimID
        // }))
        // console.log('startSetClaim is working')
        }).catch(() => {
          // console.log('startSetClaim not working')
      })
    }
  }
}

//Method for reversing a SomewhatSupports vote on a supportFig should do the following:
//1) Remove the vote from the "somewhatSupports" array on the supportFig
//2) Remove the occurrence from the engagment array.
//3) Remove the occurrence from the engagement array under the updates object.
//Validated Claim/:id
export const reverseSomewhatSupports = (vote) => {
  return async (dispatch) => {
    const docRef = doc(db, "Claims", vote.claimID);
    const claimDocument = await getDoc(docRef)
    //Get the document for the Claim
    const ClaimData = claimDocument.data()
    if (ClaimData) {
      //Function for finding the correct supporting figure.
      const findSupportFig = (supportFig) => {
        return supportFig.id == vote.figureID
      }
      //Get index of correct supporting figure.
      const index = ClaimData.supportFigs.findIndex(findSupportFig)
      //Get the index of the somewhatSupportsVote.
      const voteIndex = ClaimData.supportFigs[index].somewhatSupports.findIndex((theVote) => {
        return theVote.id == vote.user
      })

      //Get the occurrence of the somewhatSupportsVote.
      let theOccurrence = ClaimData.supportFigs[index].somewhatSupports[voteIndex].occurrence

      //Generate new array with the user's somewhatSupports vote removed.
      const remove = ClaimData.supportFigs[index].somewhatSupports.filter((oneVote) => {
        return oneVote.id != vote.user
      })
    
      //Replace the somewhatSupports array with the new one we just generated.
      ClaimData.supportFigs[index].somewhatSupports = remove

      //Remove the occurrence from the engagement array
      const newEngagements = ClaimData.engagement.filter((engagement) => {
        return engagement.occurrence != theOccurrence;
      })
      ClaimData.engagement = newEngagements

      //Remove the occurrence from the engagement array under the updates object
      const newEngagementsUpdate = ClaimData.updates.engagement.filter((engagement) => {
        return engagement.occurrence != theOccurrence;
      })
      ClaimData.updates.engagement = newEngagementsUpdate
      return setDoc(docRef, ClaimData).then(() => {
        // dispatch(setClaim({
        //   ...ClaimData,
        //   id: vote.claimID
        // }))
        // console.log('startSetClaim is working')
        }).catch(() => {
          // console.log('startSetClaim not working')
      })
    }
  }
}

//Method for voting ReallySupports on a supportFig connected to a claim will do the following:
//1) Add the user's vote to the reallySupports array of the supportFig - check
//2) Adds the occurrence to the engagement array on the claim with the fellow's ID + occurrence. - check
//3) Adds the occurrence to the engagement array under the updates object with the fellow's ID + occurrence. - check
//Validated Claim/:id
export const startReallySupports = (vote) => {
  return async (dispatch) => {
    const docRef = doc(db, "Claims", vote.claimID);
    const claimDocument = await getDoc(docRef)
    const ClaimData = claimDocument.data()
    if (ClaimData) {
      ClaimData.supportFigs.forEach((fig) => {
        if (fig.id == vote.figureID) {
          fig.reallySupports.push(
            {
              id: vote.user,
              occurrence: vote.occurrence,
              timeStamp: vote.timeStamp
            }
          )
        }
      })
      ClaimData.engagement.push(
        {
          id: vote.user,
          occurrence: vote.occurrence,
          timeStamp: vote.timeStamp
        }
      )
      ClaimData.updates.engagement.push(
        {
          id: vote.user,
          occurrence: vote.occurrence,
          timeStamp: vote.timeStamp
        }
      )
      return setDoc(docRef, ClaimData).then(() => {
        // dispatch(setClaim({
        //   ...ClaimData,
        //   id: vote.claimID
        // }))
        // console.log('startSetClaim is working')
        }).catch(() => {
          // console.log('startSetClaim not working')
      })
    }
  }
}

//Method for reversing a ReallySupports vote on a supportFig should do the following:
//1) Remove the vote from the "reallySupports" array on the supportFig
//2) Remove the occurrence from the engagment array.
//3) Remove the occurrence from the engagement array under the updates object.
//Validated Claim/:id
export const reverseReallySupports = (vote) => {
  return async (dispatch) => {
    const docRef = doc(db, "Claims", vote.claimID);
    const claimDocument = await getDoc(docRef)
    //Get the document for the Claim
    const ClaimData = claimDocument.data()
    if (ClaimData) {
      //Function for finding the correct supporting figure.
      const findSupportFig = (supportFig) => {
        return supportFig.id == vote.figureID
      }
      //Get index of correct supporting figure.
      const index = ClaimData.supportFigs.findIndex(findSupportFig)
      //Get the index of the reallySupportsVote.
      const voteIndex = ClaimData.supportFigs[index].reallySupports.findIndex((theVote) => {
        return theVote.id == vote.user
      })

      //Get the occurrence of the reallySupportsVote.
      let theOccurrence = ClaimData.supportFigs[index].reallySupports[voteIndex].occurrence

      //Generate new array with the user's reallySupports vote removed.
      const remove = ClaimData.supportFigs[index].reallySupports.filter((oneVote) => {
        return oneVote.id != vote.user
      })
    
      //Replace the reallySupports array with the new one we just generated.
      ClaimData.supportFigs[index].reallySupports = remove

      //Remove the occurrence from the engagement array
      const newEngagements = ClaimData.engagement.filter((engagement) => {
        return engagement.occurrence != theOccurrence;
      })
      ClaimData.engagement = newEngagements

      //Remove the occurrence from the engagement array under the updates object
      const newEngagementsUpdate = ClaimData.updates.engagement.filter((engagement) => {
        return engagement.occurrence != theOccurrence;
      })
      ClaimData.updates.engagement = newEngagementsUpdate
      return setDoc(docRef, ClaimData).then(() => {
        // dispatch(setClaim({
        //   ...ClaimData,
        //   id: vote.claimID
        // }))
        // console.log('startSetClaim is working')
        }).catch(() => {
          // console.log('startSetClaim not working')
      })
    }
  }
}

//Method for voting DoesNotContra on a contraFig connected to a claim will do the following:
//1) Add the user's vote to the doesNotSupport array of the contraFig - check
//2) Adds the occurrence to the engagement array on the claim with the fellow's ID + occurrence. - check
//3) Adds the occurrence to the engagement array under the updates object with the fellow's ID + occurrence. - check
//Validated Claim/:id
export const startDoesNotContra = (vote) => {
  return async (dispatch) => {
    const docRef = doc(db, "Claims", vote.claimID);
    const claimDocument = await getDoc(docRef)
    const ClaimData = claimDocument.data()
    if (ClaimData) {
      ClaimData.contraFigs.forEach((fig) => {
        if (fig.id == vote.figureID) {
          fig.doesNotSupport.push(
            {
              id: vote.user,
              occurrence: vote.occurrence,
              timeStamp: vote.timeStamp
            }
          )
        }
      })
      ClaimData.engagement.push(
        {
          id: vote.user,
          occurrence: vote.occurrence,
          timeStamp: vote.timeStamp
        }
      )
      ClaimData.updates.engagement.push(
        {
          id: vote.user,
          occurrence: vote.occurrence,
          timeStamp: vote.timeStamp
        }
      )
      return setDoc(docRef, ClaimData).then(() => {
        // dispatch(setClaim({
        //   ...ClaimData,
        //   id: vote.claimID
        // }))
        // console.log('startSetClaim is working')
        }).catch(() => {
          // console.log('startSetClaim not working')
      })
    }
  }
}

//Method for reversing a DoesNotContra vote on a contraFig should do the following:
//1) Remove the vote from the "doesNotSupport" array on the contraFig - check
//2) Remove the occurrence from the engagment array - check
//3) Remove the occurrence from the engagement array under the updates object - check
//Validated Claim/:id
export const reverseDoesNotContra = (vote) => {
  return async (dispatch) => {
    const docRef = doc(db, "Claims", vote.claimID);
    const claimDocument = await getDoc(docRef)
    //Get the document for the Claim
    const ClaimData = claimDocument.data()
    if (ClaimData) {
      //Function for finding the correct supporting figure.
      const findContraFig = (contraFig) => {
        return contraFig.id == vote.figureID
      }
      //Get index of correct supporting figure.
      const index = ClaimData.contraFigs.findIndex(findContraFig)
      //Get the index of the doesNotSupportVote.
      const voteIndex = ClaimData.contraFigs[index].doesNotSupport.findIndex((theVote) => {
        return theVote.id == vote.user
      })

      //Get the occurrence of the doesNotSupportVote.
      let theOccurrence = ClaimData.contraFigs[index].doesNotSupport[voteIndex].occurrence

      //Generate new array with the user's doesNotSupport vote removed.
      const remove = ClaimData.contraFigs[index].doesNotSupport.filter((oneVote) => {
        return oneVote.id != vote.user
      })
    
      //Replace the doesNotSupport array with the new one we just generated.
      ClaimData.contraFigs[index].doesNotSupport = remove

      //Remove the occurrence from the engagement array
      const newEngagements = ClaimData.engagement.filter((engagement) => {
        return engagement.occurrence != theOccurrence;
      })
      ClaimData.engagement = newEngagements

      //Remove the occurrence from the engagement array under the updates object
      const newEngagementsUpdate = ClaimData.updates.engagement.filter((engagement) => {
        return engagement.occurrence != theOccurrence;
      })
      ClaimData.updates.engagement = newEngagementsUpdate
      return setDoc(docRef, ClaimData).then(() => {
        // dispatch(setClaim({
        //   ...ClaimData,
        //   id: vote.claimID
        // }))
        // console.log('startSetClaim is working')
        }).catch(() => {
          // console.log('startSetClaim not working')
      })
    }
  }
}


//Method for voting SomewhatSupports on a supportFig connected to a claim will do the following:
//1) Add the user's vote to the somewhatSupports array of the supportFig - check
//2) Adds the occurrence to the engagement array on the claim with the fellow's ID + occurrence. - check
//3) Adds the occurrence to the engagement array under the updates object with the fellow's ID + occurrence. - check
//Validated Claim/:id
export const startSomewhatContras = (vote) => {
  return async (dispatch) => {
    const docRef = doc(db, "Claims", vote.claimID);
    const claimDocument = await getDoc(docRef)
    const ClaimData = claimDocument.data()
    if (ClaimData) {
      ClaimData.contraFigs.forEach((fig) => {
        if (fig.id == vote.figureID) {
          fig.somewhatSupports.push(
            {
              id: vote.user,
              occurrence: vote.occurrence,
              timeStamp: vote.timeStamp
            }
          )
        }
      })
      ClaimData.engagement.push(
        {
          id: vote.user,
          occurrence: vote.occurrence,
          timeStamp: vote.timeStamp
        }
      )
      ClaimData.updates.engagement.push(
        {
          id: vote.user,
          occurrence: vote.occurrence,
          timeStamp: vote.timeStamp
        }
      )
      return setDoc(docRef, ClaimData).then(() => {
        // dispatch(setClaim({
        //   ...ClaimData,
        //   id: vote.claimID
        // }))
        // console.log('startSetClaim is working')
        }).catch(() => {
          // console.log('startSetClaim not working')
      })
    }
  }
}

//Method for reversing a SomewhatContradicts vote on a contraFig should do the following:
//1) Remove the vote from the "somewhatSupports" array on the contraFig
//2) Remove the occurrence from the engagment array
//3) Remove the occurrence from the engagement array under the updates object
//Validated Claim/:id
export const reverseSomewhatContras = (vote) => {
  return async (dispatch) => {
    const docRef = doc(db, "Claims", vote.claimID);
    const claimDocument = await getDoc(docRef)
    //Get the document for the Claim
    const ClaimData = claimDocument.data()
    if (ClaimData) {
      //Function for finding the correct supporting figure.
      const findContradictFig = (contraFig) => {
        return contraFig.id == vote.figureID
      }
      //Get index of correct supporting figure.
      const index = ClaimData.contraFigs.findIndex(findContradictFig)
      //Get the index of the somewhatContradictsVote.
      const voteIndex = ClaimData.contraFigs[index].somewhatSupports.findIndex((theVote) => {
        return theVote.id == vote.user
      })

      //Get the occurrence of the somewhatSupportsVote.
      let theOccurrence = ClaimData.contraFigs[index].somewhatSupports[voteIndex].occurrence

      //Generate new array with the user's somewhatSupports vote removed.
      const remove = ClaimData.contraFigs[index].somewhatSupports.filter((oneVote) => {
        return oneVote.id != vote.user
      })
    
      //Replace the somewhatSupports array with the new one we just generated.
      ClaimData.contraFigs[index].somewhatSupports = remove

      //Remove the occurrence from the engagement array
      const newEngagements = ClaimData.engagement.filter((engagement) => {
        return engagement.occurrence != theOccurrence;
      })
      ClaimData.engagement = newEngagements

      //Remove the occurrence from the engagement array under the updates object
      const newEngagementsUpdate = ClaimData.updates.engagement.filter((engagement) => {
        return engagement.occurrence != theOccurrence;
      })
      ClaimData.updates.engagement = newEngagementsUpdate
      return setDoc(docRef, ClaimData).then(() => {
        // dispatch(setClaim({
        //   ...ClaimData,
        //   id: vote.claimID
        // }))
        // console.log('startSetClaim is working')
        }).catch(() => {
          // console.log('startSetClaim not working')
      })
    }
  }
}

//Method for voting ReallyContras on a contraFig connected to a claim will do the following:
//1) Add the user's vote to the reallySupports array of the contraFigs array - check
//2) Adds the occurrence to the engagement array on the claim with the fellow's ID + occurrence. - check
//3) Adds the occurrence to the engagement array under the updates object with the fellow's ID + occurrence. - check
//Validated Claim/:id
export const startReallyContras = (vote) => {
  return async (dispatch) => {
    const docRef = doc(db, "Claims", vote.claimID);
    const claimDocument = await getDoc(docRef)
    const ClaimData = claimDocument.data()
    if (ClaimData) {
      ClaimData.contraFigs.forEach((fig) => {
        if (fig.id == vote.figureID) {
          fig.reallySupports.push(
            {
              id: vote.user,
              occurrence: vote.occurrence,
              timeStamp: vote.timeStamp
            }
          )
        }
      })
      ClaimData.engagement.push(
        {
          id: vote.user,
          occurrence: vote.occurrence,
          timeStamp: vote.timeStamp
        }
      )
      ClaimData.updates.engagement.push(
        {
          id: vote.user,
          occurrence: vote.occurrence,
          timeStamp: vote.timeStamp
        }
      )
      return setDoc(docRef, ClaimData).then(() => {
        // dispatch(setClaim({
        //   ...ClaimData,
        //   id: vote.claimID
        // }))
        // console.log('startSetClaim is working')
        }).catch(() => {
          // console.log('startSetClaim not working')
      })
    }
  }
}

//Method for reversing a ReallyContras vote on a contraFig should do the following:
//1) Remove the vote from the "reallySupports" array on the contraFig - check
//2) Remove the occurrence from the engagment array. - check
//3) Remove the occurrence from the engagement array under the updates object. - check
//Validated Claim/:id
export const reverseReallyContras = (vote) => {
  return async (dispatch) => {
    const docRef = doc(db, "Claims", vote.claimID);
    const claimDocument = await getDoc(docRef)
    //Get the document for the Claim
    const ClaimData = claimDocument.data()
    if (ClaimData) {
      //Function for finding the correct contradicting figure.
      const findSupportFig = (contraFig) => {
        return contraFig.id == vote.figureID
      }
      //Get index of correct contradicting figure.
      const index = ClaimData.contraFigs.findIndex(findSupportFig)
      //Get the index of the reallySupportsVote.
      const voteIndex = ClaimData.contraFigs[index].reallySupports.findIndex((theVote) => {
        return theVote.id == vote.user
      })

      //Get the occurrence of the reallySupportsVote.
      let theOccurrence = ClaimData.contraFigs[index].reallySupports[voteIndex].occurrence

      //Generate new array with the user's reallySupports vote removed.
      const remove = ClaimData.contraFigs[index].reallySupports.filter((oneVote) => {
        return oneVote.id != vote.user
      })
    
      //Replace the reallySupports array with the new one we just generated.
      ClaimData.contraFigs[index].reallySupports = remove

      //Remove the occurrence from the engagement array
      const newEngagements = ClaimData.engagement.filter((engagement) => {
        return engagement.occurrence != theOccurrence;
      })
      ClaimData.engagement = newEngagements

      //Remove the occurrence from the engagement array under the updates object
      const newEngagementsUpdate = ClaimData.updates.engagement.filter((engagement) => {
        return engagement.occurrence != theOccurrence;
      })
      ClaimData.updates.engagement = newEngagementsUpdate
      return setDoc(docRef, ClaimData).then(() => {
        // dispatch(setClaim({
        //   ...ClaimData,
        //   id: vote.claimID
        // }))
        // console.log('startSetClaim is working')
        }).catch(() => {
          console.log('startSetClaim not working')
      })
    }
  }
}


//Method to follow a claim should do the following:
//1) Add the claimID to the followedClaims array under the myClaims object of the fellow.
//2) Add the fellowID + occurrence to the "follows" array of the Claim.
//3) Add the fellowID + occurrence to the "follows" array under the updates object of the Claim.
//Validated Claim/:id
export const startAddClaimFollow = (user, claimID, occurrence, timeStamps) => {
  return async (dispatch) => {
    try {
      //First fetch the data from the specific claim from the database.
      const docRef = doc(db, "Claims", claimID);
      const ClaimDocument = await getDoc(docRef)
      const ClaimData = ClaimDocument.data()

      //Add the user's ID to the follows array, which tells us who is following this particular claim.
      ClaimData.follows.push({
        id: user,
        occurrence: occurrence,
        timeStamp: timeStamps.followed
      })
      ClaimData.updates.follows.push(
        {
          id: user,
          occurrence: occurrence,
          timeStamp: timeStamps.followed
        }
      )
      await setDoc(docRef, ClaimData)

      //Additionally, remove claim ID from "myClaims" array in the user Profile.
      //First fetch the profile data from the specific profile from the database.
      const profileRef = doc(db, "Profiles", user);
      const profileDocument = await getDoc(profileRef);
      const profileData = profileDocument.data()

      if (profileData) {
        //Add the claim's ID to the myClaims array.
        profileData.myClaims.followedClaims.push({
          id: claimID,
          lastViewed: timeStamps.visited
        })
        
        await setDoc(profileRef, profileData)
        dispatch(setProfile({
          ...profileData, 
          id: user
        }))
        // dispatch(setClaim({
        //   ...ClaimData,
        //   id: claimID
        // }))
      }
    } catch (err) {
      console.log('unable to startAddClaimFollow')
    }
  }
}

//Method to reverse following a claim should do the following:
//1) Remove the claimID from the followedClaims array under the myClaims object of the fellow.
//2) Remove the fellowID + occurrence from the "follows" array of the Claim.
//3) Remove the fellowID + occurrence from the "follows" array under the updates object of the Claim.
//Validated Claim/:id
export const startRemoveClaimFollow = (user, claimID) => {
  return async (dispatch) => {
    try {
      //First fetch the claim data from the specific claim from the database.
      const claimRef = doc(db, "Claims", claimID);
      const claimDocument = await getDoc(claimRef)
      const claimData = claimDocument.data()

      //Check to see if the claimData is there first incase the claim was deleted.
      if (claimData) {
        //Create a new "follows" array and remove the user's ID from it.
        const removeUser = claimData.follows.filter((theUser) => {
          return theUser.id != user
        })
        claimData.follows = removeUser

        //Create a new follows array for the updates and remove the fellow's ID from it:
        const removeUser2 = claimData.updates.follows.filter((theUser) => {
          return theUser.id != user
        })
        claimData.updates.follows = removeUser2
        
        //Set the follows array to the new array.
        await setDoc(claimRef, claimData)
      }
      //Additionally, remove claim ID from "myClaims" array in the user Profile.
      //First fetch the profile data from the specific profile from the database.
      const profileRef = doc(db, "Profiles", user);
      const profileDocument = await getDoc(profileRef);
      const profileData = profileDocument.data()

      if (profileData) {
        //Create a new "myClaims" array and remove the claim's ID from it.
        const removeClaim = profileData.myClaims.followedClaims.filter((theClaim) => {
          return theClaim.id != claimID
        })

        //Set the myClaims array to the new array.
        profileData.myClaims.followedClaims = removeClaim
        await setDoc(profileRef, profileData)
        dispatch(setProfile({
          ...profileData, 
          id: user
        }))

        // dispatch(setClaim({
        //   ...claimData,
        //   id: claimID
        // }))
      }
    } catch (err) {
      console.log('unable to startRemoveClaimFollow')
    }
  }
}

// REMOVE_EXPENSE
export const removeClaim = ({ title } = {}) => ({
  type: 'REMOVE_CLAIM',
  title
});

// EDIT_EXPENSE
export const editClaim = (id, updates) => ({
  type: 'EDIT_EXPENSE',
  id,
  updates
});

//Method to add criticism to claim should do the following: 
//1) Add the criticism to the "criticisms" array of the fig of that claim.
//2) Add the fellowID + occurrence to the "engagements" array
//3) Add the fellowID + occurrence to the "criticisms" array under the "updates" object of the claim.
//4) Add the claimID to the comments array under the "updates" object of the fellow.
//Validated figureClaimStatelessSupport 
//Validated figureClaimStatelessContra
export const startAddCriticismClaim = (info) => {
  const theCriticism = {
    owner: info.owner,
    ownerTags: info.ownerTags,
    critDownVotes: info.critDownVotes,
    critUpVotes: info.critUpVotes,
    text: info.text,
    occurrence: info.occurrence,
    timeStamp: info.timeStamp
  }
  
  return async (dispatch) => {
    try {
      //fetch the correct claim from Firestore.
      const docRef = doc(db, "Claims", info.claimID);
      const claimDocument = await getDoc(docRef);
      const claimData = claimDocument.data()
      
      if (claimData) {
        //Add the new criticism to the criticisms array of that particular contraFig.
        let theArray;
        if (info.figType === "support") {
          theArray = claimData.supportFigs
        } else if (info.figType === "contradict") {
          theArray = claimData.contraFigs
        }
        //Add the whole criticism to the criticisms array.
        theArray[info.figIndex].criticisms.push(theCriticism)

        //Update the array and re-save to Firestore
        if (info.figType === "support") {
          claimData.supportFigs = theArray
        } else if (info.figType === "contradict") {
          claimData.contraFigs = theArray
        }

        //check to see if the user has engaged with this claim before. If they have not, add their id to the engagement array.
        claimData.engagement.push(
          {
            id: info.owner,
            occurrence: info.occurrence,
            timeStamp: info.timeStamp
          }
        )
        claimData.updates.comments.push(
          {
            id: info.owner,
            occurrence: info.occurrence,
            timeStamp: info.timeStamp
          }
        )
        await setDoc(docRef, claimData)
        // dispatch(setClaim({
        //   ...claimData,
        //   id: claimDocument.id 
        // }))
        const profRef = doc(db, "Profiles", info.owner);
        let profileDocument = await getDoc(profRef)
        const profileData = profileDocument.data()
        if (profileData) {
          profileData.updates.comments.push(
            {
              timeStamp: info.timeStamp,
              occurrence: info.occurrence,
              id: info.claimID,
              anonymous: info.anonymous
            }
          )
          await setDoc(profRef, profileData)
        }
      }
    } catch (err) {
      console.log('adding criticism on figure of claim did not work.')
    }
  }
}

//Method to remove criticism from claim should do the following:
//1) Remove the criticism from the "criticisms" array of the fig of that claim.- check
//2) Remove the fellowID + occurrence of the criticism from the "engagements" array. - check
//3) Remove the fellowID + occurrence of the criticism from the "engagements" array under the "updates" object of the claim. - check
//4) Remove the claimID from the "comments" array under the "updates" object of the fellow.
//5) Remove all occurrences of all critUpVotes and critDownVotes from the "engagements" array.
//6) Remove all occurrences of all critUpVotes and critDownVotes from the "engagements" array.
//Validated figureClaimStatelessContra
//Validated figureClaimStatelessSupport
export const startDeleteCriticism = (figType, claimID, fellowID, critOccurrence, figid) => {
  return async (dispatch) => {
    try {
      //fetch the correct claim from Firestore.
      const docRef = doc(db, "Claims", claimID);
      const claimDocument = await getDoc(docRef);
      const claimData = claimDocument.data()
      if (claimData) {
        //Add the new criticism to the criticisms array of that particular support/contraFig.
        let theArray;
        if (figType === "support") {
          theArray = claimData.supportFigs
        } else if (figType === "contradict") {
          theArray = claimData.contraFigs
        }

        //Find the correct figureIndex.
        const figIndex = theArray.findIndex((figure) => {
          return figure.id == figid
        })

        //Gather all occurrences of critUpVotes and critDownVotes in one array:
        let allCritVoteEngagements = [];
        let critIndex = theArray[figIndex].criticisms.findIndex(criticism => criticism.occurrence == critOccurrence)
        theArray[figIndex].criticisms[critIndex].critUpVotes.forEach(vote => allCritVoteEngagements.push(vote))
        theArray[figIndex].criticisms[critIndex].critDownVotes.forEach(vote => allCritVoteEngagements.push(vote))
        claimData.engagement = removeEngagement(claimData.engagement, allCritVoteEngagements)
        claimData.updates.engagement = removeEngagement(claimData.updates.engagement, allCritVoteEngagements)
        
        //Find the correct criticism to be deleted.
        const newClaimCriticisms = theArray[figIndex].criticisms.filter(crit => crit.occurrence != critOccurrence)
        theArray[figIndex].criticisms = newClaimCriticisms
        const newClaimCommentUpdates = claimData.updates.comments.filter(update => update.occurrence != critOccurrence)
        claimData.updates.comments = newClaimCommentUpdates
        const newEngagements = claimData.engagement.filter(engagement => engagement.occurrence != critOccurrence)
        claimData.engagement = newEngagements

        //Update the array and re-save to Firestore
        if (figType === "support") {
          claimData.supportFigs = theArray
        } else if (figType === "contradict") {
          claimData.contraFigs = theArray
        }
        await setDoc(docRef, claimData)
        // dispatch(setClaim({
        //   ...claimData,
        //   id: claimDocument.id 
        // }))
      // console.log('the state should be updated')
      }
      const profRef = doc(db, "Profiles", fellowID);
      let profileDocument = await getDoc(profRef)
      const profileData = profileDocument.data()
      if (profileData) {
        const newUpdatesComments = profileData.updates.comments.filter((update) => {
          return update.occurrence != critOccurrence
        })
        profileData.updates.comments = newUpdatesComments
        await setDoc(profRef, profileData)
      }
    } catch (err) {
      console.log('adding criticism on figure of claim did not work.')
    }
  }
}

//Method for upVoting a criticism on a claim will do the following: 
//1) Add the fellowID + occurrence to the "critUpVotes" array under the correct criticism of the correct figure.
//2) Add the fellowID + occurrence to the "engagements" array of the claim.
//3) Add the fellowID + occurrence to the "engagements" array under the "updates" object of the claim.
//Validated figureClaimStatelessContra
//Validated figureClaimStatelessSupport
export const startAcceptCriticismClaim = (critOccurrence, FigureID, figType, user, claimID, timeStamp, voteOccurrence) => {
  return async (dispatch) => {
    try {
      // fetch the data for the claim
      const docRef = doc(db, "Claims", claimID);
      const claimDocument = await getDoc(docRef);
      const claimData = claimDocument.data()
      if (claimData) {
        let theArray;

        //Get either the supportFig or contraFig list depending on the figType.
        if (figType === "support") {
          theArray = claimData.supportFigs
        } else if (figType === "contradict") {
          theArray = claimData.contraFigs
        }

        //First find the correct figure index:
        let figIndex = theArray.findIndex(fig => fig.id == FigureID)

        //If the figure is there, find the correct criticism index:
        if (figIndex > -1) {
          let critIndex = theArray[figIndex].criticisms.findIndex(criticism => criticism.occurrence == critOccurrence)
          //If the criticism is there, add the fellow's vote to it.
          if (critIndex > -1) {
            //Add the userID to the list of critUpVotes
            theArray[figIndex].criticisms[critIndex].critUpVotes.push(
              {
                id: user,
                occurrence: voteOccurrence,
                timeStamp: timeStamp
              }
            )

            //Update the array and re-save to Firestore
            if (figType === "support") {
              claimData.supportFigs = theArray
            } else if (figType === "contradict") {
              claimData.contraFigs = theArray
            }

            //Add the fellowID + occurrence to the "engagements" array of the claim.
            claimData.engagement.push(
              {
                id: user,
                occurrence: voteOccurrence,
                timeStamp: timeStamp
              }
            )
            
            //Add the fellowID + occurrence to the "engagements" array under the "updates" object of the claim.
            claimData.updates.engagement.push(
              {
                id: user,
                occurrence: voteOccurrence,
                timeStamp: timeStamp
              }
            )
            
            //Re-save the document.
            await setDoc(docRef, claimData)
            
            //Reset the claim on Redux
            // dispatch(setClaim({
            //   ...claimData,
            //   id: claimDocument.id 
            // }))
          }
        }
      }
    } catch (err) {
      console.log('Claim criticism upVote did not work')
    }
  }
}

//Method for reversing upVoting a criticism on a claim will do the following: 
//1) Remove the fellowID + occurrence from the "critUpVotes" array under the correct criticism of the correct figure.
//2) Remove the fellowID + occurrence from the "engagements" array of the claim.
//3) Remove the fellowID + occurrence from the "engagements" array under the "updates" object of the claim.
//Validated figureClaimStatelessContra
//Validated figureClaimStatelessSupport
export const reverseAcceptCriticismClaim = (critOccurrence, FigureID, figType, user, claimID) => {
  return async (dispatch) => {

    try {
    //fetcht the data for the claim.
    const docRef = doc(db, "Claims", claimID);
    const claimDocument = await getDoc(docRef);
    const claimData = claimDocument.data()
    let theArray;

    //Get either the supportFig or contraFig list depending on the figType.
    if (figType === "support") {
      theArray = claimData.supportFigs
    } else if (figType === "contradict") {
      theArray = claimData.contraFigs
    }

    if (claimData) {
      let theArray;

      //Get either the supportFig or contraFig list depending on the figType.
      if (figType === "support") {
        theArray = claimData.supportFigs
      } else if (figType === "contradict") {
        theArray = claimData.contraFigs
      }

      //First find the correct figure index:
      let figIndex = theArray.findIndex(fig => fig.id == FigureID)

      //If the figure is there, find the correct criticism index:
      if (figIndex > -1) {
        let critIndex = theArray[figIndex].criticisms.findIndex(criticism => criticism.occurrence == critOccurrence)
        //If the criticism is there, run the following:
        if (critIndex > -1) {

          //Get the correct critUpVoteIndex:
          let critUpVoteIndex = theArray[figIndex].criticisms[critIndex].critUpVotes.findIndex(critUpVote => critUpVote.id == user)
          //If there is a critUpVote belonging to the fellow, run the following:

          if (critUpVoteIndex > -1) {
            let voteOccurrence = theArray[figIndex].criticisms[critIndex].critUpVotes[critUpVoteIndex].occurrence
            
            //Remove the fellow ID + occurrence from the list of critUpVotes
            let newCritUpVotes = theArray[figIndex].criticisms[critIndex].critUpVotes.filter(vote => vote.id != user)
            theArray[figIndex].criticisms[critIndex].critUpVotes = newCritUpVotes

            //Update the array and re-save to Firestore
            if (figType === "support") {
              claimData.supportFigs = theArray
            } else if (figType === "contradict") {
              claimData.contraFigs = theArray
            }

            //Remove the fellowID + occurrence from the "engagements" array of the claim.
            let newEngagement = claimData.engagement.filter(engagement => engagement.occurrence != voteOccurrence)
            claimData.engagement = newEngagement
            
            //Remove the fellowID + occurrence from the "engagements" array under the "updates" object of the claim.
            let newEngagementUpdates = claimData.updates.engagement.filter(update => update.occurrence != voteOccurrence)
            claimData.updates.engagement = newEngagementUpdates
            
            //Re-save the document.
            await setDoc(docRef, claimData)
            
            //Reset the claim on Redux
            // dispatch(setClaim({
            //   ...claimData,
            //   id: claimDocument.id 
            // }))
          }
        }
      }
    }
    } catch (err) {
      console.log('Claim criticism remove upVote did not work')
    }
  }
}

//Method for downVoting a criticism on a claim will do the following: 
//1) Add the fellowID + occurrence to the "critDownVotes" array under the correct criticism of the correct figure.
//2) Add the fellowID + occurrence to the "engagements" array of the claim.
//3) Add the fellowID + occurrence to the "engagements" array under the "updates" object of the claim.
//Validated figureClaimStatelessContra
//Validated figureClaimStatelessSupport
export const startRejectCriticismClaim = (critOccurrence, FigureID, figType, user, claimID, timeStamp, voteOccurrence) => {
  
  return async (dispatch) => {
    try {
      // fetch the data for the claim
      const docRef = doc(db, "Claims", claimID);
      const claimDocument = await getDoc(docRef);
      const claimData = claimDocument.data()
      if (claimData) {
        let theArray;

        //Get either the supportFig or contraFig list depending on the figType.
        if (figType === "support") {
          theArray = claimData.supportFigs
        } else if (figType === "contradict") {
          theArray = claimData.contraFigs
        }

        //First find the correct figure index:
        let figIndex = theArray.findIndex(fig => fig.id == FigureID)

        //If the figure is there, find the correct criticism index:
        if (figIndex > -1) {
          let critIndex = theArray[figIndex].criticisms.findIndex(criticism => criticism.occurrence == critOccurrence)
          //If the criticism is there, add the fellow's vote to it.
          if (critIndex > -1) {
            //Add the userID to the list of critDownVotes
            theArray[figIndex].criticisms[critIndex].critDownVotes.push(
              {
                id: user,
                occurrence: voteOccurrence,
                timeStamp: timeStamp
              }
            )

            //Update the array and re-save to Firestore
            if (figType === "support") {
              claimData.supportFigs = theArray
            } else if (figType === "contradict") {
              claimData.contraFigs = theArray
            }

            //Add the fellowID + occurrence to the "engagements" array of the claim.
            claimData.engagement.push(
              {
                id: user,
                occurrence: voteOccurrence,
                timeStamp: timeStamp
              }
            )
            
            //Add the fellowID + occurrence to the "engagements" array under the "updates" object of the claim.
            claimData.updates.engagement.push(
              {
                id: user,
                occurrence: voteOccurrence,
                timeStamp: timeStamp
              }
            )
            
            //Re-save the document.
            await setDoc(docRef, claimData)
            
            //Reset the claim on Redux
            // dispatch(setClaim({
            //   ...claimData,
            //   id: claimDocument.id 
            // }))
          }
        }
      }
    } catch (err) {
      console.log('Claim criticism upVote did not work')
    }
  }
}

//Method for reversing downVoting a criticism on a claim will do the following: 
//1) Remove the fellowID + occurrence from the "critDownVotes" array under the correct criticism of the correct figure.
//2) Remove the fellowID + occurrence from the "engagements" array of the claim.
//3) Remove the fellowID + occurrence from the "engagements" array under the "updates" object of the claim.
//Validated figureClaimStatelessContra
//Validated figureClaimStatelessSupport
export const reverseRejectCriticismClaim = (critOccurrence, FigureID, figType, user, claimID) => {
  return async (dispatch) => {

    try {
      //fetcht the data for the claim.
      const docRef = doc(db, "Claims", claimID);
      const claimDocument = await getDoc(docRef);
      const claimData = claimDocument.data()
      let theArray;

      //Get either the supportFig or contraFig list depending on the figType.
      if (figType === "support") {
        theArray = claimData.supportFigs
      } else if (figType === "contradict") {
        theArray = claimData.contraFigs
      }

      if (claimData) {
        let theArray;

        //Get either the supportFig or contraFig list depending on the figType.
        if (figType === "support") {
          theArray = claimData.supportFigs
        } else if (figType === "contradict") {
          theArray = claimData.contraFigs
        }

        //First find the correct figure index:
        let figIndex = theArray.findIndex(fig => fig.id == FigureID)

        //If the figure is there, find the correct criticism index:
        if (figIndex > -1) {
          let critIndex = theArray[figIndex].criticisms.findIndex(criticism => criticism.occurrence == critOccurrence)
          //If the criticism is there, run the following:
          if (critIndex > -1) {

            //Get the correct critDownVoteIndex:
            let critDownVoteIndex = theArray[figIndex].criticisms[critIndex].critDownVotes.findIndex(critDownVote => critDownVote.id == user)
            //If there is a critDownVote belonging to the fellow, run the following:

            if (critDownVoteIndex > -1) {
              let voteOccurrence = theArray[figIndex].criticisms[critIndex].critDownVotes[critDownVoteIndex].occurrence
              
              //Remove the fellow ID + occurrence from the list of critDownVotes
              let newCritUpVotes = theArray[figIndex].criticisms[critIndex].critDownVotes.filter(vote => vote.id != user)
              theArray[figIndex].criticisms[critIndex].critDownVotes = newCritUpVotes

              //Update the array and re-save to Firestore
              if (figType === "support") {
                claimData.supportFigs = theArray
              } else if (figType === "contradict") {
                claimData.contraFigs = theArray
              }

              //Remove the fellowID + occurrence from the "engagements" array of the claim.
              let newEngagement = claimData.engagement.filter(engagement => engagement.occurrence != voteOccurrence)
              claimData.engagement = newEngagement
              
              //Remove the fellowID + occurrence from the "engagements" array under the "updates" object of the claim.
              let newEngagementUpdates = claimData.updates.engagement.filter(update => update.occurrence != voteOccurrence)
              claimData.updates.engagement = newEngagementUpdates
              
              //Re-save the document.
              await setDoc(docRef, claimData)
              
              //Reset the claim on Redux
              // dispatch(setClaim({
              //   ...claimData,
              //   id: claimDocument.id 
              // }))
            }
          }
        }
      }
    } catch (err) {
      console.log('Claim criticism remove upVote did not work')
    }
  }
}

const getClaimConfidence = (doesNotSupportTally, somewhatSupportsTally, reallySupportsTally, doesNotContradictTally, somewhatContradictsTally, reallyContradictsTally) => {
  let numeratorCalculation = 100 * (reallySupportsTally*3 + somewhatSupportsTally)
  let denominatorCalculation = (reallyContradictsTally*3 + somewhatContradictsTally - doesNotContradictTally + doesNotSupportTally + reallySupportsTally*3 + somewhatSupportsTally)
  
  if (numeratorCalculation < 0 || isNaN(numeratorCalculation) ) {
      numeratorCalculation = 0;
  }

  if (denominatorCalculation < 0 || isNaN(denominatorCalculation) ) {
      denominatorCalculation = 1;
  }

  let calculation = numeratorCalculation/denominatorCalculation;

  if (calculation < 0) {
      calculation = 0;
  }

  if (isNaN(calculation)) {
      
      return "?";
  }

  if (Math.trunc(calculation) > 100) {
      return 100;
  }

  if (Math.trunc(calculation) < 0) {
      return 0;
  }
  return Math.trunc(calculation);
}



const checkIfAnonymous = (owner, ownerTags) => {
  let anonymous = true;
  ownerTags.forEach((tag) => {
    if (tag.id == owner) {
      anonymous = false
    }
  })
  return anonymous;
}

export const returnClaimInfoProfile = (claim) => {
  return async (dispatch) => {
    try {
      //fetch the correct claim from the Claims database in firestore
      const docRef = doc(db, "Claims", claim);
      const claimDocument = await getDoc(docRef);
      const claimData = claimDocument.data()

      if (claimData == undefined) {
        const date = new Date('Thu Jan 01 1970 00:00:00 GMT+0000')
        const claimInfo = {
          confidence: '?',
          engagementNumber: '?',
          title: 'Claim Unavailable',
          timeStamp: moment(date),
          unavailable: true
        }
        return claimInfo;
      }
      //calculate the claim confidence of that claim
      let doesNotSupportTally = 0;
      let somewhatSupportsTally = 0;
      let reallySupportsTally = 0;
      let doesNotContradictTally = 0;
      let somewhatContradictsTally = 0;
      let reallyContradictsTally = 0;

      for (const figure of claimData.supportFigs) {
        doesNotSupportTally += figure.doesNotSupport.length
        somewhatSupportsTally += figure.somewhatSupports.length
        reallySupportsTally += figure.reallySupports.length
      }
      for (const figure of claimData.contraFigs) {
        doesNotContradictTally += figure.doesNotSupport.length
        somewhatContradictsTally += figure.somewhatSupports.length
        reallyContradictsTally += figure.reallySupports.length
      }

      const claimConfidence = getClaimConfidence(doesNotSupportTally, somewhatSupportsTally, 
        reallySupportsTally, doesNotContradictTally, somewhatContradictsTally, reallyContradictsTally)
      const engagementNumber = getUniqueEngagement(claimData.engagement).length
      const title = claimData.title;
      const theTimeStamp = new Date(claimData.timeStamp)

      const claimInfo = {
        confidence: claimConfidence,
        engagementNumber: engagementNumber,
        title: title,
        timeStamp: moment(theTimeStamp),
        id: claim,
        moment: moment(theTimeStamp)
      }
      claimInfo.timeStamp = claimInfo.timeStamp.format('LLL')
      claimInfo.anonymous = checkIfAnonymous(claimData.owner, claimData.ownerTags)
      return claimInfo;
    } catch (err) {
      console.log('could not fetch claimInfo')
    }
  }
}

export const returnClaimInfo = (claim) => {
  return async (dispatch) => {
    try {
      //fetch the correct claim from the Claims database in firestore
      const docRef = doc(db, "Claims", claim);
      const claimDocument = await getDoc(docRef);
      const claimData = claimDocument.data()

      if (claimData == undefined) {
        const date = new Date('Thu Jan 01 1970 00:00:00 GMT+0000')
        const claimInfo = {
          confidence: '?',
          engagementNumber: '?',
          title: 'Claim Unavailable',
          timeStamp: moment(date)
        }
        return claimInfo;
      }
      //calculate the claim confidence of that claim
      let doesNotSupportTally = 0;
      let somewhatSupportsTally = 0;
      let reallySupportsTally = 0;
      let doesNotContradictTally = 0;
      let somewhatContradictsTally = 0;
      let reallyContradictsTally = 0;

      for (const figure of claimData.supportFigs) {
        doesNotSupportTally += figure.doesNotSupport.length
        somewhatSupportsTally += figure.somewhatSupports.length
        reallySupportsTally += figure.reallySupports.length
      }
      for (const figure of claimData.contraFigs) {
        doesNotContradictTally += figure.doesNotSupport.length
        somewhatContradictsTally += figure.somewhatSupports.length
        reallyContradictsTally += figure.reallySupports.length
      }

      const claimConfidence = getClaimConfidence(doesNotSupportTally, somewhatSupportsTally, 
        reallySupportsTally, doesNotContradictTally, somewhatContradictsTally, reallyContradictsTally)
      const engagementNumber = claimData.engagement.length
      const title = claimData.title;
      const theTimeStamp = new Date(claimData.timeStamp)
      const claimInfo = {
        confidence: claimConfidence,
        engagementNumber: engagementNumber,
        title: title,
        timeStamp: moment(theTimeStamp)
      }
      return claimInfo;
    } catch (err) {
      console.log('adding criticism on figure of claim did not work.')
    }
  }
}

export const returnClaimInfoMyClaims2 = (claim) => {
  return async (dispatch) => {
    try {
      //fetch the correct claim from the Claims database in firestore
      const docRef = doc(db, "Claims", claim);
      const claimDocument = await getDoc(docRef);
      const claimData = claimDocument.data()

      if (claimData == undefined) {
        const date = new Date('Thu Jan 01 1970 00:00:00 GMT+0000')
        const claimInfo = {
          confidence: '?',
          engagementNumber: '?',
          title: 'Claim Unavailable',
          timeStamp: moment(date)
        }
        return claimInfo;
      }
      //calculate the claim confidence of that claim
      let doesNotSupportTally = 0;
      let somewhatSupportsTally = 0;
      let reallySupportsTally = 0;
      let doesNotContradictTally = 0;
      let somewhatContradictsTally = 0;
      let reallyContradictsTally = 0;

      for (const figure of claimData.supportFigs) {
        doesNotSupportTally += figure.doesNotSupport.length
        somewhatSupportsTally += figure.somewhatSupports.length
        reallySupportsTally += figure.reallySupports.length
      }
      for (const figure of claimData.contraFigs) {
        doesNotContradictTally += figure.doesNotSupport.length
        somewhatContradictsTally += figure.somewhatSupports.length
        reallyContradictsTally += figure.reallySupports.length
      }

      const claimConfidence = getClaimConfidence(doesNotSupportTally, somewhatSupportsTally, 
        reallySupportsTally, doesNotContradictTally, somewhatContradictsTally, reallyContradictsTally)
      const engagementNumber = getUniqueEngagement(claimData.engagement).length
      const title = claimData.title;
      const theTimeStamp = new Date(claimData.timeStamp)
      const claimInfo = {
        confidence: claimConfidence,
        engagementNumber: engagementNumber,
        title: title,
        timeStamp: moment(theTimeStamp)
      }
      return claimInfo;
    } catch (err) {
      console.log('adding criticism on figure of claim did not work.')
    }
  }
}


//Method to delete a claim should accomplish the following:
//1) Delete the claim from the "Claims" collection. - check
//2) Remove the claim from the "createdClaims" array of the "myClaims" object of the fellow's profile. - check
//3) Remove the claim from the "createdClaims" array of the "updates" object of the fellow's profile. - check
//4) If the claim is extracted from a Paper, remove it from the "extractedClaims" array of that Paper. - check
//5) If the claim is extracted from a Paper, remove it from the "extractedClaims" array under the "updates" object of that paper. - check
//5) Remove the claim from the "associatedClaims" array of its figure that has been connected to it. - check
//6) Add the text "(UNAVAILABLE)" to the title of each element of a claimsList of a theory in which this claim is referenced. - check
//Validated Claim/:id
export const startDeleteClaim = (id) => {
  return async (dispatch) => {
    try {
      const docRef = doc(db, "Claims", id)
      const claimDocument = await getDoc(docRef)
      const claimData = claimDocument.data()
      //If the claim can be fetched, do the following:
      if (claimData) {
        //Firstly, each theory that uses this claim will have the title of the claim updated to reflect that it is no longer
        //available.
        //get all of the docRefs for the theories that this claim has been connected to from its "associatedTheories array"
        let allDocRefs = []
        const associatedTheories = claimData.associatedTheories
        associatedTheories.forEach((theory) => {
          allDocRefs.push(doc(db, "Theories", theory))
        })
        //get the data for each theory that this claim is connected to.
        allDocRefs.forEach( async (ref) => {
          const document = await getDoc(ref)
          const theoryData = document.data()
          if (theoryData) {
            for (let i = 0; i < theoryData.claimsList.length; i++) {
              if (i % 2 == 1) {
                if (theoryData.claimsList[i].id == id) {
                  theoryData.claimsList[i].title = theoryData.claimsList[i].title + ' (UNAVAILABLE)'
                }
              }
            }
            await setDoc(ref, theoryData)
          }
        })

        //Next
        //get all of the docRefs for the contraFigs that are connected to this claim.
        let allContraFigDocRefs = []
        const allContraFigsData = [...claimData.contraFigs]
        allContraFigsData.forEach((contraFig) => {
          allContraFigDocRefs.push(doc(db, "Figures", contraFig.id))
        })

        //For each contraFig, remove each associatedClaim object that has the id of the claim being deleted.
        //Also delete the occurrence in the claimConnections array of the updates object.
        allContraFigDocRefs.forEach(async (ref) => {
          const document = await getDoc(ref)
          const figureData = document.data()
          //if the figure can be fetched, run the following:
          if (figureData) {
            let newAssociatedClaims = figureData.associatedClaims.filter((claim) => {
              return claim.id != id;
            })
            figureData.associatedClaims = newAssociatedClaims;
            let newUpdates = figureData.updates.claimConnections.filter((update) => {
              return update.id != id;
            })
            figureData.updates.claimConnections = newUpdates;
            await setDoc(ref, figureData)
          }
        })
        //get all of the docRefs for the supportFigs that are connected to this claim.
        let allSupportFigDocRefs = []
        const allSupportFigsData = [...claimData.supportFigs]
        allSupportFigsData.forEach((supportFig) => {
          allSupportFigDocRefs.push(doc(db, "Figures", supportFig.id))
        })

        //delete from the associatedClaims array those elements that have the id of the claim and re-save each figure.
        allSupportFigDocRefs.forEach(async (ref) => {
          const document = await getDoc(ref)
          const figureData = document.data()
          //if the figure can be fetched, run the following:
          if (figureData) {
            let newAssociatedClaims = figureData.associatedClaims.filter((claim) => {
              return claim.id != id;
            })
            figureData.associatedClaims = newAssociatedClaims
            let newUpdates = figureData.updates.claimConnections.filter((update) => {
              return update.id != id;
            })
            figureData.updates.claimConnections = newUpdates;
            await setDoc(ref, figureData)
          }
        })
        //Finally, delete the claim
        await deleteDoc(docRef)
      }
      //Next, delete the claim from the "createdClaims" array in the "myClaims" map of the owner's profile
      //1) get the profile data from the owner
      const ownerProfileRef = doc(db, "Profiles", claimData.owner)
      const profileDocument = await getDoc(ownerProfileRef)
      const profileData = profileDocument.data()
      if (profileData) {
        // console.log(profileData)

        //2) delete the claimID from the createdClaims array
        let newCreatedClaims = profileData.myClaims.createdClaims.filter((claim) => {
          return claim.id != id
        })
        profileData.myClaims.createdClaims = newCreatedClaims
        let newUpdates = profileData.updates.createdClaims.filter((update) => {
          return update.id != id
        })
        profileData.updates.createdClaims = newUpdates
        //3) re-save the profile.
        await setDoc(ownerProfileRef, profileData)
        dispatch(setProfile({
          ...profileData, 
          id: claimData.owner
        }))
      }
      //Additionally, if this is a claim extracted from paper, remove it from the extractedClaims array of that paper.
      if (claimData.extractedClaim) {
        //1) Fetch the paper.
        const paperDocRef = doc(db, "Papers", claimData.paperID)
        const paperDocument = await getDoc(paperDocRef)
        const paperData = paperDocument.data()
        
        if (paperData) {
          //2) Remove claim ID from the extracted Claims array
          let newExtractedClaims = paperData.extractedClaims.filter((claim) => {
            return claim.id !== id
          })
          paperData.extractedClaims = newExtractedClaims
          let newUpdates = paperData.updates.claimExtractions.filter((update) => {
            return update.id != id
          })
          paperData.updates.claimExtractions = newUpdates
          //3) Re-save the paperdata into Firebase
          await setDoc(paperDocRef, paperData)
          
        }
      }
    } catch (err) {
      console.log('error in deleting the claim.')
    }
  }
}


//Method to update the last time a Claim was viewed will do the following:
//1) Update the "lastViewed" string to reflect the current time when the figure is viewed if it is somewhere in the fellow's colleciton.
export const updateLastViewedClaim = (user, claimID, timeStamp) => {
  return async (dispatch) => {
    try {
      //Fetch the profile document of the owner.
      const profRef = doc(db, "Profiles", user);
      const document = await getDoc(profRef)
      let profileData = document.data()

      if (profileData) {
        const isInCreated = profileData.myClaims.createdClaims.findIndex((figure) => {
          return figure.id == claimID
        })
        const isInFollowed = profileData.myClaims.followedClaims.findIndex((figure) => {
          return figure.id == claimID
        })
        const isInFigureClaims = profileData.myClaims.figureClaims.findIndex((figure) => {
          return figure.id == claimID
        })
        const isInPaperClaims = profileData.myClaims.paperClaims.findIndex((figure) => {
          return figure.id == claimID
        })

        //Search through all figures within figureClaims, createdClaims, and followedClaims for the ID
        if (isInCreated > -1) {
          profileData.myClaims.createdClaims[isInCreated].lastViewed = timeStamp
        }
        if (isInFollowed > -1) {
          profileData.myClaims.followedClaims[isInFollowed].lastViewed = timeStamp
        }
        if (isInFigureClaims > -1) {
          profileData.myClaims.figureClaims[isInFigureClaims].lastViewed = timeStamp
        }
        if (isInPaperClaims > -1) {
          profileData.myClaims.figureClaims[isInPaperClaims].lastViewed = timeStamp
        }

        await setDoc(profRef, profileData)
        return;
      }

    } catch (err) {
      console.log('Not able to update lastViewed in myClaims.')
    }
  }
}



//This function returns all the claim information from Firebase. It fetches the whole document.
export const returnClaimInfoHomeFeed = (claim) => {
  return async () => {
    try {
      //fetch the correct claim from the Claims database in firestore
      const docRef = doc(db, "Claims", claim);
      const claimDocument = await getDoc(docRef);
      const claimData = claimDocument.data()
      return claimData;
    } catch (err) {
      console.log('adding criticism on figure of claim did not work.')
    }
  }
}

export const getClaimInfo = (claim) => {
  return async () => {
    try {
      //fetch the correct claim from the claims database in firestore
      const docRef = doc(db, "Claims", claim);
      const claimDocument = await getDoc(docRef);
      const claimData = claimDocument.data()
      claimData.id = claim;

      if (claimData == undefined) {
        return false;
      }
      return claimData;
    } catch (err) {
      console.log('Fetching claim info didnt work from getClaimInfo.')
    }
  }
}

















// export const startRejectCriticism = (text, FigureID, imgsrc, user) => {
//   console.log(user)
//   return (dispatch) => {
//     const docRef = doc(db, "Figures", FigureID);
//       return getDoc(docRef).then((doc) => {
//         let FigureData = doc.data()
//         FigureData.criticisms.forEach((criticism) => {
//             if (text == criticism.text) {
//               criticism.critDownVotes.push(user)
//             }
//         })
//         return setDoc(docRef, FigureData).then((doc) => {
//           dispatch(setFigure({
//             ...FigureData,
//             id: FigureID,
//             imgsrc: imgsrc }))
//           }).catch(() => {
//             console.log('startRejectCriticism not working')
//           })
//       })
//     }
// }

// export const reverseRejectCriticism = (text, FigureID, imgsrc, user) => {
//   return (dispatch) => {
//     const docRef = doc(db, "Figures", FigureID);
//       return getDoc(docRef).then((doc) => {
//         let FigureData = doc.data()
        
//         const findCrit = (criticism) => {
//           return criticism.text == text
//         }

//         const index = FigureData.criticisms.findIndex(findCrit)

//         const hello = FigureData.criticisms[index].critDownVotes.filter((theUser) => {
//             return theUser != user
//         })
        
//         FigureData.criticisms[index].critDownVotes = hello
//         return setDoc(docRef, FigureData).then((doc) => {
//           dispatch(setFigure({
//             ...FigureData,
//             id: FigureID,
//             imgsrc: imgsrc }))
//           }).catch(() => {
//             console.log('startAcceptCriticism not working')
//           })
//       })
//     }
// }

// export const startAddCriticism = (info) => {
//   const theCriticism = {
//     owner: info.owner,
//     ownerTags: info.ownerTags,
//     critDownVotes: info.critDownVotes,
//     critUpVotes: info.critUpVotes,
//     text: info.text
//   }
//   return (dispatch) => {
//     const docRef = doc(db, "Figures", info.figureID);
//       return getDoc(docRef).then((doc) => {
//         const FigureData = doc.data()
//         FigureData.criticisms.push(theCriticism)
//         return setDoc(docRef, FigureData).then((doc) => {
//           dispatch(setFigure({
//             ...FigureData,
//             id: info.figureID,
//             imgsrc: info.imgsrc }))
//           }).catch(() => {
//             console.log('startAddCriticism not working')
//           })
//       })
//     }
// }

// export const startDeleteCriticism = (text, FigureID, imgsrc) => {
//   return (dispatch) => {
//     const docRef = doc(db, "Figures", FigureID);
//       return getDoc(docRef).then((doc) => {
//         let FigureData = doc.data()
//         const newFigure = FigureData.criticisms.filter(crit => crit.text != text)
//         FigureData.criticisms = newFigure
//         return setDoc(docRef, FigureData).then((doc) => {
//           dispatch(setFigure({
//             ...FigureData,
//             id: FigureID,
//             imgsrc: imgsrc }))
//           }).catch(() => {
//             console.log('startDeleteCriticism not working')
//           })
//       })
//     }
// }