import React from "react";


function SVGFigure() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className='marginSVG' width="20" height="20">
      <path className="color000 svgShape" fill="#002c2c" d="m24 3.875l-6 1.221 1.716 1.708-5.351 5.358-3.001-3.002-7.336 7.242 1.41 1.418 5.922-5.834 2.991 2.993 6.781-6.762 1.667 1.66 1.201-6.002zm0 17.125v2h-24v-22h2v20h22z"/>
    </svg>
  );
}

export default SVGFigure;