import React, { useState, useRef, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { useFetcher, useNavigate } from "react-router-dom"
import { startAddFellowFollow, startRemoveFellowFollow, updateLastViewedFellow } from '../actions/profModels';
import Tippy from '@tippyjs/react'
import moment from 'moment';
import SVGOptionsDots from './SVGs/SVGoptionsDots';
import { db, profileCollection, app, storage } from '../firebase/firebase'
import { doc, setDoc, getDoc, addDoc, deleteDoc } from 'firebase/firestore'
import ClaimsList from './ProfilePageComponents/claimsList'
import PapersList from './ProfilePageComponents/papersList'
import FiguresList from './ProfilePageComponents/figuresList'
import TheoriesList from './ProfilePageComponents/theoriesList'
import { history } from '../routers/AppRouter'
import { v4 as uuidv4 } from 'uuid';


const Profile = (props) => {
    const [profileProps, setProfileProps] = useState({tags: [], userName: ''})
    const [optionsOpen, setOptionsOpen] = useState(false)
    const [createdClaims, setCreatedClaims] = useState([])
    const [authoredTheories, setAuthoredTheories] = useState([])
    const [uploadedFigures, setUploadedFigures] = useState([])
    const [identifiedPapers, setIdentifiedPapers] = useState([])
    const [fellowID, setFellowID] = useState('')
    const [followed, setFollowed] = useState(false)
    const [followsList, setFollowsList] = useState([])
    const [followEngagement, setFollowEngagement] = useState([])
    const [figuresListAccepted, setFiguresListAccepted] = useState([])
    const [figuresListRejected, setFiguresListRejected] = useState([])
    const [claimsFollowsList, setClaimsFollowsList] = useState([])
    const [theoriesFollowsList, setTheoriesFollowsList] = useState([])
    const [papersFollowsList, setPapersFollowsList] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [anonTag, setAnonTag] = useState(false)
    const [anonTagName, setAnonTagName] = useState('')
    const [anonTagDescription, setAnonTagDescription] = useState('')

    function findObjectById(arr, id) {
        return arr.find(obj => obj.id === id);
    }
    

    
    function removeEngagement (array1, array2) {
        return array1.filter(obj1 => !array2.some(obj2 => obj1.occurrence === obj2.occurrence));
    }
    

    useEffect( async () => {
        setLoaded(false)
        let URLpath = window.location.pathname
        let split = URLpath.split('/')
        let id;
        if (split.length == 3) {
            id = split[2]
        }
        // console.log('ran')
        //Grab the profile data of the fellow
        if (id !== '') {
            const profileDocRef = doc(db, "Profiles", id);
            const profileDoc = await getDoc(profileDocRef);
            const profileData = profileDoc.data()
            // console.log(profileData.tagHolders)
            if (profileData.tagHolders) {
                setAnonTag(true)
                setAnonTagName(profileData.name)
                setAnonTagDescription(profileData.description)
                setProfileProps({ tags: [], userName: '' });
                setOptionsOpen(false);
                setCreatedClaims([]);
                setAuthoredTheories([]);
                setUploadedFigures([]);
                setIdentifiedPapers([]);
                setFellowID('');
                setFollowed(false);
                setFollowsList([]);
                setFollowEngagement([]);
                setFiguresListAccepted([]);
                setFiguresListRejected([]);
                setClaimsFollowsList([]);
                setTheoriesFollowsList([]);
                setPapersFollowsList([]);
                setLoaded(false);
                setLoaded(true)
                return;
            }
            setIdentifiedPapers(profileData.myPapers.identifiedPapers)
            setUploadedFigures(profileData.myFigures.uploadedFigures)
            setFiguresListAccepted(profileData.myFigures.acceptedFigures)
            setFiguresListRejected(profileData.myFigures.rejectedFigures)
            setAuthoredTheories(profileData.myTheories.authoredTheories)
            setCreatedClaims(profileData.myClaims.createdClaims)
            setClaimsFollowsList(profileData.myClaims.followedClaims)
            setTheoriesFollowsList(profileData.myTheories.followedTheories)
            setPapersFollowsList(profileData.myPapers.followedPapers)
            setProfileProps(profileData)
            setFollowsList(profileData.follows)
            setFellowID(id)
            let object = findObjectById(props.follows, id)

            if (object) {
                setFollowed(true)
                setFollowEngagement([object])
            }
            setLoaded(true)
            if (props.loggedIn) {
                for(var i = 0; i < profileData.follows.length; i++) {
                    if (profileData.follows[i].id === props.user) {
                        setFollowed(true)
                        break;
                    }
                }
                // let timeStamp = moment().utc().toString()
                // await props.updateLastViewedFellow(props.user, id, timeStamp)
            }
        }
    }, [window.location.pathname])

    const navigate = useNavigate()
    const [whichTabIsIt, setWhichTabIsIt] = useState('Theories')
    
    const whichTab = (tab) => {
        if (loaded != true) {
            return;
        }
        setWhichTabIsIt(tab)
    }
    
    let optionsRefDiv = useRef();
    let optionsRefIcon = useRef();

    useEffect(() => {
        let handler = (event) => {
            if(!(optionsRefDiv.current.contains(event.target) || optionsRefIcon.current.contains(event.target))) {
                setOptionsOpen(false)
            }
        }
        document.addEventListener("mousedown", handler)
        return () => {
            document.removeEventListener("mousedown", handler)
        }
    })

    const reportFellow = () => {
        navigate('/Report/Fellow/' + fellowID)
    }

    const followFellow = () => {
        setFollowed(!followed)
        let timeStamp = moment().utc().toString();
        let occurrence = uuidv4();

        let newFollow = {
            id: props.user,
            occurrence: occurrence,
            timeStamp: timeStamp
        }

        if (!followed) {
            setFollowsList(prev => [...prev, newFollow])
            setFollowEngagement([newFollow])
            props.startAddFellowFollow(props.user, fellowID, occurrence, timeStamp).then(() => {
                
            }).catch(() => {
                // console.log('theory was not followed')
            })
        } else if (followed) {
            setFollowsList(prev => removeEngagement([...prev], followEngagement))
            setFollowEngagement([])
            props.startRemoveFellowFollow(props.user, fellowID).then(() => {
                
            }).catch(() => {
                // console.log('theory was not followed')
            })
        } else {
            // console.log('something wrong with theory-follow logic')
        }
        
    }

    function removeSubstring(originalString) {
        let split = originalString.split('/')
        let theURL = '/' + split[3] + '/' + split[4]
        return theURL;
    }

    function handleClick(event) {
        event.preventDefault();
        let URL = removeSubstring(event.target.href, event.target.baseURI)
        navigate(URL)
    }

    return (
        <div>
            <div hidden={props.loggedIn} className="loginNoticeProfile" ><a className="anchor" href="/Login">
                    Login or create your own account</a>
            </div>
            <div className="spacerProfile">
                <div className="headerProfile2">
                    <img className="SettingsImgProfile" src={profileProps.imgsrc ? profileProps.imgsrc : '/Images/Book2.jpg'}/>
                    <div className="spacerHeaderProfile2">
                        <div className={!anonTag ? "paddingLeftHeaderProfile" : "hidden"}>{profileProps.name}</div>
                        <div className={anonTag ? "paddingLeftHeaderProfile" : "hidden"}>{anonTagName}</div>
                        <span className='paddingTopAnchorProfile'>
                        {
                            profileProps.tags.map((tag, index) => {
                                return (<a key={tag.displayName} className={index != 0 ? "anchorNameProfile" : "anchorNameProfile0"} 
                                href={'/AnonTag/' + tag.id } onClick={handleClick}>{tag.displayName}</a>)
                            })
                        }
                        </span>
                        <div className={!anonTag ? 'followersDivProfile' : 'hidden'}>Followers: {followsList.length}</div>
                        <div className={anonTag ? 'followersDivProfile' : 'hidden'}>{anonTagDescription}</div>
                    </div>
                    <div className={props.loggedIn && !anonTag ? "ProfileOptionsIcon" : "hidden"}>
                        <div className={props.user != fellowID ? "" : "hidden"}>
                            <Tippy content={followed ? "Unfollow " + profileProps.name : "Follow " + profileProps.name} >
                                <svg className={followed ? "followSVGSVGTheoryClicked" : "followSVGSVGTheory"} onClick={followFellow} version="1.1" 
                                viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                                    <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path className="color000 svgShape" d="m24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" 
                                        fill={followed ? "#fff" : "#002c2c"}/>
                                    </svg>
                                </svg>
                            </Tippy>
                        </div>
                        <div className='optionsClaim' onClick={() => setOptionsOpen(!optionsOpen)} ref={optionsRefIcon}>
                            <SVGOptionsDots/>
                        </div>
                        <div className="dropDownClaimWrapper" ref={optionsRefDiv} hidden={!optionsOpen} >
                            <div className='dropDownTheoryLarge'>
                                <div className='optionsAnchorDiv' onClick={reportFellow}>
                                    <svg className='marginSVG' width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 
                                        0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z" fill="#002c2c"/>
                                    </svg>
                                    <a><b>Report this Profile</b></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={anonTag ? 'hidden' : 'center'}>
                <div className="myClaimsMajorContainer">
                    <div className='myClaimsTabContainer'>
                        <div onClick={() => {whichTab('Theories')}} 
                        className={whichTabIsIt == 'Theories' ? "myClaimsTabSelected" : "myClaimsTab"}>
                            Theories
                        </div>
                        <div onClick={() => {whichTab('Figures')}} 
                        className={whichTabIsIt == 'Figures' ? "myClaimsTabSelected" : "myClaimsTab"}>
                            Figures
                        </div>
                        <div onClick={() => {whichTab('Claims')}} 
                        className={whichTabIsIt == 'Claims' ? "myClaimsTabSelected" : "myClaimsTab"}>
                            Claims
                        </div>
                        <div onClick={() => {whichTab('Papers')}} 
                        className={whichTabIsIt == 'Papers' ? "myClaimsTabSelected" : "myClaimsTab"}>
                            Papers
                        </div>
                    </div>
                    <ClaimsList whichTabIsIt={whichTabIsIt} createdClaims={createdClaims} loaded={loaded}
                    fellowID={fellowID} claimsFollowsList={claimsFollowsList} fellowName={profileProps.name}/>
                    <PapersList whichTabIsIt={whichTabIsIt} identifiedPapers={identifiedPapers} loaded={loaded}
                    fellowID={fellowID} papersFollowsList={papersFollowsList} fellowName={profileProps.name}/>
                    <FiguresList whichTabIsIt={whichTabIsIt} loaded={loaded} uploadedFigures={uploadedFigures}
                    figuresListAccepted={figuresListAccepted} figuresListRejected={figuresListRejected}
                    fellowID={fellowID} fellowName={profileProps.name}/>
                    <TheoriesList whichTabIsIt={whichTabIsIt} loaded={loaded} authoredTheories={authoredTheories}
                    fellowID={fellowID} theoriesFollowsList={theoriesFollowsList} fellowName={profileProps.name}/>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state, profileProps) => {
    if (state.profModels.length == 1) {
        return { 
            user: state.profModels[0].id,
            userName: state.profModels[0].name,
            tags: state.profModels[0].tags,
            loggedIn: true,
            follows: state.profModels[0].follows
        }
    } else {
        return { 
            user: '',
            userName: '',
            tags: [],
            loggedIn: false,
            follows: []
        }
    }
}

const mapDispatchToProps = (dispatch, profileProps) => ({
    startSetProfile: (email) => dispatch(startSetProfile(email)),
    returnClaimInfoProfile: (claim) => dispatch(returnClaimInfoProfile(claim)),
    startAddFellowFollow: (user, fellowID, occurrence, timeStamp) => dispatch(startAddFellowFollow(user, fellowID, occurrence, timeStamp)),
    startRemoveFellowFollow: (user, fellowID) => dispatch(startRemoveFellowFollow(user, fellowID)),
    updateLastViewedFellow: (user, id, timeStamp) => dispatch(updateLastViewedFellow(user, id, timeStamp))
})

const ConnectedProfile = connect(mapStateToProps, mapDispatchToProps)(Profile);

export default ConnectedProfile;