import uuid from 'uuid';
import { db, theoryCollection, app, storage } from '../firebase/firebase'
import { doc, setDoc, getDoc, addDoc, deleteDoc } from 'firebase/firestore'
import configureStore from '../store/configureStore'
import { getStorage, ref, uploadBytes, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import moment from 'moment'
import { useNavigate } from "react-router-dom"
import { setProfile } from './profModels';


// function that returns the dispatch call you need to make to the store

function getUniqueEngagement (objects) {
  const seenIds = new Set();
  return objects.filter(obj => {
      if (seenIds.has(obj.id)) {
      return false;
      } else {
      seenIds.add(obj.id);
      return true;
      }
  });
}

export const setTheory = (
  {
    title = '',
    owner = '',
    ownerTags = [],
    id,
    claimsList = [],
    comprehensiveness = {five: [], four: [], three: [], two: [], one: []},
    follows = [],
    published = false,
    timeStamp = 0,
    engagement = []
  } = {theory}
) => ({
  type: 'SET_THEORY',
  theory: {
    title: title,
    owner: owner,
    ownerTags: ownerTags,
    id: id,
    claimsList: claimsList,
    comprehensiveness: comprehensiveness,
    follows: follows,
    published: published,
    timeStamp: timeStamp,
    engagement: engagement
  }
});


export const startSetTheory = (id) => {
  return async (dispatch) => {
      const docRef = doc(db, "Theories", id);
      let theoryDocument = await getDoc(docRef)
      const TheoryData = theoryDocument.data()
      try {
        // dispatch(setTheory({
        // ...TheoryData,
        // id: theoryDocument.id
        //  }))
        // console.log('theory state set?')
    } catch(err) {
        console.log('startSetTheory not working')
    }
  }
}

export const startSetAddTheory = (id) => {
  return async (dispatch) => {
    const docRef = doc(db, "Theories", id);
    let theoryDocument = await getDoc(docRef)
    const TheoryData = theoryDocument.data()
    try {
        // dispatch(setTheory({
        // ...TheoryData,
        // id: theoryDocument.id,
        //  }))
        // console.log('theory state set?')
    } catch(err) {
        console.log('startSetTheory not working')
    }
  }
}

export const startAddTheory = (TheoryData = {}) => {
  return (dispatch) => {
    const theTheory = {
      ...TheoryData
    }
    return addDoc(theoryCollection, theTheory)
  }
}



const getClaimConfidence = (doesNotSupportTally, somewhatSupportsTally, reallySupportsTally, doesNotContradictTally, somewhatContradictsTally, reallyContradictsTally) => {
  let numeratorCalculation = 100 * (reallySupportsTally*3 + somewhatSupportsTally)
  let denominatorCalculation = (reallyContradictsTally*3 + somewhatContradictsTally - doesNotContradictTally + doesNotSupportTally + reallySupportsTally*3 + somewhatSupportsTally)
  
  if (numeratorCalculation < 0 || isNaN(numeratorCalculation) ) {
      numeratorCalculation = 0;
  }

  if (denominatorCalculation < 0 || isNaN(denominatorCalculation) ) {
      denominatorCalculation = 1;
  }

  let calculation = numeratorCalculation/denominatorCalculation;

  if (calculation < 0) {
      calculation = 0;
  }

  if (isNaN(calculation)) {
      
      return "?";
  }

  if (Math.trunc(calculation) > 100) {
      return 100;
  }

  if (Math.trunc(calculation) < 0) {
      return 0;
  }
  return Math.trunc(calculation);
}

const calculateAveragePercentageConfidenceClaims = (averageImportances, claimConfidences) => {
  let i = 0;
  let numerator = 0;
  let denominator = 0;
  while (i < claimConfidences.length) {
    //If any given claim does not have a percentage confidence, then omit it from the final calculation.
    if (claimConfidences[i] != '?') {
      numerator += averageImportances[i] * claimConfidences[i]
      denominator += averageImportances[i]
    }
    i += 1
  }
  // console.log('the numerator is ' + numerator)
  // console.log('the denominator is ' + denominator)
  return numerator/denominator;
}

const getAverageImportances = (claimsList) => {
  let averageImportances = [];
  let totalCount = 0;
  for (const claim of claimsList) {
    let importanceCount = claim.somewhatImportant.length + claim.veryImportant.length * 3
    let count = claim.notImportant.length + claim.somewhatImportant.length + claim.veryImportant.length
    //If the importance of any given claimRef has not been voted on yet, just assign a value of 1.
    if (count == 0) {
      averageImportances.push(1)
    } else {
    averageImportances.push(importanceCount/count)
    }
  }
  return averageImportances;
}


//This parseOutText function removes all the text elements in the claimsList array.
//It assumes that the array alternates between text and claimObjects and would not work if it didnt.
const parseOutText = (claimsList) => {
  let newArray = [];
  let i = 0;
  while (i < claimsList.length) {
    if ((i % 2) == 1) {
      newArray.push(claimsList[i])
    }
    i += 1;
  }
  return newArray;
}

function calculateAverageComprehensiveness(comprehensivenessObject) {
  let allCheckBoxes = []
  allCheckBoxes.push(comprehensivenessObject.one)
  allCheckBoxes.push(comprehensivenessObject.two)
  allCheckBoxes.push(comprehensivenessObject.three)
  allCheckBoxes.push(comprehensivenessObject.four)
  allCheckBoxes.push(comprehensivenessObject.five)
  let sum = 0;
  let voteCount = 0;
  allCheckBoxes.forEach((array, index) => {
      sum += array.length * (index + 1)
      voteCount += array.length
  })
  return (sum/voteCount * 0.2);
}

//Method to voteNotImportant on a claimReference of a Theory will accomplish the following:
//1) Add the fellowID + occurrence to the "notImportant" array of the correct claimReference of the claimsList
//2) Add the fellowID + occurrence to the "engagement" array of the Theory.
//3) Add the fellowID + occurrence to the "engagement" array under the "updates" object of the Theory.
//Validated Theory/:id
export const startVoteNotImportant = (vote) => {
  return async (dispatch) => {
    const docRef = doc(db, "Theories", vote.theoryID);
    const theoryDocument = await getDoc(docRef);

    //Get the document for the Theory
    const theoryData = theoryDocument.data()

    //If the theory can be fetched from Firebase, run the following:
    if (theoryData) {
      //Add users ID to notImportant array of that claimReference
      theoryData.claimsList[vote.index].notImportant.push(
        {
          timeStamp: vote.timeStamp,
          occurrence: vote.occurrence,
          id: vote.user
        }
      )

      theoryData.engagement.push(
        {
          id: vote.user,
          occurrence: vote.occurrence,
          timeStamp: vote.timeStamp
        }
      )

      theoryData.updates.engagement.push(
        {
          timeStamp: vote.timeStamp,
          occurrence: vote.occurrence,
          id: vote.user
        }
      )

      //Re-save the theory data in firebase and update data in Redux
      await setDoc(docRef, theoryData)
      // dispatch(setTheory({
      //   ...theoryData,
      //   id: vote.theoryID
      // }))
    }
  }
}

//Method to reverseVoteNotImportant will accomplish the following:
//1) Remove the fellowID + occurrence from the "notImportant" array of the correct claimReference of the claimsList.
//2) Remove the fellowID + occurrence from the "engagement" array of the Theory.
//3) Remove the fellowID + occurrence from the "engagement" array under the "updates" object of the Theory.
//Validated Theory/:id
export const reverseVoteNotImportant = (vote) => {
  return async (dispatch) => {
    const docRef = doc(db, "Theories", vote.theoryID);
    const theoryDocument = await getDoc(docRef);
    //Get the document for the Claim
    const theoryData = theoryDocument.data()

    if (theoryData) {
    //Function for finding the correct supporting figure.
    // const findClaim = (claim) => {
    //   if (typeof claim == 'string') {
    //     return false;
    //   } else if (typeof claim == 'object' && claim.id == vote.claimID) {
    //     return true;
    //   }
    // }
    // //Get index of correct claim.
    // const index = theoryData.claimsList.findIndex(findClaim)
    
    //First, find the occurrence of the fellow's vote:
      let voteIndex = theoryData.claimsList[vote.index].notImportant.findIndex(theVote => theVote.id == vote.user) 
      // console.log(voteIndex)
      if (voteIndex > -1) {
        let theOccurrence = theoryData.claimsList[vote.index].notImportant[voteIndex].occurrence
        // console.log(theOccurrence)
        // console.log('hello')
        //Remove the fellow's vote from the "notImportant" array.
        let newNotImportant = theoryData.claimsList[vote.index].notImportant.filter(theVote => theVote.id != vote.user)
        theoryData.claimsList[vote.index].notImportant = newNotImportant

        //Remove the engagement from the "engagement" array of the Theory.
        let newEngagement = theoryData.engagement.filter(engagement => engagement.occurrence != theOccurrence)
        theoryData.engagement = newEngagement

        //Remove the update from the "engagement" array under the "updates" object of the Theory.
        let newUpdates = theoryData.updates.engagement.filter(engagement => engagement.occurrence != theOccurrence)
        theoryData.updates.engagement = newUpdates

        await setDoc(docRef, theoryData)
        // dispatch(setTheory({
        //   ...theoryData,
        //   id: vote.theoryID
        // }))
      }
    }
  }
}


//Method to voteSomewhatImportant on a claimReference of a Theory will accomplish the following:
//1) Add the fellowID + occurrence to the "somewhatImportant" array of the correct claimReference of the claimsList
//2) Add the fellowID + occurrence to the "engagement" array of the Theory.
//3) Add the fellowID + occurrence to the "engagement" array under the "updates" object of the Theory.
//Validated Theory/:id
export const startVoteSomewhatImportant = (vote) => {
  return async (dispatch) => {
    const docRef = doc(db, "Theories", vote.theoryID);
    const theoryDocument = await getDoc(docRef);

    //Get the document for the Theory
    const theoryData = theoryDocument.data()

    //If the theory can be fetched from Firebase, run the following:
    if (theoryData) {
      //Add users ID to somewhatImportant array of that claimReference
      theoryData.claimsList[vote.index].somewhatImportant.push(
        {
          timeStamp: vote.timeStamp,
          occurrence: vote.occurrence,
          id: vote.user
        }
      )

      theoryData.engagement.push(
        {
          id: vote.user,
          occurrence: vote.occurrence,
          timeStamp: vote.timeStamp
        }
      )

      theoryData.updates.engagement.push(
        {
          timeStamp: vote.timeStamp,
          occurrence: vote.occurrence,
          id: vote.user
        }
      )

      //Re-save the theory data in firebase and update data in Redux
      await setDoc(docRef, theoryData)
      // dispatch(setTheory({
      //   ...theoryData,
      //   id: vote.theoryID
      // }))
    }
  }
}

//Method to reverseVoteNotImportant will accomplish the following:
//1) Remove the fellowID + occurrence from the "somewhatImportant" array of the correct claimReference of the claimsList.
//2) Remove the fellowID + occurrence from the "engagement" array of the Theory.
//3) Remove the fellowID + occurrence from the "engagement" array under the "updates" object of the Theory.
//Validated Theory/:id
export const reverseVoteSomewhatImportant = (vote) => {
  return async (dispatch) => {
    const docRef = doc(db, "Theories", vote.theoryID);
    const theoryDocument = await getDoc(docRef);
    //Get the document for the Claim
    const theoryData = theoryDocument.data()

    if (theoryData) {
    //First, find the occurrence of the fellow's vote:
      let voteIndex = theoryData.claimsList[vote.index].somewhatImportant.findIndex(theVote => theVote.id == vote.user) 
      if (voteIndex > -1) {
        let theOccurrence = theoryData.claimsList[vote.index].somewhatImportant[voteIndex].occurrence
        //Remove the fellow's vote from the "somewhatImportant" array.
        let newSomewhatImportant = theoryData.claimsList[vote.index].somewhatImportant.filter(theVote => theVote.id != vote.user)
        theoryData.claimsList[vote.index].somewhatImportant = newSomewhatImportant

        //Remove the engagement from the "engagement" array of the Theory.
        let newEngagement = theoryData.engagement.filter(engagement => engagement.occurrence != theOccurrence)
        theoryData.engagement = newEngagement

        //Remove the update from the "engagement" array under the "updates" object of the Theory.
        let newUpdates = theoryData.updates.engagement.filter(engagement => engagement.occurrence != theOccurrence)
        theoryData.updates.engagement = newUpdates

        await setDoc(docRef, theoryData)
        // dispatch(setTheory({
        //   ...theoryData,
        //   id: vote.theoryID
        // }))
      }
    }
  }
}

//Method to voteVeryImportant on a claimReference of a Theory will accomplish the following:
//1) Add the fellowID + occurrence to the "veryImportant" array of the correct claimReference of the claimsList
//2) Add the fellowID + occurrence to the "engagement" array of the Theory.
//3) Add the fellowID + occurrence to the "engagement" array under the "updates" object of the Theory.
//Validated Theory/:id
export const startVoteVeryImportant = (vote) => {
  return async (dispatch) => {
    const docRef = doc(db, "Theories", vote.theoryID);
    const theoryDocument = await getDoc(docRef);

    //Get the document for the Theory
    const theoryData = theoryDocument.data()

    //If the theory can be fetched from Firebase, run the following:
    if (theoryData) {
      //Add users ID to veryImportant array of that claimReference
      theoryData.claimsList[vote.index].veryImportant.push(
        {
          timeStamp: vote.timeStamp,
          occurrence: vote.occurrence,
          id: vote.user
        }
      )

      theoryData.engagement.push(
        {
          id: vote.user,
          occurrence: vote.occurrence,
          timeStamp: vote.timeStamp
        }
      )

      theoryData.updates.engagement.push(
        {
          timeStamp: vote.timeStamp,
          occurrence: vote.occurrence,
          id: vote.user
        }
      )

      //Re-save the theory data in firebase and update data in Redux
      await setDoc(docRef, theoryData)
      // dispatch(setTheory({
      //   ...theoryData,
      //   id: vote.theoryID
      // }))
    }
  }
}


//Method to reverseVoteVeryImportant will accomplish the following:
//1) Remove the fellowID + occurrence from the "veryImportant" array of the correct claimReference of the claimsList.
//2) Remove the fellowID + occurrence from the "engagement" array of the Theory.
//3) Remove the fellowID + occurrence from the "engagement" array under the "updates" object of the Theory.
//Validated Theory/:id
export const reverseVoteVeryImportant = (vote) => {
  return async (dispatch) => {
    const docRef = doc(db, "Theories", vote.theoryID);
    const theoryDocument = await getDoc(docRef);
    //Get the document for the Claim
    const theoryData = theoryDocument.data()

    if (theoryData) {
    //First, find the occurrence of the fellow's vote:
      let voteIndex = theoryData.claimsList[vote.index].veryImportant.findIndex(theVote => theVote.id == vote.user) 
      if (voteIndex > -1) {
        let theOccurrence = theoryData.claimsList[vote.index].veryImportant[voteIndex].occurrence
        //Remove the fellow's vote from the "veryImportant" array.
        let newVeryImportant = theoryData.claimsList[vote.index].veryImportant.filter(theVote => theVote.id != vote.user)
        theoryData.claimsList[vote.index].veryImportant = newVeryImportant

        //Remove the engagement from the "engagement" array of the Theory.
        let newEngagement = theoryData.engagement.filter(engagement => engagement.occurrence != theOccurrence)
        theoryData.engagement = newEngagement

        //Remove the update from the "engagement" array under the "updates" object of the Theory.
        let newUpdates = theoryData.updates.engagement.filter(engagement => engagement.occurrence != theOccurrence)
        theoryData.updates.engagement = newUpdates

        await setDoc(docRef, theoryData)
        dispatch(setTheory({
          ...theoryData,
          id: vote.theoryID
        }))
      }
    }
  }
}

//Method to follow a theory will accomplish the following:
//1) Add the fellowID + occurrence to the "follows" array of the Theory.
//2) Add the fellowID + occurrence to the "follows" array under the "updates" object of the Theory.
//3) Add the theoryID to the followedTheories array under the myTheories object of the fellow.
//Validated Theory/:id
export const startAddTheoryFollow = (user, theoryID, occurrence, timeStamp) => {
  return async (dispatch) => {
    try {
      //First fetch the data from the specific claim from the database.
      const docRef = doc(db, "Theories", theoryID);
      const TheoryDocument = await getDoc(docRef)
      const theoryData = TheoryDocument.data()

      //If the Theory can be fetched from Firebase, run the following:
      if (theoryData) {
        //Add the user's ID to the follows array, which tells us who is following this particular claim.
        theoryData.follows.push({
          id: user,
          occurrence: occurrence,
          timeStamp: timeStamp
        })
        theoryData.updates.follows.push(
          {
            id: user,
            occurrence: occurrence,
            timeStamp: timeStamp
          }
        )
        await setDoc(docRef, theoryData)
        dispatch(setTheory({
          ...theoryData,
          id: theoryID
        }))

        //Additionally, remove claim ID from "myTheories" array in the user Profile.
        //First fetch the profile data from the specific profile from the database.
        const profileRef = doc(db, "Profiles", user);
        const profileDocument = await getDoc(profileRef);
        const profileData = profileDocument.data()

        //If the Fellow can be fetched from Firebase, run the following:
        if (profileData) {
          //Add the theory's ID to the myTheories array.
          profileData.myTheories.followedTheories.push({
            id: theoryID,
            lastViewed: timeStamp
          })
          await setDoc(profileRef, profileData)
          dispatch(setProfile({
            ...profileData, 
            id: user
          }))
        }
      }
    } catch (err) {
      console.log('unable to startAddTheoryFollow')
    }
  }
}

//Method to reverse following a theory should do the following:
//1) Remove the theoryID from the followedTheories array under the myTheories object of the fellow.
//2) Remove the fellowID + occurrence from the "follows" array of the Theory.
//3) Remove the fellowID + occurrence from the "follows" array under the updates object of the Theory.
//Validated Theory/:id
export const startRemoveTheoryFollow = (user, theoryID) => {
  return async (dispatch) => {
    try {
      //First fetch the theory data from the specific theory from the database.
      const theoryRef = doc(db, "Theories", theoryID);
      const theoryDocument = await getDoc(theoryRef)
      const theoryData = theoryDocument.data()

      //Check to see if the theoryData is there first incase the theory was deleted.
      if (theoryData) {
        //Create a new "follows" array and remove the user's ID from it.
        const removeUser = theoryData.follows.filter((theUser) => {
          return theUser.id != user
        })
        theoryData.follows = removeUser

        //Create a new follows array for the updates and remove the fellow's ID from it:
        const removeUser2 = theoryData.updates.follows.filter((theUser) => {
          return theUser.id != user
        })
        theoryData.updates.follows = removeUser2
        
        //Set the follows array to the new array.
        await setDoc(theoryRef, theoryData)
      }
      //Additionally, remove theory ID from "myTheories" array in the user Profile.
      //First fetch the profile data from the specific profile from the database.
      const profileRef = doc(db, "Profiles", user);
      const profileDocument = await getDoc(profileRef);
      const profileData = profileDocument.data()

      //If the profile can be fetched from Firebase, run the following:
      if (profileData) {
        //Create a new "myTheories" array and remove the theory's ID from it.
        const removeTheory = profileData.myTheories.followedTheories.filter((theTheory) => {
          return theTheory.id != theoryID
        })

        //Set the myTheories array to the new array.
        profileData.myTheories.followedTheories = removeTheory
        await setDoc(profileRef, profileData)

        dispatch(setProfile({
          ...profileData, 
          id: user
        }))
      }
    } catch (err) {
      console.log('unable to startRemoveTheoryFollow')
    }
  }
}

//Method to vote on a theory's comprehensiveness will accomplish the following:
//1) Add the fellowID + occurrence to the correct comprehensiveness array of the Theory.
//2) Remove the fellowID + occurrence from the correct comprehensiveness array of the Theory if it is there.
//3) Add the fellowID + occurrence to the "engagement" array under the "updates" object of the Theory.
//4) Remove the fellowID + occurrence from the "engagement" array under the "updates" object of the Theory if it is there.
//5) Add the fellowID + occurrence to the "engagement" array of the Theory.
//6) Remove the prior occurrence from the "engagement" array of the Theory if there was one.
//Validated Theory/:id
export const startVoteComprehensiveness = (user, theoryID, checkBoxNumber, occurrence, timeStamp) => {
  return async (dispatch) => {
    try {
      //First fetch the theory data from the specific theory from the database.
      const theoryRef = doc(db, "Theories", theoryID);
      const theoryDocument = await getDoc(theoryRef)
      const theoryData = theoryDocument.data()

      //If the theory can be fetched from Firebase, run the following:
      if (theoryData) {

        let fellowVote = {
          id: user,
          occurrence: occurrence,
          timeStamp: timeStamp
        }
        
        //First, if the fellow has voted on the comprehensiveness of the Theory before, we need the prior occurrence.
        //Then remove the fellowID + occurrence if it is currently in any of the comprehensiveness arrays.
        let thePriorOccurrence;
        let indexOne = theoryData.comprehensiveness.one.findIndex(vote => vote.id == user)
        if (indexOne > -1) {
          thePriorOccurrence = theoryData.comprehensiveness.one[indexOne].occurrence
          let newOne = theoryData.comprehensiveness.one.filter(vote => vote.id != user)
          theoryData.comprehensiveness.one = newOne
        }
        let indexTwo = theoryData.comprehensiveness.two.findIndex(vote => vote.id == user)
        if (indexTwo > -1) {
          thePriorOccurrence = theoryData.comprehensiveness.two[indexTwo].occurrence
          let newTwo = theoryData.comprehensiveness.two.filter(vote => vote.id != user)
          theoryData.comprehensiveness.two = newTwo
        }
        let indexThree = theoryData.comprehensiveness.three.findIndex(vote => vote.id == user)
        if (indexThree > -1) {
          thePriorOccurrence = theoryData.comprehensiveness.three[indexThree].occurrence
          let newThree = theoryData.comprehensiveness.three.filter(vote => vote.id != user)
          theoryData.comprehensiveness.three = newThree
        }
        let indexFour = theoryData.comprehensiveness.four.findIndex(vote => vote.id == user)
        if (indexFour > -1) {
          thePriorOccurrence = theoryData.comprehensiveness.four[indexFour].occurrence
          let newFour = theoryData.comprehensiveness.four.filter(vote => vote.id != user)
          theoryData.comprehensiveness.four = newFour
        }
        let indexFive = theoryData.comprehensiveness.five.findIndex(vote => vote.id == user)
        if (indexFive > -1) {
          thePriorOccurrence = theoryData.comprehensiveness.five[indexFive].occurrence
          let newFive = theoryData.comprehensiveness.five.filter(vote => vote.id != user)
          theoryData.comprehensiveness.five = newFive
        }

        //Remove any previous vote engagement
        if (thePriorOccurrence) {
          const newEngagements = theoryData.engagement.filter(engagement => engagement.occurrence != thePriorOccurrence)
          theoryData.engagement = newEngagements
          const newUpdates = theoryData.updates.engagement.filter(engagement => engagement.occurrence != thePriorOccurrence)
          theoryData.updates.engagement = newUpdates
        }

        //Add the fellow's vote to the necessary comprehensiveness array
        if (checkBoxNumber == 1) {
          theoryData.comprehensiveness.one.push(fellowVote)
        }
        if (checkBoxNumber == 2) {
          theoryData.comprehensiveness.two.push(fellowVote)
        }
        if (checkBoxNumber == 3) {
          theoryData.comprehensiveness.three.push(fellowVote)
        }
        if (checkBoxNumber == 4) {
          theoryData.comprehensiveness.four.push(fellowVote)
        }
        if (checkBoxNumber == 5) {
          theoryData.comprehensiveness.five.push(fellowVote)
        }

        //Add the fellowID + occurrence to the "engagement" array
        theoryData.engagement.push(fellowVote)
        
        //Add the fellowID + occurrence to the "engagement" array under the "updates" object of the Theory.
        theoryData.updates.engagement.push(fellowVote)
        await setDoc(theoryRef, theoryData)
        return theoryData;

      }
    } catch (err) {
      console.log('unable to startVoteComprehensiveness')
    }
  }
}



export const startSaveTheory = (updatedClaimsList, theoryID) => {
  return async (dispatch) => {
    try {
      //First fetch the theory data from the specific theory from the database.
      const theoryRef = doc(db, "Theories", theoryID);
      const theoryDocument = await getDoc(theoryRef)
      const theoryData = theoryDocument.data()

      if (theoryData) {
        //Set the claimsList to the updatedClaimsList and re-save in firestore
        theoryData.claimsList = updatedClaimsList
        await setDoc(theoryRef, theoryData)

        dispatch(setTheory({
          ...theoryData,
          id: theoryID
        }))
      }
    } catch (err) {
      console.log('unable to save the theory')
    }
  }
}

export const startSaveTitleTheory = (updatedTitle, theoryID) => {
  return async (dispatch) => {
    try {
      //First fetch the theory data from the specific theory from the database.
      const theoryRef = doc(db, "Theories", theoryID);
      const theoryDocument = await getDoc(theoryRef)
      const theoryData = theoryDocument.data()

      if (theoryData) {
        //Set the title to the updatedTitle and re-save in firestore
        theoryData.title = updatedTitle
        await setDoc(theoryRef, theoryData)

        dispatch(setTheory({
          ...theoryData,
          id: theoryID
        }))
      }
    } catch (err) {
      console.log('unable to save the theory')
    }
  }
}

//Method to publish a Theory should do the following:
//1) Set the "published" boolean on the Theory document to "true" and add the other relevant information.
//2) Add the theoryID + occurrence to the "associatedTheories" array of each unique claim that was referenced in the theory.
//3) Add the theoryID + occurrence to the "theoryAdditions" array under the "updates" object of each unique claim that was referenced in the theory.
//Validated AddTheory/:id
export const startPublishTheory = (theory) => {
  return async (dispatch) => {
    try {
      let time = moment().utc().toString()
      //First fetch the theory data from the specific theory from the database.
      const theoryRef = doc(db, "Theories", theory.theoryID);
      const theoryDocument = await getDoc(theoryRef)
      const theoryData = theoryDocument.data()

      if (theoryData) {
        //Set the claimsList to the updatedClaimsList and re-save in firestore
        theoryData.claimsList = theory.claimsList
        theoryData.ownerTags = theory.ownerTags
        theoryData.timeStamp = time
        theoryData.published = true
        theoryData.title = theory.title
        await setDoc(theoryRef, theoryData)

        //get all of the docRefs for the claims that are referenced by this Theory.
        let allClaimDocRefs = [];
        const allAssociatedClaims = [];
        for (let i = 0; i < theoryData.claimsList.length; i++) {
          if (i % 2 == 1) {
            allAssociatedClaims.push(theoryData.claimsList[i].id)
          }
        }

        //Remove any potential duplicates from the array:
        let uniqueClaims = [...new Set(allAssociatedClaims)]
        uniqueClaims.forEach((claim) => {
          allClaimDocRefs.push(doc(db, "Claims", claim))
        })

        //For each associatedClaim, add an associatedTheory object.
        allClaimDocRefs.forEach(async (ref) => {
          const document = await getDoc(ref)
          const claimData = document.data()
          if (claimData) {
            const newAssociation = {
              id: theory.theoryID,
              title: theory.title,
              connector: theory.owner,
              connectorTags: theory.ownerTags,
              occurrence: theory.occurrence,
              timeStamp: theory.timeStamp
            }
            claimData.associatedTheories.push(newAssociation)
            claimData.updates.theoryAdditions.push(
              {
                timeStamp: theory.timeStamp,
                occurrence: theory.occurrence,
                id: theory.theoryID
              }
            )
            await setDoc(ref, claimData)
          }
        })

        dispatch(setTheory({
          ...theoryData,
          id: theory.theoryID
        }))
        return theory.theoryID;
      }
    } catch (err) {
      console.log('unable to save the theory')
    }
  }
}

//Method to delete a theory from Firebase will do the following:
//1) Delete the theory document from the "Theories" Collection.
//2) Remove the theoryID from "authoredTheories" array under the "myTheories" object of the fellow's profile.
//3) Remove the theoryID from "authoredTheories" array under the "updates" object of the fellow's profile.
//Validated AddTheory/:id
//Validated myTheories
export const startDeleteAddTheory = (id, owner) => {
  return async (dispatch) => {
    try {
      const addTheoryRef = doc(db, "Theories", id)
      //First, delete the theory from the "authoredTheories" array in the "myTheories" map of the owner's profile
      //1) get the profile data from the owner
      const ownerProfileRef = doc(db, "Profiles", owner)
      const profileDocument = await getDoc(ownerProfileRef)
      const profileData = profileDocument.data()

      if (profileData) {
        //2) delete the theoryID from the authoredTheories array
        let theoryIndex = profileData.myTheories.authoredTheories.findIndex((theory) => {
          return theory.id == id
        })
        if (theoryIndex > -1) {
          profileData.myTheories.authoredTheories.splice(theoryIndex, 1)
        }
        let updatesIndex = profileData.updates.authoredTheories.findIndex((update) => {
          return update.id == id
        })
        if (updatesIndex > -1) {
          profileData.updates.authoredTheories.splice(updatesIndex, 1)
        }

        //3) re-save the profile.
        await setDoc(ownerProfileRef, profileData)
        dispatch(setProfile({
          ...profileData, 
          id: owner
        }))

        //Finally, delete the theory
        await deleteDoc(addTheoryRef)
      }
    } catch (err) {
      console.log('error in deleting the theory.')
    }
  }
}

//Method to delete a theory from Firebase will do the following:
//1) Delete the theory document from the "Theories" Collection.
//2) Remove the theoryID from "authoredTheories" array under the "myTheories" object of the fellow's profile.
//3) Remove the theoryID from "authoredTheories" array under the "updates" object of the fellow's profile.
//4) Removes the theoryID + occurrence from the "associatedTheories" array of any claim that was referenced by this theory.
//5) Removes the theoryID + occurrence from the "theoryAdditions" array under the "updates" object of any claim that was referenced by this theory.
//Validated Theory/:id
//Validated myTheories
export const startDeleteTheory = (id, owner) => {
  return async (dispatch) => {
    try {
      const theoryRef = doc(db, "Theories", id)
      const theoryDocument = await getDoc(theoryRef)
      const theoryData = theoryDocument.data()

      if (theoryData) {
        //get all of the docRefs for the claims that are referenced by this Theory.
        let allClaimDocRefs = [];
        const allAssociatedClaims = [];
        for (let i = 0; i < theoryData.claimsList.length; i++) {
          if (i % 2 == 1) {
            allAssociatedClaims.push(theoryData.claimsList[i].id)
          }
        }

        //Remove any potential duplicates from the array:
        let uniqueClaims = [...new Set(allAssociatedClaims)]
        uniqueClaims.forEach((claim) => {
          allClaimDocRefs.push(doc(db, "Claims", claim))
        })

        //For each associatedClaim, remove the associatedTheory object if it has the same ID.
        allClaimDocRefs.forEach(async (ref) => {
          const document = await getDoc(ref)
          const claimData = document.data()
          if (claimData) {
            let newAssociatedTheories = claimData.associatedTheories.filter(theory => theory.id != id)
            claimData.associatedTheories = newAssociatedTheories

            let newTheoryAdditions = claimData.updates.theoryAdditions.filter(theory => theory.id != id)
            claimData.updates.theoryAdditions = newTheoryAdditions

            await setDoc(ref, claimData)
          }
        })
      }
      //First, delete the theory from the "authoredTheories" array in the "myTheories" map of the owner's profile
      //1) get the profile data from the owner
      const ownerProfileRef = doc(db, "Profiles", owner)
      const profileDocument = await getDoc(ownerProfileRef)
      const profileData = profileDocument.data()

      if (profileData) {
        //2) delete the theoryID from the authoredTheories array
        let theoryIndex = profileData.myTheories.authoredTheories.findIndex((theory) => {
          return theory.id == id
        })
        if (theoryIndex > -1) {
          profileData.myTheories.authoredTheories.splice(theoryIndex, 1)
        }
        let updatesIndex = profileData.updates.authoredTheories.findIndex((update) => {
          return update.id == id
        })
        if (updatesIndex > -1) {
          profileData.updates.authoredTheories.splice(updatesIndex, 1)
        }

        //3) re-save the profile.
        await setDoc(ownerProfileRef, profileData)
        dispatch(setProfile({
          ...profileData, 
          id: owner
        }))

        //Finally, delete the theory
        await deleteDoc(theoryRef)


      }
    } catch (err) {
      console.log('error in deleting the theory.')
    }
  }
}

//Method to create a new Theory in Firebase will do the following:
//1) Create a new Theory document in the "Theories" Collection in Firebase.
//2) Add the theoryID + lastViewed to the "authoredTheories" array under the "myTheories" object of the fellow's profile.
//3) Add the theoryID + occurrence to the "authoredTheories" array under the "updates" object of the fellow's profile.
//Validated Home
//Validted myTheories
//Validated Theory/:id
export const createNewTheory = (owner, timeStamp, theOccurrence) => {
  return async (dispatch) => {
    try {
      const ownerProfileRef = doc(db, "Profiles", owner)
      const profileDocument = await getDoc(ownerProfileRef)
      const profileData = profileDocument.data()
      const theTheory = {
        claimsList: [''],
        comprehensiveness: {
          five: [],
          four: [],
          three: [],
          two: [],
          one: []
        },
        engagement: [],
        follows: [],
        owner: owner,
        ownerTags: [],
        published: false,
        timeStamp: timeStamp,
        updates: {
          authored: [
            {
              occurrence: theOccurrence,
              timeStamp: timeStamp,
              id: owner
            }
          ],
          comments: [],
          engagement: [],
          follows: []
        },
        title: ''
      }
      if (profileData) {
        let theDoc = await addDoc(theoryCollection, theTheory)
        profileData.myTheories.authoredTheories.push(
          {
            id: theDoc.id,
            lastViewed: timeStamp
          }
        )
        profileData.updates.authoredTheories.push(
          {
            occurrence: theOccurrence,
            timeStamp: timeStamp,
            id: theDoc.id
          }
        )
        await setDoc(ownerProfileRef, profileData)
        dispatch(setProfile({
          ...profileData, 
          id: owner
        }))
        return theDoc.id;
      }
    } catch (err) {
      console.log('did not create new theory')
    }
  }
}



const calculateTheoryClaimConfidence2 = (claimsList) => {
  return async () => {
      try {
        let actualClaims = parseOutText(claimsList)
        async function getClaimConfidences(theClaimsList) {
          let claimConfidences = []
          for (const claim of theClaimsList) {
            const docRef = doc(db, "Claims", claim.id);
            let claimDocument = await getDoc(docRef)
            let claimData = claimDocument.data()
            let doesNotSupportTally = 0;
            let somewhatSupportsTally = 0;
            let reallySupportsTally = 0;
            let doesNotContradictTally = 0;
            let somewhatContradictsTally = 0;
            let reallyContradictsTally = 0;

            for (const figure of claimData.supportFigs) {
              doesNotSupportTally += figure.doesNotSupport.length
              somewhatSupportsTally += figure.somewhatSupports.length
              reallySupportsTally += figure.reallySupports.length
            }
            for (const figure of claimData.contraFigs) {
              doesNotContradictTally += figure.doesNotSupport.length
              somewhatContradictsTally += figure.somewhatSupports.length
              reallyContradictsTally += figure.reallySupports.length
            }
            claimConfidences.push(getClaimConfidence(doesNotSupportTally, somewhatSupportsTally, 
              reallySupportsTally, doesNotContradictTally, somewhatContradictsTally, reallyContradictsTally))
          }
          // console.log(claimConfidences)
          return claimConfidences;
        }
        let claimConfidences = await getClaimConfidences(actualClaims)
        let averageImportances = getAverageImportances(actualClaims)
        let averagePercentageConfidenceClaims = calculateAveragePercentageConfidenceClaims(averageImportances, claimConfidences)
        // console.log('the average Percentage Confidence Claims are ' + averagePercentageConfidenceClaims)
        // console.log('the average importances are ' + averageImportances)
        return averagePercentageConfidenceClaims;
    } catch (err) {
      console.log('did not fetch claimList data')
    }
  }
}

export const calculateTheoryClaimConfidence = (claimsList) => {
  return async (dispatch) => {
      try {
        let actualClaims = parseOutText(claimsList)
        async function getClaimConfidences(theClaimsList) {
          let claimConfidences = []
          for (const claim of theClaimsList) {
            const docRef = doc(db, "Claims", claim.id);
            let claimDocument = await getDoc(docRef)
            let claimData = claimDocument.data()
            if (claimData) {
              let doesNotSupportTally = 0;
              let somewhatSupportsTally = 0;
              let reallySupportsTally = 0;
              let doesNotContradictTally = 0;
              let somewhatContradictsTally = 0;
              let reallyContradictsTally = 0;

              for (const figure of claimData.supportFigs) {
                doesNotSupportTally += figure.doesNotSupport.length
                somewhatSupportsTally += figure.somewhatSupports.length
                reallySupportsTally += figure.reallySupports.length
              }
              for (const figure of claimData.contraFigs) {
                doesNotContradictTally += figure.doesNotSupport.length
                somewhatContradictsTally += figure.somewhatSupports.length
                reallyContradictsTally += figure.reallySupports.length
              }
              claimConfidences.push(getClaimConfidence(doesNotSupportTally, somewhatSupportsTally, 
                reallySupportsTally, doesNotContradictTally, somewhatContradictsTally, reallyContradictsTally))
            }
          }
          // console.log(claimConfidences)
          return claimConfidences;
        }
        let claimConfidences = await getClaimConfidences(actualClaims)
        if (claimConfidences) {
          let averageImportances = getAverageImportances(actualClaims)
          let averagePercentageConfidenceClaims = calculateAveragePercentageConfidenceClaims(averageImportances, claimConfidences)
          // console.log('the average Percentage Confidence Claims are ' + averagePercentageConfidenceClaims)
          // console.log('the average importances are ' + averageImportances)
          return averagePercentageConfidenceClaims;
        } else {
          console.log('could not get claimConfidence')
        }
    } catch (err) {
      console.log('did not fetch claimList data')
    }
  }
}



export const returnTheoryInfo = (theory) => {
  return async () => {
    try {
      //fetch the correct theory from the "Theories" database in firestore
      const docRef = doc(db, "Theories", theory);
      const theoryDocument = await getDoc(docRef);
      const theoryData = theoryDocument.data()
      if (theoryData == undefined) {
        const date = new Date('Thu Jan 01 1970 00:00:00 GMT+0000')
        const theoryInfo = {
          confidence: '?',
          engagementNumber: '?',
          title: 'theory Unavailable',
          timeStamp: moment(date),
          published: false
        }
        return theoryInfo;
      }

      const engagementNumber = theoryData.engagement.length
      const title = theoryData.title
      const theTimeStamp = new Date(theoryData.timeStamp)
      const published = theoryData.published

      let actualClaims = parseOutText(theoryData.claimsList)
      async function getClaimConfidences(theClaimsList) {
        let claimConfidences = []
        for (const claim of theClaimsList) {
          const docRef = doc(db, "Claims", claim.id);
          let claimDocument = await getDoc(docRef)
          let claimData = claimDocument.data()
          if (claimData) {
            let doesNotSupportTally = 0;
            let somewhatSupportsTally = 0;
            let reallySupportsTally = 0;
            let doesNotContradictTally = 0;
            let somewhatContradictsTally = 0;
            let reallyContradictsTally = 0;

            for (const figure of claimData.supportFigs) {
              doesNotSupportTally += figure.doesNotSupport.length
              somewhatSupportsTally += figure.somewhatSupports.length
              reallySupportsTally += figure.reallySupports.length
            }
            for (const figure of claimData.contraFigs) {
              doesNotContradictTally += figure.doesNotSupport.length
              somewhatContradictsTally += figure.somewhatSupports.length
              reallyContradictsTally += figure.reallySupports.length
            }
            claimConfidences.push(getClaimConfidence(doesNotSupportTally, somewhatSupportsTally, 
              reallySupportsTally, doesNotContradictTally, somewhatContradictsTally, reallyContradictsTally))
          }
        }
        return claimConfidences;
      }

      let claimConfidences = await getClaimConfidences(actualClaims)
      let averageImportances = getAverageImportances(actualClaims)
      let averagePercentageConfidenceClaims = calculateAveragePercentageConfidenceClaims(averageImportances, claimConfidences)
      let averageComprehensiveness = calculateAverageComprehensiveness(theoryData.comprehensiveness)
      let newValue = Math.round(averagePercentageConfidenceClaims * averageComprehensiveness)

      const theoryInfo = {
        confidence: newValue,
        engagementNumber: engagementNumber,
        title: title,
        timeStamp: moment(theTimeStamp),
        published: published
      }

      return theoryInfo;
    } catch (err) {
      console.log('loading theory info did not work')
    }
  }
}

export const returnTheoryInfoHomeUpdate = (theory) => {
  return async () => {
    try {
      //fetch the correct theory from the "Theories" database in firestore
      const docRef = doc(db, "Theories", theory);
      const theoryDocument = await getDoc(docRef);
      const theoryData = theoryDocument.data()
      if (theoryData == undefined) {
        return;
      } else {
        return theoryData;
      }

    } catch (err) {
      console.log('loading theory info did not work')
    }
  }
}


export const returnTheoryInfoMyTheories = (theory) => {
  return async () => {
    try {
      //fetch the correct theory from the "Theories" database in firestore
      const docRef = doc(db, "Theories", theory);
      const theoryDocument = await getDoc(docRef);
      const theoryData = theoryDocument.data()
      if (theoryData == undefined) {
        const date = new Date('Thu Jan 01 1970 00:00:00 GMT+0000')
        const theoryInfo = {
          confidence: '?',
          engagementNumber: '?',
          title: 'theory Unavailable',
          timeStamp: moment(date),
          published: false
        }
        return theoryInfo;
      }

      const engagementNumber = getUniqueEngagement(theoryData.engagement).length
      const title = theoryData.title
      const theTimeStamp = new Date(theoryData.timeStamp)
      const published = theoryData.published

      let actualClaims = parseOutText(theoryData.claimsList)
      async function getClaimConfidences(theClaimsList) {
        let claimConfidences = []
        for (const claim of theClaimsList) {
          const docRef = doc(db, "Claims", claim.id);
          let claimDocument = await getDoc(docRef)
          let claimData = claimDocument.data()
          if (claimData) {
            let doesNotSupportTally = 0;
            let somewhatSupportsTally = 0;
            let reallySupportsTally = 0;
            let doesNotContradictTally = 0;
            let somewhatContradictsTally = 0;
            let reallyContradictsTally = 0;

            for (const figure of claimData.supportFigs) {
              doesNotSupportTally += figure.doesNotSupport.length
              somewhatSupportsTally += figure.somewhatSupports.length
              reallySupportsTally += figure.reallySupports.length
            }
            for (const figure of claimData.contraFigs) {
              doesNotContradictTally += figure.doesNotSupport.length
              somewhatContradictsTally += figure.somewhatSupports.length
              reallyContradictsTally += figure.reallySupports.length
            }
            claimConfidences.push(getClaimConfidence(doesNotSupportTally, somewhatSupportsTally, 
              reallySupportsTally, doesNotContradictTally, somewhatContradictsTally, reallyContradictsTally))
          }
        }
        return claimConfidences;
      }

      let claimConfidences = await getClaimConfidences(actualClaims)
      let averageImportances = getAverageImportances(actualClaims)
      let averagePercentageConfidenceClaims = calculateAveragePercentageConfidenceClaims(averageImportances, claimConfidences)
      let averageComprehensiveness = calculateAverageComprehensiveness(theoryData.comprehensiveness)
      let newValue = Math.round(averagePercentageConfidenceClaims * averageComprehensiveness)

      const theoryInfo = {
        confidence: newValue,
        engagementNumber: engagementNumber,
        title: title,
        timeStamp: moment(theTimeStamp),
        published: published
      }

      return theoryInfo;
    } catch (err) {
      console.log('loading theory info did not work')
    }
  }
}

const checkIfAnonymous = (owner, ownerTags) => {
  let anonymous = true;
  ownerTags.forEach((tag) => {
    if (tag.id == owner) {
      anonymous = false
    }
  })
  return anonymous;
}

export const returnTheoryInfoProfile = (theory) => {
  return async () => {
    try {
      //fetch the correct theory from the "Theories" database in firestore
      const docRef = doc(db, "Theories", theory);
      const theoryDocument = await getDoc(docRef);
      const theoryData = theoryDocument.data()

      if (theoryData == undefined) {
        const date = new Date('Thu Jan 01 1970 00:00:00 GMT+0000')
        const theoryInfo = {
          confidence: '?',
          engagementNumber: '?',
          title: 'theory Unavailable',
          timeStamp: moment(date),
          published: false,
          unavailable: true
        }
        return theoryInfo;
      }

      const engagementNumber = getUniqueEngagement(theoryData.engagement).length
      const title = theoryData.title
      const theTimeStamp = new Date(theoryData.timeStamp)
      const published = theoryData.published

      let actualClaims = parseOutText(theoryData.claimsList)
      async function getClaimConfidences(theClaimsList) {
        let claimConfidences = []
        for (const claim of theClaimsList) {
          const docRef = doc(db, "Claims", claim.id);
          let claimDocument = await getDoc(docRef)
          let claimData = claimDocument.data()
          if (claimData) {
            let doesNotSupportTally = 0;
            let somewhatSupportsTally = 0;
            let reallySupportsTally = 0;
            let doesNotContradictTally = 0;
            let somewhatContradictsTally = 0;
            let reallyContradictsTally = 0;

            for (const figure of claimData.supportFigs) {
              doesNotSupportTally += figure.doesNotSupport.length
              somewhatSupportsTally += figure.somewhatSupports.length
              reallySupportsTally += figure.reallySupports.length
            }
            for (const figure of claimData.contraFigs) {
              doesNotContradictTally += figure.doesNotSupport.length
              somewhatContradictsTally += figure.somewhatSupports.length
              reallyContradictsTally += figure.reallySupports.length
            }
            claimConfidences.push(getClaimConfidence(doesNotSupportTally, somewhatSupportsTally, 
              reallySupportsTally, doesNotContradictTally, somewhatContradictsTally, reallyContradictsTally))
          }
        }
        return claimConfidences;
      }

      let claimConfidences = await getClaimConfidences(actualClaims)
      let averageImportances = getAverageImportances(actualClaims)
      let averagePercentageConfidenceClaims = calculateAveragePercentageConfidenceClaims(averageImportances, claimConfidences)
      let averageComprehensiveness = calculateAverageComprehensiveness(theoryData.comprehensiveness)
      let newValue = Math.round(averagePercentageConfidenceClaims * averageComprehensiveness)

      if (isNaN(newValue)) {
        newValue = '?'
      }

      const theoryInfo = {
        confidence: newValue,
        engagementNumber: engagementNumber,
        title: title,
        timeStamp: moment(theTimeStamp),
        published: published,
        id: theory,
        moment: moment(theTimeStamp)
      }

      theoryInfo.timeStamp = theoryInfo.timeStamp.format('LLL')
      theoryInfo.anonymous = checkIfAnonymous(theoryData.owner, theoryData.ownerTags)

      return theoryInfo;
    } catch (err) {
      console.log('loading theory info did not work')
    }
  }
}

export const updateLastViewedTheory = (user, theoryID, timeStamp) => {
  return async (dispatch) => {
    try {
      //Fetch the profile document of the owner.
      const profRef = doc(db, "Profiles", user);
      const document = await getDoc(profRef)
      let profileData = document.data()

      if (profileData) {
        const isInAuthored = profileData.myTheories.authoredTheories.findIndex((theory) => {
          return theory.id == theoryID
        })
        const isInFollowed = profileData.myTheories.followedTheories.findIndex((theory) => {
          return theory.id == theoryID
        })
  

        //Search through all theorys within theoryTheories, authoredTheories, and followedTheories for the ID
        if (isInAuthored > -1) {
          profileData.myTheories.authoredTheories[isInAuthored].lastViewed = timeStamp
        }
        if (isInFollowed > -1) {
          profileData.myTheories.followedTheories[isInFollowed].lastViewed = timeStamp
        }
        await setDoc(profRef, profileData)
      }
      return;

    } catch (err) {
      console.log('Not able to update lastViewed in myTheories.')
    }
  }
}