import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from "react-router-dom"
import { getAuth, sendPasswordResetEmail } from "firebase/auth";


const PasswordReset = (props) => {

    const navigate = useNavigate()
    const auth = getAuth();
    
    const [loginEmail, setLoginEmail] = useState("")
    const [linkSent, setLinkSent] = useState(false)
    const [noAccount, setNoAccount] = useState(false)

    if (!window.setImmediate) {
        window.setImmediate = function(callback) {
          setTimeout(callback, 0);
    };}

    const cancel = async (e) => {
        e.preventDefault()
        navigate('/Login')
    }

    const sendLink = async (e) => {
        e.preventDefault()
        // setLinkSent(true)

        sendPasswordResetEmail(auth, loginEmail)
        .then(() => {
            setLinkSent(true)
            setNoAccount(false)
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            if (errorMessage == 'Firebase: Error (auth/invalid-email).') {
                setNoAccount(true)
            }
        });
    }

    return (
        <div>
            <div className="theHeader">
                <div className="head">
                    <div id="imgWrap">
                        <img id="LogoImg" src="/Images/Book2.jpg"/>
                    </div>
                    <div className='websiteTitleLogin'>
                        <div>
                            <div id="div3">JournalClub</div>
                            <div id="div4">Channel the power of collective minds</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='spacerLogin'></div>
            <div className="login3">
                <h2 className="Login">Reset your Password</h2>
                <div className="RegisterSubtitleDiv2">
                    <div className={linkSent ? 'hidden' : ''}>
                        <div className='RegisterResetSubtitle'>Enter your Email and a link will be sent to reset your Password</div>
                        <input className="email3" required placeholder='Enter your Email'
                        onChange={(event) => {setLoginEmail(event.target.value)}}/>
                    </div>
                    <div className={linkSent ? 'RegisterResetSubtitle3' : 'hidden'}>
                        A link to reset your Password should arrive in your email inbox.
                    </div>
                    
                </div>
                <div hidden={!noAccount} className='invalidLogin'>No account associated with this email</div>
                <div className='RegisterFlexEnd'>
                    <div className={linkSent ? 'hidden' : ''}>
                        <button onClick={cancel} className='RegisterCancelButton'>Cancel</button>
                        <button onClick={sendLink} className='RegisterSendLinkButton'>Send link</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({

})

const ConnectedPasswordReset = connect(undefined, mapDispatchToProps)(PasswordReset);

export default ConnectedPasswordReset;