import uuid from 'uuid';
import { db, figureCollection, app, storage } from '../firebase/firebase'
import { doc, setDoc, getDoc, addDoc, deleteDoc } from 'firebase/firestore'
import { setProfile } from './profModels';
import configureStore from '../store/configureStore'
import { getStorage, ref, uploadBytes, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import moment from 'moment'
import { useNavigate } from "react-router-dom"


function getUniqueEngagement (objects) {
  const seenIds = new Set();
  return objects.filter(obj => {
      if (seenIds.has(obj.id)) {
      return false;
      } else {
      seenIds.add(obj.id);
      return true;
      }
  });
}


// function that returns the dispatch call you need to make to the store

//This method is for adding a figure to Firebase and does the following:
//Creates a new figure document in the "Figures" collection with all the relevant information. - check
//Creates an "uploadedFigures" update on the fellow's profile. - check
//Adds the figure to the "uploadedFigures" array under "myFigures". - check
//If a paper was created right before figure upload, add the figure info under its figures array - check
//as well as create an update under the figureUploads array - check
//Validated AddFigure
export const startAddFigure = (FigureData = {}, theOwner) => {
  return async (dispatch) => {
    try {
      //Create the figure data to be added.
      const theFigure = {
        ...FigureData,
        authors: [],
        associatedClaims: [],
        criticisms: [],
        upVotes: [],
        downVotes: [],
        engagement: []
      }

      //Make the call to firebase to add the figure.
      const figDoc = await addDoc(figureCollection, theFigure)

      //Fetch the profile document of the owner and add the id to the uploadedFigures array.
      const docRef2 = doc(db, "Profiles", theOwner);
      const document = await getDoc(docRef2)
      let profileData = document.data()
      profileData.myFigures.uploadedFigures.push(
        {
          id: figDoc.id,
          lastViewed: theFigure.timeStamp
        }
      )
      const theOccurrence = theFigure.updates.uploaded[theFigure.updates.uploaded.length-1].occurrence 
      profileData.updates.uploadedFigures.push(
        {
          occurrence: theOccurrence,
          timeStamp: theFigure.timeStamp,
          id: figDoc.id
        }
      )
      //Re-save the profile document to firebase
      await setDoc(docRef2, profileData)
      dispatch(setProfile({
        ...profileData, 
        id: theOwner
      }))

      //Lastly, if the figure is extracted from a paper, fetch that paper and add the id + occurrence to the figures array.
      //Also add an update about the figureUpload to that paper's updates array.
      if (theFigure.fromPaper) {
        const docRef3 = doc(db, "Papers", theFigure.paperID);
        const document3 = await getDoc(docRef3)
        let paperData = document3.data()
        paperData.figures.push(
          {
            id: figDoc.id,
            occurrence: theOccurrence,
            timeStamp: theFigure.timeStamp
          }
        )
        paperData.updates.figureUploads.push(
          {
            timeStamp: theFigure.timeStamp,
            occurrence: theOccurrence,
            id: figDoc.id
          }
        )
        await setDoc(docRef3, paperData)
      }

      //Return the figure document
      return figDoc;

    } catch (err) {
      // console.log('unable to Add figure')
    }
  }
}


//Method for setting the figure data on redux when visiting a Figure Page
export const setFigure = (
  {
    title = '',
    paperDoi = '',
    authors = [],
    associatedClaims = [],
    image = '',
    owner = '',
    ownerTags = [],
    description = '',
    methods = '',
    criticisms = [],
    upVotes = [],
    downVotes = [],
    engagement = [],
    id,
    imgsrc,
    timeStamp = 0,
    fromPaper = false,
    figureNumber = '',
    paperID = '',
    updates = []
  } = {figure}
) => ({
  type: 'SET_FIGURE',
  figure: {
    title: title,
    paperDoi: paperDoi,
    authors: authors,
    associatedClaims: associatedClaims,
    image: image,
    owner: owner,
    ownerTags: ownerTags,
    description: description,
    methods: methods,
    criticisms: criticisms,
    upVotes: upVotes,
    downVotes: downVotes,
    engagement: engagement,
    id: id,
    imgsrc: imgsrc,
    timeStamp: timeStamp,
    fromPaper: fromPaper,
    figureNumber: figureNumber,
    paperID: paperID,
    updates: updates
  }
});


//Method to get the necessary data off of Firebase in order to set the figure information in Redux.
// export const startSetFigure = (id) => {
//   return (dispatch) => {
//     const docRef = doc(db, "Figures", id);
//     const storageRef = ref(storage, `/figures/${id}`)
//     let imgsrc;
//     return getDownloadURL(storageRef).then((value) => {
//       imgsrc = value
//       return getDoc(docRef).then((doc) => {
//         const FigureData = doc.data()
//         dispatch(setFigure({
//           ...FigureData,
//           id: doc.id,
//           imgsrc: imgsrc }))
//         }).catch(() => {
//           console.log('startSetFigure not working')
//       })
//     })
//   }
// }

//Method for fetching the imgsrc and description of a figure.
export const getImgsrc = (id) => {
  const docRef = doc(db, "Figures", id);
  return getDoc(docRef).then((doc) => {
    let description = doc.data().description
    const storageRef = ref(storage, `/figures/${id}`)
    return getDownloadURL(storageRef).then((value) => {
      return {
        imgsrc:value,
        description: description};
      })
  }).catch(() => {
    // console.log('failed to fetch the figure description.')
  })
}

export const addCriticism = (
  {
    criticisms
  } = {figure}
) => ({
  type: 'ADD_CRITICISM',
  figure: {
    criticisms: criticisms
  }
});

//Method for adding a criticism to a figure will do the following:
//Create the criticism and add it to the criticisms array of the figure and re-save. - check
//Create a new update on the "comments" array under "updates" object of the figure - check
//Create a new update on the "comments" array under "updates" object of the fellow - check
//Add the fellowID and occurrence to the engagements array of the figure - check
//Validated for FigureShow
//Validated for figureUpdatev2
export const startAddCriticism = (info) => {
  const theCriticism = {
    owner: info.owner,
    ownerTags: info.ownerTags,
    critDownVotes: info.critDownVotes,
    critUpVotes: info.critUpVotes,
    text: info.text,
    timeStamp: info.timeStamp,
    occurrence: info.occurrence
  }
  // console.log(theCriticism)
  return async () => {
    const docRef = doc(db, "Figures", info.figureID);
    let figureDocument = await getDoc(docRef)
    const FigureData = figureDocument.data()
    FigureData.criticisms.push(theCriticism)
    //check to see if the user has engaged with this figure before. If they have not, add their id to the engagement array. - no longer doing this
    // if (!FigureData.engagement.includes(info.owner)) {
    //   FigureData.engagement.push(info.owner)
    // }
    FigureData.updates.comments.push(
      {
        timeStamp: info.timeStamp,
        occurrence: info.occurrence,
        id: info.owner
      }
    )
    FigureData.engagement.push(
      {
        id: info.owner,
        occurrence: info.occurrence,
        timeStamp: info.timeStamp
      }
    )
    // console.log(FigureData.criticisms)
    const profRef = doc(db, "Profiles", info.owner);
    let profileDocument = await getDoc(profRef)
    const profileData = profileDocument.data()

    if (profileData) {
      profileData.updates.comments.push(
        {
          timeStamp: info.timeStamp,
          occurrence: info.occurrence,
          id: info.figureID,
          anonymous: info.anonymous
        }
      )
    }
    await setDoc(profRef, profileData)
    await setDoc(docRef, FigureData)
  }
}

//Method to remove a criticism/comment from a figure will do the following:
//Remove the criticism from the criticisms array of the figure. - check
//Remove the update associated with that criticism from the "comments" array under the "updates" object of that figure. - check
//Remove the update associated with the criticism from the "comments" array under the "updates" object of the fellow who made it. - check
//Remove the fellowID + occurrence from the engagements array of the figure. - check
//Remove all occurrences of the criticism's upvotes and downvotes from the engagements array. 
//Validated figureUpdatev2
//Validated FigureShow
export const startDeleteCriticism = (occurrence, FigureID, imgsrc, user) => {
  return async (dispatch) => {
    const docRef = doc(db, "Figures", FigureID);
    const figureDocument = await getDoc(docRef)
    const figureData = figureDocument.data()
    if (figureData) {
      //Need to use specific occurrence of a comment because users can have multiple comments.
      //Gather all occurrences of critUpVotes and critDownVotes in one array:
      let allCritVoteOccurrences = [];
      let critIndex = figureData.criticisms.findIndex(criticism => criticism.occurrence == occurrence)
      figureData.criticisms[critIndex].critUpVotes.forEach(vote => allCritVoteOccurrences.push(vote.occurrence))
      figureData.criticisms[critIndex].critDownVotes.forEach(vote => allCritVoteOccurrences.push(vote.occurrence))
      
      //Remove the criticism from the criticisms array:
      const newFigureCriticisms = figureData.criticisms.filter(crit => crit.occurrence != occurrence)
      figureData.criticisms = newFigureCriticisms

      //Remove the update from the comments array of the updates object.
      const newFigureCommentUpdates = figureData.updates.comments.filter(update => update.occurrence != occurrence)
      figureData.updates.comments = newFigureCommentUpdates

      //Remove the engagement from the engagements array by filtering with the occurrence
      const newEngagements = figureData.engagement.filter(engagement => engagement.occurrence != occurrence)
      figureData.engagement = newEngagements

      //Lastly, remove every instance of occurrence in the engagements array that happened to be attached to that specific criticism.
      let newArray = figureData.engagement.filter(obj1 => !allCritVoteOccurrences.some(obj2 => obj1.occurrence === obj2))
      figureData.engagement = newArray

      //Re-save the data in Firebase.
      await setDoc(docRef, figureData)

      //Fetch the fellows profile data from Firebase.
      const profRef = doc(db, "Profiles", user);
      const profileDocument = await getDoc(profRef)
      const profileData = profileDocument.data()
      
      //Now, delete the update from the comments array of the updates object on that particular fellows profile.
      if (profileData) {

        //Remove the update by using the specific occurrence of that comment.
        let newCommentUpdates = profileData.updates.comments.filter(update => update.occurrence != occurrence)
        profileData.updates.comments = newCommentUpdates

        //Re-save the data in Firebase.
        await setDoc(profRef, profileData)
      }
    }
  }
}

export const deleteFigure = () => ({
  type: 'DELETE_FIGURE',
});

//Method to delete a figure from Firebase will do the following:
//Remove the figure from the "Figures" collection - check
//Remove its supportFig or contraFig object from any claim it has been connected to - check
//If is has been connected to a claim, remove the figure from the "figureConnections" array under the "updates" object of that claim. - check
//Remove the update from the "uploadedFigures" array of the updates object belonging to the fellow who uploaded it. - check
//Remove the figure from the "uploadedFigures" array of the myFigures object belonging to the fellow who uploaded it. - check
//If figure is from a paper, remove it from the "figures" array of that paper document. - check
//If the figure is from a paper, remove it from the "figureUploads" array of the "updates" object of that paper document. - check
//Validated figureUpdatev2
//Validated FigureShow
//Validated myFigures
export const startDeleteFigure = (id, theOwner) => {
  return async (dispatch) => {
    try {
      //Fetch the figure document from Firebase
      const docRef = doc(db, "Figures", id)
      const figureDocument = await getDoc(docRef)
      const figureData = figureDocument.data()
      //check first that the figure exists and could be fetched from Firebase.
      if (figureData) {
        //get all of the docRefs for the claims that this figure has been connected to from its "associated Claims array"
        let allDocRefs = []
        const associatedClaims = figureData.associatedClaims
        associatedClaims.forEach((claim) => {
          allDocRefs.push(doc(db, "Claims", claim.id))
        })
        //get the data for each claim that this figure is connected to.
        //Delete the contraFig/supportFig object from each claim that represents this figure and re-save.
        //Also delete the figureConnection update for each figure on that claim.
        allDocRefs.forEach( async (ref) => {
          const document = await getDoc(ref)
          const claimData = document.data()
          
          if (claimData) {
            let removeFromContraFigsIndex = claimData.contraFigs.findIndex((contraFig) => {
              return contraFig.id == id
            })

            let contraFigOccurrence = 0;
            if (removeFromContraFigsIndex > -1) {
              contraFigOccurrence = claimData.contraFigs[removeFromContraFigsIndex].occurrence
              claimData.contraFigs.splice(removeFromContraFigsIndex, 1)
            }
            
            let removeFromSupportFigsIndex = claimData.supportFigs.findIndex((supportFig) => {
              return supportFig.id == id
            })

            let supportFigOccurrence = 0;
            if (removeFromSupportFigsIndex > -1) {
              supportFigOccurrence = claimData.supportFigs[removeFromSupportFigsIndex].occurrence
              claimData.supportFigs.splice(removeFromSupportFigsIndex, 1)
            }

            let newUpdates1 = claimData.updates.figureConnections.filter((update) => {
              return (update.id != id && update.occurrence != contraFigOccurrence)
            })
            claimData.updates.figureConnections = newUpdates1
            let newUpdates2 = claimData.updates.figureConnections.filter((update) => {
              return (update.id != id && update.occurrence != supportFigOccurrence)
            })
            claimData.updates.figureConnections = newUpdates2

            await setDoc(ref, claimData)
          }
        })

        //Next, if the figure is from a paper, fetch that paper and remove the id from the figures array.
        //Also remove the update that this figure was added if its still there.
        if (figureData.fromPaper) {
          const docRef3 = doc(db, "Papers", figureData.paperID);
          const document3 = await getDoc(docRef3)
          let paperData = document3.data()

          //Next, remove the figureID from the figures array
          let figureIndex = paperData.figures.findIndex((figure) => {
            return figure.id == id
          })  

          //Grab the occurence for when this figure was connected to the paper.
          let occurrence = paperData.figures[figureIndex].occurrence

          //Delete the figureIndex on the figures array.
          paperData.figures.splice(figureIndex, 1)

          //If the occurrence can be found in the updates array on the paper, delete that as well.
          let updateIndex = paperData.updates.figureUploads.findIndex((update) => {
            return (update.occurrence == occurrence && update.id == id);
          })
          if (updateIndex > -1) {
              paperData.updates.figureUploads.splice(updateIndex, 1)
          }
          await setDoc(docRef3, paperData)
        }

        //Lastly, delete the Figure from the "Figures" collection in Firebase. 
        await deleteDoc(docRef)
      }
    
      //Fetch the profile document of the owner and remove the id from the uploadedFigures array.
      //Also remove the update from the updates.uploadedFigures array if the same occurrence is there.
      const docRef2 = doc(db, "Profiles", theOwner);
      const document = await getDoc(docRef2)
      let profileData = document.data()

      if (profileData) {
        let newUploadedFigures = profileData.myFigures.uploadedFigures.filter((figure) => {
          return figure.id != id
        })
        profileData.myFigures.uploadedFigures = newUploadedFigures
        let newUploadedFiguresUpdates = profileData.updates.uploadedFigures.filter((update) => {
          return update.id != id
        })
        profileData.updates.uploadedFigures = newUploadedFiguresUpdates
        //Re-save the profile document to firebase
        await setDoc(docRef2, profileData)
        dispatch(setProfile({
          ...profileData, 
          id: theOwner
        }))
      }
    } catch (err) {
      // console.log('error in deleting the figure.')
    }
  }
}

//Method to reject a figure in Firebase will do the following:
//1) Add the fellow's ID to the "downVotes" array of that figure. - check
//2) Add the fellow's ID to the "engagement" array of that figure. - check
//3) Create an update under the "rejects" array of the "updates" object of the figure. - check
//4) Add the figure's ID to the "rejectedFigures" array of the "myFigures" object of the figure. - check
//Validated figureUpdatev2
//Validated FigureShow
export const startRejectFigure = (downVote) => {
  return async (dispatch) => {
    try {
      //get the figure document from Firebase
      const docRefFigure = doc(db, "Figures", downVote.figureID);
      const figureDocument = await getDoc(docRefFigure)
      const figureData = figureDocument.data()
      //Check if the figure is still available, run the code. If it isn't, do nothing.
      if (figureData) {
        //add the user's ID to the downVotes array if its not already there
        //Also add to the updates rejects array
        const findVote = (vote) => {
          return vote.id === downVote.user
        }
        const index = figureData.downVotes.findIndex(findVote)
        if (index == -1) {
          figureData.downVotes.push(
            {
              timeStamp: downVote.timeStamp,
              occurrence: downVote.occurrence,
              id: downVote.user
            }
          )
          figureData.updates.rejects.push(
            {
              timeStamp: downVote.timeStamp,
              occurrence: downVote.occurrence,
              id: downVote.user
            }
          )
          //Add the user to the engagement array.
          figureData.engagement.push(
            {
              id: downVote.user,
              occurrence: downVote.occurrence,
              timeStamp: downVote.timeStamp
            }
          )
          //re-save the updated figure into Firebase
          await setDoc(docRefFigure, figureData)
        }

        //update the figure in redux.
        // dispatch(setFigure({
        //   ...figureData,
        //   id: downVote.figureID,
        //   imgsrc: downVote.imgsrc })
        // )

        //get the profile document from firebase
        const docRefProfile = doc(db, "Profiles", downVote.user);
        const profileDocument = await getDoc(docRefProfile)
        const profileData = profileDocument.data()
        //Check to see if the profile is still available, then run code if it is:
        if (profileData) {
          const findMyFigures = (figure) => {
            return figure.id === downVote.figureID
          }

          //add the figure's ID to the rejectedFigures array if its not already there.
          const index2 = profileData.myFigures.rejectedFigures.findIndex(findMyFigures)
          if (index2 == -1) {
            profileData.myFigures.rejectedFigures.push(
              {
                id: downVote.figureID,
                lastViewed: downVote.lastViewed
              }
            )
            //re-save the updated profile into Firebase
            await setDoc(docRefProfile, profileData)
          }

          //update the profile in redux
          dispatch(setProfile({
            ...profileData, 
            id: downVote.user
          }))
        }
      }
    } catch (err) {
      // console.log('did not reject figure')
    }
  }
}

//Method to reverse the rejection of a figure will do the following:
//1) Remove the downVote from the "downVotes" array of the figure. - check
//2) Remove the update from the "rejects" array under the "updates" object of the figure. - check
//3) Remove the figure from the "rejectedFigures" array under the "myFigures" object of the fellow. - check
//4) Remove the specific occurrence of the fellow's interaction under the "engagement" array of the figure. - check
//5) Return the occurrence of the fellow's original vote.
//Validated FigureShow
//Validated figureUpdatev2
export const reverseRejectFigure = (downVote) => {
  return async (dispatch) => {
    try {
      //get the figure document from Firebase
      const docRefFigure = doc(db, "Figures", downVote.figureID);
      const figureDocument = await getDoc(docRefFigure)
      const figureData = figureDocument.data()
      let theOccurrence;

      //Assuming the figure was able to be fetched from Firebase, run the code. If not, do nothing.
      if (figureData) {
        //Get the occurrence of the downVote
        let downVoteIndex = figureData.downVotes.findIndex((theDownVote) => {
          return theDownVote.id == downVote.user
        })
        theOccurrence = figureData.downVotes[downVoteIndex].occurrence
        
        //remove the user's ID to the downVotes array
        const remove = figureData.downVotes.filter((theDownVote) => {
          return theDownVote.id != downVote.user
        })
        figureData.downVotes = remove

        const newRejectUpdates = figureData.updates.rejects.filter((update) => {
          return update.id != downVote.user
        })
        figureData.updates.rejects = newRejectUpdates

        //Remove the specific engagement from the engagements array by using the occurrence.
        const newEngagements = figureData.engagement.filter((engagement) => {
          return engagement.occurrence != theOccurrence
        })
        figureData.engagement = newEngagements
        //re-save the updated figure into Firebase
        await setDoc(docRefFigure, figureData)

        //update the figure in redux.
        // dispatch(setFigure({
        //   ...figureData,
        //   id: downVote.figureID,
        //   imgsrc: downVote.imgsrc })
        // )
      }
      
      //get the profile document from firebase
      const docRefProfile = doc(db, "Profiles", downVote.user);
      const profileDocument = await getDoc(docRefProfile)
      const profileData = profileDocument.data()

      //Assuming the user's profile was able to be fetched from Firebase:
      if (profileData) {

        //add the figure's ID to the rejectedFigures array.
        const removedRejected = profileData.myFigures.rejectedFigures.filter((Vote) => {
          return Vote.id != downVote.figureID
        })
        profileData.myFigures.rejectedFigures = removedRejected

        //re-save the updated profile into Firebase
        await setDoc(docRefProfile, profileData)

        //update the profile in redux
        dispatch(setProfile({
          ...profileData, 
          id: downVote.user
        }))

        

        if (theOccurrence) {
        return theOccurrence;
      } else {
        return;
      }
      }
      
    } catch (err) {
      // console.log('did not reverse-reject figure')
    }
  }
}

//Method to accept a figure in Firebase will do the following:
//1) Add the fellow's ID to the "upVotes" array of that figure. - check
//2) Add the fellow's ID to the "engagement" array of that figure. - check
//3) Create an update under the "accepts" array of the "updates" object of the figure. - check
//4) Add the figure's ID to the "acceptedFigures" array of the "myFigures" object of the figure. - check
//Validated FigureShow
//Validated figureUpdatev2
export const startAcceptFigure = (upVote) => {
  return async (dispatch) => {
    try {
      //get the figure document from Firebase
      const docRefFigure = doc(db, "Figures", upVote.figureID);
      const figureDocument = await getDoc(docRefFigure)
      const figureData = figureDocument.data()

      if (figureData) {
        //add the user's ID to the upVotes array if its not already there and accepts array under updates.
        const findVote = (vote) => {
          return vote.id === upVote.user
        }
        const index = figureData.upVotes.findIndex(findVote)
        if (index == -1) {
          figureData.upVotes.push(
            {
              timeStamp: upVote.timeStamp,
              occurrence: upVote.occurrence,
              id: upVote.user
            }
          )
          figureData.updates.accepts.push(
            {
              timeStamp: upVote.timeStamp,
              occurrence: upVote.occurrence,
              id: upVote.user
            }
          )
          //Add the user to the engagement array.
          figureData.engagement.push(
            {
              id: upVote.user,
              occurrence: upVote.occurrence,
              timeStamp: upVote.timeStamp
            }
          )
          //re-save the updated figure into Firebase
          await setDoc(docRefFigure, figureData)
        }

        //get the profile document from firebase
        const docRefProfile = doc(db, "Profiles", upVote.user);
        const profileDocument = await getDoc(docRefProfile)
        const profileData = profileDocument.data()

        //Check to see if the profile is still available, then run code if it is:
        if (profileData) {
          const findMyFigures = (figure) => {
            return figure.id === upVote.figureID
          }
          //add the figure's ID to the acceptedFigures array if its not already there.
          const index2 = profileData.myFigures.acceptedFigures.findIndex(findMyFigures)

          if (index2 == -1) {
            profileData.myFigures.acceptedFigures.push(
              {
                id: upVote.figureID,
                lastViewed: upVote.lastViewed
              }
            )
          }
          //re-save the updated profile into Firebase
          await setDoc(docRefProfile, profileData)

          //update the profile in redux
          dispatch(setProfile({
            ...profileData, 
            id: upVote.user
          }))
        }
      }
    } catch (err) {
      console.log('did not accept figure')
    }
  }
}


//Method to reverse the accept of a figure will do the following:
//1) Remove the upVote from the "upVotes" array of the figure. - check
//2) Remove the update from the "accepts" array under the "updates" object of the figure. - check
//3) Remove the figure from the "acceptedFigures" array under the "myFigures" object of the fellow. - check
//4) Remove the specific occurrence of the fellow's interaction under the "engagement" array of the figure. - check 
//5) Returns the occurrence of the original fellow's vote if available (not available if figure was deleted already)
//Validated figureUpdatev2
//Validated FigureShow
export const reverseAcceptFigure = (upVote) => {
  return async (dispatch) => {
    try {
      //get the figure document from Firebase
      const docRefFigure = doc(db, "Figures", upVote.figureID);
      const figureDocument = await getDoc(docRefFigure)
      const figureData = figureDocument.data()
      let theOccurrence;

      //Assuming the figure was able to be fetched from Firebase:
      if (figureData) {
        //Get the occurrence of the upVote
        let upVoteIndex = figureData.upVotes.findIndex((theUpVote) => {
          return theUpVote.id == upVote.user
        })
        theOccurrence = figureData.upVotes[upVoteIndex].occurrence
        
        //remove the user's ID to the upVotes array
        const remove = figureData.upVotes.filter((theUpVote) => {
          return theUpVote.id != upVote.user
        })
        figureData.upVotes = remove

        const newAcceptUpdates = figureData.updates.accepts.filter((update) => {
          return update.id != upVote.user
        })
        figureData.updates.accepts = newAcceptUpdates

        //Remove the specific engagement from the engagements array by using the occurrence.
        const newEngagements = figureData.engagement.filter((engagement) => {
          return engagement.occurrence != theOccurrence
        })
        figureData.engagement = newEngagements

        //re-save the updated figure into Firebase
        await setDoc(docRefFigure, figureData)

        //update the figure in redux.
        // dispatch(setFigure({
        //   ...figureData,
        //   id: upVote.figureID,
        //   imgsrc: upVote.imgsrc })
        // )

        
      }

      //get the profile document from firebase
      const docRefProfile = doc(db, "Profiles", upVote.user);
      const profileDocument = await getDoc(docRefProfile)
      const profileData = profileDocument.data()

      //Assuming the user's profile was able to be fetched from Firebase:
      if (profileData) {
          
        //add the figure's ID to the acceptedFigures array.
        const removeAccepted = profileData.myFigures.acceptedFigures.filter((Vote) => {
          return Vote.id != upVote.figureID
        })
        profileData.myFigures.acceptedFigures = removeAccepted

        //re-save the updated profile into Firebase
        await setDoc(docRefProfile, profileData)
        //update the profile in redux
        dispatch(setProfile({
          ...profileData, 
          id: upVote.user
        }))

        if (theOccurrence) {
          return theOccurrence;
        } else {
          return;
        }
      }
    } catch (err) {
      console.log('did not reverse-accept figure')
    }
  }
}

//Method for accepting a criticism on a figure will do the following:
//1) Add the fellow + voteOccurrence to the figure's engagement array. - check
//2) Add the fellow + voteOccurrence to the critUpVotes array of that specific criticism of the figure - check
//Validated FigureShow
//Validated figureUpdatev2
export const startAcceptCriticism = (critOccurrence, FigureID, timeStamp, fellowID, voteOccurrence) => {
  // console.log(fellowID)
  return async (dispatch) => {
    const docRef = doc(db, "Figures", FigureID);
    let figureDocument = await getDoc(docRef)
    let figureData = figureDocument.data()

    //Check to see if the figure still exists and run the code, otherwise do nothing:
    if (figureData) {
      //Get the correct index of that particular criticism in the "criticisms" array that has the right occurrence
      const index = figureData.criticisms.findIndex((criticism) => {
        return criticism.occurrence == critOccurrence
      })
      //If the criticism was found, run the following:
      if (index != -1) {
        //Check to make sure the fellowID is not already in the critUpVotes array.
        let critUpVoteIndex = figureData.criticisms[index].critUpVotes.findIndex((critUpVote) => {
          return critUpVote.id === fellowID
        });
        if (critUpVoteIndex == -1) {
          figureData.criticisms[index].critUpVotes.push(
            {
              id: fellowID,
              occurrence: voteOccurrence,
              timeStamp: timeStamp
            }
          )
          //Add the fellowID to the engagement array.
          figureData.engagement.push(
            {
              id: fellowID,
              occurrence: voteOccurrence,
              timeStamp: timeStamp
            }
          )
          await setDoc(docRef, figureData)
        }
      }
    }
  }
}

//Method for reversing accepting a criticism on a figure will do the following:
//1) Remove the fellow + voteOccurrence to the figure's engagement array. - check
//2) Remove the fellow + voteOccurrence to the critUpVotes array of that specific criticism of the figure - check
//Validated FigureShow
//Validated figureUpdatev2
export const reverseAcceptCriticism = (critOccurrence, FigureID, timeStamp, fellowID) => {
  return async (dispatch) => {
    const docRef = doc(db, "Figures", FigureID);
    let figureDocument = await getDoc(docRef)
    let figureData = figureDocument.data()

    //Check to make sure the figure still exists. If it does, run the following code:
    if (figureData) {
      //Get the fellow's voteOccurrence of this specific criticism 
      const index = figureData.criticisms.findIndex((criticism) => {
        return criticism.occurrence == critOccurrence
      })
      if (index != -1) {
        const voteOccurrenceIndex = figureData.criticisms[index].critUpVotes.findIndex((critUpVote) => {
          return critUpVote.id === fellowID
        })
        if (voteOccurrenceIndex != -1) {
          //Remove the specific critUpvote.
          let voteOccurrence = figureData.criticisms[index].critUpVotes[voteOccurrenceIndex].occurrence
          figureData.criticisms[index].critUpVotes.splice(voteOccurrenceIndex, 1)

          //Remove the specific engagement from the engagements array by using the occurrence.
          const newEngagements = figureData.engagement.filter((engagement) => {
            return engagement.occurrence != voteOccurrence
          })
          figureData.engagement = newEngagements
          await setDoc(docRef, figureData)
        }
      }
    }
  }
}


//Method for rejecting a criticism on a figure will do the following:
//1) Add the fellow + voteOccurrence to the figure's engagement array. - check
//2) Add the fellow + voteOccurrence to the critDownVotes array of that specific criticism of the figure - check
//Validated FigureShow
//Validated figureUpdatev2
export const startRejectCriticism = (critOccurrence, FigureID, timeStamp, fellowID, voteOccurrence) => {
  return async (dispatch) => {
    const docRef = doc(db, "Figures", FigureID);
    let figureDocument = await getDoc(docRef)
    let figureData = figureDocument.data()
    //Check to see if the figure still exists and run the code, otherwise do nothing:
    if (figureData) {
      //Get the correct index of that particular criticism in the "criticisms" array that has the right occurrence
      const index = figureData.criticisms.findIndex((criticism) => {
        return criticism.occurrence == critOccurrence
      })
      //If the criticism was found, run the following:
      if (index != -1) {
        //Check to make sure the fellowID is not already in the critDownVotes array.
        let critDownVoteIndex = figureData.criticisms[index].critDownVotes.findIndex((critDownVote) => {
          return critDownVote.id === fellowID
        });
        if (critDownVoteIndex == -1) {
          figureData.criticisms[index].critDownVotes.push(
            {
              id: fellowID,
              occurrence: voteOccurrence,
              timeStamp: timeStamp
            }
          )
          //Add the fellowID to the engagement array.
          figureData.engagement.push(
            {
              id: fellowID,
              occurrence: voteOccurrence,
              timeStamp: timeStamp
            }
          )
          await setDoc(docRef, figureData)
        }
      }
    }
  }
}
//Method for reversing rejecting a criticism on a figure will do the following:
//1) Remove the fellow + voteOccurrence to the figure's engagement array. - check
//2) Remove the fellow + voteOccurrence to the critDownVotes array of that specific criticism of the figure - check
//Validated FigureShow
//Validated figureUpdatev2
export const reverseRejectCriticism = (critOccurrence, FigureID, timeStamp, fellowID) => {
  return async (dispatch) => {
    const docRef = doc(db, "Figures", FigureID);
    let figureDocument = await getDoc(docRef)
    let figureData = figureDocument.data()

    //Check to make sure the figure still exists. If it does, run the following code:
    if (figureData) {
      //Get the fellow's voteOccurrence of this specific criticism 
      const index = figureData.criticisms.findIndex((criticism) => {
        return criticism.occurrence == critOccurrence
      })
      if (index != -1) {
        const voteOccurrenceIndex = figureData.criticisms[index].critDownVotes.findIndex((critDownVote) => {
          return critDownVote.id === fellowID
        })
        if (voteOccurrenceIndex != -1) {
          //Remove the specific critDownVote.
          let voteOccurrence = figureData.criticisms[index].critDownVotes[voteOccurrenceIndex].occurrence
          figureData.criticisms[index].critDownVotes.splice(voteOccurrenceIndex, 1)

          //Remove the specific engagement from the engagements array by using the occurrence.
          const newEngagements = figureData.engagement.filter((engagement) => {
            return engagement.occurrence != voteOccurrence
          })
          figureData.engagement = newEngagements
          await setDoc(docRef, figureData)
        }
      }
    }
  }
}


export const returnFigureInfo = (figure) => {
  return async (dispatch) => {
    try {
      //fetch the correct figure from the figures database in firestore
      const docRef = doc(db, "Figures", figure);
      const figureDocument = await getDoc(docRef);
      const figureData = figureDocument.data()

      if (figureData == undefined) {
        const date = new Date('Thu Jan 01 1970 00:00:00 GMT+0000')
        const figureInfo = {
          acceptancePercentage: '?',
          engagementNumber: '?',
          title: 'figure Unavailable',
          timeStamp: moment(date)
        }
        return figureInfo;
      }

      //calculate the figure acceptancePercentage of that figure
      let acceptancePercentage = (figureData.upVotes.length/(figureData.upVotes.length + figureData.downVotes.length)) * 100
      acceptancePercentage = Math.trunc(acceptancePercentage)
      if (Math.trunc(acceptancePercentage) > 100) {
        acceptancePercentage = 100;
      }
    
      if (Math.trunc(acceptancePercentage) < 0) {
        acceptancePercentage = 0
      }  
      
      const engagementNumber = figureData.engagement.length
      const title = figureData.title
      const theTimeStamp = new Date(figureData.timeStamp)
      const theFigureNumber = figureData.figureNumber
      const figureInfo = {
        acceptancePercentage: acceptancePercentage,
        engagementNumber: engagementNumber,
        title: title,
        timeStamp: moment(theTimeStamp),
        figureNumber: theFigureNumber
      }
      // console.log(figureInfo.acceptancePercentage)
      if (isNaN(figureInfo.acceptancePercentage)) {
        figureInfo.acceptancePercentage = "?"
      }
      // console.log(figureInfo)
      return figureInfo;
    } catch (err) {
      console.log('Fetching figure info didnt work.')
    }
  }
}

export const returnFigureInfoMyFigures = (figure) => {
  return async (dispatch) => {
    try {
      //fetch the correct figure from the figures database in firestore
      const docRef = doc(db, "Figures", figure);
      const figureDocument = await getDoc(docRef);
      const figureData = figureDocument.data()

      if (figureData == undefined) {
        const date = new Date('Thu Jan 01 1970 00:00:00 GMT+0000')
        const figureInfo = {
          acceptancePercentage: '?',
          engagementNumber: '?',
          title: 'figure Unavailable',
          timeStamp: moment(date)
        }
        return figureInfo;
      }

      //calculate the figure acceptancePercentage of that figure
      let acceptancePercentage = (figureData.upVotes.length/(figureData.upVotes.length + figureData.downVotes.length)) * 100
      acceptancePercentage = Math.trunc(acceptancePercentage)
      if (Math.trunc(acceptancePercentage) > 100) {
        acceptancePercentage = 100;
      }
    
      if (Math.trunc(acceptancePercentage) < 0) {
        acceptancePercentage = 0
      }  
      
      const engagementNumber = getUniqueEngagement(figureData.engagement).length
      const title = figureData.title
      const theTimeStamp = new Date(figureData.timeStamp)
      const theFigureNumber = figureData.figureNumber
      const figureInfo = {
        acceptancePercentage: acceptancePercentage,
        engagementNumber: engagementNumber,
        title: title,
        timeStamp: moment(theTimeStamp),
        figureNumber: theFigureNumber
      }
      // console.log(figureInfo.acceptancePercentage)
      if (isNaN(figureInfo.acceptancePercentage)) {
        figureInfo.acceptancePercentage = "?"
      }
      // console.log(figureInfo)
      return figureInfo;
    } catch (err) {
      console.log('Fetching figure info didnt work.')
    }
  }
}

const checkIfAnonymous = (owner, ownerTags) => {
  let anonymous = true;
  ownerTags.forEach((tag) => {
    if (tag.id == owner) {
      anonymous = false
    }
  })
  return anonymous;
}

export const returnFigureInfoProfile = (figure) => {
  return async (dispatch) => {
    try {
      //fetch the correct figure from the figures database in firestore
      const docRef = doc(db, "Figures", figure);
      const figureDocument = await getDoc(docRef);
      const figureData = figureDocument.data()

      if (figureData == undefined) {
        const date = new Date('Thu Jan 01 1970 00:00:00 GMT+0000')
        const figureInfo = {
          acceptancePercentage: '?',
          engagementNumber: '?',
          title: 'figure Unavailable',
          timeStamp: moment(date),
          unavailable: true
        }
        return figureInfo;
      }

      //calculate the figure acceptancePercentage of that figure
      let acceptancePercentage = (figureData.upVotes.length/(figureData.upVotes.length + figureData.downVotes.length)) * 100
      acceptancePercentage = Math.trunc(acceptancePercentage)
      if (Math.trunc(acceptancePercentage) > 100) {
        acceptancePercentage = 100;
      }
    
      if (Math.trunc(acceptancePercentage) < 0) {
        acceptancePercentage = 0
      }  
      
      const engagementNumber = getUniqueEngagement(figureData.engagement).length
      const title = figureData.title
      const theTimeStamp = new Date(figureData.timeStamp)
      const theFigureNumber = figureData.figureNumber
      const figureInfo = {
        acceptancePercentage: acceptancePercentage,
        engagementNumber: engagementNumber,
        title: title,
        timeStamp: moment(theTimeStamp),
        figureNumber: theFigureNumber,
        moment: moment(theTimeStamp),
        id: figure
      }
      if (isNaN(figureInfo.acceptancePercentage)) {
        figureInfo.acceptancePercentage = "?"
      }
      figureInfo.timeStamp = figureInfo.timeStamp.format('LLL')
      figureInfo.anonymous = checkIfAnonymous(figureData.owner, figureData.ownerTags)
      return figureInfo;
    } catch (err) {
      console.log('Fetching figure info didnt work.')
    }
  }
}

export const returnFigureInfoUpdates = (figure) => {
  return async (dispatch) => {
    try {
      //fetch the correct figure from the figures database in firestore
      const docRef = doc(db, "Figures", figure);
      const figureDocument = await getDoc(docRef);
      const figureData = figureDocument.data()

      if (figureData == undefined) {
        const date = new Date('Thu Jan 01 1970 00:00:00 GMT+0000')
        const figureInfo = {
          acceptancePercentage: '?',
          engagementNumber: '?',
          title: 'figure Unavailable',
          timeStamp: moment(date)
        }
        return false;
      }

      //calculate the figure acceptancePercentage of that figure
      let acceptancePercentage = (figureData.upVotes.length/(figureData.upVotes.length + figureData.downVotes.length)) * 100
      acceptancePercentage = Math.trunc(acceptancePercentage)
      if (Math.trunc(acceptancePercentage) > 100) {
        acceptancePercentage = 100;
      }
    
      if (Math.trunc(acceptancePercentage) < 0) {
        acceptancePercentage = 0
      }  

      figureData.acceptancePercentage = acceptancePercentage
      
      const engagementNumber = figureData.engagement
      const title = figureData.title
      const theTimeStamp = new Date(figureData.timeStamp)
      const theFigureNumber = figureData.figureNumber
      const figureInfo = {
        acceptancePercentage: acceptancePercentage,
        engagementNumber: engagementNumber,
        title: title,
        timeStamp: moment(theTimeStamp),
        figureNumber: theFigureNumber,
        downVotes: figureData.downVotes,
        upVotes: figureData.upVotes,
        ownerTags: figureData.ownerTags
      }
      
      if (isNaN(figureData.acceptancePercentage)) {
        figureData.acceptancePercentage = '?'
      }
      // console.log(figureData.acceptancePercentage)
      const storageRef = ref(storage, `/figures/${figure}`)
      let imgsrc = await getDownloadURL(storageRef)
      figureData.imgsrc = imgsrc;
      figureData.id = figure;
      // console.log(figureData)
      return figureData;
    } catch (err) {
      console.log('Fetching figure info didnt work.')
    }
  }
}

export const gatherFigureUpdates = (user, figure) => {
  return async (dispatch) => {
    try {
      //fetch the correct figure from the figures database in firestore
      const docRef = doc(db, "Figures", figure);
      const figureDocument = await getDoc(docRef);
      const figureData = figureDocument.data()

      if (figureData == undefined) {
        const date = new Date('Thu Jan 01 1970 00:00:00 GMT+0000')
        const figureInfo = {
          acceptancePercentage: '?',
          engagementNumber: '?',
          title: 'figure Unavailable',
          timeStamp: moment(date)
        }
        return figureInfo;
      }

      //calculate the figure acceptancePercentage of that figure
      let acceptancePercentage = (figureData.upVotes.length/(figureData.upVotes.length + figureData.downVotes.length)) * 100
      acceptancePercentage = Math.trunc(acceptancePercentage)
      if (Math.trunc(acceptancePercentage) > 100) {
        acceptancePercentage = 100;
      }
    
      if (Math.trunc(acceptancePercentage) < 0) {
        acceptancePercentage = 0
      }  
      
      const engagementNumber = figureData.engagement.length
      const title = figureData.title
      const theTimeStamp = new Date(figureData.timeStamp)
      const theFigureNumber = figureData.figureNumber
      const figureInfo = {
        acceptancePercentage: acceptancePercentage,
        engagementNumber: engagementNumber,
        title: title,
        timeStamp: moment(theTimeStamp),
        figureNumber: theFigureNumber
      }

      return figureInfo;
    } catch (err) {
      console.log('Fetching figure info didnt work.')
    }
  }
}



//This function takes in the userID and figureID and returned whether or not the figure is in the
//"uploadedFigures", "acceptedFigures", or "rejectedFigures" arrays in their profile. If it is in
//none of these, the function returns false.
export const updateLastViewedFigure = (user, figureID, timeStamp) => {
  return async (dispatch) => {
    try {
      //Fetch the profile document of the owner.
      const profRef = doc(db, "Profiles", user);
      const document = await getDoc(profRef)
      let profileData = document.data()

      const isInAccepted = profileData.myFigures.acceptedFigures.findIndex((figure) => {
        return figure.id == figureID
      })
      const isInRejected = profileData.myFigures.rejectedFigures.findIndex((figure) => {
        return figure.id == figureID
      })
      const isInUploaded = profileData.myFigures.uploadedFigures.findIndex((figure) => {
        return figure.id == figureID
      })

      //Search through all figures within uploadedFigures, acceptedFigures, and rejectedFigures for the ID
      if (isInAccepted > -1) {
        profileData.myFigures.acceptedFigures[isInAccepted].lastViewed = timeStamp
      }
      if (isInRejected > -1) {
        profileData.myFigures.rejectedFigures[isInRejected].lastViewed = timeStamp
      }
      if (isInUploaded > -1) {
        profileData.myFigures.uploadedFigures[isInUploaded].lastViewed = timeStamp
      }

      await setDoc(profRef, profileData)
      //update the profile in redux
      dispatch(setProfile({
        ...profileData, 
        id: user
      }))
      return;

    } catch (err) {
      console.log('Not able to check if figure is in myFigures.')
    }
  }
}


//Not in use?
export const updateFiguresFirebase = () => {
  return async (dispatch) => {
    try {

      let figuresToUpdate = [
        // '3TIhfb92Q3HoeDvz3ejT'
        'CNCeOdv2Vq6y3Fg9er7P'
        // 'KbuGSOjzeXb6O66Cd2ps',
        // 'uzV4rZdOfboNdSYJUUw2',
        // 'vOdTwGyrIftJBG3ezrqs'
        // 'x1JMy3JsYIPmNIIzu2yG'
      ]

      figuresToUpdate.forEach(async (figure) => {
        const profRef = doc(db, "Figures", figure);
        const document = await getDoc(profRef)
        let figureData = document.data()

        figureData.updates = {
          accepts: [],
          rejects: [],
          comments: [],
          claimsConnections: [],
          uploaded: [
            {
              occurrence: '788a830f-6bb4-4b85-b4ea-9d382b68a2el',
              timeStamp: 'Sun Dec 04 2022 16:03:39 GMT+0000'

            }
          ]
        }
        await setDoc(profRef, figureData)
      })

      return;

    } catch (err) {
      console.log('Not able to update Figures in Firebase.')
    }
  }
}

//Not in use?
export const updateClaimsFirebase = () => {
  return async (dispatch) => {
    try {

      let claimsToUpdate = [
        // '4v6m4AuXcPwI8USRZ47e'
        // '5ZtiuhrhbTR4fFtScnre'
        // '6B8JX0mhV0sLAOHy1S7L'
        // 'DguclNGFzYvzlpervoSA'
        // 'DtmKoTqdeKtd5Zg4tdsj'
        // 'PKKaTcMJrMlzAaMwUcDH'
        // 'YfsAkGqT1k4n4cMYfK0c'
        'abDZVuwEsjV5Q2ajcMvp'
      ]

      claimsToUpdate.forEach(async (claim) => {
        const profRef = doc(db, "Claims", claim);
        const document = await getDoc(profRef)
        let claimData = document.data()

        claimData.updates = {
          figureConnections: [],
          theoryConnections: [],
          comments: [],
          engagement: [],
          created: [
            {
              occurrence: '788a830f-6bb4-4b85-b4ea-9d382b68a2et',
              timeStamp: 'Sun Dec 04 2022 16:03:47 GMT+0000'

            }
          ]
        }
        await setDoc(profRef, claimData)
      })

      return;

    } catch (err) {
      console.log('Not able to update Claims in Firebase.')
    }
  }
}


//Not in use?
export const reverseAcceptFigureMyFigures = (upVote) => {
  return async (dispatch) => {
    try {
      //get the figure document from Firebase
      const docRefFigure = doc(db, "Figures", upVote.figureID);
      const figureDocument = await getDoc(docRefFigure)
      const figureData = figureDocument.data()

      //remove the user's ID from the upVotes array if the figure still exists in firebase
      if (figureData) {
        const remove = figureData.upVotes.filter((Vote) => {
          return Vote != upVote.user
        })
        figureData.upVotes = remove

        //re-save the updated figure into Firebase
        await setDoc(docRefFigure, figureData)
      } 

      

      //get the profile document from firebase
      const docRefProfile = doc(db, "Profiles", upVote.user);
      const profileDocument = await getDoc(docRefProfile)
      const profileData = profileDocument.data()

      //remove the figure's ID from the acceptedFigures array.
      const removeAccepted = profileData.myFigures.acceptedFigures.filter((Vote) => {
        return Vote != upVote.figureID
      })
      profileData.myFigures.acceptedFigures = removeAccepted

      //re-save the updated profile into Firebase
      const profileSaved = await setDoc(docRefProfile, profileData)

      //update the profile in redux
      dispatch(setProfile({
        ...profileData, 
        id: upVote.user
      }))
    } catch (err) {
      console.log('did not reverse-accept figure')
    }
  }
}

//Not in use?
export const reverseAcceptFigureMyFigures2 = (upVote) => {
  return async (dispatch) => {
    try {
      //get the figure document from Firebase
      const docRefFigure = doc(db, "Figures", upVote.figureID);
      const figureDocument = await getDoc(docRefFigure)
      const figureData = figureDocument.data()

      //remove the user's ID from the upVotes array
      const remove = figureData.upVotes.filter((Vote) => {
        return Vote.id != upVote.user
      })
      figureData.upVotes = remove

      const newAcceptUpdates = figureData.updates.accepts.filter((update) => {
        return update.id != upVote.user
      })
      figureData.updates.accepts = newAcceptUpdates

      //re-save the updated figure into Firebase
      await setDoc(docRefFigure, figureData)

      //get the profile document from firebase
      const docRefProfile = doc(db, "Profiles", upVote.user);
      const profileDocument = await getDoc(docRefProfile)
      const profileData = profileDocument.data()

      //add the figure's ID to the acceptedFigures array.
      const removeAccepted = profileData.myFigures.acceptedFigures.filter((Vote) => {
        return Vote.id != upVote.figureID
      })
      profileData.myFigures.acceptedFigures = removeAccepted

      //re-save the updated profile into Firebase
      await setDoc(docRefProfile, profileData)

      //update the profile in redux
      dispatch(setProfile({
        ...profileData, 
        id: upVote.user
      }))
    } catch (err) {
      console.log('did not reverse-accept figure')
    }
  }
}

//Not in use?
export const reverseRejectFigureMyFigures = (downVote) => {
  return async (dispatch) => {
    try {
      //get the figure document from Firebase
      const docRefFigure = doc(db, "Figures", downVote.figureID);
      const figureDocument = await getDoc(docRefFigure)
      const figureData = figureDocument.data()

      if (figureData) {
        //remove the user's ID from the downVotes array
        const remove = figureData.downVotes.filter((Vote) => {
          return Vote.id != downVote.user
        })
        figureData.downVotes = remove
        //re-save the updated figure into Firebase
        await setDoc(docRefFigure, figureData)
      }
      //get the profile document from firebase
      const docRefProfile = doc(db, "Profiles", downVote.user);
      const profileDocument = await getDoc(docRefProfile)
      const profileData = profileDocument.data()

      //remove the figure's ID from the rejectedFigures array.
      const removeRejected = profileData.myFigures.rejectedFigures.filter((Vote) => {
        return Vote != downVote.figureID
      })
      profileData.myFigures.rejectedFigures = removeRejected

      //re-save the updated profile into Firebase
      await setDoc(docRefProfile, profileData)

      //update the profile in redux
      dispatch(setProfile({
        ...profileData, 
        id: downVote.user
      }))
    } catch (err) {
      console.log('did not reverse-accept figure')
    }
  }
}

//Not in use?
export const reverseRejectFigureMyFigures2 = (downVote) => {
  return async (dispatch) => {
    try {
      //get the figure document from Firebase
      const docRefFigure = doc(db, "Figures", downVote.figureID);
      const figureDocument = await getDoc(docRefFigure)
      const figureData = figureDocument.data()

      //add the user's ID to the downVotes array
      const remove = figureData.downVotes.filter((Vote) => {
        return Vote.id != downVote.user
      })
      figureData.downVotes = remove

      const newRejectUpdates = figureData.updates.rejects.filter((update) => {
        return update.id != downVote.user
      })
      figureData.updates.rejects = newRejectUpdates

      //re-save the updated figure into Firebase
      await setDoc(docRefFigure, figureData)

      //get the profile document from firebase
      const docRefProfile = doc(db, "Profiles", downVote.user);
      const profileDocument = await getDoc(docRefProfile)
      const profileData = profileDocument.data()

      //add the figure's ID to the rejectedFigures array.
      const removeAccepted = profileData.myFigures.rejectedFigures.filter((Vote) => {
        return Vote.id != downVote.figureID
      })
      profileData.myFigures.rejectedFigures = removeAccepted

      //re-save the updated profile into Firebase
      await setDoc(docRefProfile, profileData)

      //update the profile in redux
      dispatch(setProfile({
        ...profileData, 
        id: downVote.user
      }))
    } catch (err) {
      console.log('did not reverse-reject figure')
    }
  }
}

export const getFigureInfo = (figure) => {
  return async (dispatch) => {
    try {
      //fetch the correct figure from the figures database in firestore
      const docRef = doc(db, "Figures", figure);
      const figureDocument = await getDoc(docRef);
      const figureData = figureDocument.data()
      const storageRef = ref(storage, `/figures/${figure}`)
      let imgsrc = await getDownloadURL(storageRef)
      figureData.imgsrc = imgsrc;
      figureData.id = figure;


      if (figureData == undefined) {
        return false;
      }
      return figureData;
    } catch (err) {
      console.log('Fetching figure info didnt work from getFigureInfo.')
    }
  }
}
