import React, { useState, useRef, useEffect } from 'react';
import Tippy from '@tippyjs/react'
import { connect } from 'react-redux'
import DeleteModal from './deleteModal';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import ReportCriticismModal from './reportCriticismModal';


import { startAcceptCriticism, reverseAcceptCriticism, 
    startRejectCriticism, reverseRejectCriticism } from '../actions/figModels';
import SVGdeleteCriticism from './SVGs/SVGDelete';


export const CriticismStateless = (props) => {
    const [upVote, setUpVote] = useState(false)
    const [downVote, setDownVote] = useState(false)
    const [deleteModalState, setDeleteModalState] = useState(false)
    const [reportCriticismModalState, setReportCriticismModalState] = useState(false)

    const [upTally, setUpTally] = useState(props.upvotes.length)
    const [downTally, setDownTally] = useState(props.downvotes.length)
    const [critOptionsOpen, setCritOptionsOpen] = useState(false)
    const [upVoteEngagement, setUpVoteEngagement] = useState([{}])
    const [downVoteEngagement, setDownVoteEngagement] = useState([{}])
    const [upVotes, setUpVotes] = useState(props.upvotes)
    const [downVotes, setDownVotes] = useState(props.downvotes)

    let critOptionsRefDiv = useRef();
    let critOptionsRefIcon = useRef();

    const theDate = new Date(props.timeStamp)
    const newMoment = moment(theDate)
    newMoment.local()
    const localDate = newMoment.format('LLL')

    useEffect(() => {
        const upVoteIndex = props.upvotes.findIndex((theUpVote) => {
            return theUpVote.id === props.user
        })
        if (upVoteIndex != -1) {
            setUpVote(true)
            setUpVoteEngagement(props.upvotes[upVoteIndex])
        }

        const downVoteIndex = props.downvotes.findIndex((theDownVote) => {
            return theDownVote.id === props.user
        })
        if (downVoteIndex != -1) {
            setDownVote(true)
            setDownVoteEngagement(props.downvotes[downVoteIndex])
        }
    }, [])


    useEffect(() => {
        // console.log("use effect ran")
        let handler = (event) => {
            if(!(critOptionsRefDiv.current.contains(event.target) || critOptionsRefIcon.current.contains(event.target))) {
                setCritOptionsOpen(false)
            }
        }
        document.addEventListener("mousedown", handler)
        return () => {
            document.removeEventListener("mousedown", handler)
        }
    })

    const checkIfOwner = () => {
        if (props.user === props.owner) {
            return true;
        } else {
            return false;
        }
    }

    const reportCriticism = () => {
        setReportCriticismModalState(true)
    }

    const upVoteCriticism = () => {
        if (!props.loggedin) {
            return;
        }
        // let currentDownTally = downTally
        let currentUpVotes = upVotes
        // let currentDownVotes = downVotes
        let timeStamp = moment().utc().toString()
        let occurrence = uuidv4();
        let currentEngagement = [...props.totalEngagement]
        let theUpVoteEngagement = [...upVoteEngagement]
        let theDownVoteEngagement = [...downVoteEngagement]
        let newEngagement = {
            id: props.user,
            occurrence: occurrence,
            timeStamp: timeStamp
        }
        if (upVote) {
            setUpVote(false)
            setDownVote(false)
            setUpTally(prev => prev - 1)
            setUpVotes(props.removeEngagement(currentUpVotes, theUpVoteEngagement))
            let newTotalEngagement = props.removeEngagement(currentEngagement, theUpVoteEngagement)
            props.setTotalEngagement(newTotalEngagement)
            setUpVoteEngagement([{}])
            props.reverseAcceptCriticism(props.occurrence, props.id, timeStamp, props.user, occurrence).then(() => {

            }).catch((err) => {
                // console.log('did not reverse accept criticism')
            })
        } else if (downVote) {
            setUpVote(true)
            setDownVote(false)
            setUpTally(prev => prev + 1)
            currentUpVotes.push(newEngagement)
            setUpVotes(currentUpVotes)
            setDownTally(prev => prev - 1)
            setDownVotes(props.removeEngagement(currentUpVotes, theUpVoteEngagement))
            currentEngagement.push(newEngagement)
            let newTotalEngagement = props.removeEngagement(currentEngagement, theDownVoteEngagement)
            props.setTotalEngagement(newTotalEngagement)
            setUpVoteEngagement([newEngagement])
            setDownVoteEngagement([{}])
            props.reverseRejectCriticism(props.occurrence, props.id, timeStamp, props.user, occurrence).then(() => {
                props.startAcceptCriticism(props.occurrence, props.id, timeStamp, props.user, occurrence)
            }).catch((err) => {
                // console.log('did not reverse accept criticism')
            })
        } else {
            setUpVote(true)
            setDownVote(false)
            setUpTally(prev => prev + 1)
            currentUpVotes.push(newEngagement)
            setUpVotes(currentUpVotes)
            props.startAcceptCriticism(props.occurrence, props.id, timeStamp, props.user, occurrence)
            currentEngagement.push(newEngagement)
            setUpVoteEngagement([newEngagement])
            props.setTotalEngagement(currentEngagement)
        }
    }
    const downVoteCriticism = () => {
        if (!props.loggedin) {
            return;
        }
        // let currentUpTally = upTally
        // let currentDownTally = downTally
        // let currentUpVotes = upVotes
        let currentDownVotes = downVotes
        let timeStamp = moment().utc().toString()
        let occurrence = uuidv4();
        let currentEngagement = [...props.totalEngagement]
        let theUpVoteEngagement = [...upVoteEngagement]
        let theDownVoteEngagement = [...downVoteEngagement]
        let newEngagement = {
            id: props.user,
            occurrence: occurrence,
            timeStamp: timeStamp
        }
        if (downVote) {
            setUpVote(false)
            setDownVote(false)
            setDownTally(prev => prev - 1)
            setDownVotes(props.removeEngagement(currentDownVotes, theDownVoteEngagement))
            let newTotalEngagement = props.removeEngagement(currentEngagement, theDownVoteEngagement)
            props.setTotalEngagement(newTotalEngagement)
            setDownVoteEngagement([{}])
            props.reverseRejectCriticism(props.occurrence, props.id, timeStamp, props.user, occurrence).then(() => {

            }).catch((err) => {
                // console.log('did not reverse reject criticism')
            })
        } else if (upVote) {
            setUpVote(false)
            setDownVote(true)
            setDownTally(prev => prev + 1)
            currentDownVotes.push(newEngagement)
            setDownVotes(currentDownVotes)
            setUpTally(prev => prev - 1)
            setUpVotes(props.removeEngagement(currentDownVotes, theDownVoteEngagement))
            currentEngagement.push(newEngagement)
            let newTotalEngagement = props.removeEngagement(currentEngagement, theUpVoteEngagement)
            props.setTotalEngagement(newTotalEngagement)
            setDownVoteEngagement([newEngagement])
            setUpVoteEngagement([{}])
            props.reverseAcceptCriticism(props.occurrence, props.id, timeStamp, props.user, occurrence).then(() => {
                props.startRejectCriticism(props.occurrence, props.id, timeStamp, props.user, occurrence)
            })
        } else {
            setUpVote(false)
            setDownVote(true)
            setDownTally(prev => prev + 1)
            currentDownVotes.push(newEngagement)
            setDownVotes(currentDownVotes)
            props.startRejectCriticism(props.occurrence, props.id, timeStamp, props.user, occurrence)
            currentEngagement.push(newEngagement)
            setDownVoteEngagement([newEngagement])
            props.setTotalEngagement(currentEngagement)
        }
    }

    const criticismOptions = () => {
        if (!props.loggedin) {
            return;
        }

        setCritOptionsOpen(!critOptionsOpen)
    }

    return (
        <div className="critDiv" key={props.text}>
        {deleteModalState && <DeleteModal critText={props.text} id={props.id} imgsrc={props.imgsrc} occurrence={props.occurrence}
        closeModal={setDeleteModalState} element='criticism' user={props.user} criticisms={props.criticisms} index={props.index}
        setTheCriticisms={props.setTheCriticisms} upVotes={upVotes} downVotes={downVotes} totalEngagement={props.totalEngagement}
        setTotalEngagement={props.setTotalEngagement} removeEngagement={props.removeEngagement}/>}
        {reportCriticismModalState && <ReportCriticismModal id={props.id} occurrence={props.occurrence} critText={props.text}
        closeModal={setReportCriticismModalState} user={props.user} criticismOwner={props.owner} elementType={'figure'}/>}
            <div className={props.loggedin ? "crit" : "critUnlogged"}>
                {
                    props.ownerTags.map((tag) => {
                        return (<a key={tag.displayName} className="anchorName" href={'/Profile/' + tag.id }>{tag.displayName}</a>)
                    })
                }
                <br/>
                <br/>
                {props.text}
                <br/>
                <div className='criticismTimeStampFigure'><b>- {localDate}</b></div>
            </div>
            <div className="critButtonsFigure">
                <div>
                <Tippy content="Endorse criticism">
                    <div className='critFigureButtonUp' onClick={() => {upVoteCriticism()}}>
                        <svg className = {upVote ? "UpClickedClaim" : "UpClaim"} name= {props.text} version="1.1" viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                            <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path className="color000 svgShape" d="m20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" 
                                fill={upVote ? "#FFFFFF" : "rgb(98, 189, 184)"}/>
                            </svg>
                        </svg>
                        {upTally}
                    </div>
                </Tippy>
                <Tippy content="Reject criticism">
                    <div className='critFigureButton1' onClick={() => {downVoteCriticism()}}>
                        <svg className={downVote ? "DownClickedClaim" : "DownClaim"}
                        name = {props.text} version="1.1" viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                            <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path className="color000 svgShape" d="m24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 
                                3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" 
                                fill={downVote ? "#FFFFFF" : "rgb(228, 92, 92)"}/>
                            </svg>
                        </svg>
                        {downTally}
                    </div>
                </Tippy>
                </div>
            </div>
            <div className={props.loggedin ? 'optionsDivClaim' : 'hidden'}>
                <Tippy content="Options">
                    <div className="critClaimButtonDelete" ref={critOptionsRefIcon} onClick={() => criticismOptions()} >
                        <svg className="deleteCritClaimSVG" version="1.1" viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                            <svg width="288" height="288" enableBackground="new 0 0 32.055 32.055" viewBox="0 0 32.055 32.055" xmlns="http://www.w3.org/2000/svg">
                                <path className="color000 svgShape" d="m3.968 12.061c-2.193 0-3.968 1.774-3.968 3.966s1.773 3.967 3.968 3.967c2.189 0 3.966-1.772 
                                3.966-3.967 0-2.192-1.777-3.966-3.966-3.966zm12.265 0c-2.188 0-3.968 1.773-3.968 3.965s1.778 3.967 3.968 3.967 
                                3.97-1.772 3.97-3.967c-2e-3 -2.191-1.78-3.965-3.97-3.965zm11.857 0c-2.192 0-3.969 1.774-3.969 3.967 0 2.19 1.774 3.965 3.969 
                                3.965 2.188 0 3.965-1.772 3.965-3.965s-1.777-3.967-3.965-3.967z" fill="#002c2c"/>
                            </svg>
                        </svg>
                    </div>
                </Tippy>
                <div hidden={!critOptionsOpen} ref={critOptionsRefDiv} className='dropDownClaimCrit'>
                    <div className={checkIfOwner() ? 'optionsAnchorDiv' : 'hidden'} onClick={() => {setDeleteModalState(true)}}>
                        <SVGdeleteCriticism/>
                        <a><b>Delete Criticism</b></a>
                    </div>
                    <div className='optionsAnchorDiv' onClick={reportCriticism}>
                        <svg className='marginSVG' width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 
                            0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z" fill="#002c2c"/>
                        </svg>
                        <a><b>Report this criticism</b></a>
                    </div>
                </div>
            </div>
        </div>
    )
} 

const mapDispatchToProps = (dispatch, props) => ({
    startAcceptCriticism: (critOccurrence, id, timeStamp, user, voteOccurrence) => dispatch(startAcceptCriticism(critOccurrence, id, timeStamp, user, voteOccurrence)),
    reverseAcceptCriticism: (critOccurrence, id, timeStamp, user, voteOccurrence) => dispatch(reverseAcceptCriticism(critOccurrence, id, timeStamp, user, voteOccurrence)),
    startRejectCriticism: (critOccurrence, id, timeStamp, user, voteOccurrence) => dispatch(startRejectCriticism(critOccurrence, id, timeStamp, user, voteOccurrence)),
    reverseRejectCriticism: (critOccurrence, id, timeStamp, user, voteOccurrence) => dispatch(reverseRejectCriticism(critOccurrence, id, timeStamp, user, voteOccurrence))
})

const ConnectedCriticism = connect(undefined, mapDispatchToProps)(CriticismStateless);

export default ConnectedCriticism;
