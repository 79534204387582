import { createStore, combineReducers, applyMiddleware, compose} from 'redux'
import claimsReducer from '../reducers/claimModels'
import figsReducer from '../reducers/figModels'
import profsReducer from '../reducers/profModels'
import theoriesReducer from '../reducers/theoryModels'
import papersReducer from '../reducers/paperModels'
import thunk from 'redux-thunk'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
    const store = createStore(
        combineReducers({
          claimModels: claimsReducer,
          figModels: figsReducer,
          profModels: profsReducer,
          theoryModels: theoriesReducer,
          paperModels: papersReducer
        }),
        composeEnhancers(applyMiddleware(thunk))
        // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
      );
    return store;
}

