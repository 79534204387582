import React, {useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from "react-router-dom"
import SearchBarClaim from './searchBarClaim.js'
import { returnClaimInfoHomeFeed } from '../actions/claimModels';

const Claim = (props) => {
    const navigate = useNavigate()
    const connectClaim = () => {
        navigate('/ConnectClaim')
    }
    const [claimID, setClaimID] = useState('')
    const [theClaimData, setTheClaimData] = useState({ associatedTheories: [], contraFigs: [], engagement: [], extactText: '',
        extractedClaim: false, follows: [], owner: '', ownerTags: [], paperDoi: '', paperID: '', relatedClaims: [], supportFigs: [],
    timeStamp: '', title: '', updates: {}})
    const [notDeleted, setNotDeleted] = useState(true)



    useEffect(async () => {
        let URLpath = window.location.pathname
        let split = URLpath.split('/')
        let id;
        if (split.length == 3) {
            id = split[2]
        }

        try {
            setClaimID(id)
            //Make call to Firebase with returnClaimInfoUpdates function to fetch info off of database.
            let claimInfo = await props.returnClaimInfoHomeFeed(id)
            //If info is able to be fetched, update all relevant state values.
            if (claimInfo) {
                setTheClaimData(claimInfo)
            } else {
                //If the figure is not available on Firebase, it is probably because it was deleted.
                //If it was deleted, nothing should be shown.

                //Also set to false if there are no updates to be shown for this claim so that it does
                //not show up.
                setNotDeleted(false)
            }
        } catch (err) {
            // console.log(err)
        }
    }, [])
    
    return (
        <div className="claimShow">
            <h1 className="notFound" hidden={notDeleted}>Error: Claim does not exist or was deleted</h1>
            <div hidden={props.loggedIn || !notDeleted} className="loginNotice" ><a className="anchor" onClick={() => {navigate("/Login")}}>
                Login or create an account</a> to connect figures with claims.
            </div>
            <div hidden={!props.loggedIn} className='majorContainer2'>
                <h1>Connect a figure to this claim</h1>
                <h2 className="connectFiguresConnectClaimH2">Claim: <a className="claimTitle" onClick={() => {navigate("/Claim/" + claimID)}} >
                    {theClaimData.title}</a></h2>
                <SearchBarClaim title={theClaimData.title} id={claimID} owner={theClaimData.owner} relatedClaims={theClaimData.relatedClaims}
                contraFigs={theClaimData.contraFigs} supportFigs={theClaimData.supportFigs}/>
            </div>
            
        </div>
    )

}

const mapStateToProps = (state, props) => {
    if (state.profModels.length == 1) {
        return {
            user: state.profModels[0].id,
            userName: state.profModels[0].name,
            tags: state.profModels[0].tags,
            loggedIn: true,
        };
    } else {
        return {
            user: '',
            userName: '',
            tags: [],
            loggedIn: false,
        };
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    returnClaimInfoHomeFeed: (id) => dispatch(returnClaimInfoHomeFeed(id))
})



const ConnectedClaim = connect(mapStateToProps, mapDispatchToProps)(Claim);

export default ConnectedClaim;