import React, { Component, useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import { storage } from '../firebase/firebase'
import { ref, uploadBytesResumable } from 'firebase/storage'
import { startAddFigure, startSetFigure } from '../actions/figModels'
import { startIdentifyPaper, searchForPaper, getPaperDois } from '../actions/paperModels';
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from "react-router-dom"
import Spinner from "react-svg-spinner";




export const AddFigure = (props) => {
    const [unchecked, setUnchecked] = useState(false)
    const [tagsOpen, setTagsOpen] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [figureFromPaper, setFigureFromPaper] = useState(false)
    const [paperFound, setPaperFound] = useState(true)
    const [loaded, setLoaded] = useState(true)
    const allPapers = useRef()
    const [thePaperID, setThePaperID] = useState('')
    const [loaded2, setLoaded2] = useState(true)

    let doiRef = useRef();
    let figureNumberRef = useRef();
    let paperTitleRef = useRef();
    let paperJournalRef = useRef();

    const navigate = useNavigate()

    useEffect( async () => {
        let papers = await props.getPaperDois()
        allPapers.current = papers
    }, [])
    const addFigure = async (e) => {
        e.preventDefault()
        let values = []
        for (let i = 0; i < e.target.elements.length; i++) {
            if (e.target.elements[i].checked) {
                let ownerTag = {
                    displayName: e.target.elements[i].name,
                    id: e.target.elements[i].id
                }
                if (e.target.elements[i].name !== 'fromPaper') {
                    values.push(ownerTag)
                }
            }
        }
        if (values.length == 0) {
            setUnchecked(true)
            return;
        } else {
            setUnchecked(false)
        }
        setLoaded2(false)
        setSubmitted(true)
        let timeStamp = moment().utc().toString()

        let paperID = '';
        let occurrence = uuidv4()

        //First, if the figure is from a paper that hasn't been identified on JournalClub yet, that paper needs to be added
        //and the paperID set accordingly.
        if (figureFromPaper && !paperFound) {
            const paperInfo = {
                title: paperTitleRef.current.value.trim(),
                doi: doiRef.current.value.trim(),
                journal: paperJournalRef.current.value.trim(),
                owner: props.user,
                ownerTags: values,
                timeStamp: timeStamp,
                figures: [],
                follows: [],
                extractedClaims: [],
                updates: {
                    claimExtractions: [],
                    comments: [],
                    figureUploads: [],
                    follows: [],
                    identified: [
                        {
                            timeStamp: timeStamp,
                            occurrence: occurrence
                        }
                    ]
                }
            }
            paperID = await props.startIdentifyPaper(paperInfo, props.user)
        }

        //If the figure is from a paper which was found in firebase, set the correct paperID from the state.
        if (figureFromPaper && paperFound) {
            paperID = thePaperID
        }

        //Create the figure to be passed into the startAddFigure function.
        let figureInfo = {
            title: e.target.title.value,
            description: e.target.description.value,
            methods: e.target.methods.value,
            owner: props.user,
            ownerTags: values,
            timeStamp: timeStamp,
            fromPaper: false,
            paperDoi: '',
            paperID: paperID,
            figureNumber: '',
            updates: {
                comments: [],
                accepts: [],
                rejects: [],
                claimConnections: [],
                uploaded: [
                    {
                        timeStamp: timeStamp,
                        occurrence: occurrence
                    }
                ]
            }
        }

        //If it is a figure from a paper, fill out the four values at the bottom.
        //If it is not extracted from a paper, don't change the values.
        if (figureFromPaper) {
            figureInfo.fromPaper = true
            figureInfo.figureNumber = figureNumberRef.current.value
            figureInfo.paperDoi = doiRef.current.value.trim()
        }
        const file = e.target[0].files[0]

        let doc = await props.startAddFigure(figureInfo, props.user)
        let figureID = doc.id
        let a = await uploadthem(file, figureID)
        let figureURL = '/Figure/' + figureID
        navigate(figureURL)
    }
       
    const uploadthem = (file, id) => {
        if (!file) return;
        const storageRef = ref(storage, `/figures/${id}`)
        return uploadBytesResumable(storageRef, file)
        // uploadBytes(imageRef, file).then((snapshot) => {
        // })
    }

    const fromPaperBox = () => {
        setFigureFromPaper(!figureFromPaper)
        setPaperFound(true)
        doiRef.current.value = ''
        figureNumberRef.current.value = ''
        paperTitleRef.current.value = ''
        paperJournalRef.current.value = ''
    }

    const searchForPaper = async () => {
        setLoaded(false)
        //When the doi is pasted into the field, search Firebase to see if it has already been identified. If it has, gather its data.
        let doi = doiRef.current.value.trim()
        let paperInfo = await props.searchForPaper(doi, allPapers.current)
        //If the paper has already been identified, autopopulate 
        if (!paperInfo && doi !== '') {
            setPaperFound(false)
            setThePaperID('')
        } else {
            setPaperFound(true)
            setThePaperID(paperInfo.id)
        }
        setLoaded(true)
    }
    
    return (
        
        <form className="AddFigureForm" onSubmit={addFigure}>
            <div hidden={props.loggedIn} className="addFigureLogin" ><a className="anchor" href="/Login">
                Login or create an account</a> to post your own figures or criticisms
            </div>
            <div hidden={!props.loggedIn} className="AddFigureMajorContainer2">
                <h1>Upload a new figure</h1>
                <div className="fileUploadAddFigure">
                    <div>
                        <div className='AddFigureSelectImage'>Select an image to upload</div>
                        <input className="fileUpload" type = "file" required/>
                    </div>
                </div>
                <br/>
                <h2 className='AddClaimTitleHeader'>Figure Title</h2> 
                <br/>
                <div>
                    <textarea name="title" className="halfTextArea" placeholder="Paste title of figure here..."  required>
                    </textarea>
                </div> 
                <div className='extractedDivAddFigure'>
                    <input status='userName' className="checkBox1Claim" type='checkbox' id='fromPaper' name='fromPaper' 
                    value='fromPaper' onClick={() => {fromPaperBox()}}/>
                    <span>This figure is from a paper</span>
                </div>   
                <div className="container" hidden={!figureFromPaper}>
                    <h2 className='AddClaimTitleHeader'>Figure number</h2>
                    <br/>
                    <div>
                        <textarea name="doi" placeholder="What is the number of the figure? (ex: 5b)" 
                        required={figureFromPaper} className="smallTextAreaPaper" ref={figureNumberRef} onChange={(e) => {searchForPaper(e)}}></textarea>
                    </div>
                    <br/>
                    <h2 className='AddClaimTitleHeader'>Source</h2>
                    <br/>
                    <div>
                        <textarea name="doi" placeholder="Please paste the full DOI link of the paper that this figure comes from (if available)" 
                        required={figureFromPaper} className="smallTextAreaPaper" ref={doiRef} onChange={(e) => {searchForPaper(e)}}></textarea>
                    </div>
                </div>
                
                <div hidden={paperFound}>
                    <br/>
                    <br/>
                    <span hidden={paperFound}>This paper has not been identified in Journal Club yet, please add the following info:</span>
                    <br/>
                    <br/>
                    <h2 className='AddClaimTitleHeader'>Paper Title</h2> 
                    <br/>
                    <textarea name="paperTitle" placeholder="Paste the title of the paper where this figure is from..."
                    required={figureFromPaper && !paperFound} className="halfTextArea" ref={paperTitleRef}>
                    </textarea>
                    <br/>
                    <br/>
                    <h2 className='AddClaimTitleHeader'>Journal</h2> 
                    <br/>
                    <textarea name="paperJournal" placeholder="Paste the journal where the paper was published..."
                    required={figureFromPaper && !paperFound} className="smallTextAreaPaper" 
                    ref={paperJournalRef}>
                    </textarea>
                </div>
                <div className="container">
                    <h2 className='AddClaimTitleHeader'>Figure Description</h2>
                    <br/>
                    <div>
                        <textarea name="description" className="fullTextArea" placeholder="Describe the new figure here..." required></textarea>
                    </div>
                </div>
                <div className="container">
                    <h2 className='AddClaimTitleHeader'>Figure Methods</h2>
                    <br/>
                    <div>
                        <textarea name="methods" className="fullTextArea" placeholder="Describe the new figure here..." required></textarea>
                    </div>
                </div>
                <div className="container">
                    <div className="centered">
                        <div className="postAsAddFigure">
                            <span className="postAsSpanAddFigure">Post as:  </span>
                            <div className='tags'>
                                <span className='firstCheck'>
                                    <div className="checkBoxDivFirst">
                                        <div className='FigureDiv1'>
                                            <input status='userName' className="checkBox1" type='checkbox' id={props.user}
                                            name={props.userName} value={props.userName}/>
                                            <label className='label'>{props.userName}</label>
                                        </div>
                                        <span className='tagsSpan'>
                                            <Tippy content='Post Tags'>
                                                <svg transform={tagsOpen ? "rotate(180)" : ""} id="tagSVG" onClick={() => setTagsOpen(!tagsOpen)} 
                                                xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                                                    <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/>
                                                </svg>
                                            </Tippy>
                                        </span>
                                    </div>
                                    <div className={tagsOpen ? "postAsDiv" : "hidden"}>
                                        <div>
                                            {
                                                props.tags.map((tag, index) => {
                                                    return (
                                                        <div key={tag.displayName + '1'} className="checkBoxDiv">
                                                            <input status='tag' key={tag.displayName} name={tag.displayName} id={tag.id} 
                                                            type='checkbox' className="checkBox" />
                                                            <label key={tag.displayName + '2'} className="label">{tag.displayName}</label>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="redFontFigure" hidden={!unchecked} >You must check at least one box!</div> 
                </div>
            </div>
            <div className={props.loggedIn ? "centerButtonDiv" : "hidden"}>
                <button className={loaded2 ? "SubmitNewFigureButton" : "SubmitNewFigureButtonClicked"}>
                    <b hidden={!loaded2}>Submit New Figure</b>
                    <Spinner hidden={loaded2} />
                </button>
            </div>
        </form>
    )
}

const mapStateToProps = (state, props) => {
    if (state.profModels.length == 1) {
        return {
            user: state.profModels[0].id,
            userName: state.profModels[0].name,
            tags: state.profModels[0].tags,
            loggedIn: true
        };
    } else {
        return {
            user: '',
            userName: '',
            tags: [],
            loggedIn: false
        };
    }
}

const mapDispatchToProps = (dispatch) => ({
    startAddFigure: (FigureData, theOwner) => dispatch(startAddFigure(FigureData, theOwner)),
    startSetFigure: (id) => dispatch(startSetFigure(id)),
    searchForPaper: (paperDoi, allPapers) => dispatch(searchForPaper(paperDoi, allPapers)),
    startIdentifyPaper: (paperInfo, theOwner) => dispatch(startIdentifyPaper(paperInfo, theOwner)),
    getPaperDois: () => dispatch(getPaperDois())


})

const ConnectedAddFigure = connect(mapStateToProps, mapDispatchToProps)(AddFigure);

export default ConnectedAddFigure;