import React, { Component, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from "react-router-dom"
import { getPaperDois, startIdentifyPaper, searchForPaper } from '../actions/paperModels'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid';



export const AddPaper = (props) => {
    const [unchecked, setUnchecked] = useState(false)
    const [tagsOpen, setTagsOpen] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    let navigate = useNavigate()
    const identifyPaper = async (e) => {
        e.preventDefault()
        let values = []
        for (let i = 0; i < e.target.elements.length; i++) {
            if (e.target.elements[i].checked) {
                let ownerTag = {
                    displayName: e.target.elements[i].name,
                    id: e.target.elements[i].id
                }
                values.push(ownerTag)
            }
        }
        if (values.length == 0) {
            setUnchecked(true)
            return;
        } 
        setSubmitted(true)
        let timeStamp = moment().utc().toString()
        let occurrence = uuidv4();
        const paperData = {
            title: e.target.title.value,
            doi: e.target.doi.value,
            journal: e.target.journal.value,
            owner: props.user,
            ownerTags: values,
            timeStamp: timeStamp,
            figures: [],
            follows: [],
            extractedClaims: [],
            updates: {
                claimExtractions: [],
                comments: [],
                figureUploads: [],
                follows: [],
                identified: [
                    {
                        timeStamp: timeStamp,
                        occurrence: occurrence,
                        id: props.user
                    }
                ]
            }
        }

        let paperID = await props.startIdentifyPaper(paperData, props.user)
        setTimeout(() => {navigate(`/Paper/${paperID}`)}, 100)
    }

    
    return (
        
        <form className="AddFigureForm" onSubmit={identifyPaper}>
            <div hidden={props.loggedIn} className="addFigureLogin" ><a className="anchor" href="/Login">
                Login or create an account</a> to post your own figures or criticisms
            </div>
            <div hidden={!props.loggedIn} className="AddFigureMajorContainer2">
                <h1>Identify a new paper</h1>
                <br/>
                <h2 className='AddClaimTitleHeader'>Paper Title</h2> 
                <br/>
                <div>
                    <textarea name="title" className="halfTextArea" placeholder="Paste the title of the paper here..."  required>
                    </textarea>
                </div>    
                <div id="SourceParent" className="container">
                    <h2 className='AddClaimTitleHeader'>Source</h2>
                    <br/>
                    <div>
                        <textarea name="doi" className="halfTextArea" placeholder="Paste the doi of the paper here..." required></textarea>
                    </div>
                </div>
                <div className="container">
                    <h2 className='AddClaimTitleHeader'>Journal</h2>
                    <br/>
                    <div>
                        <textarea name="journal" className="halfTextArea" placeholder="Describe the new figure here..." required></textarea>
                    </div>
                </div>
                
                <div className="container">
                    <div className="centered">
                        <div id="postAs">
                            <span className="postAsSpan">Post as:  </span>
                            <div className='tags'>
                                <span className='firstCheck'>
                                    <div className="checkBoxDivFirst">
                                        <div className='FigureDiv1'>
                                            <input status='userName' className="checkBox1" type='checkbox' id={props.user}
                                            name={props.userName} value={props.userName}/>
                                            <label className='label'>{props.userName}</label>
                                        </div>
                                        <span className='tagsSpan'>
                                            <Tippy content='Post Tags'>
                                                <svg transform={tagsOpen ? "rotate(180)" : ""} id="tagSVG" onClick={() => setTagsOpen(!tagsOpen)} 
                                                xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                                                    <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/>
                                                </svg>
                                            </Tippy>
                                        </span>
                                    </div>
                                    <div className={tagsOpen ? "postAsDiv" : "hidden"}>
                                        <div>
                                            {
                                                props.tags.map((tag) => {
                                                    return (
                                                        <div key={tag.displayName + '1'} className="checkBoxDiv">
                                                            <input status='tag' key={tag.displayName} name={tag.displayName} id={tag.id} 
                                                            type='checkbox' className="checkBox" />
                                                            <label key={tag.displayName + '2'} className="label">{tag.displayName}</label>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </span>
                            </div>
                            <div className="redFont" hidden={!unchecked} >You must check at least one box!</div> 
                        </div>
                    </div>
                </div>
            </div>
            <div className={props.loggedIn ? "centerButtonDiv" : "hidden"}><button id="SubmitNewFigureButton"><b>Submit New Paper</b></button></div>
            <div className={submitted ? 'loadingBarParent2' : 'hidden'}>
                <div className='block1'></div>
                <div className='block2'></div>
                <div className='block3'></div>
                <div className='block4'></div>
                <div className='block5'></div>
                <div className='block6'></div>
                <div className='block7'></div>
            </div>
        </form>
    )
}

const mapStateToProps = (state, props) => {
    if (state.profModels.length == 1) {
        return {
            user: state.profModels[0].id,
            userName: state.profModels[0].name,
            tags: state.profModels[0].tags,
            loggedIn: true
        };
    } else {
        return {
            user: '',
            userName: '',
            tags: [],
            loggedIn: false
        };
    }
}

const mapDispatchToProps = (dispatch) => ({
    startIdentifyPaper: (paperData, theOwner) => dispatch(startIdentifyPaper(paperData, theOwner)),
    getPaperDois: () => dispatch(getPaperDois())
})

const ConnectedAddPaper = connect(mapStateToProps, mapDispatchToProps)(AddPaper);

export default ConnectedAddPaper;