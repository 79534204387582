import React, { useState, useRef, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { updateProfilePicture } from '../actions/profModels';
import moment from 'moment';
import { db, profileCollection, app, storage } from '../firebase/firebase'
import { doc, setDoc, getDoc, addDoc, deleteDoc } from 'firebase/firestore'
import Spinner from "react-svg-spinner";
import SettingsProfileInformation from './SettingsPageComponents/SettingsProfileInformation2'
import SettingsAnonTags from './SettingsPageComponents/SettingsAnonTags'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';


const MySettings = (props) => {
    const [fellowID, setFellowID] = useState('')
    const [loaded, setLoaded] = useState(true)
    const [profilePic, setProfilePic] = useState(props.imgsrc)
    const fileInputRef = useRef(null);

    function findObjectById(arr, id) {
        return arr.find(obj => obj.id === id);
    }

    
    const handleFileChange = async (event) => {
        setLoaded(false)
        const file = event.target.files[0]; // Get the first file
        if (!file) {
            return;
        }

        try {
            const storageRef = ref(storage, `/profilePictures/${props.user}`)
            let upload = await uploadBytesResumable(storageRef, file)
            const theImgsrc = await getDownloadURL(storageRef)
            //Update the profilePic image source on the user's profile document:
            let profPicUpdate = await props.updateProfilePicture(props.user, theImgsrc)
            setProfilePic(profPicUpdate)
            setLoaded(true)
        } catch (error) {
            console.error(error)
        }
    };

    const handleClick = () => {
        fileInputRef.current.click(); // Programmatically click the hidden file input
    };

    

    useEffect( async () => {
        //Grab the profile data of the fellow
        if (props.user === '') {
            setProfilePic('/Images/Book2.jpg')
            return
        }
        const profileDocRef = doc(db, "Profiles", props.user);
        const profileDoc = await getDoc(profileDocRef);
        const profileData = profileDoc.data()
        setFellowID(props.user)
        if (profileData.imgsrc === '') {
            setProfilePic('/Images/Book2.jpg')
        } else {
            setProfilePic(profileData.imgsrc)
        }
    }, [props.user])



    const [whichTabIsIt, setWhichTabIsIt] = useState('Profile Information')
    
    const whichTab = (tab) => {
        setWhichTabIsIt(tab)
    }
    
    // let optionsRefDiv = useRef();
    // let optionsRefIcon = useRef();

    // useEffect(() => {
    //     let handler = (event) => {
    //         if(!(optionsRefDiv.current.contains(event.target) || optionsRefIcon.current.contains(event.target))) {
    //             setOptionsOpen(false)
    //         }
    //     }
    //     document.addEventListener("mousedown", handler)
    //     return () => {
    //         document.removeEventListener("mousedown", handler)
    //     }
    // })


    return (
        <div>
            <div hidden={props.loggedIn} className="loginNoticeProfile" ><a className="anchor" href="/Login">
                    Login or create your own account</a>
            </div>
            <div className={props.loggedIn ? "spacerProfile" : "hidden"}>
                <div className="headerProfile">
                <img className="SettingsImgProfile" src={profilePic}/>
                    <div className="spacerHeaderProfile">
                        <div className="paddingLeftHeaderProfile">{props.userName}'s Settings</div>
                        <div >
                            <input type="file" style={{ display: 'none' }} ref={fileInputRef} onChange={handleFileChange} accept="image/*"/>
                            <button className='SettingsEditProfButton' onClick={handleClick}>
                                <b className={loaded ? '' : 'hidden'}>Edit Profile Picture</b>
                                <Spinner hidden={loaded}/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={props.loggedIn ? 'center' : 'hidden'}>
                <div className="myClaimsMajorContainer">
                    <div className='myClaimsTabContainer'>
                        <div onClick={() => {whichTab('Profile Information')}} 
                        className={whichTabIsIt == 'Profile Information' ? "myClaimsTabSelected" : "myClaimsTab"}>
                            Profile Information
                        </div>
                        <div onClick={() => {whichTab('AnonTags')}} 
                        className={whichTabIsIt == 'AnonTags' ? "myClaimsTabSelected" : "myClaimsTab"}>
                            Anon-Tags
                        </div>
                    </div>
                    <SettingsProfileInformation whichTabIsIt={whichTabIsIt} loaded={loaded} fellowID={fellowID}/>
                    <SettingsAnonTags whichTabIsIt={whichTabIsIt} loaded={loaded} fellowID={fellowID}/>

                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state, profileProps) => {
    if (state.profModels.length == 1) {
        return { 
            user: state.profModels[0].id,
            userName: state.profModels[0].name,
            tags: state.profModels[0].tags,
            loggedIn: true,
            follows: state.profModels[0].follows,
            imgsrc: state.profModels[0].imgsrc,
            occupation: state.profModels[0].occupation,
            location: state.profModels[0].location
        }
    } else {
        return { 
            user: '',
            userName: '',
            tags: [],
            loggedIn: false,
            follows: [],
            imgsrc: '',
            occupation: '',
            location: ''
        }
    }
}

const mapDispatchToProps = (dispatch, profileProps) => ({
    startSetProfile: (email) => dispatch(startSetProfile(email)),
    updateProfilePicture: (profImgID, theImgsrc) => dispatch(updateProfilePicture(profImgID, theImgsrc))
})

const ConnectedMySettings = connect(mapStateToProps, mapDispatchToProps)(MySettings);

export default ConnectedMySettings;