import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import { storage } from '../firebase/firebase'
import { ref, uploadBytesResumable } from 'firebase/storage'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid';
import { startRequestHelp } from '../actions/profModels'
import Spinner from "react-svg-spinner";
import { useNavigate } from 'react-router-dom'


export const Help = (props) => {
    const [submitted, setSubmitted] = useState(false)
    const [requested, setRequested] = useState(false)

    let LoggedIn;
    if (props.user) {
        LoggedIn = true
    } else {
        LoggedIn = false
    }

    const requestHelp = async (e) => {

        e.preventDefault()
        setSubmitted(true)
        let timeStamp = moment().utc().toString()

        if (requested) {
            setRequested(false)
            setSubmitted(false)
            return;
        }

        try {

            //Create the tagInfo to be included on the new tag document in Firebase.
            let helpRequest = {
                user: props.user,
                timeStamp: timeStamp,
                request: e.target.request.value.trim()
            }

            await props.startRequestHelp(helpRequest)
            setSubmitted(false)
            setRequested(true)
        } catch (error) {
            console.error(error)
        }
        
    }

    return (
        
        <form className="AddFigureForm" onSubmit={requestHelp}>
            <div hidden={LoggedIn} className="addFigureLogin" ><a className="anchor" href="/Login">
                Login or create an account</a> to request help
            </div>
            <div hidden={!LoggedIn} className="AddFigureMajorContainer2">
                <div className={!requested ? '' : 'hidden'}>
                    <h1>Help Request</h1>
                    <br/>
                    <h2 className='ApplyTitleHeader'>Please use the area below to ask questions or describe any issues you are having</h2>
                    <div>
                        <textarea required name='request' className="ApplyFullTextArea" placeholder='Describe the question or issue here...'>
                        </textarea>
                    </div>
                </div>
                <div className={requested ? 'ApplyRequestSubmitted' : 'hidden'}>
                    <div>
                        We will email you shortly regarding your help request. Thank you.
                    </div>
                    <br/>
                    <button className='ApplyResetRequest'>Need Help Again?</button>
                </div>
            </div>

            <div className={LoggedIn ? "centerButtonDiv" : "hidden"}>
                <div className={requested ? 'hidden' : ''}>
                    <button className={!submitted ? 'ApplySubmitNewTag' : 'ApplySubmitNewTagSubmitted'} >
                        <b className={submitted ? 'hidden' : ''}>Submit</b>
                        <Spinner hidden={!submitted}/>
                    </button>
                </div>
            </div>
        </form>
    )
}

const mapStateToProps = (state, props) => {
    if (state.profModels.length == 1) {
        return {
            user: state.profModels[0].id,
            userName: state.profModels[0].name,
            tags: state.profModels[0].tags,
            loggedIn: true
        };
    } else {
        return {
            user: '',
            userName: '',
            tags: [],
            loggedIn: false
        };
    }
}

const mapDispatchToProps = (dispatch) => ({
    startRequestHelp: (helpRequest) => dispatch(startRequestHelp(helpRequest)) 
})

const ConnectedHelp = connect(mapStateToProps, mapDispatchToProps)(Help);

export default ConnectedHelp;