import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from "react-router-dom"
import { startAddCriticism, startRejectFigure,
    reverseRejectFigure, startAcceptFigure, reverseAcceptFigure,
    updateLastViewedFigure, getFigureInfo } from '../actions/figModels';
import Modal from './FigureShowModal'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import Criticism from './CriticismFigure';
import DeleteModal from './deleteModalFigure';
import { history } from '../routers/AppRouter'
import SVGaddFigure from './SVGs/SVGaddFigure';
import SVGDelete from './SVGs/SVGDelete';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';



export const FigureShow = (props) => {

    const checkArrayUp = (array) => {
        let check = false;
        for (let i = 0; i < array.length; i++) {
            if (array[i].id === props.user) {
                check = true;
                setUpVoteEngagement([array[i]])
            } 
        }
        return check;
    }

    const checkArrayDown = (array) => {
        let check = false;
        for (let i = 0; i < array.length; i++) {
            if (array[i].id === props.user) {
                check = true;
                setDownVoteEngagement([array[i]])
            } 
        }
        return check;
    }

    const [upVoteEngagement, setUpVoteEngagement] = useState([{}])
    const [downVoteEngagement, setDownVoteEngagement] = useState([{}])
    
    //Check the figModel to see if the upVotes array contains the ID of the current profModel
    //Check the figModel to see if the downVotes array contains the ID of the current profModel
    const [downVoteFigure, setdownVoteFigure] = useState(false)
    const [upVoteFigure, setupVoteFigure] = useState(false)
    const [criticisms, setTheCriticisms] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const [unchecked, setUnchecked] = useState(false)
    const [optionsOpen, setOptionsOpen] = useState(false)
    const [tagsOpen, setTagsOpen] = useState(false)
    const [deleteModalState, setDeleteModalState] = useState(false)
    const [voteCountC, setVoteCountC] = useState(0)
    const [voteCountX, setVoteCountX] = useState(0)
    const [acceptanceRate, setAcceptanceRate] = useState(0)
    const [uniqueEngagement, setUniqueEngagement] = useState([])
    const [totalEngagement, setTotalEngagement] = useState([])
    const [timeStampPageVisit, setTimeStampPageVisit] = useState()
    const [figureInfo, setFigureInfo] = useState({ associatedClaims:[], ownerTags: [], id: '', imgsrc: ''})
    const [figureThere, setFigureThere] = useState(true)
    const [loaded, setLoaded] = useState(false)
    const [figureDate, setFigureDate] = useState('')
    
    
    
    
    let optionsRef = useRef();

    function getUniqueEngagement (objects) {
        const seenIds = new Set();
        return objects.filter(obj => {
            if (seenIds.has(obj.id)) {
            return false;
            } else {
            seenIds.add(obj.id);
            return true;
            }
        });
    }
    

    function removeEngagement (array1, array2) {
        return array1.filter(obj1 => !array2.some(obj2 => obj1.occurrence === obj2.occurrence));
    }
      

    useEffect(() => {
        let handler = (event) => {
            if(!optionsRef.current.contains(event.target)) {
                setOptionsOpen(false)
            }
        }
        document.addEventListener("mousedown", handler)
        return () => {
            document.removeEventListener("mousedown", handler)
        }
    })

    useEffect(async () => {
        //Get the id of the figure from the URL
        let theSplit = window.location.pathname.split('/')
        let figureID = theSplit[2]
        //Make call to Firebase with returnFigureInfoUpdates function to fetch info off of database.
        let theFigureInfo = await props.getFigureInfo(figureID)
        

        //If info is able to be fetched, set the Figure Info .
        if (theFigureInfo) {
            setFigureInfo(theFigureInfo)
            setLoaded(true)
            setTheCriticisms(theFigureInfo.criticisms)
            setVoteCountC(theFigureInfo.upVotes.length)
            setVoteCountX(theFigureInfo.downVotes.length)
            setTotalEngagement(theFigureInfo.engagement)
            const theDate = new Date(theFigureInfo.timeStamp)
            const newMoment = moment(theDate)
            newMoment.local()
            const localDate = newMoment.format('LLL')
            setFigureDate(localDate)
        } else {
            setFigureThere(false)
            setLoaded(true)
        }

        let timeStamp = moment().utc().toString()
        setTimeStampPageVisit(timeStamp)
        // if (props.loggedIn) {
        //     await props.updateLastViewedFigure(props.user, props.id, timeStamp)
        // }
        setdownVoteFigure(checkArrayDown(theFigureInfo.downVotes))
        setupVoteFigure(checkArrayUp(theFigureInfo.upVotes))
    }, [window.location.pathname])

    useEffect(() => {
        let upVotes = voteCountC
        let downVotes = voteCountX
        let calculatedAcceptanceRate = Math.round(upVotes/(downVotes + upVotes)*100*100/100)
        if (isNaN(calculatedAcceptanceRate)) {
            calculatedAcceptanceRate = "?"
        }
        setAcceptanceRate(calculatedAcceptanceRate)
        

    }, [voteCountC, voteCountX])

    useEffect(() => {
        let theUniqueEngagement = getUniqueEngagement(totalEngagement)
        setUniqueEngagement(theUniqueEngagement)
    }, [totalEngagement])

    //Code to reject the figure
    const rejectFigure = (e) => {
        e.preventDefault()
        if (!props.loggedIn) {
            return;
        }

        let timeStamp = moment().utc().toString()
        let occurrence = uuidv4();

        const Vote = {
            figureID: figureInfo.id,
            user: props.user,
            imgsrc: figureInfo.imgsrc,
            timeStamp: timeStamp,
            occurrence: occurrence,
            lastViewed: timeStampPageVisit
        }

        let newEngagement = {
            id: props.user,
            occurrence: occurrence,
            timeStamp: timeStamp
        }

        if (downVoteFigure) {
            setdownVoteFigure(false)
            setVoteCountX(prev => prev - 1)
            setTotalEngagement(prev => removeEngagement([...prev], [...downVoteEngagement]))
            setDownVoteEngagement([{}])
            props.reverseRejectFigure(Vote).then(() => {
                
            }).catch(() => {
                // console.log('figure not un-rejected')
            })
        } else {
            setdownVoteFigure(true)
            if (upVoteFigure) {
                setTotalEngagement(prev => removeEngagement([...prev], [...upVoteEngagement]))
                setVoteCountC(prev => prev - 1)
                setUpVoteEngagement([])
            }
            setupVoteFigure(false)
            setVoteCountX(prev => prev + 1)
            setDownVoteEngagement([newEngagement])
            setTotalEngagement(prev => [...prev, newEngagement])
            props.startRejectFigure(Vote).then(() => {
                if (upVoteFigure) {
                    props.reverseAcceptFigure(Vote).then(() => {
                        
                    }).catch(() => {
                        // console.log('figure not un-accepted')
                    })
                }
            }).catch(() => {
                // console.log('figure not rejected')
            })
        }
    }

    //Code to accept the figure
    const acceptFigure = (e) => {
        e.preventDefault()

        if (!props.loggedIn) {
            return;
        }

        let timeStamp = moment().utc().toString()
        let occurrence = uuidv4();

        const Vote = {
            figureID: figureInfo.id,
            user: props.user,
            imgsrc: figureInfo.imgsrc,
            timeStamp: timeStamp,
            occurrence: occurrence,
            lastViewed: timeStampPageVisit
        }

        let newEngagement = {
            id: props.user,
            occurrence: occurrence,
            timeStamp: timeStamp
        }

        if (upVoteFigure) {
            setupVoteFigure(false)
            setVoteCountC(prev => prev - 1)
            setTotalEngagement(prev => removeEngagement([...prev], [...upVoteEngagement]))
            setUpVoteEngagement([])
            props.reverseAcceptFigure(Vote).then(() => {
                
            }).catch(() => {
                // console.log('figure not un-accepted')
            })
        } else {
            setdownVoteFigure(false)
            if (downVoteFigure) {
                setTotalEngagement(prev => removeEngagement([...prev], [...downVoteEngagement]))
                setVoteCountX(prev => prev - 1)
                setDownVoteEngagement([])
            }
            setupVoteFigure(true)
            setVoteCountC(prev => prev + 1)
            setUpVoteEngagement([newEngagement])
            setTotalEngagement(prev => [...prev, newEngagement])
            props.startAcceptFigure(Vote).then(() => {
                if(downVoteFigure) {
                    props.reverseRejectFigure(Vote).then(() => {

                    }).catch(() => {
                        // console.log('figure not un-rejected')
                    })
                }
            }).catch(() => {
                // console.log('figure not accepted')
            })
        }
    }
    const navigate = useNavigate()
    
    //Code for adding and deleteing Criticisms here
    const addCriticism = (e) => {
        e.preventDefault()
        let values = []
        let anonymous = true
        for (let i = 0; i < e.target.elements.length; i++) {
            if (e.target.elements[i].checked) {
                let ownerTag = {
                    displayName: e.target.elements[i].name,
                    id: e.target.elements[i].id
                }
                values.push(ownerTag)
                if (ownerTag.displayName == props.userName) {
                    anonymous = false
                }
            }
        }
        if (values.length == 0) {
            setUnchecked(true)
            return;
        } 
        let timeStamp = moment().utc().toString()
        let theOccurrence = uuidv4()
        const newCriticism = {
            figureID: figureInfo.id,
            owner: props.user,
            ownerTags: values,
            critDownVotes: [],
            critUpVotes: [],
            text: e.target.critText.value,
            imgsrc: figureInfo.imgsrc,
            timeStamp: timeStamp,
            occurrence: theOccurrence,
            anonymous: anonymous
        }
        let newEngagement = {
            id: props.user,
            occurrence: theOccurrence,
            timeStamp: timeStamp
        }
        setTotalEngagement(prev => [...prev, newEngagement])
        e.target.critText.value = ''
        setTheCriticisms(prev => [...prev, newCriticism])
        setUnchecked(false)
        props.startAddCriticism(newCriticism).then(() => {
            
        }).catch((err) => {
            // console.log(err)
        })
    }
    
    const reportFigure = () => {
        navigate('/Report/Figure/' + figureInfo.id)
    }

    function removeSubstring(originalString) {
        let split = originalString.split('/')
        let theURL = '/' + split[3] + '/' + split[4]
        return theURL;
    }

    function handleClick(event) {
        event.preventDefault();
        let URL = removeSubstring(event.target.href, event.target.baseURI)
        navigate(URL)
    }
    
    return (  
        <div className="figureShow">
        {openModal && <Modal closeModal={setOpenModal} src={figureInfo.imgsrc} />}
        {deleteModalState && <DeleteModal critText={figureInfo.text} id={figureInfo.id} imgsrc={figureInfo.imgsrc} 
        closeModal={setDeleteModalState} element='figure' user={props.user}/>}

            <div hidden={props.loggedIn} className="loginNotice" ><a className="anchor" href="/Login">
                Login or create an account</a> to interact with figures or upload your own
            </div>
            <h1 className="notFound" hidden={!(!figureThere)}>Error: Figure does not exist or was deleted</h1>
            <div className={figureThere ? "majorContainer" : "hidden"}>
                <div className="figurePageLeft">
                    <div>
                        <h1>{figureInfo.title}</h1>    
                    </div>
                    <div className={!figureInfo.fromPaper ? 'hidden' : 'fromPaper'}><b>Source: <a className="paperAnchor" 
                    href={'/Paper/' + figureInfo.paperID} onClick={handleClick}>
                        Paper</a></b>
                    </div>
                    <div hidden={!figureInfo.fromPaper} id="doi"><b>DOI: </b>
                        <a className="anchor" href={figureInfo.paperDoi} >{figureInfo.paperDoi}</a>
                    </div>
                    <div className='divAbove2Figure'>
                        <div className='addedByFigure'><b>Uploaded by: </b></div>
                        {
                            figureInfo.ownerTags.map((tag, index) => {
                                return (<a key={tag.displayName + index} className="anchorNameClaim" href={'/Profile/' + tag.id }
                                onClick={handleClick}>
                                    {tag.displayName}
                                </a>)
                            })
                        }
                    </div>
                    <div className='divAbove3Theory'><b>- {figureDate}</b></div>
                    <br/>
                    <div className="figureContainer">
                        <div hidden={!props.loggedIn} ref={optionsRef} className='topBarFigure'>
                            <div className='centerVertical'>
                                <div className='verticalStrecthFigure'>
                                    <div><b>Acceptance Rate: {acceptanceRate}%</b></div>
                                    <div><b>Engagement: {uniqueEngagement.length}</b></div>
                                </div>
                            </div>
                            <div>
                                <Tippy content='Options'>
                                    <svg hidden={!props.loggedIn} id="input5" onClick={() => setOptionsOpen(!optionsOpen)} version="1.1" 
                                    viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                                        <svg width="288" height="288" enableBackground="new 0 0 32.055 32.055" viewBox="0 0 32.055 32.055" 
                                        xmlns="http://www.w3.org/2000/svg">
                                            <path className="color000 svgShape" d="m3.968 12.061c-2.193 0-3.968 1.774-3.968 3.966s1.773 3.967 3.968 3.967c2.189 0 3.966-1.772 3.966-3.967 0-2.192-1.777-3.966-3.966-3.966zm12.265 0c-2.188 0-3.968 1.773-3.968 3.965s1.778 3.967 3.968 3.967 3.97-1.772 3.97-3.967c-2e-3 -2.191-1.78-3.965-3.97-3.965zm11.857 0c-2.192 0-3.969 1.774-3.969 3.967 0 2.19 1.774 3.965 3.969 3.965 2.188 0 3.965-1.772 3.965-3.965s-1.777-3.967-3.965-3.967z" fill="#002c2c"/>
                                        </svg>
                                    </svg>
                                </Tippy>
                                <div hidden={!optionsOpen} className='dropDownFigure'>
                                    <div className='optionsAnchorDiv' hidden={props.user != figureInfo.owner} 
                                    onClick={() => {navigate('/AddFigure')}}>
                                        <SVGaddFigure/>
                                        <a><b>Add a figure</b></a>
                                    </div>
                                    <div className={figureInfo.owner == props.user ? 'optionsAnchorDiv' : 'hidden'} 
                                    onClick={() => setDeleteModalState(true)}>
                                        <SVGDelete/>
                                        <a><b>Delete this figure</b></a>
                                    </div>
                                    <div className='optionsAnchorDiv' onClick={reportFigure}>
                                        <svg className='marginSVG' width="20" height="20" viewBox="0 0 24 24" 
                                        xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 
                                            0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z" fill="#002c2c"/>
                                        </svg>
                                        <a><b>Report this figure</b></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img onClick={() => {setOpenModal(true)}} className={loaded ? "figureShowImage" : 'hidden'} 
                        src={figureInfo.imgsrc} alt="Figure image not found..."/>
                        <div className="theFlex">
                            <Tippy content='Reject figure'>
                                <div className="supportFigButtons" onClick={rejectFigure}>
                                    <svg id={downVoteFigure ? "input3Clicked" : "input3"} 
                                    version="1.1" viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                                        <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path className="color000 svgShape" d="m24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" 
                                            fill={downVoteFigure ? "#FFFFFF" : "#002c2c"}/>
                                        </svg>
                                    </svg>
                                    {voteCountX}
                                </div>
                            </Tippy>
                            <Tippy content='Accept figure'>
                                <div className="supportFigButtons" onClick={acceptFigure}>
                                    <svg  id={upVoteFigure ? "input3Clicked" : "input3"} 
                                    version="1.1" viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                                        <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path className="color000 svgShape" d="m20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" fill={upVoteFigure ? "#FFFFFF" : "#002c2c"}/>
                                        </svg>
                                    </svg>
                                    {voteCountC}
                                </div>
                            </Tippy>
                        </div>
                    </div>
                    <br/>
                    <div id="description">
                        <h2>Description</h2>
                        <br/>
                        <p>{figureInfo.description}</p>
                    </div>
                    <br/>
                    <div id="methods">
                        <h2>Methods</h2>
                        <br/>
                        <p>{figureInfo.methods}</p>
                    </div>
                </div>
                <div className='figurePageRightContainer'>
                    <div className="figurePageRight">
                        <div id="Criticisms">
                            <h1>Criticisms</h1> 
                        </div>  
                        <div className='criticismsDiv'>
                            {
                                criticisms.map((crit, index) => {
                                    return (
                                        <Criticism key={crit.text} ownerTags={crit.ownerTags} text={crit.text} downvotes={crit.critDownVotes}
                                        owner={crit.owner} imgsrc={figureInfo.imgsrc} id={figureInfo.id} loggedin={props.loggedIn} user={props.user}
                                        upvotes={crit.critUpVotes} timeStamp={crit.timeStamp} occurrence={crit.occurrence}
                                        criticisms={[...criticisms]} setTheCriticisms={setTheCriticisms} index={index}
                                        totalEngagement={totalEngagement} setTotalEngagement={setTotalEngagement} 
                                        removeEngagement={removeEngagement}/>
                                    )   
                                })
                            }
                        </div>
                        <div hidden={criticisms.length != 0} className={loaded ? "noCriticismsYetFigure" : 'hidden'}>
                            No criticisms of this figure have been made yet...
                        </div>
                        <div className='line3CriticismsFigure' hidden={criticisms.length == 0 || !props.loggedIn}></div>
                        <form className={loaded ? 'formDiv' : 'hidden'} hidden={!props.loggedIn} onSubmit={addCriticism}>
                            <div className='addCritDiv'>
                                <textarea  required={true} id="newCrit" 
                                placeholder="Add your own criticism..." name="critText"></textarea>
                                <label>
                                <input hidden={true} type="submit"/>
                                <div className='centerTheAddButton'>
                                <div className='addDiv'>
                                    <svg version="1.1" viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                                        <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path className="color000 svgShape" d="m24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" fill="#fff"/>
                                        </svg>
                                    </svg>
                                </div>
                                </div>
                                </label>
                            </div>
                            <div id="postAs">
                                <span className="postAsSpan">Post as:  </span>
                                <div className='tags'>
                                    
                                    <span className='firstCheck'>
                                        <div className="checkBoxDivFirst">
                                            <div className='FigureDiv1'>
                                                <input status='userName' className="checkBox1" type='checkbox' id={props.user} name={props.userName} 
                                                value={props.userName}/>
                                                {props.userName}
                                            </div>
                                            <span className='tagsSpan'>
                                                <Tippy content='Post Tags'>
                                                    <svg transform={tagsOpen ? "rotate(180)" : ""} id="tagSVG" onClick={() => setTagsOpen(!tagsOpen)} 
                                                    xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                                                        <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/>
                                                    </svg>
                                                </Tippy>
                                            </span>
                                        </div>

                                        <div className={tagsOpen ? "postAsDiv" : "hidden"}>
                                            <div>
                                                {
                                                    props.tags.map((tag, index) => {
                                                        return (
                                                            <div key={tag.displayName + index + 'div'} className="checkBoxDiv">
                                                                <input status='tag' key={tag.displayName + 'input'} name={tag.displayName} id={tag.id} 
                                                                type='checkbox' className="checkBox" />
                                                                <label key={tag.displayName + index + 'label'} className="label">{tag.displayName}
                                                                </label>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </form>
                        <div className="redFontFigure" hidden={!unchecked} >You must check at least one box!</div> 
                    </div>
                    <div className="figureCritRight">
                        <div id="AssociatedClaims">
                            <h1>Associated Claims</h1> 
                            { figureInfo.associatedClaims.map((claim, index) => {
                                return <div key={index} className='associatedClaimsList'>
                                            <a className='associatedClaimLink' href={'/Claim/' + claim.id} onClick={handleClick}
                                            key={claim + index}>- {claim.title}{' (' + claim.direction + 'ing)'}</a>
                                        </div>
                            }) }
                            <div hidden={figureInfo.associatedClaims.length != 0} className={loaded ? '' : 'hidden'}>
                                This figure has not been connected to any claims yet...
                            </div>
                        </div>
                        <br/>
                    </div>
                </div>    
            </div>
        </div>
    ) 
}

const mapStateToProps = (state, props) => {
    if (state.profModels.length == 1) {
        return { 
            user: state.profModels[0].id,
            userName: state.profModels[0].name,
            tags: state.profModels[0].tags,
            loggedIn: true,
            myFigures: state.profModels[0].myFigures
        }
    } else {
        return { 
            user: '',
            userName: '',
            tags: [],
            loggedIn: false,
            myFigures: []
        }
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    startAddCriticism: (criticism) => dispatch(startAddCriticism(criticism)),
    startRejectFigure: (downVote) => dispatch(startRejectFigure(downVote)),
    reverseRejectFigure: (downVote) => dispatch(reverseRejectFigure(downVote)),
    startAcceptFigure: (upVote) => dispatch(startAcceptFigure(upVote)),
    reverseAcceptFigure: (upVote) => dispatch(reverseAcceptFigure(upVote)),
    updateLastViewedFigure: (userID, figureID, timeStamp) => dispatch(updateLastViewedFigure(userID, figureID, timeStamp)),
    getFigureInfo: (figureID) => dispatch(getFigureInfo(figureID))

})

const ConnectedFigureShow = connect(mapStateToProps, mapDispatchToProps)(FigureShow);

export default ConnectedFigureShow;