import React from "react";


function SVGlogout() {
  return (
    
    <svg   viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className='marginSVG'  width="20" height="20">
        <path fill="#002c2c" d="m24 22h-20v-7h2v5h16v-16h-16v5h-2v-7h20v20zm-13-11v-4l6 5-6 5v-4h-11v-2h11z"/>
    </svg>
  );
}

export default SVGlogout;