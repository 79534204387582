import React, { useState, useEffect } from 'react'
import { BrowserRouter, Route, Routes, NavLink, Link } from 'react-router-dom';
import Header from '../elements/NewHeader2'
import AddFigure from '../elements/AddFigure'
import Login from '../elements/LoginFinal'
import FigureShow from '../elements/Figure';
import Claim from '../elements/Claim2'
import Theory from '../elements/Theory3'
import ConnectClaim from '../elements/ConnectClaim'
import createHistory from 'history/createBrowserHistory';
import MyClaims from '../elements/myClaims2'
import MyFigures from '../elements/myFigures'
import MyTheories from '../elements/myTheories'
import MyPapers from '../elements/myPapers'
import MyProfile from '../elements/myProfile'
import AddTheory from '../elements/AddTheory5'
import Register from '../elements/Register2';
import PasswordReset from '../elements/PasswordReset';
import AddClaim from '../elements/AddClaim';
import About from '../elements/about'
import AboutAnonTags from '../elements/AboutAnonTags'
import Profile from '../elements/Profile2'
import AnonTag from '../elements/AnonTag'
import Home from '../elements/Homev2'
import AddPaper from '../elements/AddPaper'
import Paper from '../elements/Paper'
import MySettings from '../elements/mySettings'
import ApplyAnonymous from '../elements/ApplyAnonymousCredentials'
import Help from '../elements/Help'
import Report from '../elements/Report'

export const history = createHistory();
// console.log(history)
const AboutPage = (props) => (
    <div>
        <Header />
        <About />
    </div>
);
  
const AboutAnontagsPage = (props) => (
    <div>
        <Header />
        <AboutAnonTags />
    </div>
);

const AddFigurePage = (props) => (
    <div>
        <Header />
        <AddFigure />
    </div>
)

const AddPaperPage = (props) => (
    <div>
        <Header />
        <AddPaper />
    </div>
)
const AddTheoryPage = (props) => (
    <div>
        <Header />
        <AddTheory />
    </div>
)

const TheoryPage = (props) => (
    <div>
        <Header />
        <Theory />
    </div>
);

const PaperPage = (props) => (
    <div>
        <Header />
        <Paper />
    </div>
);

const FigureShowPage = (props) => (
    <div>
        <Header />
        <FigureShow />
    </div>
)

const LoginPage = (props) => (
    <div>
        <Login />
    </div>
);

const ClaimPage = (props) => (
    <div>
        <Header myPage='Claim'/>
        <Claim />
    </div>
);



const RegisterPage = (props) => (
    <div>
        <Register />
    </div>
);

const ConnectClaimPage = (props) => (
    <div>
        <Header />
        <ConnectClaim />
    </div>
);

const myClaimsPage = (props) => (
    <div>
        <Header myPage='myClaims'/>
        <MyClaims />
    </div>
)

const myFiguresPage = (props) => (
    <div>
        <Header myPage='myFigures'/>
        <MyFigures />
    </div>
)

const AddClaimPage = (props) => (
    <div>
        <Header />  
        <AddClaim />
    </div>
)


const myPapersPage = (props) => (
    <div>
        <Header myPage='myPapers'/>
        <MyPapers />
    </div>
)

const myTheoriesPage = (props) => (
    <div>
        <Header myPage='myTheories'/>
        <MyTheories />
    </div>
)

const myProfilePage = (props) => (
    <div>
        <Header/>
        <MyProfile />
    </div>
)

const mySettingsPage = (props) => (
    <div>
        <Header/>
        <MySettings />
    </div>
)

const ProfilePage = (props) => (
    <div>
        <Header/>
        <Profile />
    </div>
)

const AnonTagPage = (props) => (
    <div>
        <Header/>
        <AnonTag />
    </div>
)

const HomePage = (props) => (
    <div>
        <Header/>
        <Home />
    </div>
)

const PasswordResetPage = (props) => (
    <div>
        <PasswordReset />
    </div>
)


const ApplyAnonymousPage = (props) => (
    <div>
        <Header />
        <ApplyAnonymous />
    </div>
)

const HelpPage = (props) => (
    <div>
        <Header />
        <Help />
    </div>
)

const ReportPage = (props) => (
    <div>
        <Header />
        <Report />
    </div>
)


const page404 = () => (
    <div>
        404 - Page not found - <Link to="/">Go Home</Link>
    </div>
);

// console.log('app router ran')

const AppRouter = () => {
    return (
        <BrowserRouter history={history}>
            <div>
                <Routes>
                <Route exact path="/" element={HomePage()}/>
                    <Route exact path="/About" element={AboutPage()}/>
                    <Route exact path="/About/Anon-tags" element={AboutAnontagsPage()}/>
                    <Route exact path="/Help" element={HelpPage()}/>
                    <Route path="/Register" element={RegisterPage()} />
                    <Route path="/AddFigure" element={AddFigurePage()}/>
                    <Route path="/AddClaim" element={AddClaimPage()}/>
                    <Route path="/AddPaper" element={AddPaperPage()}/>
                    <Route path="/Login" element={LoginPage()} />
                    <Route path="/AddTheory/:id" element={AddTheoryPage()} />
                    <Route path="/Theory/:id" element={TheoryPage()} />
                    <Route path="/Figure/:id" element={FigureShowPage()} />
                    <Route path="/Report/Figure/:id" element={ReportPage()} />
                    <Route path="/Report/Theory/:id" element={ReportPage()} />
                    <Route path="/Report/Claim/:id" element={ReportPage()} />
                    <Route path="/Report/Paper/:id" element={ReportPage()} />
                    <Route path="/Report/Fellow/:id" element={ReportPage()} />
                    <Route path="/Claim/:id" element={ClaimPage()} />
                    <Route path="/ConnectClaim/:id" element={ConnectClaimPage()} />
                    <Route path="/myClaims" element={myClaimsPage()} />
                    <Route path="/myFigures" element={myFiguresPage()} />
                    <Route path="/myTheories" element={myTheoriesPage()} />
                    <Route path="/myProfile" element={myProfilePage()} />
                    <Route path="/myPapers" element={myPapersPage()} />
                    <Route path="/mySettings" element={mySettingsPage()} />
                    <Route path="/Profile/:id" element={ProfilePage()} />
                    <Route path="/AnonTag/:id" element={AnonTagPage()} />
                    <Route path="/Paper/:id" element={PaperPage()} />
                    <Route path="/PasswordReset" element={PasswordResetPage()} />
                    <Route path="/ApplyForAnonymousCredentials" element={ApplyAnonymousPage()} />
                    <Route path="*" element={page404()}/>
                </Routes>  
            </div>
        </BrowserRouter>
    )
}
export default AppRouter;