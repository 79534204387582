import React, { useEffect, useState, useRef } from "react";


function SVGGeneralProfileEdit(props) {
  
  const newFunc = () => {
      //If generalEdit is true, it means we need to save the updates that the user has made to their profile.
      if (props.generalEdit) {
        //Call the saveProfileEdits function that was passed in.
        props.saveProfileEdits()
      }
      props.setGeneralEdit(!props.generalEdit)

  }
  return (
    <div className='SettingsSVGEdit' onClick={() => {newFunc()}}>
        <div className='SettingsEditText'><b>{props.generalEdit ? 'Save' : 'Edit'}</b></div>
        <svg className={!props.generalEdit ? "SettingsSVGEditIcon" : 'hidden'} clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" width="17" height="17" xmlns="http://www.w3.org/2000/svg">
            <path d="m9.134 19.319 11.587-11.588c.171-.171.279-.423.279-.684 0-.229-.083-.466-.28-.662l-3.115-3.104c-.185-.185-.429-.277-.672-.277s-.486.092-.672.277l-11.606 11.566c-.569 1.763-1.555 4.823-1.626 5.081-.02.075-.029.15-.029.224 0 .461.349.848.765.848.511 0 .991-.189 5.369-1.681zm-3.27-3.342 2.137 2.137-3.168 1.046zm.955-1.166 10.114-10.079 2.335 2.327-10.099 10.101z" fill="white" fillRule="nonzero"/>
        </svg>
        <svg className={props.generalEdit ? "SettingsSVGSaveIcon" : 'hidden'} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24">
          <path d="M14 3h2.997v5h-2.997v-5zm9 1v20h-22v-24h17.997l4.003 4zm-17 5h12v-7h-12v7zm14 4h-16v9h16v-9z" fill="white"/>
        </svg>
    </div>
  );
}

export default SVGGeneralProfileEdit;