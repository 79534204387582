import React, { useState } from "react";
import { connect } from 'react-redux'
import { startRemoveConnection } from '../actions/claimModels'
import Spinner from "react-svg-spinner";



//This modal is for removing figure connections from claims.
function DeleteModal (props) {
    
    const [loaded, setLoaded] = useState(true)
    function removeElementAtIndex(index, array) {
        // Check if the index is within the bounds of the array
        if (index > -1 && index < array.length) {
            array.splice(index, 1);
        } else {
            // console.log("Index out of bounds");
        }
        return array;
    }

    function removeObjectById(idToRemove, array) {
        // Filter the array to exclude any object whose id matches idToRemove
        const resultArray = array.filter(object => object.id !== idToRemove);
        return resultArray;
    }    

    const removeConnection = async () => {
        setLoaded(false)
        //Reset the voting tallies:
        
        try {
            await props.startRemoveConnection(props.claimID, props.figid, props.direction)
            if (props.direction === "supporting") {
                props.setDoesNotSupportTally(props.doesNotSupportTally - props.voteCountX)
                props.setSomewhatSupportsTally(props.somewhatSupportsTally - props.voteCountC)
                props.setReallySupportsTally(props.reallySupportsTally - props.voteCountCC)
                props.setSupportFigsToShow(prev => removeObjectById(props.figid, prev))
            } else if (props.direction === "contradicting") {
                props.setDoesNotContradictTally(props.doesNotContradictTally - props.voteCountX)
                props.setSomewhatContradictsTally(props.somewhatContradictsTally - props.voteCountC)
                props.setReallyContradictsTally(props.reallyContradictsTally - props.voteCountCC)
                props.setContraFigsToShow(prev => removeObjectById(props.figid, prev))
            }

        } catch (error) {
            // console.log(error)
        }



    }
    
    return (
        <div>
            <div onClick={() => {props.closeModal(false)}} className="blackBackground"></div>
            <div className='deleteModal'>
                <div className="deleteFlex">
                    <h1 id="areYouSureModalHead">Disconnect?</h1>
                    <svg onClick={() => {props.closeModal(false)}} className="deleteX" version="1.1" viewBox="0 0 288 288" 
                    xmlns="http://www.w3.org/2000/svg">
                        <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path className="color000 svgShape" d="m24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" 
                            fill="#002c2c"/>
                        </svg>
                    </svg>
                </div>
                <div className='withinDelete'>
                    <div>Are you sure you would like to disconnect this figure?</div>
                    
                </div>
                <div className='ClaimButtonFlexEnd'>
                    <button className={loaded ? "disconnectFigureButton" : "disconnectFigureButtonClicked"} onClick={() => {removeConnection()}}>
                        <span className={loaded ? "" : "hidden"}>Disconnect</span>
                        <Spinner hidden={loaded}/>
                    </button>
                </div>

            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch, props) => ({
    startRemoveConnection: (claimID, figureID, direction) => dispatch(startRemoveConnection(claimID, figureID, direction))
})

const DeleteModalConnected = connect(undefined, mapDispatchToProps)(DeleteModal);


export default DeleteModalConnected