import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { storage } from '../firebase/firebase'
import { ref, uploadBytesResumable } from 'firebase/storage'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid';
import { startReportIssue } from '../actions/profModels'
import Spinner from "react-svg-spinner";
import { useNavigate } from 'react-router-dom'


export const Report = (props) => {
    const [submitted, setSubmitted] = useState(false)
    const [requested, setRequested] = useState(false)
    const [id, setID] = useState('')
    const [type, setType] = useState('')

    useEffect(() => {
        let URLpath = window.location.href
        let split = URLpath.split('/')
        setID(split[5])
        setType(split[4])
    }, [])

    const requestHelp = async (e) => {
        e.preventDefault()
        setSubmitted(true)
        let timeStamp = moment().utc().toString()
        if (requested) {
            setRequested(false)
            setSubmitted(false)
            return;
        }
        try {
            let reportRequest = {
                user: props.user,
                timeStamp: timeStamp,
                request: e.target.request.value.trim(),
                type: type,
                id: id
            }

            let report = await props.startReportIssue(reportRequest)
            
            setSubmitted(false)
            setRequested(true)
        } catch (error) {
            // console.error(error)
        }
        
    }

    return (
        
        <form className="AddFigureForm" onSubmit={requestHelp}>
            <div hidden={props.loggedIn} className="addFigureLogin" ><a className="anchor" href="/Login">
                Login or create an account</a> to request help
            </div>
            <div hidden={!props.loggedIn} className="AddFigureMajorContainer2">
                <div className={!requested ? '' : 'hidden'}>
                    <h1>Report an issue with this {type}</h1>
                    <br/>
                    <h2 className='ApplyTitleHeader'>Please use the area below to describe the issue</h2>
                    <div>
                        <textarea required name='request' className="ApplyFullTextArea" placeholder='Describe the question or issue here...'>
                        </textarea>
                    </div>
                </div>
                <div className={requested ? 'ApplyRequestSubmitted' : 'hidden'}>
                    <div>
                        We will take a look at the {type} and email you shortly regarding the report. Thank you.
                    </div>
                </div>
            </div>

            <div className={props.loggedIn ? "centerButtonDiv" : "hidden"}>
                <div className={requested ? 'hidden' : ''}>
                    <button className={!submitted ? 'ApplySubmitNewTag' : 'ApplySubmitNewTagSubmitted'} >
                        <b className={submitted ? 'hidden' : ''}>Submit</b>
                        <Spinner hidden={!submitted}/>
                    </button>
                </div>
            </div>
        </form>
    )
}

const mapStateToProps = (state, props) => {
    if (state.profModels.length == 1) {
        return {
            user: state.profModels[0].id,
            userName: state.profModels[0].name,
            tags: state.profModels[0].tags,
            loggedIn: true
        };
    } else {
        return {
            user: '',
            userName: '',
            tags: [],
            loggedIn: false
        };
    }
}

const mapDispatchToProps = (dispatch) => ({
    startReportIssue: (reportRequest) => dispatch(startReportIssue(reportRequest)) 
})

const ConnectedReport = connect(mapStateToProps, mapDispatchToProps)(Report);

export default ConnectedReport;