import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from "react-router-dom"
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import SVGoptionsDots from './SVGs/SVGoptionsDots';
import SVGoptionsDotsMyPapers from './SVGs/SVGoptionsDotsMyPapers';
import { history } from '../routers/AppRouter'
import useWindowDimensions from '../custom hooks/windowDimensions'
import { returnPaperInfo } from '../actions/paperModels'
import Spinner from "react-svg-spinner";
import moment from 'moment'




export const myPapers = (props) => {
    const [whichTabIsIt, setWhichTabIsIt] = useState('Identified Papers')
    const [numberOfExtractedClaims, setNumberOfEtractedClaims] = useState([])
    const [numberOfFigures, setNumberOfFigures] = useState([])
    const [paperTitles, setPaperTitles] = useState([])
    const [paperMoments, setPaperMoments] = useState([])
    const [paperTimeStamps, setPaperTimeStamps] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [papersListIdentified, setPapersListIdentified] = useState(props.myPapers.identifiedPapers)
    const [papersListFollowed, setPapersListFollowed] = useState(props.myPapers.followedPapers)
    const [papersListCurrent, setPapersListCurrent] = useState(props.myPapers.identifiedPapers)
    const [sortBySelector, setSortBySelector] = useState('None')
    const [selectorOpen, setSelectorOpen] = useState(false)
    const [redo, setRedo] = useState(false)
    let selectorRef = useRef();
    const navigate = useNavigate()


    let loaded2;
    if (props.myPapers != []) {
        loaded2 = false
    } else {
        loaded2 = true
    }
    
    // let noReload;
    // if (history.location.pathname != window.location.pathname) {
    //     noReload = false;
    //     window.location.reload(false);
    // } else {
    //     noReload = true;
    // }

    const whichTab = (tab) => {
        if (loaded != true) {
            return;
        }
        setLoaded(false)
        if (tab === 'Identified Papers') {
            setPapersListCurrent(papersListIdentified)
            setSortBySelector('None')
        } else if (tab === 'Followed Papers') {
            setPapersListCurrent(papersListFollowed)
            setSortBySelector('None')
        }
        setWhichTabIsIt(tab)
    }

    const based = (index, id) => {
        let newArray1 = [...papersListCurrent]
        let newArray2 = [...numberOfExtractedClaims]
        let newArray3 = [...paperTitles]
        let newArray4 = [...numberOfFigures]
        let newArray5 = [...paperMoments]
        let newArray6 = [...paperTimeStamps]

        newArray1.splice(index, 1)
        newArray2.splice(index, 1)
        newArray3.splice(index, 1)
        newArray4.splice(index, 1)
        newArray5.splice(index, 1)
        newArray6.splice(index, 1)

        //If we are in the Identified Papers tab, this method will delete the paper when called.
        if (whichTabIsIt === 'Identified Papers') {
            let newArray7 = [...papersListIdentified]
            let nextList = newArray7.filter((paper) => {
                return paper.id !== id
            })
            setPapersListIdentified(nextList)
            //Also check to see if the paper is in the AcceptedFigures or RejectedFigures and remove if it is.
            if (papersListFollowed.includes(id)) {
                let newArray = papersListFollowed.filter((paper) => {
                    return paper.id !== id;
                })
                setPapersListFollowed(newArray)
            }
        }

        //If we are in the Followed Papers tab, this method will un-follow the paper when called.
        if (whichTabIsIt === 'Followed Papers') {
            let newArray7 = [...papersListFollowed]
            let nextList = newArray7.filter((paper) => {
                return paper.id !== id
            })
            setPapersListFollowed(nextList)
        }


        setPapersListCurrent(newArray1)
        setNumberOfEtractedClaims(newArray2)
        setPaperTitles(newArray3)
        setNumberOfFigures(newArray4)
        setPaperMoments(newArray5)
        setPaperTimeStamps(newArray6)

    }

   
    useEffect(() => {
        let handler = (event) => {
            if(!selectorRef.current.contains(event.target)) {
                setSelectorOpen(false)
            }
        }
        document.addEventListener("mousedown", handler)
        return () => {
            document.removeEventListener("mousedown", handler)
        }
    })

    useEffect(async () => {
        let theExtractedClaims = []
        let theTitles = []
        let theFigures = []
        let theMoments = []
        let paperTimeStamps = []
        for (const paper of papersListCurrent) {
            let paperInfo = await props.returnPaperInfo(paper.id)
            theTitles.push(paperInfo.title)
            theFigures.push(paperInfo.numberOfFigures)
            theMoments.push(paperInfo.moment)
            paperTimeStamps.push(paperInfo.timeStamp)
            theExtractedClaims.push(paperInfo.numberOfExtractedClaims)
        }
        setNumberOfEtractedClaims(theExtractedClaims)
        setPaperTitles(theTitles)
        setNumberOfFigures(theFigures)
        setPaperMoments(theMoments)
        setPaperTimeStamps(paperTimeStamps)
        setLoaded(true)
        
    }, [whichTabIsIt, redo])
    
    const openSelector = () => {
        setSelectorOpen(!selectorOpen)
    }

    const sortPapersByExtractedClaims = () => {
        //if Extracted Claims is already selected, don't do anything.
        if (sortBySelector === 'Extracted Claims') {
            setSelectorOpen(false)
            return;
        }
        
        //combine all data into array of objects for easy sorting.
        let allArrayData = []
        for (let i = 0; i < paperTitles.length; i++) {
            let item = {
                title: paperTitles[i],
                numberOfFigures: numberOfFigures[i],
                numberOfExtractedClaims: numberOfExtractedClaims[i],
                id: papersListCurrent[i],
                moment: paperMoments[i],
                timeStamp: paperTimeStamps[i]
            }
            allArrayData.push(item)
        }
        //sort allArrayData on the basis of Figures
        allArrayData.sort((a,b) => {
            if (a.numberOfExtractedClaims !== '?' && b.numberOfExtractedClaims !== '?') {
                return b.numberOfExtractedClaims - a.numberOfExtractedClaims; 
            } else if (a.numberOfExtractedClaims == '?') {
                return 1;
            } else if (b.numberOfExtractedClaims == '?') {
                return -1;
            }
        })
        
        //create a new array for dates, numberOfFigures, confidences, and timeStamps and recreate the state arrays
        let newNumberOfFigures = []
        let newNumberOfExtractedClaims = []
        let newTitles = []
        let newPapersList = []
        let newMoments = []
        let newTimeStamps = []
        for (let i = 0; i < allArrayData.length; i++) {
            newNumberOfFigures.push(allArrayData[i].numberOfFigures)
            newNumberOfExtractedClaims.push(allArrayData[i].numberOfExtractedClaims)
            newTitles.push(allArrayData[i].title)
            newPapersList.push(allArrayData[i].id)
            newMoments.push(allArrayData[i].moment)
            newTimeStamps.push(allArrayData[i].timeStamp)
        }
        
        //all we have to do is set everything to the original list, which is stored when the page is loaded and never changes
        setNumberOfEtractedClaims(newNumberOfExtractedClaims)
        setNumberOfFigures(newNumberOfFigures)
        setPapersListCurrent(newPapersList)
        setPaperTitles(newTitles)
        setPaperMoments(newMoments)
        setPaperTimeStamps(newTimeStamps)
        setSelectorOpen(false)
        setSortBySelector('Extracted Claims')
    }


    const sortPapersByNumberOfFigures = () => {
        //if Figures is already selected, don't do anything.
        if (sortBySelector === 'Figures') {
            setSelectorOpen(false)
            return;
        }

        //combine all data into array of objects for easy sorting.
        let allArrayData = []
        for (let i = 0; i < paperTitles.length; i++) {
            let item = {
                title: paperTitles[i],
                numberOfFigures: numberOfFigures[i],
                numberOfExtractedClaims: numberOfExtractedClaims[i],
                id: papersListCurrent[i],
                moment: paperMoments[i],
                timeStamp: paperTimeStamps[i]
            }
            allArrayData.push(item)
        }

        //sort allArrayData on the basis of Figures
        allArrayData.sort((a,b) => b.numberOfFigures - a.numberOfFigures)

        //create a new array for dates, numberOfFigures, newNumberOfExtractedClaims, and timeStamps and recreate the state arrays
        let newNumberOfFigures = []
        let newNumberOfExtractedClaims = []
        let newTitles = []
        let newPapersList = []
        let newMoments = []
        let newTimeStamps = []

        for (let i = 0; i < allArrayData.length; i++) {
            newNumberOfFigures.push(allArrayData[i].numberOfFigures)
            newNumberOfExtractedClaims.push(allArrayData[i].numberOfExtractedClaims)
            newTitles.push(allArrayData[i].title)
            newPapersList.push(allArrayData[i].id)
            newMoments.push(allArrayData[i].moment)
            newTimeStamps.push(allArrayData[i].timeStamp)
        }
        
        //all we have to do is set everything to the original list, which is stored when the page is loaded and never changes
        setNumberOfEtractedClaims(newNumberOfExtractedClaims)
        setNumberOfFigures(newNumberOfFigures)
        setPapersListCurrent(newPapersList)
        setPaperTitles(newTitles)
        setPaperMoments(newMoments)
        setPaperTimeStamps(newTimeStamps)
        setSelectorOpen(false)
        setSortBySelector('Figures')
    }

    const sortPapersByNewest = (tab) => {
        //if Newest is already selected, don't do anything.
        if (sortBySelector === 'Newest') {
            setSelectorOpen(false)
            return;
        }

        //combine all data into array of objects for easy sorting.
        let allArrayData = []
        for (let i = 0; i < paperTitles.length; i++) {
            let item = {
                title: paperTitles[i],
                numberOfFigures: numberOfFigures[i],
                numberOfExtractedClaims: numberOfExtractedClaims[i],
                id: papersListCurrent[i],
                moment: paperMoments[i],
                timeStamp: paperTimeStamps[i]
            }
            allArrayData.push(item)
        }

        //sort allArrayData on the basis of Figures
        allArrayData.sort((a,b) => {
            if (a.moment.isBefore(b.moment)) {
                return 1; 
            } else {
                return -1;
            }
        })


        //create a new array for dates, numberOfFigures, confidences, and timeStamps and recreate the state arrays
        let newNumberOfFigures = []
        let newNumberOfExtractedClaims = []
        let newTitles = []
        let newPapersList = []
        let newMoments = []
        let newTimeStamps = []
        for (let i = 0; i < allArrayData.length; i++) {
            newNumberOfFigures.push(allArrayData[i].numberOfFigures)
            newNumberOfExtractedClaims.push(allArrayData[i].numberOfExtractedClaims)
            newTitles.push(allArrayData[i].title)
            newPapersList.push(allArrayData[i].id)
            newMoments.push(allArrayData[i].moment)
            newTimeStamps.push(allArrayData[i].timeStamp)
        }
        
        //all we have to do is set everything to the original list, which is stored when the page is loaded and never changes
        setNumberOfEtractedClaims(newNumberOfExtractedClaims)
        setNumberOfFigures(newNumberOfFigures)
        setPapersListCurrent(newPapersList)
        setPaperTitles(newTitles)
        setPaperMoments(newMoments)
        setPaperTimeStamps(newTimeStamps)
        setSelectorOpen(false)
        setSortBySelector('Newest')
    }

    const sortPapersByOldest = (tab) => {
        //if Oldest is already selected, don't do anything.
        if (sortBySelector === 'Oldest') {
            setSelectorOpen(false)
            return;
        }

        //combine all data into array of objects for easy sorting.
        let allArrayData = []
        for (let i = 0; i < paperTitles.length; i++) {
            let item = {
                title: paperTitles[i],
                numberOfFigures: numberOfFigures[i],
                numberOfExtractedClaims: numberOfExtractedClaims[i],
                id: papersListCurrent[i],
                moment: paperMoments[i],
                timeStamp: paperTimeStamps[i]
            }
            allArrayData.push(item)
        }

        //sort allArrayData on the basis of Figures
        allArrayData.sort((a,b) => {
            if (a.moment.isBefore(b.moment)) {
                return -1; 
            } else {
                return 1;
            }
        })

        //create a new array for dates, numberOfFigures, confidences, and timeStamps and recreate the state arrays
        let newNumberOfFigures = []
        let newNumberOfExtractedClaims = []
        let newTitles = []
        let newPapersList = []
        let newMoments = []
        let newTimeStamps = []
        for (let i = 0; i < allArrayData.length; i++) {
            newNumberOfFigures.push(allArrayData[i].numberOfFigures)
            newNumberOfExtractedClaims.push(allArrayData[i].numberOfExtractedClaims)
            newTitles.push(allArrayData[i].title)
            newPapersList.push(allArrayData[i].id)
            newMoments.push(allArrayData[i].moment)
            newTimeStamps.push(allArrayData[i].timeStamp)
        }
        
        //all we have to do is set everything to the original list, which is stored when the page is loaded and never changes
        setNumberOfEtractedClaims(newNumberOfExtractedClaims)
        setNumberOfFigures(newNumberOfFigures)
        setPapersListCurrent(newPapersList)
        setPaperTitles(newTitles)
        setPaperMoments(newMoments)
        setPaperTimeStamps(newTimeStamps)
        setSelectorOpen(false)
        setSortBySelector('Oldest')
    }

    function removeSubstring(originalString) {
        let split = originalString.split('/')
        let theURL = '/' + split[3] + '/' + split[4]
        return theURL;
    }

    function handleClick(event) {
        event.preventDefault();
        let URL = removeSubstring(event.target.href, event.target.baseURI)
        navigate(URL)
    }


    return ( 
        <div>
            <div hidden={props.loggedIn} className="loginNoticeMyPage" ><a className="anchor" href="/Login">
                    Login or create an account</a> and to start tracking your papers.
            </div>
            <div className='center' hidden={!loaded2 || !props.loggedIn}>
                <div className="myClaimsMajorContainer">
                    <div hidden={!props.loggedIn} className='myClaimsTabContainer'>
                        <div onClick={() => {whichTab('Identified Papers')}} 
                        className={whichTabIsIt == 'Identified Papers' ? "myClaimsTabSelected" : "myClaimsTab"}>
                            Identified Papers
                        </div>
                        <div onClick={() => {whichTab('Followed Papers')}} 
                        className={whichTabIsIt == 'Followed Papers' ? "myClaimsTabSelected" : "myClaimsTab"}>
                            Followed Papers
                        </div>
                    </div>
                    <div className='myClaimsWrapperContainer'>
                        <div hidden={!props.loggedIn} className="myClaimsClaimContainer">
                        <div className='addClaimButtonMyClaims'>
                        <h1 className='myHeadersMyClaims'>{whichTabIsIt}</h1>
                        <Tippy content='Identify a paper'>
                            <div className='addDivMyClaims' onClick={() => {navigate('/AddPaper')}}>
                                <svg version="1.1" viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                                    <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path className="color000 svgShape" d="m24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" fill="#fff"/>
                                    </svg>
                                </svg>
                            </div>
                        </Tippy>
                        </div>  
                            <div className={papersListCurrent.length != 0 ? 'sortByDivMyClaimWrapper' : 'hidden'}>
                                <div className='slightSpacerMyClaim'>Sort by:</div>
                                <div className='sortByDivMyPapers'>
                                    <span className='minimumWidthMyClaims'>{sortBySelector}</span>
                                    <span className='tagsSpanMyClaims'>
                                            <svg onClick={() => {openSelector()}} id="tagSVG" xmlns="http://www.w3.org/2000/svg" width="18" 
                                            height="18" viewBox="0 0 24 24">
                                                <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/>
                                            </svg>
                                    </span>
                                    <div className='seletorsDivMyClaims' hidden={!selectorOpen} ref={selectorRef}>
                                        <div className='selectorOptionsMyClaims' onClick={() => {sortPapersByExtractedClaims()}}>
                                            Extracted Claims
                                        </div>
                                        <div className='selectorOptionsMyClaims' onClick={() => {sortPapersByNumberOfFigures()}}>Figures</div>
                                        <div className='selectorOptionsMyClaims' onClick={() => {sortPapersByOldest()}}>Oldest</div>
                                        <div className='selectorOptionsMyClaims' onClick={() => {sortPapersByNewest()}}>Newest</div>
                                    </div>
                                </div>
                            </div>
                            {
                                papersListCurrent.map((paper, index) => {
                                    return (
                                        <div key={index} className={loaded ? 'userClaimWrapperMyClaims' : 'hidden'}>
                                            <div className='userClaimMyClaims'>
                                                <div className='myClaimsAnchorSpan'>
                                                    <a className='ProfileFontBold' href={'/Paper/' + papersListCurrent[index].id} 
                                                    onClick={handleClick}>
                                                        {paperTitles[index]}
                                                    </a>
                                                    <div className='timeStampDivMyClaims'>- {paperTimeStamps[index]}</div>
                                                </div>
                                                <div className='claimStatsWidgetMyPapers'>
                                                    <div className='confidenceLoader'>  <Spinner hidden={loaded} /></div>
                                                    <div hidden={!loaded}>
                                                        <div className='countMyPapers'>
                                                            Claims: {numberOfExtractedClaims[index]} 
                                                        </div>
                                                        <div >
                                                            Figures: {numberOfFigures[index]}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='optionsClaimWidget'>
                                                    <SVGoptionsDotsMyPapers whichTabIsIt={whichTabIsIt}
                                                    user={props.user} id={paper.id} index={index} based={based} papersListFollowed={papersListFollowed}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div className={loaded ? 'hidden' : 'ProfileComponentsSpinnerDiv'}>
                                <Spinner />
                            </div>
                            <div className={whichTabIsIt === 'Identified Papers' && papersListCurrent.length == 0 ? 'noClaimsToShow' : 'hidden'}>
                                You have not identified any papers yet <div className='noClaimSpacer'></div>
                            </div>
                            <div className={whichTabIsIt === 'Followed Papers' && papersListCurrent.length == 0 ? 'noClaimsToShow' : 'hidden'}>
                                You have not followed any papers yet <div className='noClaimSpacer'></div>
                            </div>     
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) 
}

const mapStateToProps = (state, props) => {
    if (state.profModels.length == 1) {
        return { 
            user: state.profModels[0].id,
            userName: state.profModels[0].name,
            tags: state.profModels[0].tags,
            myPapers: state.profModels[0].myPapers,
            loggedIn: true
        }
    } else {
        return {
            user: '',
            userName: '',
            tags: [],
            myPapers: [],
            loggedIn: false,
        };
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    returnPaperInfo: (paper) => dispatch(returnPaperInfo(paper))
})

const ConnectedMyPapers = connect(mapStateToProps, mapDispatchToProps)(myPapers);

export default ConnectedMyPapers;