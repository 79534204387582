// Figures Reducer

const figuresReducerDefaultState = [];
  
  export default (state = figuresReducerDefaultState, action) => {
    switch (action.type) {
      case 'ADD_FIGURE':
        return [
            ...state,
            action.figure
          ];
      case 'REMOVE_FIGURE':
        return state.filter(({ id }) => id !== action.id);

      case 'ADD_CRITICISM':
        return [
          action.figure
        ]
      case 'SET_FIGURE':
        return [
          action.figure
        ];
      case 'DELETE_FIGURE':
        return [
          
        ];
      case 'REJECT_FIGURE':
        return [
          action.figure
        ];
      default:
          return state;
    }
  };
  