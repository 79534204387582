const profsReducerDefaultState = [];
  
  export default (state = profsReducerDefaultState, action) => {
    switch (action.type) {
      case 'ADD_PROFILE':
        return [
            action.profile
          ];
      case 'REMOVE_PROFILE':
        return state.filter(({ id }) => id !== action.id);
      case 'SET_PROFILE':
        return [
          action.profile
        ];
      default:
          return state;
    }
  };