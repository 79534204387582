import * as firebase from './firebase'
import { getFirestore, collection, getDocs, setDoc, doc, deleteDoc, updateDoc, onSnapshot, unsubscribe } from 'firebase/firestore'
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes } from 'firebase/storage'
import 'firebase/storage'
import { getAuth } from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA4r_lrUt6fP6oV-pXXpGJFSZOcgYP42eQ",
  authDomain: "journalclub-2eccb.firebaseapp.com",
  databaseURL: "https://journalclub-2eccb-default-rtdb.firebaseio.com",
  projectId: "journalclub-2eccb",
  storageBucket: "journalclub-2eccb.appspot.com",
  messagingSenderId: "878711586165",
  appId: "1:878711586165:web:6dea73fe21095ea728a73c"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)


const storage = getStorage(app)
const db = getFirestore()

const figureCollection = collection(db, 'Figures')
const profileCollection = collection(db, 'Profiles')
const claimCollection = collection(db, 'Claims')
const paperCollection = collection(db, 'Papers')
const theoryCollection = collection(db, 'Theories')
const helpRequestCollection = collection(db, 'HelpRequests')
const reportedIssuesCollection = collection(db, 'ReportedIssues')



console.log('firebase data fetched')





//Make sure to export collections and other firebase info in order to access it.

export { app, db, storage, figureCollection, profileCollection, claimCollection, theoryCollection, paperCollection, auth, helpRequestCollection,
    reportedIssuesCollection }























// export default () => {
//     const docRef = doc(db, 'Testing', 'genericDocID');
//     console.log('first call to FireBase')
//     getDocs(colRef).then((snapshot) => {
//         console.log('this is the snapshot')
//         let data = []
//         snapshot.docs.forEach((doc) => {
//             console.log(doc.data())
//         })
//         console.log(data)
//     }).catch((error) => {
//         console.log('Cannot connect to Firebase: ' + error)
//     })

//     const handleNew = async () => {
        
//         const payload = {
//             id: 'kevin',
//             age: 18,
//             codename: 'Killa'
//         }
//         await setDoc(docRef, payload)
//     }

//     const deleteIt = async () => {
       
//         await deleteDoc(docRef)
//     }


//     const hello2 = onSnapshot(doc(db, 'Testing', 'Cool'), (snapshot) => {
//         console.log(snapshot.data())
//     })

//     // hello2()

//     // handleNew()

// }

