import React from "react";

function loadingScreen (props) {
    

    
    return (
        <div hidden={props.noReload || props.FigureThere} className="whiteBackground">
            <div className='loadingScreen'>
                Outwit
            </div>
            <div className='loadingBarParent'>
                <div className='block1'></div>
                <div className='block2'></div>
                <div className='block3'></div>
                <div className='block4'></div>
                <div className='block5'></div>
                <div className='block6'></div>
                <div className='block7'></div>
            </div>
        </div>
    )
}



export default loadingScreen