import React, {useState} from "react";
import { connect } from 'react-redux'
import { startDisconnectFigure } from '../actions/paperModels';
import { startDisconnectClaim } from '../actions/paperModels';
import Spinner from "react-svg-spinner";

function DeleteModal (props) {
    const [loaded, setLoaded] = useState(true)

    const disconnect = () => {
        //If the button was already clicked, dont do anything
        if (!loaded) {
            return;
        }
        setLoaded(false)
        if (props.type === 'figure') {
            props.startDisconnectFigure(props.figureID, props.paperID, props.user).then(() => {
                props.based(props.index)
                props.closeModal(false)
            }).catch(() => {
                // console.log('Figure did not disconnect')
            })
        }
        if (props.type === 'claim') {
            props.startDisconnectClaim(props.claimID, props.paperID, props.user).then(() => {
                props.based(props.index)
                props.closeModal(false)
            }).catch(() => {
                // console.log('Claim did not disconnect')
            })
        }
    }
    
    return (
        <div>
            <div onClick={() => {props.closeModal(false)}} className="blackBackground"></div>
            <div className='deleteModal'>
                <div className="deleteFlex">
                    <h1>Disconnect?</h1>
                    <svg onClick={() => {props.closeModal(false)}} className="deleteX" version="1.1" viewBox="0 0 288 288" 
                    xmlns="http://www.w3.org/2000/svg">
                        <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path className="color000 svgShape" d="m24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" 
                            fill="#002c2c"/>
                        </svg>
                    </svg>
                </div>
                <div className='withinDelete'>
                    <div>Are you sure you would like to disconnect this {props.type}?</div>
                </div>
                <div className='deleteButtonMyClaimWrapper'>
                    <button className={loaded ? "deleteButtonPaper": 'deleteButtonPaperClicked'} onClick={disconnect}>
                        <div hidden={!loaded}>Disconnect</div>
                        <Spinner hidden={loaded} />
                    </button>
                </div>    
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch, props) => ({
    startDisconnectFigure: (figureID, paperID, user) => dispatch(startDisconnectFigure(figureID, paperID, user)),
    startDisconnectClaim: (claimID, paperID, user) => dispatch(startDisconnectClaim(claimID, paperID, user))
})

const DeleteModalConnected = connect(undefined, mapDispatchToProps)(DeleteModal);


export default DeleteModalConnected