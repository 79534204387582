import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from "react-router-dom"
import { startSetProfile } from '../actions/profModels'
import Modal from './FigureShowModal'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import SVGoptionsDots from './SVGs/SVGoptionsDots';
import { history } from '../routers/AppRouter'
import useWindowDimensions from '../custom hooks/windowDimensions'
import { returnClaimInfo } from '../actions/claimModels'
import Spinner from "react-svg-spinner";
import moment from 'moment'




export const myFigures = (props) => {
    const [whichTabIsIt, setWhichTabIsIt] = useState('Created Claims')
    const [percentageConfidences, setPercentageConfidences] = useState([])
    const [claimTitles, setClaimTitles] = useState([])
    const [claimEngagements, setClaimEngagements] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [claimsListCreated, setClaimsListCreated] = useState(props.myClaims.createdClaims)
    const [claimsListFollowed, setClaimsListFollowed] = useState(props.myClaims.followedClaims)
    const [claimsListPaper, setClaimsListPaper] = useState(props.myClaims.paperClaims)
    const [claimsListFigure, setClaimsListFigure] = useState(props.myClaims.figureClaims)
    const [mostRecentCreated, setMostRecentCreated] = useState(props.myClaims.createdClaims)
    const [mostRecentFollowed, setMostRecentFollowed] = useState(props.myClaims.createdClaims)
    const [mostRecentPapers, setMostRecentPapers] = useState(props.myClaims.createdClaims)
    const [mostRecentFigures, setMostRecentFigures] = useState(props.myClaims.createdClaims)
    const [claimsListCurrent, setClaimsListCurrent] = useState(props.myClaims.createdClaims)
    const [sortBySelector, setSortBySelector] = useState('Most Recent')
    const [selectorOpen, setSelectorOpen] = useState(false)
    const [redo, setRedo] = useState(false)
    let selectorRef = useRef();


    let loaded2;
    if (props.myClaims != []) {
        loaded2 = false
    } else {
        loaded2 = true
    }
    
    // let noReload;
    // if (history.location.pathname != window.location.pathname) {
    //     noReload = false;
    //     window.location.reload(false);
    // } else {
    //     noReload = true;
    // }

    const whichTab = (tab) => {
        setLoaded(false)
        if (tab === 'Created Claims') {
            setClaimsListCurrent(claimsListCreated)
            setSortBySelector('Most Recent')
        } else if (tab === 'Followed Claims') {
            setClaimsListCurrent(claimsListFollowed)
            setSortBySelector('Most Recent')
        } else if (tab === 'Claims from my papers') {
            setClaimsListCurrent(claimsListPaper)
            setSortBySelector('Most Recent')
        } else if (tab === 'Claims from my figures') {
            setClaimsListCurrent(claimsListFigure)
            setSortBySelector('Most Recent')
        }
        setWhichTabIsIt(tab)
    }
   
    // useEffect(() => {
    //     let handler = (event) => {
    //         if(!selectorRef.current.contains(event.target)) {
    //             setSelectorOpen(false)
    //         }
    //     }
    //     document.addEventListener("mousedown", handler)
    //     return () => {
    //         document.removeEventListener("mousedown", handler)
    //     }
    // })

    useEffect(async () => {
        let thePercentageConfidences = []
        let theTitles = []
        let theEngagements = []

        for (const claim of claimsListCurrent) {
            let claimInfo = await props.returnClaimInfo(claim)
            theTitles.push(claimInfo.title)
            theEngagements.push(claimInfo.engagementNumber)
            if (isNaN(claimInfo.confidence)) {
                thePercentageConfidences.push('?')
            } else {
                thePercentageConfidences.push(claimInfo.confidence)
            }
        }
        
        setPercentageConfidences(thePercentageConfidences)
        setClaimTitles(theTitles)
        setClaimEngagements(theEngagements)
        setLoaded(true)

    }, [whichTabIsIt, redo])

    const openSelector = () => {
        setSelectorOpen(!selectorOpen)
    }

    const sortClaimsByConfidence = () => {
        //if Confidence is already selected, don't do anything.
        if (sortBySelector === 'Confidence') {
            setSelectorOpen(false)
            return;
        }
        
        //combine all data into array of objects for easy sorting.
        let allArrayData = []
        for (let i = 0; i < claimTitles.length; i++) {
            let item = {
                title: claimTitles[i],
                engagements: claimEngagements[i],
                confidence: percentageConfidences[i],
                id: claimsListCurrent[i]
            }
            allArrayData.push(item)
        }

        //sort allArrayData on the basis of Engagement
        allArrayData.sort((a,b) => b.confidence - a.confidence)

        //create a new array for dates, engagements, and confidences and recreate the state arrays
        let newEngagements = []
        let newConfidences = []
        let newTitles = []
        let newClaimsList = []


        for (let i = 0; i < allArrayData.length; i++) {
            newEngagements.push(allArrayData[i].engagements)
            newConfidences.push(allArrayData[i].confidence)
            newTitles.push(allArrayData[i].title)
            newClaimsList.push(allArrayData[i].id)
        }

        setClaimEngagements(newEngagements)
        setPercentageConfidences(newConfidences)
        setClaimTitles(newTitles)
        setClaimsListCurrent(newClaimsList)
        setSortBySelector('Engagement')
        setSelectorOpen(false)
        

        setSortBySelector('Confidence')
        setSelectorOpen(false)
    }

    const sortClaimsByEngagement = () => {
        //if Engagement is already selected, don't do anything.
        if (sortBySelector === 'Engagement') {
            setSelectorOpen(false)
            return;
        }

        //combine all data into array of objects for easy sorting.
        let allArrayData = []
        for (let i = 0; i < claimTitles.length; i++) {
            let item = {
                title: claimTitles[i],
                engagements: claimEngagements[i],
                confidence: percentageConfidences[i],
                id: claimsListCurrent[i]
            }
            allArrayData.push(item)
        }

        //sort allArrayData on the basis of Engagement
        allArrayData.sort((a,b) => b.engagements - a.engagements)

        //create a new array for dates, engagements, and confidences and recreate the state arrays
        // let newDates = []
        let newEngagements = []
        let newConfidences = []
        let newTitles = []
        let newClaimsList = []


        for (let i = 0; i < allArrayData.length; i++) {
            newEngagements.push(allArrayData[i].engagements)
            newConfidences.push(allArrayData[i].confidence)
            newTitles.push(allArrayData[i].title)
            newClaimsList.push(allArrayData[i].id)
        }

        setClaimEngagements(newEngagements)
        setPercentageConfidences(newConfidences)
        setClaimTitles(newTitles)
        setClaimsListCurrent(newClaimsList)
        setSortBySelector('Engagement')
        setSelectorOpen(false)
    }

    const sortClaimsByMostRecent = () => {
        //if Most Recent is already selected, don't do anything.
        if (sortBySelector === 'Most Recent') {
            setSelectorOpen(false)
            return;
        }
        
        //all we have to do to get the 
        setSortBySelector('Most Recent')
        setSelectorOpen(false)
    }

    const changeRedux = () => {
        props.changeRedux()
    }
    return ( 
        <div className="theoryMajorContainer" >
            <div className="loginAbout" >
                <div hidden={props.loggedIn}>Create an account<a className="anchorAbout" href="/Login">here</a></div>
            </div>
            <div className={props.loggedIn ? 'centerWrapperAbout' : 'hidden'}>
                <div className="containerMyFigures">
                <br/>
                    <br/>
                    <h1>myProfile page coming soon...</h1>
                    <br/>
                </div>
            </div>
        </div>
    ) 
}

const mapStateToProps = (state, props) => {
    if (state.profModels.length == 1) {
        return { 
            user: state.profModels[0].id,
            userName: state.profModels[0].name,
            tags: state.profModels[0].tags,
            myClaims: state.profModels[0].myClaims,
            loggedIn: true
        }
    } else {
        return {
            user: '',
            userName: '',
            tags: [],
            myClaims: [],
            loggedIn: false,
        };
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    returnClaimInfo: (claim) => dispatch(returnClaimInfo(claim))
})

const ConnectedMyFigures = connect(mapStateToProps, mapDispatchToProps)(myFigures);

export default ConnectedMyFigures;