import React, {useState} from "react";
import { useNavigate } from "react-router-dom"
import { connect } from 'react-redux'
import { startDeleteAccount } from '../actions/profModels';  
import Spinner from "react-svg-spinner";
import { deleteUser } from "firebase/auth";

function DeleteModal (props) {
    const navigate = useNavigate()
    const [loaded, setLoaded] = useState(true)
    const deleteAccount = async () => {
        setLoaded(false)
        try {
            await deleteUser(props.userToDelete)
            await props.startDeleteAccount(props.userToDelete.uid)
            navigate('/')
        } catch (e) {
            console.log(e)
        }
    }
    
    return (
        <div>
            <div onClick={() => {props.closeModal(false)}} className="blackBackground"></div>
            <div className='deleteModal'>
                <div className="deleteFlex">
                    <h1>Delete?</h1>
                    <svg onClick={() => {props.closeModal(false)}} className="deleteX" version="1.1" viewBox="0 0 288 288" 
                    xmlns="http://www.w3.org/2000/svg">
                        <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path className="color000 svgShape" d="m24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" 
                            fill="#002c2c"/>
                        </svg>
                    </svg>
                </div>
                <div className='withinDelete'>
                    <div>Are you sure you would like to delete your account?</div>
                </div>
                <div className='deleteButtonMyClaimWrapper'>
                    <button className={loaded ? "deleteButtonMyClaim": 'deleteButtonMyClaimClicked'} onClick={deleteAccount}>
                        <div hidden={!loaded}>Delete</div>
                        <Spinner hidden={loaded} />
                    </button>
                </div>    
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch, props) => ({
    startDeleteAccount: (id) => dispatch(startDeleteAccount(id))
})

const DeleteModalConnected = connect(undefined, mapDispatchToProps)(DeleteModal);


export default DeleteModalConnected