import React from 'react'
import ContentEditable from 'react-contenteditable'
import { ClaimReference } from './ClaimReference';

class MyComponent extends React.Component {
  constructor(props) {
    super(props)
    this.contentEditable = React.createRef();
    // this.state = {html: this.props.theText.map((theElement) => {
    //     if (typeof theElement == "string") {
    //         return (theElement)
    //     } else {
    //         return ("<div className='claimReference' contenteditable='false'></div>")
    //     }
    // })
    this.state = {html: "<div style='color:blue;text-align:center background-color: aqua; height: 70px;' >Maybe?</div>" }
    };
  

  handleChange = evt => {
    this.setState({html: evt.target.value});
  };

  render = () => {
    return <ContentEditable
            id="theTheoryDiv"
              innerRef={this.contentEditable}
              html={this.state.html} // innerHTML of the editable div
              disabled={false}       // use true to disable editing
              onChange={this.handleChange} // handle innerHTML change
              tagName='article' // Use a custom HTML tag (uses a div by default)
            />
  };
};

export default MyComponent;

