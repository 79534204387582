import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom"
import { connect } from 'react-redux'
import { startDeletePaper, startRemovePaperFollow } from '../actions/paperModels';
import { ref, deleteObject } from 'firebase/storage'
import { storage } from '../firebase/firebase'
import { history } from '../routers/AppRouter'
import Spinner from "react-svg-spinner";


function DeleteModal (props) {

    const [message, setTheMessage] = useState('')
    const [message2, setTheMessage2] = useState('')
    const [loaded, setLoaded] = useState(true)

    useEffect(() => {
        if (props.whichTabIsIt === 'Identified Papers') {
            setTheMessage('Are you sure you would like to delete this Paper?')
            setTheMessage2('Delete')
        } else if (props.whichTabIsIt === 'Followed Papers') {
            setTheMessage('Are you sure you would like to unfollow this Paper?')
            setTheMessage2('Unfollow')
        } 
    })

    //This action is called can tells the based function to either delete or unfollow the paper.
    const action = async (index) => {
        try {
            //If the button was already clicked, dont do anything
            if (!loaded) {
                return;
            }
            setLoaded(false)
            //If we are deleting a paper, first check to see if it is in the followedPapers list
            //If it is in the list, remove the user's ID from it.
            if (message2 === 'Delete') {
                if (props.papersListFollowed.includes(props.id)) {
                    await props.startRemovePaperFollow(props.user, props.id)
                }
                
                //Lastly, call the startDeletePaper method from figModels with the paperID.
                props.startDeletePaper(props.id, props.user).then(() => {
                    //Next remove the element from the array list on the parent state by calling the based method and passing 
                    //in the correct index and paper id.
                    props.closeModal(false)
                    props.based(index, props.id)
                }).catch((err) => {
                    // console.log('Unable to delete the claim')
                })
            } 
            //If we are just unfollowing a paper, call the reverseAcceptFigure method from figModels with the userID and paperID.
            if (message2 === 'Unfollow') {
                props.startRemovePaperFollow(props.user, props.id).then(() => {
                    props.closeModal(false)

                    //Next remove the element from the array list on the parent state by calling the based method and passing 
                    //in the correct index and claim id.
                    props.based(index, props.id)
                }).catch((err) => {
                    // console.log('Unable to unfollow the paper')
                })
            } 

        } catch(err) {
            // console.log('error deleting paper')
        } 
    }
    
    return (
        <div>
            <div onClick={() => {props.closeModal(false)}} className="blackBackground"></div>
            <div className='deleteModal'>
                <div className="deleteFlex">
                    <h1>{message2}?</h1>
                    <svg onClick={() => {props.closeModal(false)}} className="deleteX" version="1.1" viewBox="0 0 288 288" 
                    xmlns="http://www.w3.org/2000/svg">
                        <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path className="color000 svgShape" 
                            d="m24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" 
                            fill="#002c2c"/>
                        </svg>
                    </svg>
                </div>
                <div className='withinDelete'>
                    <div>{message}</div>
                </div>
                <div className='deleteButtonMyClaimWrapper'>
                    <button className={loaded ? "deleteButtonMyFigure": 'deleteButtonMyFigureClicked'} onClick={() => {action(props.index)}} >
                        <div hidden={!loaded}>{message2}</div>
                        <Spinner hidden={loaded} />
                    </button>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch, props) => ({
    startDeletePaper: (id, theOwner) => dispatch(startDeletePaper(id, theOwner)),
    startRemovePaperFollow: (user, paperID) => dispatch(startRemovePaperFollow(user, paperID))
})

const DeleteModalConnected = connect(undefined, mapDispatchToProps)(DeleteModal);


export default DeleteModalConnected