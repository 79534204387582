import React from "react";
import { connect } from 'react-redux'
import { startDeleteCriticism } from '../actions/claimModels'; 

function DeleteModal (props) {
    
    const deleteCriticism = async () => {
        let newArray = [...props.sortedArray];
        let newIndexes = [...props.sortedIndices];
        let value = props.sortedIndices[props.critIndex]
        newArray.splice(props.critIndex, 1)
        newIndexes.splice(props.critIndex, 1)

        let updatedIndexes = [];
        newIndexes.forEach((index) => {
            if (index > value) {
                updatedIndexes.push(index-1)
            } else {
                updatedIndexes.push(index)
            }
        })
        
        props.criticismRemoved(true)
        //Here all engagements from the upvotes and downvotes of that particular criticism being removed from the total engagement of the Claim.
        props.setTotalEngagement(prev => props.removeEngagement([...prev], [props.critEngagement, ...props.upVotes, ...props.downVotes]))

        //Here the criticism is deleted in Firebase.
        const deleteIt = await props.startDeleteCriticism(props.figType, props.claimID, props.user, props.occurrence, props.figid)
        props.setSortedArray(newArray)
        props.setSortedIndices(updatedIndexes)
    }

    return (
        <div>
            <div onClick={() => {props.closeModal(false)}} className="blackBackground"></div>
            <div className='deleteModal'>
                <div className="deleteFlex">
                    <h1 className="deleteModalClaimCriticism">Delete?</h1>
                    <svg onClick={() => {props.closeModal(false)}} className="deleteX" version="1.1" viewBox="0 0 288 288" 
                    xmlns="http://www.w3.org/2000/svg">
                        <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path className="color000 svgShape" d="m24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" 
                            fill="#002c2c"/>
                        </svg>
                    </svg>
                </div>
                <div className='withinDelete'>
                    <div>Are you sure you would like to delete this criticism?</div>
                </div>
                <button className="deleteButton" onClick={() => {deleteCriticism()}} >Delete</button>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch, props) => ({
    startDeleteCriticism: (critIndex, figType, fellowID, occurrence, figid) => dispatch(startDeleteCriticism(critIndex, figType, fellowID, occurrence, figid))
})

const DeleteModalConnected = connect(undefined, mapDispatchToProps)(DeleteModal);


export default DeleteModalConnected